import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import styles from "../../StudioLeftSidebarMenu.module.scss";
import classNames from "classnames";
import SubMenuOption from "../../SubMenuOption";

const SideMenuElements = (props) => {
  const { partDefinitionName, elements = [], mainElement, productName } = props;
  const { t } = useTranslation();

  const mainElementClassNames = useMemo(() => {
    if (mainElement.isSelected) {
      return classNames(
        styles.blankBoxTop,
        styles.blankBoxSelectable,
        styles.blankBoxSelected
      );
    } else {
      return classNames(styles.blankBoxTop, styles.blankBoxSelectable);
    }
  }, [mainElement.isSelected]);

  //Main element is clickable and on click it expands it children? as well as show the current elements for the design?
  return (
    <li className={styles.sideNavItem}>
      <div
        className={mainElementClassNames}
        onClick={() => {
          mainElement.onClick();
        }}
      >
        <div className={styles.labelWithMenuContainer}>
          <div className={styles.labelWithIconContainer}>
            {mainElement?.display?.displayMethod === "icon" &&
              mainElement?.display?.menuIconUrl && (
                <div className={styles.iconContainer}>
                  <img alt="iconUrl" src={mainElement.display.menuIconUrl} />
                </div>
              )}
            <span className={styles.specsLabel}>
              {t(`specs.${productName}.${partDefinitionName}`)}
            </span>
            {mainElement &&
              mainElement.deprecatedDesignItems &&
              mainElement.deprecatedDesignItems.length !== 0 && (
                <span className={styles.deprecatedValueDot}></span>
              )}
            {mainElement &&
              mainElement.colorBleedingItems &&
              mainElement.colorBleedingItems.length !== 0 && (
                <span className={styles.colorBleedDot}></span>
              )}
          </div>
        </div>
      </div>
      <ul className={styles.sideNavSecondLevel}>
        {elements.map(
          (o) => o.display.displayable && <SubMenuOption {...o} key={o.name} />
        )}
      </ul>
    </li>
  );
};

export default SideMenuElements;
