import { Typography } from "@mui/material";
import { Box, SxProps, Theme } from "@mui/system";
import { Button } from "../Button";
import { useTranslation } from "react-i18next";
import { palette } from "../../../styles/theme";
import { ROUTES } from "../../../utils/Constant";
import { useNavigate } from "react-router-dom";

const styles: Record<string, SxProps<Theme>> = {
  container: {
    minWidth: "100vw",
    minHeight: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  message: {
    fontWeight: 600,
    color: palette.blue.primary,
  },
};

export const ErrorFallback = ({ error }: any) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Box role="alert" sx={styles.container} gap={6}>
      <Typography variant="h3" sx={styles.message}>
        Something went wrong
      </Typography>
      <Button onClick={() => navigate(ROUTES.HOME)}>
        {t("general.backToHome")}
      </Button>
    </Box>
  );
};
