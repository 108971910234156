import classNames from "classnames";
import styles from "./Link.module.scss";

import { LinkProps, Link as RRDLink } from "react-router-dom";

interface Props extends LinkProps {
  smallFont?: boolean;
}

export const Link = ({ smallFont, ...props }: Props) => {
  return (
    <RRDLink
      {...props}
      className={classNames(props.className, styles.link, {
        [styles.smallFont]: smallFont,
      })}
    />
  );
};
