import { OrderDetail } from "../../types";
import { Action } from "../../types/redux";

export enum MY_ORDERS_ACTION_ENUM {
  UPDATE_ORDERS = "update_orders",
  UPDATE_LOADING = "update_orders_loading",
}

interface MyOrdersPayload {
  orders: OrderDetail[];
}

const initialState = {
  orders: [],
  loading: false,
  loaded: false,
};

const reducer = (
  state = initialState,
  action: Action<MY_ORDERS_ACTION_ENUM, MyOrdersPayload>
) => {
  switch (action.type) {
    case MY_ORDERS_ACTION_ENUM.UPDATE_ORDERS:
      return { orders: action.payload, loading: false, loaded: true };
    case MY_ORDERS_ACTION_ENUM.UPDATE_LOADING:
      return { ...state, loading: true, loaded: false };
    default:
      return state;
  }
};

export default reducer;
