import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Autocomplete } from "../../common/components/Autocomplete";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitleWithCloseButton from "../DialogTitleWithCloseButton/DialogTitleWithCloseButton";

import { Typography } from "@mui/material";
import { Box, Button } from "../../common/components";
import TeamService from "../../service/TeamService";
import { spacing } from "../../styles/theme";
import { FlattenTeam, Team } from "../../types";
import { useThemeBreakpoints } from "../../utils/themeUtils";

interface Props {
  open: boolean;
  onClickClose: () => void;
  onAddToTeam: (teamId: string) => void;
  myTeams: Team[];
}

const SearchTeamsModal = (props: Props) => {
  const { open, onClickClose, onAddToTeam, myTeams } = props;
  const { t } = useTranslation();
  const { isMobile } = useThemeBreakpoints();

  const [selectedTeam, setSelectedTeam] = useState<FlattenTeam | null>(null);

  const onSelection = () => {
    onAddToTeam(selectedTeam?.teamId ?? "");
    onClickClose();
    setSelectedTeam(null);
  };

  const onClose = () => {
    onClickClose();
    setSelectedTeam(null);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth={true}
      fullScreen={isMobile}
      maxWidth={"md"}
    >
      <DialogTitleWithCloseButton
        id="customized-dialog-title"
        onClose={onClose}
        sx={{ mb: 2 }}
      >
        {t("search.search_team")}
      </DialogTitleWithCloseButton>
      <DialogContent>
        <Box
          display="flex"
          flexDirection="column"
          gap={{ xs: spacing.regularMobile, lg: spacing.regularDesktop }}
        >
          <Box>
            <Autocomplete
              id="teamSearchInput"
              value={selectedTeam}
              clearIcon={false}
              onChange={(event, newValue) => {
                setSelectedTeam(newValue);
              }}
              apiCall={TeamService.searchTeams}
              groupBy={(team) =>
                team?.first_name + " " + team?.last_name + " " + team?.email
              }
              getOptionLabel={(team) =>
                team?.name +
                ",  " +
                team?.first_name +
                " " +
                team?.last_name +
                " " +
                team?.email
              }
              getOptionDisabled={
                (team) => myTeams.some((myTeam) => myTeam?.id === team?.teamId) //Disabled if already joined
              }
              renderOption={(props, team) => {
                return (
                  <li {...props} key={team?.teamId + team?.userId}>
                    {team?.name}
                  </li>
                );
              }}
              label={t("general.search")}
            />
          </Box>
          {isMobile && (
            <Button
              onClick={onSelection}
              disabled={selectedTeam === null}
              fullWidth
            >
              <Typography variant="actionBoldBigIcon">
                {t("search.add_to_team")}
              </Typography>
            </Button>
          )}
        </Box>
      </DialogContent>
      {!isMobile && (
        <DialogActions>
          <Button onClick={onSelection} disabled={selectedTeam === null}>
            <Typography variant="actionBoldBigIcon">
              {t("search.add_to_team")}
            </Typography>
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default SearchTeamsModal;
