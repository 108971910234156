import React from "react";
import styles from "./button.module.scss";

// export const ButtonStyles = Object.freeze({
//   BLUE: "blue",
//   BLACK: "black",
// });

const Button = (props) => {
  const {
    onClick,
    text,
    disabled = false,
    // TODO: Make this generic and use it everywhere
    // iconBeforeText = null,
    // iconAfterText = null,
    // buttonStyle= ButtonStyles.BLUE,
  } = props;

  return (
    <React.Fragment>
      <button
        className={disabled ? styles.buttonDisabled : styles.button}
        onClick={onClick}
        disabled={disabled}
      >
        {text}
      </button>
    </React.Fragment>
  );
};

export default Button;
