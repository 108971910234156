import { createStyles } from "@mui/styles";
import { palette } from "../../../styles/theme";
import { Box, BoxProps } from "./Box";
const PaddingX = "20px";
const PaddingY = "12px";

const gradientContainerStyles = {
  background: palette.borderGradient,
  px: `calc(${PaddingX} - 1px)`,
  py: `calc(${PaddingY} - 1px)`,
  border: "2px solid transparent",
  ":hover": {
    background: `linear-gradient(90deg, rgba(36, 132, 186, 0.04) 0%, rgba(169, 84, 222, 0.04) 100%),
    ${palette.borderGradient}`,
    border: "2px solid transparent",
  },
};

const styles = createStyles({
  container: {
    borderRadius: "12px",
    cursor: "pointer",
    border: `1px solid ${palette.grey.darkGrey}`,
    px: PaddingX,
    py: PaddingY,
    "&:hover": {
      background: `linear-gradient(90deg, rgba(36, 132, 186, 0.04) 0%, rgba(169, 84, 222, 0.04) 100%),
      ${palette.borderGradientHover}`,
      border: "1px solid transparent",
    },
    "&:active": gradientContainerStyles,
  },
  gradientContainer: gradientContainerStyles,
});

interface Props extends BoxProps {
  showGradient?: boolean;
}

export const GradientBorderedBox = ({
  showGradient = true,
  sx,
  ...props
}: Props) => {
  return (
    <Box
      {...props}
      sx={[
        styles.container,
        showGradient ? styles.gradientContainer : null,
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    />
  );
};
