import { Skeleton, Typography } from "@mui/material";
import { t } from "i18next";
import { memo, useEffect, useState } from "react";
import { Box } from "../../common/components";
import DesignService from "../../service/DesignService";
import DesignStateService from "../../service/DesignStateService";
import { Styles, spacing } from "../../styles/theme";
import { DesignFromDB, DesignStateWithProductName } from "../../types";
import { useGlobalLoading } from "../../utils/ContextHooks";
import { DesignCard } from "../Design/DesignCard";
import {
  DESIGN_CARD_DESKTOP_MIN_WIDTH,
  DESIGN_CARD_MOBILE_MIN_WIDTH,
} from "../Design/DesignCard/DesignCard";
import DesignCardSkeleton from "../Design/DesignCard/DesignCardSkeleton";

const styles: Styles = {
  container: {
    mt: { xs: spacing.groupingMobile, lg: spacing.groupingDesktop },
    display: "grid",
    flexWrap: "wrap",
    gridTemplateColumns: {
      xs: `repeat(auto-fill,minmax(${DESIGN_CARD_MOBILE_MIN_WIDTH}, 1fr))`,
      lg: `repeat(auto-fill,minmax(${DESIGN_CARD_DESKTOP_MIN_WIDTH}, 1fr))`,
    },
    gap: {
      xs: `${spacing.regularMobile} ${spacing.groupingMobile}`,
      lg: `${spacing.regularDesktop} ${spacing.groupingDesktop}`,
    },
  },
};

const RecentJams = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [designs, setDesigns] = useState<DesignStateWithProductName[]>([]);
  const { isGloballyLoading } = useGlobalLoading();
  const getRecentJams = async (hideLoading?: boolean) => {
    !hideLoading && setIsLoading(true);
    const designFromDb = (await DesignService.getRecentDesigns()).data
      .data as DesignFromDB[];
    const imagesFromGenerator = (await DesignStateService.getDesignsImages(
      designFromDb?.map((d) => d.id)
    )) as { imagesUrl: string[]; latestImageTimestamp: number }[];

    const mergedDesigns = designFromDb?.map((d, index) => ({
      ...d,
      images: imagesFromGenerator[index].imagesUrl.map((url: string) => ({
        url,
        side: "",
      })),
    })) as DesignStateWithProductName[];
    setDesigns(mergedDesigns ?? []);
    !hideLoading && setIsLoading(false);
  };

  useEffect(() => {
    getRecentJams();
  }, []);

  return (
    <>
      <Typography variant="title">
        {isGloballyLoading ? (
          <Skeleton variant="text" width={150} />
        ) : (
          t("HomePage.recent_jams.title")
        )}
      </Typography>
      <Box sx={styles.container}>
        {isLoading || isGloballyLoading ? (
          <DesignCardSkeleton />
        ) : (
          designs.map((design) => (
            <DesignCard
              key={design.id}
              design={design}
              onUpdate={getRecentJams}
              setIsLoading={setIsLoading}
            />
          ))
        )}
      </Box>
    </>
  );
};

export default memo(RecentJams);
