import React, { useMemo } from "react";
import { useTranslation, Trans } from "react-i18next";
import { useCurrency } from "../../../utils/Currencies";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import IconButton from "@mui/material/IconButton";
import styles from "./CartItemPreview.module.scss";
import ExtraSizeModal from "../../../containers/SizeSelectorContainer/ExtraSizeModal/ExtraSizeModal";
import config from "../../../config";

const CartItemPreview = (props) => {
  const [openSizeModal, setOpenSizeModal] = React.useState(false);
  const { t } = useTranslation();
  const { NarrowCurrencyFormatter } = useCurrency();
  const { pricing, designName, designState } = props;
  const onOpenSizeModalClick = () => {
    setOpenSizeModal(true);
  };
  const closeOpenSizeModalClick = () => {
    setOpenSizeModal(false);
  };

  const deprecatedDesign = useMemo(() => {
    return (
      (designState?.design?.deprecated &&
        config.blockPaymentWhenDesignIsDeprecated) ||
      false
    );
  }, [designState]);

  return (
    <div className={styles.container}>
      <div className={styles.imgContainer}>
        <img
          alt="Design"
          src={designState.images[0].url}
          className={styles.img}
        />
      </div>
      <div className={styles.description}>
        <div className={styles.designName}>
          <span>
            <b>{designName || t("design.untitled_design")}</b>
          </span>
        </div>
        <div className={styles.priceLine}>
          <div className={styles.leftOfPriceLine}>
            <div>
              {deprecatedDesign && <div>{t("design.modify_to_purchase")}</div>}
              {!deprecatedDesign &&
                pricing.hasMinimum &&
                pricing.hasMinimumPerSize &&
                !pricing.hasReachedMaximum && (
                  <div>{`${pricing.qty} x ${NarrowCurrencyFormatter.format(
                    pricing.pricePerUnit
                  )}`}</div>
                )}
              {!deprecatedDesign && !pricing.hasMinimum && (
                <div>
                  {t("checkout.minimum_qty", { minQty: pricing.minQty })}
                </div>
              )}
              {/*TODO: Rendu a tester ici*/}
              {!deprecatedDesign && !pricing.hasMinimumPerSize && (
                <div>
                  {t("checkout.minimum_qty_per_size", {
                    minQtyPerSize: pricing.minQtyPerSize,
                  })}
                </div>
              )}
              {!deprecatedDesign && pricing.hasReachedMaximum && (
                <div>
                  <Trans i18nKey="checkout.goingbig">
                    Contact <a href="mailto:info@jameo.com">info@jameo.com</a>{" "}
                    for a tailored price.
                  </Trans>
                </div>
              )}
            </div>
          </div>
          <div className={styles.rightOfPriceLine}>
            <span>
              {!deprecatedDesign &&
              pricing.hasMinimum &&
              pricing.hasMinimumPerSize &&
              !pricing.hasReachedMaximum
                ? NarrowCurrencyFormatter.format(
                    pricing.priceWithoutExtraSizesNorShipping
                  )
                : t("checkout.tbd")}
            </span>
          </div>
        </div>
        {pricing.priceOfExtraSizes > 0 && (
          <div className={styles.extraPriceLine}>
            <div className={styles.leftOfPriceLine}>
              <span>
                {`${pricing.extraPricePerSize.reduce(
                  (qtySum, extraPriceSizeObj) => qtySum + extraPriceSizeObj.qty,
                  0
                )}
                 x `}
                {t("checkout.extra_sizes")}
                <IconButton
                  onClick={onOpenSizeModalClick}
                  size="small"
                  color="black"
                >
                  <InfoOutlinedIcon fontSize="inherit" />
                </IconButton>
              </span>

              <ExtraSizeModal
                open={openSizeModal}
                includedSizesPerRange={pricing.includedSizesPerRange}
                pricingFromFrag={pricing}
                onClose={closeOpenSizeModalClick}
              />
            </div>
            <div className={styles.rightOfPriceLine}>
              <span>
                {NarrowCurrencyFormatter.format(pricing.priceOfExtraSizes)}
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CartItemPreview;
