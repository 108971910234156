import { useEffect, useMemo, useState } from "react";
import {
  generatePath,
  useNavigate,
  useOutlet,
  useParams,
} from "react-router-dom";
import Modal from "../../components/Modal";
import { ProductModal } from "../../components/ProductModal";

import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { Box, GradientLink } from "../../common/components";
import GradientButton from "../../common/components/Button/GradientButton";
import { usePageTitle } from "../../common/hooks/usePageTitle";
import { useSelector } from "../../common/hooks/useSelector";
import CreatingCanvaModal from "../../components/CreatingCanvaModal";
import {
  DashboardToolbarDesktopHeight,
  DashboardToolbarMobileHeight,
} from "../../components/DashboardToolbar/DashboardToolbar";
import {
  DesignCard,
  DesignCardSkeleton,
} from "../../components/Design/DesignCard";
import {
  DESIGN_CARD_DESKTOP_MIN_WIDTH,
  DESIGN_CARD_MOBILE_MIN_WIDTH,
} from "../../components/Design/DesignCard/DesignCard";
import DesignWithErrorsCard from "../../components/Design/DesignWithErrorsCard";
import DesignService from "../../service/DesignService";
import DesignStateService from "../../service/DesignStateService";
import { Styles, spacing } from "../../styles/theme";
import { DesignFamily, DesignProduct } from "../../types";
import { ROUTES } from "../../utils/Constant";
import { useGlobalLoading } from "../../utils/ContextHooks";
import { useDesignFetcher } from "../../utils/DesignsFetcherHooks";
import {
  getScreenHeightMinusSpacing,
  getScreenWidthMinusSpacing,
} from "../../utils/themeUtils";
import TopSection from "./TopSection/TopSection";

const styles: Styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    pt: { xs: spacing.wrapperMobile, lg: spacing.regularDesktop },
    px: { xs: spacing.wrapperMobile, lg: spacing.largeDesktop },
    pb: { xs: spacing.xlMobile, lg: spacing.xlDesktop },
    maxWidth: {
      lg: getScreenWidthMinusSpacing(
        spacing.largeDesktop,
        spacing.largeDesktop
      ),
    },
    maxHeight: {
      xs: getScreenHeightMinusSpacing(DashboardToolbarMobileHeight),
      lg: getScreenHeightMinusSpacing(DashboardToolbarDesktopHeight),
    },
    overflowY: "auto",
    overflowX: "hidden",
  },
  projectName: {
    mb: { xs: spacing.groupingMobile, lg: spacing.groupingDesktop },
  },
  buttonContainer: {
    mb: { xs: spacing.largeMobile, lg: spacing.largeDesktop },
    gap: { xs: spacing.ctaSpacingMobile, lg: spacing.ctaSpacingDesktop },
    display: "flex",
    alignItems: "center",
  },
  designsContainer: {
    display: "grid",
    gridTemplateColumns: {
      xs: `repeat(auto-fill,minmax(${DESIGN_CARD_MOBILE_MIN_WIDTH}, 1fr))`,
      lg: `repeat(auto-fill,minmax(${DESIGN_CARD_DESKTOP_MIN_WIDTH}, 1fr))`,
    },
    gap: {
      xs: `${spacing.regularMobile} ${spacing.groupingMobile}`,
      lg: `${spacing.regularDesktop} ${spacing.groupingDesktop}`,
    },
  },
};

interface Props {
  showNewDesignModal?: boolean;
}

const Designs = ({ showNewDesignModal = false }: Props) => {
  const { t } = useTranslation();
  const [productPopup, setProductPopup] = useState(showNewDesignModal);
  const { isGloballyLoading } = useGlobalLoading();
  const [creating, setCreating] = useState(false);
  const outlet = useOutlet();

  const navigate = useNavigate();
  const params = useParams();
  const { fetchMyDesignsAndUpdateStore } = useDesignFetcher();
  const projectsState = useSelector((state) => state.projects);

  //Load designs to get the latest
  useEffect(() => {
    fetchMyDesignsAndUpdateStore(params.projectId);
  }, [fetchMyDesignsAndUpdateStore, params.projectId]);

  const projects = useMemo(() => {
    return projectsState?.allProjects;
  }, [projectsState]);

  const projectsLoaded = useMemo(() => {
    return projectsState?.allProjectsLoaded;
  }, [projectsState]);

  const designsReduxState = useSelector((state) => state.designs);
  const designs = useMemo(() => {
    return designsReduxState?.designs;
  }, [designsReduxState]);

  const userInfo = useSelector((state) => state.userInfo);

  const project = useMemo(() => {
    const projectId = params.projectId;
    return projects.find((p) => p.id === projectId);
  }, [params.projectId, projects]);

  usePageTitle(`${t("pages.projects.title", { id: project?.name })}`);

  const projectDesigns = useMemo(
    () => designs.filter((d) => d.project_id === project?.id),
    [designs, project]
  );

  const addDesign = () => {
    setProductPopup(true);
  };

  const onCloseProductPopup = () => {
    setProductPopup(false);
  };

  const createDesign = async (
    family: DesignFamily,
    product: DesignProduct,
    newDesignName: string
  ) => {
    setCreating(true);
    try {
      const res = await DesignStateService.createDesignState({
        family,
        product,
      });

      const {
        data: { designId },
      } = res;

      const projectId = params.projectId;

      //TODO test if success
      await DesignService.addDesign(
        {
          projectId,
          designId,
          name: newDesignName,
        },
        product
      );

      await fetchMyDesignsAndUpdateStore(projectId);

      navigate(generatePath(ROUTES.STUDIO, { designId, projectId }));
    } catch (error) {
      console.error(error);
      setCreating(false);
    }
  };

  //If project is not found, then we go home
  useEffect(() => {
    if (projectsLoaded && !project) {
      toast.warn(t("project.not_found"));
      navigate(ROUTES.HOME);
      return;
    }
  }, [projectsLoaded, project, navigate, t]);

  if (outlet) {
    return outlet;
  }

  return (
    <>
      <Box sx={styles.container}>
        <Box sx={styles.projectName}>
          <TopSection
            project={project}
            canDeleteProject={userInfo.canDeleteProject}
            canRenameProject={userInfo.canRenameProject}
          />
        </Box>
        <Box sx={styles.buttonContainer}>
          {userInfo.canAddDesign && (
            <GradientButton
              text={t("design.new_design")}
              icon={"add"}
              onClick={addDesign}
            />
          )}
          <GradientLink
            text={t("HomePage.work_with_designer")}
            icon={"palette"}
            to={t("navigation.work_with_designer.linkUrl")}
            target="_blank"
          />
        </Box>
        <Box sx={styles.designsContainer} fullWidth>
          {designsReduxState.loading || isGloballyLoading ? (
            <DesignCardSkeleton />
          ) : (
            projectDesigns &&
            projectDesigns.map((design) =>
              //If design is in error, then we can only delete it
              design.errorKey ? (
                <DesignWithErrorsCard
                  key={design.id}
                  design={design}
                  project={project}
                  canDeleteDesign={userInfo.canDeleteDesign}
                />
              ) : (
                <DesignCard key={design.id} design={design} hideProjectName />
              )
            )
          )}
        </Box>
      </Box>
      <Modal show={creating} key="creating">
        <CreatingCanvaModal />
      </Modal>

      <ProductModal
        open={productPopup && !creating}
        createDesign={createDesign}
        onCloseModal={onCloseProductPopup}
      />
    </>
  );
};

export default Designs;
