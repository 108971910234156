import React, { useCallback, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import styles from "../adminPreorderPriceAndShippingDetails.module.scss";
import { Link } from "react-router-dom";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import OrderSummary from "../../OrderSummary";

const EditableInputInvoiceField = (props) => {
  const { t } = useTranslation();
  const {
    initialValue,
    onUpdate,
    titleLabel,
    editLabel,
    saveLabel,
    cancelLabel,
    // emptyLabel
  } = props;

  const [isEditing, setEditing] = useState(false);
  const [valueState, setValueState] = useState({
    discounts: [],
    shipping: 0,
    total: 0,
    subtotal: 0,
    currency: "",
    tax: "",
    taxes: [],
    hasReachedMaximum: false,
  });

  //Initial loading of the address
  useEffect(() => {
    //We add a new empty discount row if one doesn't already exists
    const updatedInitialValue = _.cloneDeep(initialValue);
    if (
      isEditing &&
      updatedInitialValue.discounts &&
      updatedInitialValue.discounts.length === 0
    ) {
      updatedInitialValue.discounts.push({
        id: uuidv4(),
        name: "",
        amount: 0,
      });
    }

    setValueState(updatedInitialValue);
  }, [initialValue, setValueState, isEditing]);

  const onClickEdit = useCallback(
    (e) => {
      e.preventDefault();

      setEditing(true);
    },
    [setEditing]
  );

  const onClickSave = useCallback(
    (e) => {
      e.preventDefault();

      onUpdate(valueState.discounts).then(() => {
        setEditing(false);
      });
    },
    [setEditing, onUpdate, valueState]
  );

  const onClickCancel = useCallback(
    (e) => {
      e.preventDefault();

      setValueState(initialValue);

      setEditing(false);
    },
    [setValueState, setEditing, initialValue]
  );

  const onInputDiscountValueChange = (newValue, discountId, attributeName) => {
    setValueState((oldValueState) => {
      const newState = _.cloneDeep(oldValueState);

      const discountFound = newState.discounts.find(
        (discount) => discount.id === discountId
      );
      discountFound[attributeName] = newValue;

      return newState;
    });
  };

  return (
    <div className={styles.editableColumnFieldContainer}>
      <div className={styles.editColumnFieldTitle} key="editColumnFieldTitle">
        <span className={styles.title}>{titleLabel} </span>
        <div className={styles.action}>
          {onUpdate && !isEditing && (
            <Link to="" onClick={(e) => onClickEdit(e)}>
              {editLabel}
            </Link>
          )}
          {isEditing && (
            <React.Fragment>
              <Link to="" onClick={(e) => onClickSave(e)}>
                {saveLabel}
              </Link>{" "}
              <Link to="" onClick={(e) => onClickCancel(e)}>
                {cancelLabel}
              </Link>
            </React.Fragment>
          )}
        </div>
      </div>
      <div className={styles.editableColumnField} key="editField">
        {isEditing ? (
          <div className={styles.discountForm} key="form">
            {valueState.discounts.map((discount) => (
              <div className={styles.rowField}>
                <div className={styles.discountName}>
                  <input
                    type="text"
                    className={styles.editableText}
                    value={discount.name}
                    placeholder={t("preorder.discount_name")}
                    onChange={(e) =>
                      onInputDiscountValueChange(
                        e.target.value,
                        discount.id,
                        "name"
                      )
                    }
                  />
                </div>
                <div className={styles.discountValue}>
                  <input
                    type="number"
                    min="0"
                    className={styles.editableText}
                    value={discount.amount}
                    onChange={(e) =>
                      onInputDiscountValueChange(
                        parseInt(e.target.value),
                        discount.id,
                        "amount"
                      )
                    }
                  />
                </div>
              </div>
            ))}
          </div>
        ) : (
          <OrderSummary
            tax={valueState.tax}
            taxes={valueState.taxes}
            total={valueState.total}
            subtotal={valueState.subtotal}
            shipping={valueState.shipping}
            currency={valueState.currency}
            discounts={valueState.discounts || []} //Because legacy before march 2023 may not have the field
            taxesExcluded={true}
            hasReachedMaximum={valueState.hasReachedMaximum}
          />
        )}
      </div>
    </div>
  );
};

export default EditableInputInvoiceField;
