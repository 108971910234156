import { Divider, Menu, MenuItem } from "@mui/material";
import { memo } from "react";
import { useTranslation } from "react-i18next";
import { palette } from "../../../styles/theme";
import { Icon } from "../Icon";
import { MenuOption } from "./Menu";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  target: Element | null | undefined;
  options: MenuOption[];
}

const MenuControlled = ({
  options,
  isOpen,
  onClose,
  target,
  ...rest
}: Props) => {
  const { t } = useTranslation();

  const onClick = (callback?: () => void) => () => {
    callback?.();
    onClose();
  };

  return (
    <Menu
      open={isOpen}
      id="menu-controlled"
      anchorEl={target}
      onClose={onClose}
      {...rest}
      sx={{
        "& .MuiPaper-root": {
          width: 200,
          minWidth: "fit-content",
        },
      }}
    >
      {options.map((option, index) => {
        if (
          (typeof option.hideIf === "function" && option.hideIf?.()) ||
          option.hideIf === true
        ) {
          return null;
        }
        if (option.isDivider) {
          return <Divider key={`${option.labelKey}-${index}`} />;
        }

        return (
          <MenuItem
            onClick={onClick(option.onClick)}
            key={`${option.labelKey}-${index}`}
            disabled={option.disabled}
            data-test={option.labelKey}
          >
            {option.labelKey && t(option.labelKey)}
            {option.isChecked?.() && (
              <Icon icon="done" color={palette.grey.nearBlack} size={18} />
            )}
          </MenuItem>
        );
      })}
    </Menu>
  );
};

export default memo(MenuControlled);
