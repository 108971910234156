import React, { useState, useCallback } from "react";
import { useTimeSince } from "../../utils/dateUtils";
import { AddressBox } from "../AddressBox";
import YourContactBox from "../YourContactBox";
import OrderSummary from "../OrderSummary";
import styles from "./order.module.scss";
import { useTranslation } from "react-i18next";
import { getLanguage } from "../../utils/LocaleUtil";

import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import PurchasedItemDetails from "../PurchasedItemDetails";

const Order = (props) => {
  const { t } = useTranslation();
  const { dateFormatter } = useTimeSince();
  const { orderDetail, shouldStartExpanded, type } = props;

  const [isExpanded, setExpanded] = useState(shouldStartExpanded); //Set the initial value from the parent

  const dateTimeOptions = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    timeZoneName: "short",
  };

  /**
   *
   */
  const handleClick = useCallback(() => {
    setExpanded(!isExpanded);
  }, [isExpanded]);

  return (
    <React.Fragment>
      <div className={styles.container}>
        <div className={styles.orderId}>
          {orderDetail?.invoice?.invoice_number && (
            <span>
              {t("order.invoice_no")} {orderDetail.invoice.invoice_number}
            </span>
          )}
        </div>
        <div className={styles.orderHeader}>
          <div className={styles.orderColumn}>
            <div className={styles.orderDate}>
              {type === "order" && (
                <span>
                  <strong>{t("order.ordered_on")}</strong>
                </span>
              )}
              {type === "preorder" && (
                <span>
                  <strong>
                    {t("order.preordered_on")}{" "}
                    {t(`order.status.${orderDetail.preorder?.status}`)}
                  </strong>
                </span>
              )}
              {type === "order" && (
                <span>
                  {dateFormatter(
                    orderDetail.order.created_at
                  ).toLocaleDateString(getLanguage(), dateTimeOptions)}
                </span>
              )}
              {type === "preorder" && (
                <span>
                  {dateFormatter(
                    orderDetail.preorder.created_at
                  ).toLocaleDateString(getLanguage(), dateTimeOptions)}
                </span>
              )}
            </div>
          </div>
          {type === "preorder" && (
            <div className={styles.yourContact}>
              <React.Fragment>
                <span>
                  <strong>{t("order.your_contact")}</strong>
                </span>
                <YourContactBox userDetails={orderDetail.user} />
              </React.Fragment>
            </div>
          )}
          <div className={styles.shipping}>
            <span>
              <strong>{t("order.billing")}</strong>
            </span>
            <AddressBox address={orderDetail.invoice.customer} />
          </div>
          <div className={styles.shipping}>
            <span>
              <strong>{t("order.delivery")}</strong>
            </span>
            <AddressBox address={orderDetail.shipping} />
          </div>

          <div className={styles.orderTotal}>
            <div className={styles.paymentSummary}>
              <strong>{t("order.order_summary")}</strong>
              <OrderSummary
                taxes={orderDetail.invoice.taxes}
                total={orderDetail.invoice.total}
                subtotal={orderDetail.invoice.subtotal}
                shipping={orderDetail.invoice.shipping}
                currency={orderDetail.invoice.currency}
                discounts={orderDetail.invoice.discounts}
                hasReachedMaximum={orderDetail.invoice.hasReachedMaximum}
                taxesExcluded={type === "preorder" ? true : false}
              />
            </div>
            {type === "order" && (
              <div className={styles.orderReceipt}>
                <a href={orderDetail.invoice.receiptUrl} target="_blank" rel="noreferrer">
                  {t("order.receipt")}
                </a>
              </div>
            )}
            {type === "order" && (
              <div className={styles.taxNumbers}>
                <React.Fragment>
                  <span>TPS / GST: 734021926RT0001</span>
                  <span>TVQ / QST: 1224389848TQ0001</span>
                </React.Fragment>
              </div>
            )}
          </div>
        </div>
        <div className={styles.collapseActions} onClick={handleClick}>
          {isExpanded ? <ExpandLess /> : <ExpandMore />}
        </div>
        <Collapse in={isExpanded} timeout="auto" unmountOnExit>
          <div className={styles.productList}>
            {
              //Because of legacy orders (before march 2023), we need to use cartItems because they didn't have purchasedItems
              (orderDetail.purchasedItems &&
              orderDetail.purchasedItems.length > 0
                ? orderDetail.purchasedItems
                : orderDetail.cartItems
              ).map((purchasedItem, purchasedItemIndex) => (
                // <OrderItem
                //   key={item.designId}
                //   price={item?.pricing?.price}
                //   unitPrice={item?.pricing?.pricePerUnit}
                //   qty={item?.pricing?.qty}
                //   sizes={item.sizes}
                //   designState={item.designState}
                //   description={""} //TODO: Validate where description is
                //   images={item.designState.images}
                //   projectName={item.projectName}
                //   designName={item.designName} //TODO: validate
                //   currency={orderDetail.currency}
                //   showPriceInfo={true} //{type === "order" ? true : false}
                // />
                <div
                  className={styles.purchasedItemContainer}
                  key={purchasedItem.id}
                >
                  <PurchasedItemDetails
                    key={purchasedItem.id}
                    purchasedItem={purchasedItem}
                    title={`${purchasedItem.projectName} - ${
                      purchasedItem.designName
                    } (${purchasedItemIndex + 1}/${
                      (orderDetail.purchasedItems &&
                      orderDetail.purchasedItems.length > 0
                        ? orderDetail.purchasedItems
                        : orderDetail.cartItems
                      ).length
                    })`}
                  />
                </div>
              ))
            }
          </div>
        </Collapse>
      </div>
    </React.Fragment>
  );
};

export default Order;
