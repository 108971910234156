import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Select from "../../../common/components/Select";
import { DESIGN_CHANGE_ENUM } from "../../../utils/ReactHooks";
import { reformatTheInches } from "../../../utils/LocaleUtil";
import StyledInchSlider from "../../../components/StyledInchSlider";
import styles from "../AssetSizeBox.module.scss";

const AssetTagSizeBox = (props) => {
  const {
    canUpdateArtworkPosition,
    canUpdateArtworkColor,
    onDesignChange,
    decoration,
    tagOptionsDef,
    hideSizeValue = false,
  } = props;

  const { t } = useTranslation();

  const [sizeIndex, setSizeIndex] = useState(0);
  const [selectedTag, setSelectedTag] = useState(null);
  const [selectedShape, setSelectedShape] = useState(null);
  const [tagsForShape, setTagsForShape] = useState([]);

  useEffect(() => {
    if (!decoration) {
      return;
    }

    const { tagName } = decoration.tagDetails;

    if (!tagName) {
      return;
    }
    const tagFound = tagOptionsDef.find((t) => t.name === tagName);
    setSelectedTag(tagFound);
    setSelectedShape(tagFound.shape);

    const filteredTags = tagOptionsDef
      //Filter out the sizes that are not in the available tag names array
      .filter((t) => decoration.tagDetails.availableTagNames.includes(t.name))
      .filter((t) => t.shape === tagFound.shape);

    const index = filteredTags.findIndex(
      (t) => t.name === decoration.tagDetails.tagName
    );

    setTagsForShape(filteredTags);
    setSizeIndex(index);
  }, [decoration, selectedShape, tagOptionsDef]);

  const onSliderChange = useCallback(
    (e) => {
      //console.log("slider change");
      const index = Number(e.target.value);
      const tag = tagsForShape[index];
      setSizeIndex(index);
      setSelectedTag(tag);
    },
    [tagsForShape]
  );

  const onSliderChangeCommitted = useCallback(
    (e) => {
      onDesignChange(DESIGN_CHANGE_ENUM.SET_DECORATION_TAG, {
        tagName: selectedTag.name,
        decorationId: decoration.id,
      });
    },
    [decoration.id, onDesignChange, selectedTag?.name]
  );

  const shapes = useMemo(
    () =>
      decoration
        ? tagOptionsDef
            //Filter out the sizes that are not in the available tag names array
            .filter((s) =>
              decoration.tagDetails.availableTagNames.includes(s.name)
            )
            .reduce(
              (prev, { shape }) =>
                prev.includes(shape) ? prev : [...prev, shape],
              []
            )
            .map((s) => ({
              value: s,
              label: t(`shapes.${s}`),
            }))
        : [],
    [decoration, tagOptionsDef, t]
  );

  return (
    <React.Fragment>
      {selectedTag &&
        selectedTag?.widthInInches &&
        selectedTag?.heightInInches && (
          <div className={styles.sizeContainer}>
            <h6>
              {/* If planeAngle=90, -90 or 270, it means vertical so height = width and widht=height */}
              {decoration?.location?.selectedLocation?.planeAngle === 90 ||
              decoration?.location?.selectedLocation?.planeAngle === 270 ||
              decoration?.location?.selectedLocation?.planeAngle === -90 ? (
                <React.Fragment>
                  {hideSizeValue
                    ? t("specs.size")
                    : t("specs.size") +
                      t("specs.width") +
                      " " +
                      reformatTheInches(selectedTag.heightInInches) +
                      " " +
                      t("specs.height") +
                      " " +
                      reformatTheInches(selectedTag.widthInInches)}
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {hideSizeValue
                    ? t("specs.size")
                    : t("specs.size") +
                      t("specs.width") +
                      " " +
                      reformatTheInches(selectedTag.widthInInches) +
                      " " +
                      t("specs.height") +
                      " " +
                      reformatTheInches(selectedTag.heightInInches)}
                </React.Fragment>
              )}
            </h6>
            {/*//TODO make the slider use productDef's logoOptions to set min, max and step*/}
            <StyledInchSlider
              min={0}
              max={tagsForShape.length - 1}
              step={1}
              value={sizeIndex}
              onChange={onSliderChange}
              onChangeCommitted={onSliderChangeCommitted}
              minLabel={reformatTheInches(tagsForShape[0].widthInInches)}
              passedLabelText={reformatTheInches(
                tagsForShape[sizeIndex].widthInInches
              )}
              maxLabel={reformatTheInches(
                tagsForShape[tagsForShape.length - 1].widthInInches
              )}
              disabled={!selectedShape || !canUpdateArtworkColor}
              marks={true}
              hideSizeValue={hideSizeValue}
            />
          </div>
        )}
      <h6>{t("specs.shape")}</h6>
      <Select
        options={shapes}
        onChange={(e) => {
          const shape = e.value;

          const tagName = tagOptionsDef
            .filter((t) =>
              decoration.tagDetails.availableTagNames.includes(t.name)
            )
            .find((t) => t.shape === shape)?.name;

          if (!tagName) {
            console.error("Shape doesn't have any tag");
            return;
          }

          onDesignChange(DESIGN_CHANGE_ENUM.SET_DECORATION_TAG, {
            tagName: tagName,
            decorationId: decoration.id,
          });
        }}
        value={{
          value: selectedShape,
          label: selectedShape
            ? t(`shapes.${selectedShape}`)
            : t("positions.select"),
        }}
        disableAll={!canUpdateArtworkPosition}
      />
    </React.Fragment>
  );
};

export default AssetTagSizeBox;
