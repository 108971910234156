import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import TeamService from "../../service/TeamService";

import { Dialog, DialogContent, Typography } from "@mui/material";
import { Box, Button, Icon, Input } from "../../common/components";
import { useSelector } from "../../common/hooks/useSelector";
import { Teammate } from "../../containers/ProjectsContainer/Team/Teammate";
import { Styles, palette, spacing } from "../../styles/theme";
import { canDeleteUser, isCurrentUser } from "../../utils/teamUtils";
import { useThemeBreakpoints } from "../../utils/themeUtils";
import DialogTitleWithCloseButton from "../DialogTitleWithCloseButton/DialogTitleWithCloseButton";

const styles: Styles = {
  input: {
    background: palette.black.readonlyBg,
    pointerEvents: "none",
    "&>input": {
      height: "16px",
      lineHeight: "normal",
      fontSize: 14,
      color: `${palette.black.readonlyText} !important`,
    },
  },
};

interface Props {
  open: boolean;
  onClose: () => void;
  teamId?: string;
}

const InviteTeammateModal = ({ open, onClose, teamId }: Props) => {
  const { t } = useTranslation();
  const { isMobile } = useThemeBreakpoints();
  const [copied, setCopied] = useState(false);
  const [token, setToken] = useState<{
    value: string | null;
    teamId: string | null;
  }>({
    value: null,
    teamId: null,
  });

  const userInfo = useSelector((state) => state.userInfo);
  const teamsState = useSelector((state) => state.myTeams);
  const teams = useMemo(() => {
    return teamsState?.teams;
  }, [teamsState]);
  const team = useMemo(() => {
    return teams?.find((t) => t.id === teamId);
  }, [teams, teamId]);

  useEffect(() => {
    const updateToken = async () => {
      if (!teamId) {
        //Can't get a token without the teamId
        return;
      }

      if (token.value && teamId === token.teamId) {
        // Token is up to date, do not update
        return;
      }
      const {
        data: { data: newToken },
      } = await TeamService.getInviteLink(teamId);

      setToken({ value: newToken, teamId });
    };

    updateToken();
  }, [open, teamId, token]);

  const link = `${process.env.REACT_APP_CLIENT_URL}/join/${token.value}`;

  const onCopy = () => {
    navigator.clipboard.writeText(link);
    setCopied(true);
    setTimeout(() => setCopied(false), 5000);
  };

  useEffect(() => {
    if (open) {
      setCopied(false);
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth={true}
      fullScreen={isMobile}
      maxWidth={"md"}
    >
      <DialogTitleWithCloseButton
        id="customized-dialog-title"
        onClose={onClose}
      >
        {t("invite.invite_teammate")}
      </DialogTitleWithCloseButton>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: { xs: spacing.regularMobile, lg: spacing.regularDesktop },
        }}
      >
        <Typography color={palette.black.readonlyText}>
          {t("invite.invite_description")}
        </Typography>
        <Box
          display="flex"
          gap={{ xs: spacing.regularMobile, lg: spacing.ctaSpacingDesktop }}
          alignItems="center"
          flexDirection={{ xs: "column", lg: "row" }}
          fullWidth={isMobile}
        >
          <Input
            name="inviteTeamMateInput"
            value={link}
            readOnly
            sx={styles.input}
            fullWidth={isMobile}
            containerSx={{ width: "unset", maxWidth: "unset", flexGrow: 1 }}
          />
          <Button
            onClick={onCopy}
            size={isMobile ? "large" : "small"}
            fullWidth={isMobile}
            startIcon={
              <Icon
                icon={copied ? "done" : "content_copy"}
                size={16}
                color="white"
              />
            }
            sx={{
              fontSize: 14,
              fontWeight: 500,
              lineHeight: "18px", //2px more to match the margin of the input that adds 2 px
              height: "fit-content",
            }}
          >
            {copied ? t("invite.copied") : t("invite.copy")}
          </Button>
        </Box>
        {isMobile && (
          <Box>
            <Typography variant="h2" mb={spacing.groupingMobile} mt={1}>
              {t("project.team")}
            </Typography>
            <Box
              gap={spacing.mediumMobile}
              display="flex"
              flexDirection="column"
            >
              {team?.teammates.map((teammate) => (
                <Teammate
                  userId={teammate.id}
                  first_name={teammate.first_name}
                  last_name={teammate.last_name}
                  email={teammate.email}
                  canDelete={canDeleteUser(team)}
                  key={teammate.id}
                  teamId={team.id}
                  teammateUserId={teammate.id}
                  imageUrl={teammate.imageUrl}
                  roles={teammate.roles}
                  isCurrentUser={isCurrentUser(userInfo, teammate.id)}
                />
              ))}
            </Box>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default InviteTeammateModal;
