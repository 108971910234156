import { memo } from "react";
import "./dashboardToolbar.scss";

import { Skeleton } from "@mui/material";
import { useThemeBreakpoints } from "../../utils/themeUtils";

export const DashboardToolbarContentSkeleton = () => {
  const { isMobile } = useThemeBreakpoints();

  return (
    <>
      {isMobile && <Skeleton sx={{ width: "28px" }}></Skeleton>}
      <Skeleton sx={{ width: { xs: "60px", lg: "83px" } }}></Skeleton>
      <Skeleton sx={{ width: { xs: "47px", lg: "240px" } }}></Skeleton>
    </>
  );
};
export default memo(DashboardToolbarContentSkeleton);
