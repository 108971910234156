import { useMemo } from "react";
import { getLanguage } from "../../utils/LocaleUtil";
import { useTimeSince } from "../../utils/dateUtils";
import styles from "./adminPurchasedOrders.module.scss";
import { useTranslation } from "react-i18next";
import Paper from "@mui/material/Paper";
import Filter from "../Filter";
import { AdminPurchasedOrderType } from "../../types";
import { AdminPurchasedOrdersCollapsibleTable } from "./AdminPurchasedOrdersCollapsibleTable";
import { TableCellProps } from "@mui/material";

export interface AdminPurchasedOrdersColumn {
  width: number;
  minWidth: number;
  label: string;
  id: keyof Omit<AdminPurchasedOrdersRow, "orderDetail">;
  dataKey: string;
  field: string;
  headerName: string;
  description: string;
  sortable: boolean;
  align?: TableCellProps["align"];
}

export interface AdminPurchasedOrdersRow {
  id?: string;
  userInfo: string;
  orderId?: string;
  orderDate: string;
  orderStatus: string;
  orderType: string;
  projectNumber: string;
  numberOfItems: number;
  orderDetail: AdminPurchasedOrderType;
}

interface Props {
  ordersDetails: AdminPurchasedOrderType[];
  allowFiltering: boolean;
}

const AdminPurchasedOrders = ({
  ordersDetails,
  allowFiltering = false,
}: Props) => {
  const { t } = useTranslation();
  const { dateFormatter } = useTimeSince();

  const columns: AdminPurchasedOrdersColumn[] = [
    {
      width: 200,
      minWidth: 200,
      label: t("order.ordered_on"),
      id: "orderDate",
      dataKey: "orderDate",
      field: "orderDate",
      headerName: t("order.ordered_on"),
      description: "Order date",
      sortable: true,
    },
    {
      width: 100,
      minWidth: 100,
      label: t("order.project_number"),
      id: "projectNumber",
      dataKey: "projectNumber",
      field: "projectNumber",
      headerName: t("order.project_number"),
      description: "The techpack project number",
      sortable: true,
      align: "center",
    },
    {
      width: 160,
      minWidth: 160,
      label: t("order.customer"),
      id: "userInfo",
      dataKey: "userInfo",
      field: "userInfo",
      headerName: t("order.customer"),
      description: "Name and email of customer",
      sortable: true,
      align: "center",
    },
    {
      width: 200,
      minWidth: 200,
      label: t("order.order_no"),
      id: "orderId",
      dataKey: "orderId",
      field: "orderId",
      headerName: t("order.order_no"),
      description: "Order Id",
      sortable: false,
      align: "center",
    },
    {
      width: 120,
      minWidth: 120,
      label: t("order.status.title"),
      id: "orderStatus",
      dataKey: "orderStatus",
      field: "orderStatus",
      headerName: t("order.status.title"),
      description: "Status",
      sortable: true,
      align: "center",
    },
  ];

  const rows = useMemo((): AdminPurchasedOrdersRow[] => {
    const dateTimeOptions: Intl.DateTimeFormatOptions = {
      weekday: "short",
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    };

    return ordersDetails.map((orderDetail) => {
      let orderId = null;
      if (orderDetail.type === "preorder") {
        orderId = orderDetail.details.preorder?.id;
      } else {
        orderId = orderDetail.details.order?.id;
      }

      const techpackProjectNumber =
        orderDetail.details?.techpack?.project_number ||
        t("order.not_yet_assigned");

      let orderDate = null;
      if (orderDetail.type === "preorder") {
        orderDate = dateFormatter(
          orderDetail.details.preorder?.created_at
        ).toLocaleDateString(getLanguage(), dateTimeOptions);
      } else {
        orderDate = dateFormatter(
          orderDetail.details.order?.created_at
        ).toLocaleDateString(getLanguage(), dateTimeOptions);
      }

      const orderStatus = t(
        `preorder.status.options.${orderDetail.details.preorder?.status}`
      );

      const userInfo = `${orderDetail.details.user.first_name} ${orderDetail.details.user.last_name} (${orderDetail.details.user.email})`;

      //Because of legacy when we weren't using purchasedItems, we have to use cartItems
      let numberOfItems = 0;
      if (orderDetail.details.purchasedItems) {
        numberOfItems = orderDetail.details.purchasedItems.length;
      } else {
        numberOfItems = orderDetail.details.cartItems.length;
      }

      return {
        id: orderId,
        userInfo,
        orderId,
        orderDate,
        orderStatus,
        orderType: t(`order.type.${orderDetail.type}`),
        projectNumber: techpackProjectNumber,
        numberOfItems,
        orderDetail: orderDetail,
      };
    });
  }, [ordersDetails, dateFormatter, t]);

  return (
    <div className={styles.container}>
      {allowFiltering && <Filter />}
      <Paper style={{ height: "80vh", width: "100%" }}>
        <AdminPurchasedOrdersCollapsibleTable columns={columns} rows={rows} />
      </Paper>
    </div>
  );
};

export default AdminPurchasedOrders;
