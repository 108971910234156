import React from "react";

import styles from "./ColorCircle.module.scss";

import { ReactComponent as NoColor } from "./colors/NoColor.svg";

import DarkTooltip from "../../../DarkTooltip";
import { useTranslation } from "react-i18next";

const ColorCircle = ({ color, colors, isSelected, png, svg, tooltip }) => {
  const { t } = useTranslation();

  let colorCircle = null;

  if (!color && !colors && !svg && !png) {
    colorCircle = (
      <div className={styles.noColor}>
        <NoColor />
      </div>
    );
  } else {
    if (png) {
      colorCircle = <img src={png} className={styles.png} alt="color" />;
    } else if (color) {
      colorCircle = (
        <div
          style={{
            backgroundColor: color,
            border:
              !isSelected && color === "#FFFFFF" ? "1px solid #ccc" : null,
          }}
          className={styles.color}
        />
      );
    } else {
      // Multiple colors are passed via colors.
      colorCircle = (
        <div
          style={{
            background: `linear-gradient(to bottom right, ${colors[0]} 50%, ${colors[1]} 50%)`,
            border:
              !isSelected && color === "#FFFFFF" ? "1px solid #ccc" : null,
          }}
          className={styles.color}
        />
      );
    }
  }

  return (
    <React.Fragment>
      <DarkTooltip title={tooltip ? t(`colors.${tooltip}`) : ""} followCursor>
        {isSelected ? (
          <div className={styles.selected} style={{ padding: png ? 0 : 1 }}>
            {colorCircle}
          </div>
        ) : (
          <div className={styles.notSelected}>{colorCircle}</div>
        )}
      </DarkTooltip>
    </React.Fragment>
  );
};

export default ColorCircle;
