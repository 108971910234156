import React from "react";
import Design from "./Design";

import styles from "./Swag.module.scss";

const Swag = (props) => {
  const { title, designs, onAddToSelection, topNeedExtraPadding } = props;

  return (
    <div
      className={
        topNeedExtraPadding
          ? styles.containerWithExtraTopPadding
          : styles.container
      }
    >
      <h4>{title}</h4>
      {designs.map((design) => (
        <Design
          design={design}
          onAddToSelection={onAddToSelection}
          key={design.id}
        />
      ))}
    </div>
  );
};

export default Swag;
