import { Project, ProjectWithDesigns } from "../../types";
import { Action } from "../../types/redux";

export enum PROJECT_ACTION_ENUM {
  UPDATE_ALL_PROJECTS = "update_all_projects",
  UPDATE_ALL_PROJECTS_LOADING = "update_all_projects_loading",
  UPDATE_TEAM_PROJECTS = "update_team_projects",
  UPDATE_TEAM_PROJECTS_LOADING = "update_team_projects_loading",
  RESET_STATE = "reset_state",
}

const initialState = {
  allProjects: [] as Project[],
  teamProjects: [] as ProjectWithDesigns[],
  allProjectsLoading: true,
  allProjectsLoaded: false,
  teamProjectsLoading: true,
  teamProjectsLoaded: false,
};

const reducer = (
  state = initialState,
  action: Action<PROJECT_ACTION_ENUM, Project[] | ProjectWithDesigns[]>
) => {
  switch (action.type) {
    case PROJECT_ACTION_ENUM.UPDATE_ALL_PROJECTS:
      return {
        ...state,
        allProjects: action.payload as Project[],
        allProjectsLoading: false,
        allProjectsLoaded: true,
      };
    case PROJECT_ACTION_ENUM.UPDATE_ALL_PROJECTS_LOADING:
      return {
        ...state,
        allProjectsLoading: true,
        allProjectsLoaded: state.allProjects.length ? true : false,
      };
    case PROJECT_ACTION_ENUM.UPDATE_TEAM_PROJECTS:
      return {
        ...state,
        teamProjects: action.payload as ProjectWithDesigns[],
        teamProjectsLoading: false,
        teamProjectsLoaded: true,
      };
    case PROJECT_ACTION_ENUM.UPDATE_TEAM_PROJECTS_LOADING:
      return {
        ...state,
        teamProjectsLoading: true,
        teamProjectsLoaded: false,
      };
    case PROJECT_ACTION_ENUM.RESET_STATE:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
