import { useLocation, Location } from "react-router-dom";
import React, { useEffect } from "react";

function useLocationEffect(callback: (location?: Location) => any) {
  const location = useLocation();

  useEffect(() => {
    callback(location);
  }, [location, callback]);
}

const SegmentHistoryListener = (props: { children: JSX.Element[] }) => {
  //Because we are on a Single Page App,
  //we need to listen to history changes for the analytics to know we are on a different page
  const { children } = props;

  useLocationEffect(() => {
    //The first page load
    // window.analytics.page();

    //Every time there is a history change, we log it
    window.analytics.page();
  });

  return <React.Fragment>{children}</React.Fragment>;
};

export default SegmentHistoryListener;
