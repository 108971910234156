import React from "react";
import Avatar from "../Avatar/Avatar";

import styles from "./AvatarGroup.module.scss";

const AvatarGroup = (props) => {
  const { avatars } = props;
  return (
    <div className={styles.container}>
      {avatars.map((a, index) => (
        <div className={styles.avatarContainer} key={index}>
          <Avatar {...a} />
        </div>
      ))}
    </div>
  );
};

export default AvatarGroup;
