import { useCallback, useState } from "react";
import styles from "./referralValidationContainer.module.scss";
import { useTranslation } from "react-i18next";
import ReferralCodeService from "../../service/ReferralCodeService";
import Button from "../../components/Button";
import { AppContainer } from "../../common/components";
import { usePageTitle } from "../../common/hooks/usePageTitle";

const ReferralContainer = (props) => {
  const { t } = useTranslation();
  usePageTitle(t("pages.referral_validation.title"));

  const [users, setUsers] = useState([]);
  const [referralCode, setReferralCode] = useState("");
  const [warning, setWarning] = useState("");

  const getUserAssociated = useCallback(async () => {
    if (!referralCode) {
      setWarning("referral_code_missing");
      return;
    }
    if (referralCode.trim().length !== 4) {
      setWarning("length_must_be_4");
      return;
    }
    try {
      setWarning("");
      ReferralCodeService.getUserFromReferralCode({
        referralCode: referralCode.trim(),
      }).then((u) => {
        setUsers(u);
      });
    } catch (e) {
      setWarning("backend_error");
    }
  }, [referralCode]);

  return (
    <div className={styles.centerContainer}>
      <div className={styles.title}>{t("referralValidation.title")}</div>
      <div className={styles.validateCode}>
        {t("referralValidation.validate_code")}
      </div>
      {warning ? (
        <div className={styles.warning}>
          {t(`referralValidation.${warning}`)}
        </div>
      ) : (
        ""
      )}
      <div className={styles.validateButtonContainer}>
        <input
          type="text"
          className={styles.input}
          value={referralCode}
          onChange={(e) => setReferralCode(e.target.value)}
        ></input>
        <Button
          onClick={getUserAssociated}
          text={t("referralValidation.validate")}
        ></Button>
      </div>
      <div className={styles.resultsContainer}>
        {users.map((u) => {
          return (
            <div className={styles.userFound}>
              <div className={styles.resultTitle}>
                {t("referralValidation.user_found")}
              </div>
              <div>
                <b>First Name:</b> {u.firstName}
              </div>
              <div>
                <b>Last Name:</b> {u.lastName}
              </div>
              <div>
                <b>Email:</b> {u.email}
              </div>
            </div>
          );
        })}
        {users.length === 0 && (
          <div className={styles.resultTitle}>
            {t("referralValidation.user_not_found")}
          </div>
        )}
      </div>
    </div>
  );
};

export default ReferralContainer;
