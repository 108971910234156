import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { PropsWithChildren } from "react";
import { spacing } from "../../../styles/theme";

interface Props extends PropsWithChildren {
  title: string;
}

export const OnboardingTitle = ({ title, children }: Props) => {
  return (
    <Box
      display="flex"
      mb={{ xs: spacing.regularMobile, lg: spacing.regularDesktop }}
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      <Typography
        variant="title"
        mb={
          children
            ? { xs: spacing.subtitleMobile, lg: spacing.subtitleDesktop }
            : undefined
        }
        fontWeight="medium"
      >
        {title}
      </Typography>
      {children}
    </Box>
  );
};
