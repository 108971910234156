import React, { useCallback, useEffect, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { DESIGN_CHANGE_ENUM } from "../../../utils/ReactHooks";
import { reformatTheInches } from "../../../utils/LocaleUtil";
import StyledInchSlider from "../../StyledInchSlider";
import Select from "../../../common/components/Select";
import styles from "../AssetSizeBox.module.scss";

const AssetRepeatingPrintSizeBox = (props) => {
  const {
    //canUpdateArtworkPosition,
    canUpdateArtworkColor,
    onDesignChange,
    decorationDef,
    decoration,
  } = props;

  const { t } = useTranslation();

  const [repeatingDetails, setRepeatingDetails] = useState(null);

  const repeatedPatternTypeDef = decorationDef.repeatingPatternOptions;
  const hideSizeValue = !!decorationDef?.display?.hideSizeValue;

  const patternDef = useMemo(() => {
    if (!decoration || !decoration.repeatingDetails) {
      return null;
    }
    //Find the patternDef in the repeatedPatternTypeDef
    return repeatedPatternTypeDef.patterns.find(
      (patternDef) =>
        patternDef.name === decoration.repeatingDetails.patternName
    );
  }, [repeatedPatternTypeDef, decoration]);

  const allPatternNamesOptions = useMemo(() => {
    return repeatedPatternTypeDef.patterns.map((pattern) => {
      return {
        value: pattern.name,
        label: t(`specs.repeating_patterns.${pattern.name}`),
        disabled: false,
      };
    });
  }, [repeatedPatternTypeDef, t]);

  useEffect(() => {
    if (!decoration) {
      return;
    }

    if (!decoration.repeatingDetails) {
      return;
    }

    setRepeatingDetails(decoration.repeatingDetails);
  }, [decoration]);

  const onVerticalSpacingSliderChange = useCallback(
    (e) => {
      //console.log("slider change");
      const value = Number(e.target.value);
      setRepeatingDetails((previousRepeatingDetails) => {
        return {
          ...previousRepeatingDetails,
          verticalSpacingInches: value,
        };
      });
    },
    [setRepeatingDetails]
  );

  const onVerticalOffsetSliderChange = useCallback(
    (e) => {
      //console.log("slider change");
      const value = Number(e.target.value);
      setRepeatingDetails((previousRepeatingDetails) => {
        return {
          ...previousRepeatingDetails,
          verticalOffsetInches: value,
        };
      });
    },
    [setRepeatingDetails]
  );

  const onHorizontalSpacingSliderChange = useCallback(
    (e) => {
      //console.log("slider change");
      const value = Number(e.target.value);
      setRepeatingDetails((previousRepeatingDetails) => {
        return {
          ...previousRepeatingDetails,
          horizontalSpacingInches: value,
        };
      });
    },
    [setRepeatingDetails]
  );

  const onHorizontalOffsetSliderChange = useCallback(
    (e) => {
      //console.log("slider change");
      const value = Number(e.target.value);
      setRepeatingDetails((previousRepeatingDetails) => {
        return {
          ...previousRepeatingDetails,
          horizontalOffsetInches: value,
        };
      });
    },
    [setRepeatingDetails]
  );

  const onImageWidthSliderChange = useCallback(
    (e) => {
      //console.log("slider change");
      const value = Number(e.target.value);
      setRepeatingDetails((previousRepeatingDetails) => {
        return {
          ...previousRepeatingDetails,
          maxImageWidthInches: value,
          maxImageHeightInches: value, //special here, for now we assume the box will be a square to hold the logo
        };
      });
    },
    [setRepeatingDetails]
  );

  const onSliderChangeCommitted = useCallback(
    (e) => {
      onDesignChange(DESIGN_CHANGE_ENUM.SET_REPEATING_PATTERN_DETAILS, {
        repeatingDetails: repeatingDetails,
        decorationId: decoration.id,
      });
    },
    [decoration.id, onDesignChange, repeatingDetails]
  );

  const onPatternNameChange = useCallback(
    (e) => {
      setRepeatingDetails((previousRepeatingDetails) => {
        return {
          ...previousRepeatingDetails,
          patternName: e.value,
        };
      });

      //Because the react loop will not have changed the repeatingDetails yet...
      const locatRepeatingDetails = {
        ...repeatingDetails,
        patternName: e.value,
      };

      onDesignChange(DESIGN_CHANGE_ENUM.SET_REPEATING_PATTERN_DETAILS, {
        repeatingDetails: locatRepeatingDetails,
        decorationId: decoration.id,
      });
    },
    [decoration.id, onDesignChange, repeatingDetails]
  );

  return (
    <React.Fragment>
      {repeatingDetails && (
        <div className={styles.sizeContainer}>
          <h6>{t("specs.pattern_name")}</h6>
          <Select
            options={allPatternNamesOptions}
            onChange={onPatternNameChange}
            value={{
              value: decoration?.repeatingDetails.patternName,
              label: t(
                `specs.repeating_patterns.${decoration?.repeatingDetails.patternName}`
              ),
            }}
            disableAll={!canUpdateArtworkColor}
          />

          <h6>
            {hideSizeValue
              ? t("specs.size")
              : t("specs.size") +
                t("specs.width") +
                " " +
                reformatTheInches(repeatingDetails.maxImageWidthInches) +
                " " +
                t("specs.height") +
                " " +
                reformatTheInches(repeatingDetails.maxImageWidthInches)}
          </h6>
          {/*//TODO make the slider use productDef's logoOptions to set min, max and step*/}
          <StyledInchSlider
            min={repeatedPatternTypeDef.logo.minWidthInches}
            max={repeatedPatternTypeDef.logo.maxWidthInches}
            step={repeatedPatternTypeDef.logo.increment}
            value={repeatingDetails.maxImageWidthInches}
            onChange={onImageWidthSliderChange}
            onChangeCommitted={onSliderChangeCommitted}
            minLabel={reformatTheInches(
              repeatedPatternTypeDef.logo.minWidthInches
            )}
            passedLabelText={reformatTheInches(
              repeatingDetails.maxImageWidthInches
            )}
            maxLabel={reformatTheInches(
              repeatedPatternTypeDef.logo.maxWidthInches
            )}
            disabled={!repeatingDetails || !canUpdateArtworkColor}
            marks={true}
            hideSizeValue={hideSizeValue}
          />

          {patternDef && patternDef.verticalSpacing && (
            <React.Fragment>
              <h6>
                {hideSizeValue
                  ? t("specs.vertical_spacing")
                  : t("specs.vertical_spacing") +
                    t("specs.height") +
                    " " +
                    reformatTheInches(repeatingDetails.verticalSpacingInches)}
              </h6>

              <StyledInchSlider
                min={patternDef.verticalSpacing.minHeightInches}
                max={patternDef.verticalSpacing.maxHeightInches}
                step={patternDef.verticalSpacing.increment}
                value={repeatingDetails.verticalSpacingInches}
                onChange={onVerticalSpacingSliderChange}
                onChangeCommitted={onSliderChangeCommitted}
                minLabel={reformatTheInches(
                  patternDef.verticalSpacing.minHeightInches
                )}
                passedLabelText={reformatTheInches(
                  repeatingDetails.verticalSpacingInches
                )}
                maxLabel={reformatTheInches(
                  patternDef.verticalSpacing.maxHeightInches
                )}
                disabled={!repeatingDetails || !canUpdateArtworkColor}
                marks={true}
                hideSizeValue={hideSizeValue}
              />
            </React.Fragment>
          )}

          {patternDef && patternDef.horizontalSpacing && (
            <React.Fragment>
              <h6>
                {hideSizeValue
                  ? t("specs.horizontal_spacing")
                  : t("specs.horizontal_spacing") +
                    t("specs.width") +
                    " " +
                    reformatTheInches(repeatingDetails.horizontalSpacingInches)}
              </h6>

              <StyledInchSlider
                min={patternDef.horizontalSpacing.minWidthInches}
                max={patternDef.horizontalSpacing.maxWidthInches}
                step={patternDef.horizontalSpacing.increment}
                value={repeatingDetails.horizontalSpacingInches}
                onChange={onHorizontalSpacingSliderChange}
                onChangeCommitted={onSliderChangeCommitted}
                minLabel={reformatTheInches(
                  patternDef.horizontalSpacing.minWidthInches
                )}
                passedLabelText={reformatTheInches(
                  repeatingDetails.horizontalSpacingInches
                )}
                maxLabel={reformatTheInches(
                  patternDef.horizontalSpacing.maxWidthInches
                )}
                disabled={!repeatingDetails || !canUpdateArtworkColor}
                marks={true}
                hideSizeValue={hideSizeValue}
              />
            </React.Fragment>
          )}

          {patternDef && patternDef.horizontalOffset && (
            <React.Fragment>
              <h6>
                {hideSizeValue
                  ? t("specs.horizontal_offset")
                  : t("specs.horizontal_offset") +
                    t("specs.width") +
                    " " +
                    reformatTheInches(repeatingDetails.horizontalOffsetInches)}
              </h6>
              {/*//TODO make the slider use productDef's logoOptions to set min, max and step*/}
              <StyledInchSlider
                min={patternDef.horizontalOffset.minWidthInches}
                max={patternDef.horizontalOffset.maxWidthInches}
                step={patternDef.horizontalOffset.increment}
                value={repeatingDetails.horizontalOffsetInches}
                onChange={onHorizontalOffsetSliderChange}
                onChangeCommitted={onSliderChangeCommitted}
                minLabel={reformatTheInches(
                  patternDef.horizontalOffset.minWidthInches
                )}
                passedLabelText={reformatTheInches(
                  repeatingDetails.horizontalOffsetInches
                )}
                maxLabel={reformatTheInches(
                  patternDef.horizontalOffset.maxWidthInches
                )}
                disabled={!repeatingDetails || !canUpdateArtworkColor}
                marks={true}
                hideSizeValue={hideSizeValue}
              />
            </React.Fragment>
          )}

          {patternDef && patternDef.verticalOffset && (
            <React.Fragment>
              <h6>
                {hideSizeValue
                  ? t("specs.vertical_offset")
                  : t("specs.vertical_offset") +
                    t("specs.width") +
                    " " +
                    reformatTheInches(repeatingDetails.verticalOffsetInches)}
              </h6>
              {/*//TODO make the slider use productDef's logoOptions to set min, max and step*/}
              <StyledInchSlider
                min={patternDef.verticalOffset.minHeightInches}
                max={patternDef.verticalOffset.maxHeightInches}
                step={patternDef.verticalOffset.increment}
                value={repeatingDetails.verticalOffsetInches}
                onChange={onVerticalOffsetSliderChange}
                onChangeCommitted={onSliderChangeCommitted}
                minLabel={reformatTheInches(
                  patternDef.verticalOffset.minHeightInches
                )}
                passedLabelText={reformatTheInches(
                  repeatingDetails.verticalOffsetInches
                )}
                maxLabel={reformatTheInches(
                  patternDef.verticalOffset.maxHeightInches
                )}
                disabled={!repeatingDetails || !canUpdateArtworkColor}
                marks={true}
                hideSizeValue={hideSizeValue}
              />
            </React.Fragment>
          )}
        </div>
      )}
    </React.Fragment>
  );
};

export default AssetRepeatingPrintSizeBox;
