import React, { useEffect, useMemo } from "react";
import ImageService from "../../../../service/ImageService";
import Select from "../../../../common/components/Select";
import { DESIGN_CHANGE_ENUM } from "../../../../utils/ReactHooks";
import { useTranslation } from "react-i18next";
import AssetTagSizeBox from "../../../../components/AssetSizeBox/AssetTagSizeBox";

const LeatherTagSidebar = (props) => {
  const { t } = useTranslation();
  const {
    decoration,
    teamId,
    onDesignChange,
    tagOptionsDef,
    canUpdateArtworkPosition,
    canUpdateArtworkColor,
    setPreviewImageData,
    hideSizeValue = false,
  } = props;

  useEffect(() => {
    if (decoration?.assetId) {
      ImageService.getTagImage(
        decoration.assetId,
        decoration.assetVersionId,
        decoration.type,
        decoration.tagDetails.tagName,
        decoration.tagDetails.textureName
      )
        .then((data) => {
          setPreviewImageData(data);
        })
        .catch(() => {
          setPreviewImageData(null);
        });
    } else {
      setPreviewImageData(null);
    }
  }, [
    decoration?.assetId,
    decoration?.assetVersionId,
    decoration?.type,
    decoration?.tagDetails?.tagName,
    decoration?.tagDetails?.textureName,
    teamId,
    setPreviewImageData,
  ]);

  const textures = useMemo(() => {
    if (!decoration) {
      return [];
    }

    const { tagName } = decoration.tagDetails;

    if (!tagName) {
      return [];
    }
    const tagFound = tagOptionsDef.find((t) => t.name === tagName);

    if (tagFound) {
      return tagFound.textures.map((texture) => ({
        value: texture.name,
        label: t(`textures.${texture.name}`),
      }));
    }
    return [];
  }, [decoration, tagOptionsDef, t]);

  return (
    <React.Fragment>
      <AssetTagSizeBox
        canUpdateArtworkPosition={canUpdateArtworkPosition}
        canUpdateArtworkColor={canUpdateArtworkColor}
        onDesignChange={onDesignChange}
        decoration={decoration}
        tagOptionsDef={tagOptionsDef}
        hideSizeValue={hideSizeValue}
      />

      <h6>{t("specs.color")}</h6>
      <Select
        options={textures}
        onChange={(e) =>
          onDesignChange(DESIGN_CHANGE_ENUM.SET_DECORATION_TAG_TEXTURE, {
            textureName: e.value,
            decorationId: decoration.id,
          })
        }
        value={{
          value: decoration.tagDetails.textureName,
          label: t(`textures.${decoration.tagDetails.textureName}`),
        }}
        disableAll={!canUpdateArtworkColor}
      />
    </React.Fragment>
  );
};

export default LeatherTagSidebar;
