import React, { useCallback, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import _ from "lodash";
import styles from "../adminPreorderPriceAndShippingDetails.module.scss";
import Select from "../../../common/components/Select";

const EditableInputSelectField = (props) => {
  const {
    initialValue,
    onUpdate,
    titleLabel,
    editLabel,
    saveLabel,
    cancelLabel,
    emptyLabel,
    options,
  } = props;

  const [isEditing, setEditing] = useState(false);
  const [valueState, setValueState] = useState("");

  //Initial loading of invoice number
  useEffect(() => {
    setValueState(initialValue);
  }, [initialValue, setValueState]);

  const onClickEdit = useCallback(
    (e) => {
      e.preventDefault();

      setEditing(true);
    },
    [setEditing]
  );

  const onClickSave = useCallback(
    (e) => {
      e.preventDefault();

      onUpdate(valueState).then(() => {
        setEditing(false);
      });
    },
    [setEditing, onUpdate, valueState]
  );

  const onClickCancel = useCallback(
    (e) => {
      e.preventDefault();

      //Reset to original values
      setValueState(initialValue);

      setEditing(false);
    },
    [setValueState, setEditing, initialValue]
  );

  const onInputValueChange = useCallback(
    (e) => {
      setValueState(e.value || "");
    },
    [setValueState]
  );

  return (
    <div className={styles.editableFieldContainer}>
      <div className={styles.editableField} key="editField">
        <span className={styles.title}>{titleLabel} </span>
        {isEditing ? (
          <Select
            className={styles.editableSelect}
            options={options}
            onChange={(e) => {
              onInputValueChange(e);
            }}
            value={options.find((option) => option.value === valueState)}
            // value={
            //   aboutInfo.projectSize
            //     ? {
            //         value: aboutInfo.projectSize,
            //         label: t(`onboard.project_size.${aboutInfo.projectSize}`),
            //       }
            //     : { value: "", label: "" }
            // }
            // name="projectSize"
            // id="projectSize"
            required
          />
        ) : (
          <span>
            {_.isEmpty(valueState)
              ? emptyLabel
              : options.find((option) => option.value === valueState)?.label}
          </span>
        )}
      </div>
      <div className={styles.action}>
        {onUpdate && !isEditing && (
          <Link to="" onClick={(e) => onClickEdit(e)}>
            {editLabel}
          </Link>
        )}
        {isEditing && (
          <React.Fragment>
            <Link to="" onClick={(e) => onClickSave(e)}>
              {saveLabel}
            </Link>{" "}
            <Link to="" onClick={(e) => onClickCancel(e)}>
              {cancelLabel}
            </Link>
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

export default EditableInputSelectField;
