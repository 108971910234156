import { createContext } from "react";

type DashboardContextType = {
  isNavigationDrawerOpen: boolean;
  toggleNavigationDrawerOpen?: () => void;
};

export const DashboardContext = createContext<DashboardContextType>({
  isNavigationDrawerOpen: false,
  toggleNavigationDrawerOpen: undefined,
});
