import { Typography } from "@mui/material";
import { memo } from "react";
import { useTranslation } from "react-i18next";
import { Box, Icon } from "../../../../common/components";
import { Styles, palette } from "../../../../styles/theme";

const styles: Styles = {
  container: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    gap: 1.25,
    flexGrow: 1,
    "&:hover": {
      ".inviteTeammate": {
        textDecoration: "underline",
      },
    },
  },
  iconContainer: {
    padding: "9px 8px 9px 10px",
    border: `1px dashed ${palette.blue.secondary}`,
    borderRadius: "32px",
  },
};

interface Props {
  onClick: () => void;
}

const TeammateBlankState = ({ onClick }: Props) => {
  const { t } = useTranslation();

  return (
    <Box onClick={onClick} sx={styles.container}>
      <Box sx={styles.iconContainer}>
        <Icon icon="person_add" color={palette.blue.secondary} size={22} />
      </Box>
      <Box display="flex" flexDirection="column">
        <Typography
          variant="textRegular"
          color={palette.blue.secondary}
          className="inviteTeammate"
        >
          {t("invite.invite_teammate")}
        </Typography>
      </Box>
    </Box>
  );
};

export default memo(TeammateBlankState);
