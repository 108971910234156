import { memo } from "react";
import { useTranslation } from "react-i18next";

import {
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";
import { Box, Button } from "../../common/components";
import { palette, spacing } from "../../styles/theme";
import { useThemeBreakpoints } from "../../utils/themeUtils";
import { DialogTitleWithCloseButton } from "../DialogTitleWithCloseButton";

interface Props {
  open: boolean;
  title: string;
  onClose: () => void;
  onDelete: () => void;
  message: string;
  ctaText?: string;
}

const DeleteModal = (props: Props) => {
  const { t } = useTranslation();
  const { isMobile } = useThemeBreakpoints();
  const { open, onClose, onDelete, title, message, ctaText } = props;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth={true}
      maxWidth={"md"}
      fullScreen={isMobile}
    >
      <DialogTitleWithCloseButton
        id="customized-dialog-title"
        onClose={onClose}
      >
        {title}
      </DialogTitleWithCloseButton>
      <DialogContent>
        <Box
          display="flex"
          flexDirection="column"
          gap={{ xs: spacing.regularMobile, lg: spacing.regularDesktop }}
        >
          <Typography
            color={palette.grey.primary}
            fontSize={16}
            fontWeight={400}
            textAlign={"start"}
          >
            {message}
          </Typography>
          {isMobile && (
            <Button onClick={onDelete} fullWidth data-test="deleteModal.button">
              <Typography variant="actionBoldBigIcon">
                {t("general.confirm")}
              </Typography>
            </Button>
          )}
        </Box>
      </DialogContent>
      {!isMobile && (
        <DialogActions>
          <Button onClick={onDelete}>
            <Typography variant="actionBoldBigIcon">
              {ctaText || t("general.delete")}
            </Typography>
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default memo(DeleteModal);
