import React from "react";
import { useTranslation } from "react-i18next";
import parsePhoneNumber from "libphonenumber-js";
import { AddressType } from "../../types";

interface Props {
  address: AddressType;
}

const AddressBox = ({ address }: Props) => {
  const { t } = useTranslation();

  let formattedPhone = address.phone;
  if (address.phone) {
    const parsedPhoneNumber = parsePhoneNumber(
      address.phone,
      address?.address?.country || "CA"
    );

    if (parsedPhoneNumber) {
      formattedPhone = parsedPhoneNumber.formatNational();
    }
  }

  return (
    <React.Fragment>
      <span>{address && address.organization} </span>
      <span>{address && address.name} </span>
      <span>
        {address && address.address.line1}, {address && address.address.line2}
      </span>
      <span>
        {address &&
          `${address.address.city}, ${address.address.state} ${address.address.postal_code}`}
      </span>
      <span>{address && address.address.country}</span>
      <span>
        {t("order.telephone")} {formattedPhone}
      </span>
      <span>
        {t("order.telephoneExt")} {address.phoneExt}
      </span>
      <span>
        {t("order.email")} {address.email}
      </span>
    </React.Fragment>
  );
};

export default AddressBox;
