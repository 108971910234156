import { useCallback, useMemo } from "react";
import { useStore } from "react-redux";

import DesignStateService from "../service/DesignStateService";

import { useSelector } from "../common/hooks/useSelector";
import { DESIGNS_ACTION_ENUM } from "../common/reducers/designs";
import { PRODUCT_DEFINITIONS_ACTION_ENUM } from "../common/reducers/productDefinitions";
import DesignService from "../service/DesignService";
import { DesignFromDB, DesignFromGenerator } from "../types";

export const useDesignFetcher = () => {
  const { dispatch } = useStore();

  const productDefinitionsReduxState = useSelector(
    (state) => state.productDefinitions
  );

  const productDefinitions = useMemo(() => {
    return productDefinitionsReduxState?.productDefinitions;
  }, [productDefinitionsReduxState?.productDefinitions]);

  const fetchMyDesignsAndUpdateStore = useCallback(
    async (projectId: string | undefined, hideLoading?: boolean) => {
      !hideLoading &&
        dispatch({
          type: DESIGNS_ACTION_ENUM.UPDATE_LOADING,
          payload: { loading: true },
        });

      // TODO might not be needed, to check after the redesign
      // if (!projectId && params.designId) {
      //   projectId = (
      //     await DesignService.getProjectIdFromDesignId(params.designId)
      //   ).data.data as string;
      // }

      const designsFromDB = (await DesignService.getDesigns()).data
        .data as DesignFromDB[];

      //TODO: Need to set loadingError of redux design state
      if (!designsFromDB || !projectId) {
        dispatch({
          type: DESIGNS_ACTION_ENUM.UPDATE_DESIGNS,
          payload: { designs: [] },
        });
        return;
      }
      const data = await DesignStateService.getDesignStateFromList(
        designsFromDB.filter((d) => d.projectId === projectId).map((d) => d.id)
      );

      const designsFromGenerator = data.data as DesignFromGenerator[];

      const mergedDesigns = designsFromDB.map((d) => {
        const designState = designsFromGenerator.find(
          (ds) => ds.designId === d.id
        );

        if (!designState) {
          return d;
        }

        return {
          ...d,
          ...designState,
        };
      });

      dispatch({
        type: DESIGNS_ACTION_ENUM.UPDATE_DESIGNS,
        payload: { designs: mergedDesigns },
      });
    },
    [dispatch]
  );

  //Returns the productDefinition, tagOptions, printOptions, repeatingPatternsOptions
  const fetchProductDefinition = useCallback(
    async (
      family: DesignFromGenerator["family"],
      product: DesignFromGenerator["product"]
    ) => {
      //First check if already exists in the array of productDefinitions
      const productDefFound = productDefinitions.find((productDef) => {
        if (
          productDef.family.name === family.name &&
          productDef.family.version === family.version &&
          productDef.product.name === product.name &&
          productDef.product.version === product.version
        ) {
          return true;
        }
        return false;
      });

      if (productDefFound) {
        //console.debug("!!!ProductDef found in array")
        return productDefFound;
      }

      //console.debug("!!!ProductDef not found in array, must go fetch")
      //Since not found, we fetch it
      const { data } = await DesignStateService.getProductDefinition(
        family,
        product
      );

      dispatch({
        type: PRODUCT_DEFINITIONS_ACTION_ENUM.ADD_PRODUCT_DEFINITION,
        payload: { family, product, ...data },
      });

      //console.debug("!!!ProductDef fetched", data);

      return data;
    },
    [productDefinitions, dispatch]
  );

  //Returns the productDefinition, tagOptions, printOptions, repeatingPatternsOptions
  const fetchDecorationsOptionsDefinition = useCallback(async () => {
    const { data } = await DesignStateService.getDecorationsOptionsDefinition();
    return data;
  }, []);

  const fetchDesignAsAdmin = useCallback(
    async (designId: string, userIdToValidateAccess: string) => {
      try {
        const {
          data: { data: design },
        } = await DesignService.getDesignAsAdmin(
          designId,
          userIdToValidateAccess
        );

        const { data: designState } = await DesignStateService.getDesignState(
          design.id
        );

        const productDefinition = await fetchProductDefinition(
          designState.family,
          designState.product
        );

        return {
          design,
          designState,
          productDefinition,
        };
      } catch (e: any) {
        console.debug("!!!fetchDesignAsAdmin e", e);
        console.debug("!!! fetchDesignAsAdmin e.response", e.response);
        throw e;
      }
    },
    [fetchProductDefinition]
  );

  return {
    fetchMyDesignsAndUpdateStore,
    fetchProductDefinition,
    fetchDecorationsOptionsDefinition,
    fetchDesignAsAdmin,
  };
};
