import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./AssetFileBox.module.scss";
import xImg from "../../assets/editor/X.svg";

const AssetFileBox = (props) => {
  const {
    canUpdateArtworkPosition,
    thumbnailUrl,
    assetName,
    onAssetResetChange,
    decoration,
    onDownloadOriginal,
    isAdmin,
    designTemplates,
  } = props;

  const { t } = useTranslation();
  return (
    <div className={styles.container}>
      <div className={styles.titleBox}>
        <h6>{t("general.file")}</h6>
        {isAdmin && (
          <div
            className={styles.download}
            onClick={() => onDownloadOriginal(decoration)}
          >
            {t("general.download")}
          </div>
        )}
      </div>
      {designTemplates && (
        <div className={styles.designTemplatesContainer}>
          {designTemplates.map((template) => {
            return (
              <div>
                <a href={template.url}>{t("template." + template.name)}</a>
              </div>
            );
          })}
        </div>
      )}
      <div className={styles.imageInput}>
        <img src={thumbnailUrl} width={16} alt="logo" />
        <span>{assetName}</span>
        {canUpdateArtworkPosition && (
          <span className={styles.removeAsset}>
            <img
              src={xImg}
              width={16}
              alt="logo"
              onClick={() => onAssetResetChange(decoration)}
            />
          </span>
        )}
      </div>
    </div>
  );
};

export default AssetFileBox;
