import { SxProps, createTheme } from "@mui/material";

//Button
const BUTTON_SHRINK_BY_VALUE = 0.98;

export const spacing = {
  xlDesktop: "96px",
  xlMobile: "64px",
  mediumDesktop: "12px",
  mediumMobile: "12px",
  largeDesktop: "48px",
  largeMobile: "32px",
  regularDesktop: "32px",
  regularMobile: "24px",
  groupingDesktop: "24px",
  groupingMobile: "16px",
  subtitleDesktop: "8px",
  subtitleMobile: "8px",
  smallDesktop: "8px",
  smallMobile: "8px",
  xsDesktop: "4px",
  xsMobile: "4px",
  xxsDesktop: "2px",
  ctaSpacingDesktop: "16px",
  ctaSpacingMobile: "16px",
  wrapperDesktop: "24px",
  wrapperMobile: "16px",
  small400: "5px",
};

export const palette = {
  white: "#fff",
  offWhite: "#fcfcfc",
  grag: "#555555",
  darkGray: "#666666",
  lightGray: "#dddddd",
  offLightGray: "#e5e5e5",
  green: "#3e6bad",
  lightGreen: "#48a8a5",
  grayBorder: "#f1f1f1",
  /* for background*/
  bgGray: "#f9f9f9",
  /*for tshirt*/
  burgundy: "#501d36",
  cyan: "#2484BA",
  grey: {
    nearBlack: "#111927",
    primary: "#4D5761",
    midGrey: "#E5E7EB",
    lightGrey: "#F3F4F6",
    darkGrey: "#D2D6DB",
    darkerGrey: "#9DA4AE",
    darkBackground: "#F3F4F6",
    lightBackground: "#F9FAFB",
  },
  blue: {
    primary: "#074174",
    darkBlue: "#12213B",
    primaryEffect: "#095190",
    secondary: "#2484BA",
    secondaryButtonHover: "#2892cd",
    pale: "#E2F1FD",
    paleEffect: "#F2F9FF",
    secondaryEffect: "#1F73A2",
    overlay: "rgba(18, 33, 59, 0.2)",
  },
  black: {
    headings: "#222",
    readonlyBg: "#F3F4F6",
    lines: "#F3F4F6",
    readonlyText: "#4D5761",
  },
  red: {
    error: "#D80D0D",
    salmon: "#FF8772",
  },
  misc: {
    imgPreviewDark: "#666666",
    imgPreviewLight: "#F1F1F1",
    favourites: "#D61660",
  },
  borderGradient: `linear-gradient(white, white) padding-box,
    linear-gradient(to right, rgba(84, 172, 222, 1), rgba(169, 84, 222, 1)) border-box`,
  borderGradientHover: `linear-gradient(white, white) padding-box,
    linear-gradient(to right, #C8DAE4, #D9C8E4) border-box`,
};

const tempTheme = createTheme();

export const theme = createTheme({
  palette: {
    error: {
      main: palette.red.error,
    },
  },
  typography: {
    fontFamily: "Apercu Pro",
    fontSize: 13,
    textXs: {
      fontSize: "10px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "normal",
      color: palette.grey.primary,
    },
    h1: {
      fontSize: "32px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "normal",
      color: palette.blue.darkBlue,
      [tempTheme.breakpoints.down("lg")]: {
        fontSize: "20px",
      },
    },
    h2: {
      fontSize: "20px",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "normal",
      color: palette.blue.darkBlue,
      [tempTheme.breakpoints.down("lg")]: {
        fontSize: "16px",
      },
    },
    title: {
      fontSize: "24px",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "normal",
      color: palette.blue.darkBlue,
      [tempTheme.breakpoints.down("lg")]: {
        fontSize: "20px",
      },
    },
    h5: {
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "normal",
      color: palette.blue.darkBlue,
    },
    textRegular: {
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal",
      color: palette.grey.nearBlack,
    },
    textRegularBold: {
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "normal",
      color: palette.grey.nearBlack,
    },
    textSm: {
      fontSize: "12px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal",
      color: palette.grey.nearBlack,
    },
    actionBoldBigIcon: {
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "24px",
      [tempTheme.breakpoints.down("lg")]: {
        fontWeight: 600,
      },
    },
    actionBigIcon: {
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "24px",
      color: palette.grey.primary,
      [tempTheme.breakpoints.down("lg")]: {
        fontSize: "14px",
      },
    },
    actionSmallIcon: {
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "22px",
      color: palette.grey.nearBlack,
      [tempTheme.breakpoints.down("lg")]: {
        fontSize: "12px",
        lineHeight: "18px !important",
      },
    },
    actionBoldSmallIcon: {
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "22px",
      color: palette.grey.nearBlack,
      [tempTheme.breakpoints.down("lg")]: {
        fontSize: "12px",
        fontWeight: 500,
        lineHeight: "18px !important",
      },
    },
    oldH7: {
      fontSize: "12px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "normal",
    },
    oldH8: {
      fontSize: "10px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "normal",
    },
    body1: {
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal",
      color: palette.blue.darkBlue,
    },
    body2: {
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal",
      color: palette.blue.darkBlue,
    },
  },

  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          padding: "8px 12px",
          borderRadius: 8,
          fontSize: 16,
          fontWeight: 400,
          lineHeight: "24px",
          border: `1px solid ${palette.grey.midGrey}`,
          margin: "1px",
          height: "unset",

          "&>input": {
            padding: 0,
          },
          "&.Mui-disabled": {
            background: palette.black.readonlyBg,
            color: palette.grey.primary,
            "&:hover": {
              boxShadow: "none",
              border: `1px solid ${palette.grey.midGrey}`,
            },
          },
          "&:hover": {
            boxShadow: "0px 0px 0px 2px rgba(36, 132, 186, 0.10)",
          },
          "&.Mui-focused": {
            border: `2px solid ${palette.blue.secondary}`,
            margin: "0px",
            "&>span": {
              //Prevent icon from shifting right
              marginRight: "-2px",
            },
          },
          "&.Mui-error": {
            border: `1px solid ${palette.red.error}`,
            margin: "1px",
            marginBottom: "4px",
            "&:hover, &.Mui-focused": {
              boxShadow: "0px 0px 0px 2px rgba(216, 13, 13, 0.10)",
            },
            "&>span": {
              marginRight: "0",
            },
          },
        },
        input: {
          padding: 0,
          paddingBottom: "1px",
          color: palette.black.headings,
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          paddingTop: "8px", // prevents the label from being cut
          "& .MuiInputLabel-root": {
            color: "#4D5761",
            transform: "translate(14px, -7px) scale(0.75)",
          },
          "& .MuiInputLabel-root:not(.MuiFormLabel-filled)": {
            transform: "translate(14px, 11px) scale(1)",
          },
          "& .MuiInputLabel-root.Mui-focused": {
            color: palette.blue.secondary,
            transform: "translate(14px, -7px) scale(0.75)",
          },
          "& .MuiButtonBase-root": {
            padding: 0,
          },
        },
        inputRoot: {
          maxHeight: "unset",
          border: "none !important",
          fontSize: "16px",
          borderRadius: "8px",
          padding: 0,
          paddingRight: "12px",
          margin: 0,

          [tempTheme.breakpoints.down("lg")]: {
            fontSize: "14px",
          },

          ".MuiOutlinedInput-notchedOutline": {
            borderColor: palette.grey.midGrey,
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: palette.grey.midGrey,
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: palette.blue.secondary,
          },
          "&>input": {
            padding: "8px 0px 8px 12px !important",
            fontWeight: 400,
            lineHeight: "24px",
            height: "24px",
          },
          "&.Mui-focused": {
            border: `none`,
          },
          "&:hover": {
            boxShadow: `none`,
          },
          "&:active": {
            border: `none`,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        sizeSmall: {
          fontSize: 12,
          lineHeight: "16px",
          padding: "8px 12px",
        },
        sizeMedium: {
          fontSize: 16,
          lineHeight: "24px",
          padding: "12px 20px",
          "&:active": {
            transform: `scale(${BUTTON_SHRINK_BY_VALUE}, ${BUTTON_SHRINK_BY_VALUE})`,
          },
        },
        sizeLarge: {
          fontSize: 16,
          lineHeight: "24px",
          padding: "12px 24px",
          fontWeight: 600,
          "&:active": {
            transform: `scale(${BUTTON_SHRINK_BY_VALUE}, ${BUTTON_SHRINK_BY_VALUE})`,
          },
        },
        root: {
          borderRadius: 8,
          maxHeight: 48,
          textTransform: "none",
          boxShadow: "none !important",
          "&:hover": {
            boxShadow: "none",
          },
        },
        containedPrimary: {
          backgroundColor: palette.blue.primary,
          color: palette.white,
          fontWeight: 600,

          "&:hover": {
            backgroundColor: palette.blue.primaryEffect,
          },
          "&.Mui-disabled": {
            background: "#ddd",
            color: "#858585",
          },
        },
        containedSecondary: {
          backgroundColor: palette.blue.secondary,
          color: palette.white,
          padding: "8px 12px",
          maxHeight: 36,
          fontSize: 12,
          fontWeight: 500,
          maxWidth: "fit-content",

          "&:hover": {
            backgroundColor: palette.blue.secondaryButtonHover,
          },
        },
        textPrimary: {
          background: "transparent",
          color: palette.blue.primary,
          fontWeight: 500,
          fontSize: "12px !important",
          "&:hover": {
            backgroundColor: "transparent",
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: `${palette.cyan} !important`,
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontFamily: "Apercu Pro",
          fontSize: "14px",
          fontWeight: 400,
          color: palette.darkGray,
          lineHeight: "normal",
          "&.Mui-error": {
            fontSize: "12px",
            fontWeight: 400,
            lineHeight: "150%",
          },
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        fontSizeMedium: {
          width: "20px",
          height: "20px",
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          borderRadius: "4px",
          padding: "2px",
          cursor: "pointer",
          "&:hover": {
            backgroundColor: palette.blue.pale,
          },
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          gap: "4px",
          display: "flex",
          flexDirection: "column",
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        content: {
          gap: "10px",
          display: "flex",
          alignItems: "center",
        },
        expandIconWrapper: {
          transform: "none !important",
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          gap: "8px",
          display: "flex",
          flexDirection: "column",
          padding: "0px 8px",
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          paddingRight: "16px",
          paddingLeft: "16px",
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paperWidthMd: {
          maxWidth: 720,
          [tempTheme.breakpoints.down("lg")]: {
            maxWidth: "100%",
          },
        },
        paper: {
          boxShadow: "none",
          borderRadius: "8px",
          padding: spacing.wrapperDesktop,
          [tempTheme.breakpoints.down("lg")]: {
            padding: spacing.wrapperMobile,
            borderRadius: "0px",
          },
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: "0px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: spacing.regularDesktop,
          [tempTheme.breakpoints.down("lg")]: {
            marginBottom: spacing.regularMobile,
          },
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: "0px",
          overflow: "hidden",
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          marginTop: "24px",
          padding: 0,
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          borderRadius: "8px",
          boxShadow: "0px 1px 16px 0px rgba(16, 24, 40, 0.08)",
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          padding: "8px 12px",
          display: "flex",
          justifyContent: "space-between",
          fontSize: 14,
          fontWeight: 400,
          lineHeight: "18px",
          "&.Mui-selected": {
            background: "unset",
            "&:hover": {
              background: palette.blue.paleEffect,
            },
            "&:active": {
              background: palette.blue.pale,
            },
          },
          "&:active": {
            background: palette.blue.pale,
          },
          "&:hover": {
            background: palette.blue.paleEffect,
            "&:active": {
              background: palette.blue.pale,
            },
          },
          "&.Mui-focusVisible": {
            background: "unset",
          },
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: palette.grey.lightGrey,
          opacity: 1,
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        root: {
          minWidth: "16px",
        },
        badge: {
          padding: "0px 4px",
          fontSize: 9,
          lineHeight: "normal",
          fontWeight: 500,
          maxHeight: 14,
          verticalAlign: "center",
          fontFeatureSettings: "'clig' off, 'liga' off",
          textAlign: "center",
          borderRadius: "8px",
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          borderTopLeftRadius: "12px",
          borderTopRightRadius: "12px",
          paddingTop: spacing.wrapperMobile,
          paddingBottom: spacing.wrapperMobile,
        },
      },
    },
    MuiSkeleton: {
      styleOverrides: {
        root: {
          background: palette.grey.lightBackground,
        },
      },
    },
  },
});

export type Styles = { [key: string]: SxProps };
