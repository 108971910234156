const initialState = {
  allTeams: [],
};

export const ALL_TEAMS_ACTION_ENUM = {
  UPDATE_ALL_TEAMS: "update_all_teams",
  RESET_STATE: "reset_state",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ALL_TEAMS_ACTION_ENUM.UPDATE_ALL_TEAMS:
      return { ...state, allTeams: action.payload };
    case ALL_TEAMS_ACTION_ENUM.RESET_STATE:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
