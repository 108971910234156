import React, { useEffect } from "react";
import ImageService from "../../../../service/ImageService";
import AssetRepeatingPrintSizeBox from "../../../../components/AssetSizeBox/AssetRepeatingPrintSizeBox";

const RepeatingPrintSidebar = (props) => {
  const {
    decoration,
    teamId,
    onDesignChange,
    decorationDefinition,
    canUpdateArtworkPosition,
    canUpdateArtworkColor,
    setPreviewImageData,
    isAdmin,
  } = props;

  useEffect(() => {
    if (decoration?.assetId) {
      //Because designers can update a purchased design and the designer might not be part ot the team
      const getAssetPromise = isAdmin
        ? ImageService.getAssetAsAdmin(
            decoration.assetId,
            decoration.assetVersionId,
            decoration.type,
            decoration.repeatingDetails.maxImageWidthInches
          )
        : ImageService.getAsset(
            decoration.assetId,
            decoration.assetVersionId,
            teamId,
            decoration.type,
            decoration.repeatingDetails.maxImageWidthInches
          );

      getAssetPromise
        .then((data) => {
          setPreviewImageData(data);
        })
        .catch(() => {
          setPreviewImageData(null);
        });
    } else {
      setPreviewImageData(null);
    }
  }, [
    decoration?.assetId,
    decoration?.assetVersionId,
    decoration?.type,
    decoration?.repeatingDetails.maxImageWidthInches,
    teamId,
    setPreviewImageData,
    isAdmin,
  ]);

  return (
    <React.Fragment>
      <AssetRepeatingPrintSizeBox
        canUpdateArtworkPosition={canUpdateArtworkPosition}
        canUpdateArtworkColor={canUpdateArtworkColor}
        onDesignChange={onDesignChange}
        decoration={decoration}
        decorationDef={decorationDefinition}
      />
    </React.Fragment>
  );
};

export default RepeatingPrintSidebar;
