import React, { useMemo } from "react";
import SideMenuDecorations from "./SideMenuDecorations";
import { DESIGN_CHANGE_ENUM } from "../../../../utils/ReactHooks";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";

const Decorations = (props) => {
  const {
    decorations,
    onSelectionChange,
    onDesignChange,
    selection,
    availableDecorationsDefinition,
    canUpdateArtworkPosition,
    canAddArtwork,
    canDeleteArtwork,
  } = props;

  const { t } = useTranslation();

  const availableGroups = useMemo(() => {
    const groups = Array.from(
      new Set([...availableDecorationsDefinition.map((d) => d.group)])
    );

    // Give a weight to each group for the order
    const groupAndWeight = [];
    for (const group of groups) {
      const weight = availableDecorationsDefinition
        .filter((d) => d.group === group)
        .reduce((a, c) => a + c?.display?.listOrder, 0);
      groupAndWeight.push({ group, weight });
    }

    // Sort the groups
    const orderedGroups = groupAndWeight.sort((a, b) => a.weight - b.weight);
    return orderedGroups.map((gw) => gw.group);
  }, [availableDecorationsDefinition]);

  const addOptions = availableGroups.map((group) => {
    return {
      labelKey: "specs.decoration_groups." + group,
      onClick: async () => {
        const decorationId = uuidv4();

        await onDesignChange(DESIGN_CHANGE_ENUM.ADD_DECORATION, {
          group: group,
          decorationId: decorationId,
          name: t("specs.decoration_groups." + group),
        });

        onSelectionChange("Decoration", decorationId);
      },
      disabled: !canAddArtwork,
    };
  });

  const options = decorations.map((decoration) => ({
    label: decoration.name,
    display: {
      menuIconUrl: decoration.icon || "",
      displayable: true,
      displayMethod: "icon",
    },
    onClick: () => onSelectionChange("Decoration", decoration.id),
    onHide: (e) => {
      e.stopPropagation();
      onDesignChange(DESIGN_CHANGE_ENUM.SET_DECORATION_ACTIVE, {
        decorationId: decoration.id,
        active: false,
      });
    },
    onShow: (e) => {
      e.stopPropagation();
      onDesignChange(DESIGN_CHANGE_ENUM.SET_DECORATION_ACTIVE, {
        decorationId: decoration.id,
        active: true,
      });
    },
    onDelete: (e) => {
      e.stopPropagation();
      onSelectionChange("", "", "");
      onDesignChange(DESIGN_CHANGE_ENUM.ON_REMOVE, {
        decorationId: decoration.id,
      });
    },
    isSelected:
      selection.selectedPart === "Decoration" &&
      selection.selectedElementName === decoration.id,
    canDelete: canDeleteArtwork,
    canHide: canUpdateArtworkPosition,
    active: decoration.active && !decoration.inError,
    id: decoration.id,
  }));

  return <SideMenuDecorations addOptions={addOptions} decorations={options} />;
};

export default Decorations;
