import { createContext } from "react";
import { FormikProps, FormikValues } from "formik";
import { Dictionary } from "lodash";

type Errors = {
  [key: string]: string | { key: string; values: Dictionary<string> };
};

export type FormikPropsWithCustomError<
  Values extends FormikValues = FormikValues,
> = Omit<FormikProps<Values>, "errors"> & {
  errors: Errors;
};

type FormContextType = {
  formik?: FormikPropsWithCustomError;
};

export const FormContext = createContext<FormContextType>({
  formik: undefined,
});
