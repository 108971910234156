import React from "react";
import { useTranslation } from "react-i18next";

import styles from "./YourSelection.module.scss";

const YourSelection = (props) => {
  const { onChangeQuantity, selection } = props;
  const { t } = useTranslation();

  const values = Array(25)
    .fill(0)
    .map((_, index) => index + 1);

  return (
    <div className={styles.container}>
      <h4>{t("project.your_selection")}</h4>
      {selection.length === 0 && (
        <span>{t("project.your_selection_empty")}</span>
      )}
      {selection.map((design) => (
        <div className={styles.designContainer} key={design.id + design.size}>
          <div className={styles.imgContainer}>
            {design.images?.map((image) => (
              <img
                src={image.url}
                alt={image.side}
                className={styles.img}
                key={image.side}
              />
            ))}
          </div>
          <div>
            <h6>{design.name || t("design.untitled_design")}</h6>
            <span>{t(`design.sizes.${design.size.toLowerCase()}`)}</span>
          </div>
          <select
            value={design.quantity}
            onChange={(e) =>
              onChangeQuantity(design.id, design.size, Number(e.target.value))
            }
          >
            <option value={0} key="0">
              {t("project.0_remove_from_cart")}
            </option>
            {values.map((v) => (
              <option value={v} key={v}>
                {t("project.qty")} {v}
              </option>
            ))}
          </select>
        </div>
      ))}
    </div>
  );
};

export default YourSelection;
