import React, { useCallback, useContext, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { object, string } from "yup";
import {
  Box,
  Button,
  CenteredColumnBox,
  Form,
  Icon,
  Input,
  Link,
} from "../../../common/components";
import { useForm } from "../../../common/hooks/useForm";
import { OnboardingContext } from "../../../context";
import { palette, spacing } from "../../../styles/theme";
import { ROUTES } from "../../../utils/Constant";
import { OnboardingTitle } from "../Component/OnboardingTitle";

interface Props {
  onNextStep: (email: string, password: string) => void;
  onPreviousStep: (e: React.MouseEvent<HTMLAnchorElement>) => void;
  email: string;
}

const validationSchema = object({
  password: string().required("general.errors.required.password"),
});

export const Login = (props: Props) => {
  const { onNextStep, onPreviousStep, email } = props;
  const { errorMsg } = useContext(OnboardingContext);
  const [showPasswordAsText, setShowPasswordAsText] = useState(false);
  const ref = useRef<HTMLInputElement>();

  const { t } = useTranslation();

  const formik = useForm({
    initialValues: {
      email: email,
      password: "",
    },
    onSubmit: (values) => {
      onNextStep(values.email, values.password);
    },
    validationSchema: validationSchema,
  });

  const togglePasswordVisibility = useCallback(() => {
    setShowPasswordAsText((prev) => !prev);
  }, []);

  return (
    <Box>
      <OnboardingTitle title={t("onboard.welcome")} />
      <Form formik={formik}>
        <CenteredColumnBox
          gap={{ xs: spacing.groupingMobile, sm: spacing.groupingDesktop }}
          fullWidth
        >
          <Input
            id="email"
            disabled
            fullWidth
            name="email"
            autoComplete="username"
            link={
              <Link to="" onClick={onPreviousStep} smallFont>
                {t("sign_in.change_email")}
              </Link>
            }
            label={t("general.email")}
          />
          <Input
            type={showPasswordAsText ? "text" : "password"}
            autoComplete="current-password"
            id="password"
            name="password"
            autoFocus
            fullWidth
            inputRef={ref}
            label={t("sign_in.password")}
            error={errorMsg || undefined}
            link={
              <Link
                to={ROUTES.RESET_PASSWORD}
                smallFont
                data-test="login.forgotPassword"
              >
                {t("sign_in.forgot_password")}
              </Link>
            }
            endAdornment={
              <Icon
                style={{
                  cursor: "pointer",
                }}
                icon={showPasswordAsText ? "visibility" : "visibility_off"}
                onClick={togglePasswordVisibility}
                size={20}
                color={palette.black.headings}
              />
            }
            data-test="login.password"
          />
        </CenteredColumnBox>
        <Box
          display="flex"
          justifyContent="center"
          mt={{ xs: spacing.regularMobile, sm: spacing.regularDesktop }}
          fullWidth
        >
          <Button type="submit" fullWidth data-test="login.submitButton">
            {t("sign_in.continue")}
          </Button>
        </Box>
      </Form>
    </Box>
  );
};
