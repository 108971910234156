import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import styles from "../Breadcrumbs.module.scss";

const BackToBreadcrumbs = (props) => {
  const { t } = useTranslation();
  const { activeIndex, breadcrumbs } = props;

  return (
    <div className={styles.container}>
      <ChevronLeftIcon />
      {breadcrumbs.map((breadcrumb, index) => (
        <React.Fragment key={index}>
          {activeIndex - 1 === index ? (
            <Link to={breadcrumb.to}>
              <div
                className={activeIndex > index ? styles.past : styles.active}
              >
                <span>{t(breadcrumb.labelKey)}</span>
              </div>
            </Link>
          ) : (
            <div className={styles.future}>
              {/*   <span>{t(breadcrumb.labelKey)}</span>*/}
            </div>
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

export default BackToBreadcrumbs;
