import { CSSProperties, useContext } from "react";
import AutosizeInput from "react-input-autosize";
import { FormContext } from "../../../context";
import cssStyles from "./InlineInput.module.scss";

interface Props {
  name: string;
  onBlur?: () => void;
  onKeyUp?: (e: React.KeyboardEvent<HTMLDivElement>) => void;
  autoFocus?: boolean;
  defaultValue?: string;
  inputStyle?: CSSProperties;
  styles?: CSSProperties;
  maxWidth?: string;
}

export const InlineInput = ({
  name,
  autoFocus,
  defaultValue,
  styles,
  maxWidth,
  ...props
}: Props) => {
  const { formik } = useContext(FormContext);

  return (
    <AutosizeInput
      {...props}
      style={{ ...styles, maxWidth }}
      autoFocus={autoFocus}
      inputStyle={{ ...props.inputStyle, maxWidth: maxWidth }}
      className={cssStyles.input}
      value={formik?.values[name] ?? defaultValue}
      onChange={(event) => {
        formik?.setFieldValue(name, event.target.value);
      }}
      onFocus={(e) => e.target.select()}
      onBlur={props.onBlur}
      onKeyUp={props.onKeyUp}
    />
  );
};
