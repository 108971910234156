import { memo, useCallback, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { generatePath, useNavigate } from "react-router-dom";
import {
  Box,
  EntityMenuMobile,
  Icon,
  MenuControlled,
} from "../../common/components";
import { MenuOption } from "../../common/components/Menu/Menu";
import { useSelector } from "../../common/hooks/useSelector";
import ProjectService from "../../service/ProjectService";
import { Project } from "../../types";
import { useCart } from "../../utils/CartHooks";
import { ROUTES } from "../../utils/Constant";
import { useCurrency } from "../../utils/Currencies";
import { useProjectFetcher } from "../../utils/ProjectsFetcherHooks";
import { useThemeBreakpoints } from "../../utils/themeUtils";
import DeleteModal from "../DeleteModal/DeleteModal";
import { RenameModal } from "../RenameModal";

interface Props {
  project: Project;
}

const ProjectCardMenu = ({ project }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isMobile } = useThemeBreakpoints();
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLSpanElement>(null);
  const [showRenameModal, setShowRenameModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { canDeleteProject = true, canRenameProject = true } = useSelector(
    (state) => state.userInfo
  );

  const { fetchMyProjectsAndUpdateStore, fetchTeamProjectsAndUpdateStore } =
    useProjectFetcher();
  const { fetchCartItemsAndUpdateStore } = useCart();
  const { getCurrency } = useCurrency();

  const closeMenu = () => {
    setIsOpen(false);
  };

  const onRenameClose = () => {
    setShowRenameModal(false);
  };

  const onDeleteClose = () => {
    setShowDeleteModal(false);
  };

  const onRename = useCallback(
    async (name: string) => {
      try {
        await ProjectService.updateProjectName({
          id: project.id,
          name: name,
        });
        fetchMyProjectsAndUpdateStore();
        project.teamId &&
          (await fetchTeamProjectsAndUpdateStore(project.teamId));
      } catch (e) {
        console.error(e);
      } finally {
        closeMenu();
        setShowRenameModal(false);
      }
    },
    [fetchMyProjectsAndUpdateStore, project.id]
  );

  const onDelete = useCallback(async () => {
    try {
      await ProjectService.deleteProject({ id: project?.id });

      fetchMyProjectsAndUpdateStore();
      fetchCartItemsAndUpdateStore(getCurrency()); //Because deleting a Project and it's designs can impact the cart
      project.teamId && (await fetchTeamProjectsAndUpdateStore(project.teamId));
    } catch (e) {
      console.error(e);
    } finally {
      setShowDeleteModal(false);

      if (project?.team_id) {
        navigate(
          generatePath(ROUTES.TEAM_PROJECTS, {
            teamId: project?.team_id,
          })
        );
      } else {
        navigate(ROUTES.HOME);
      }
    }
  }, [
    project,
    fetchCartItemsAndUpdateStore,
    fetchMyProjectsAndUpdateStore,
    navigate,
    getCurrency,
  ]);

  const handleMenuItemClick = (callback: any) => () => {
    callback();
    closeMenu();
  };

  const options: MenuOption[] = [
    {
      labelKey: "general.rename",
      disabled: !canRenameProject,
      onClick: handleMenuItemClick(() => setShowRenameModal(true)),
      hideIf: isMobile,
    },
    {
      labelKey: "project.delete_menu",
      disabled: !canDeleteProject,
      onClick: handleMenuItemClick(() => setShowDeleteModal(true)),
    },
  ];

  return (
    <Box>
      <Icon
        icon="more_vert"
        onClick={() => setIsOpen((prev) => !prev)}
        ref={ref}
        size={20}
        style={{
          width: "2px",
          height: "10px",
          justifyContent: "center",
          alignItems: "center",
        }}
      />

      {isMobile ? (
        <EntityMenuMobile
          isOpen={isOpen}
          onClose={closeMenu}
          options={options}
          name={project?.name}
          onSave={onRename}
          updatedAt={project.updatedAt}
        />
      ) : (
        <MenuControlled
          options={options}
          isOpen={isOpen}
          onClose={closeMenu}
          target={ref.current}
        />
      )}

      <DeleteModal
        open={showDeleteModal}
        onDelete={onDelete}
        title={t("project.delete_modal_title")}
        message={t("project.delete_modal_message")}
        onClose={onDeleteClose}
      />
      <RenameModal
        open={showRenameModal}
        onRename={onRename}
        oldName={project.name}
        onClose={onRenameClose}
        title={t("project.rename_title")}
      />
    </Box>
  );
};

export default memo(ProjectCardMenu);
