import axios from "axios";
import authService from "./authService";

const USER_API_BASE_URL = process.env.REACT_APP_BACKEND_URL;
class OrderService {
  getMyOrders() {
    return axios.get(
      USER_API_BASE_URL + "/api/v1/orders",
      authService.getAuthHeader()
    );
  }

  /**
   * Should be used for Admin stuff only
   * @returns
   */
  getPurchasedOrder(orderId) {
    const data = {
      orderId,
    };

    return axios.post(
      USER_API_BASE_URL + "/api/v1/orders/purchasedorder",
      data,
      authService.getAuthHeader()
    );
  }

  /**
   * Should be used for Admin stuff only
   * @returns
   */
  getAllPurchasedOrders() {
    const data = {};

    return axios.post(
      USER_API_BASE_URL + "/api/v1/orders/purchasedorders",
      data,
      authService.getAuthHeader()
    );
  }
}

const orderService = new OrderService();

export default orderService;
