import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import styles from "./adminPreorder.module.scss";
import AdminPurchasedItem from "../AdminPurchasedItem";
import { Button } from "../../common/components";
import AdminPreOrderPriceAndShippingDetails from "../AdminPreOrderPriceAndShippingDetails";
import AddDesignToPreorderModal from "../AddDesignToPreorderModal";
import AdminPreorderHistory from "../AdminPreorderHistory";
import { useDesignFetcher } from "../../utils/DesignsFetcherHooks";
import { Timeline } from "react-event-timeline";

const AdminPreorder = (props) => {
  const { t } = useTranslation();
  const {
    preorderDetail,
    onGoToEditPurchasedDesign,
    onAddNewDesign,
    onGoToTechpack,
    onUpdateSizesQty,
    onUpdateInvoiceNumber,
    onUpdateProjectNumber,
    onUpdateStatus,
    onUpdateShippingAddress,
    onUpdateBillingAddress,
    onUpdateDiscounts,
    onUpdateCurrency,
    onRemoveDesign,
  } = props;

  const [openAddDesignModal, setOpenAddDesignModal] = React.useState(false);

  const closeAddDesignModalClick = useCallback(
    (e) => {
      setOpenAddDesignModal(false);
    },
    [setOpenAddDesignModal]
  );

  const onClickOpenAddDesignModel = useCallback(
    (e) => {
      setOpenAddDesignModal(true);
    },
    [setOpenAddDesignModal]
  );

  const { fetchDesignAsAdmin } = useDesignFetcher();

  /**
   *
   */
  const searchDesign = useCallback(
    async (designId) => {
      try {
        const designInformation = await fetchDesignAsAdmin(
          designId,
          preorderDetail.user.id
        );

        return { ...designInformation, error: {} };
      } catch (e) {
        console.debug(e);
        console.debug("!!!e.response", e.response);

        if (e.response.status) {
          return {
            error: {
              status: e.response.status,
              message: e.response.data?.message,
            },
          };
        }

        throw e;
      }
    },
    [
      /*preorderDetail.preorder.id, */ preorderDetail.user.id,
      fetchDesignAsAdmin,
    ]
  );

  return (
    <React.Fragment>
      <div className={styles.container}>
        <h3>
          {t("preorder.preorderId")} {preorderDetail.preorder.id}
        </h3>

        <div className={styles.actions}>
          <Button
            variant="contained"
            disableElevation
            color="secondary"
            sx={{
              marginRight: "10px",
              backgroundColor: "#2484BA",
              textTransform: "capitalize",
            }}
            onClick={(e) => {
              onClickOpenAddDesignModel(e);
            }}
          >
            {t("preorder.add_product")}
          </Button>
          {onGoToTechpack && (
            <Button
              variant="contained"
              disableElevation
              color="secondary"
              sx={{
                marginRight: "10px",
                backgroundColor: "#2484BA",
                textTransform: "capitalize",
              }}
              onClick={(e) => {
                onGoToTechpack(e);
              }}
            >
              {t("techpack.go_to_techpack")}
            </Button>
          )}
        </div>
        <div className={styles.center}>
          <div className={styles.productList}>
            {preorderDetail.purchasedItems.map(
              (purchasedItem, purchasedItemIndex) => (
                <AdminPurchasedItem
                  key={purchasedItem.id}
                  purchasedItem={purchasedItem}
                  purchasedItemIndex={
                    purchasedItemIndex + 1 /* Because we start at 1, not 0 */
                  }
                  purchasedItemCount={preorderDetail.purchasedItems.length}
                  // orderId={preorderDetail.preorder.id} //Needed for the edit of items
                  // orderType={"preorder"} //Needed for the edit of items
                  onGoToEditPurchasedDesign={onGoToEditPurchasedDesign}
                  onUpdateSizesQty={onUpdateSizesQty}
                  onRemoveDesign={onRemoveDesign}
                />
              )
            )}

            <div className={styles.history}>
              <span>{t("preorder.history")}</span>
              <Timeline>
                {preorderDetail.history.map((historyItem, historyItemIndex) => (
                  <AdminPreorderHistory
                    historyItem={historyItem}
                    key={historyItemIndex}
                  />
                ))}
              </Timeline>
              ,
            </div>
          </div>
          <div className={styles.orderInfo}>
            <AdminPreOrderPriceAndShippingDetails
              preorderDetail={preorderDetail}
              onUpdateInvoiceNumber={onUpdateInvoiceNumber}
              onUpdateProjectNumber={onUpdateProjectNumber}
              onUpdateStatus={onUpdateStatus}
              onUpdateShippingAddress={onUpdateShippingAddress}
              onUpdateBillingAddress={onUpdateBillingAddress}
              onUpdateDiscounts={onUpdateDiscounts}
              onUpdateCurrency={onUpdateCurrency}
            />
          </div>
        </div>
      </div>
      <AddDesignToPreorderModal
        open={openAddDesignModal}
        onClose={closeAddDesignModalClick}
        onAddNewDesign={onAddNewDesign}
        searchDesignFunction={searchDesign}
      />
    </React.Fragment>
  );
};

export default AdminPreorder;
