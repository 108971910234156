import React, { useState, useRef, useEffect, useCallback } from "react";
import styles from "./colorPicker.module.scss";

import { ChromePicker } from "react-color";
import ColorCircle from "./../ColorSelect/ColorCircle";
import { useTranslation } from "react-i18next";

const ColorPicker = (props) => {
  const {
    value,
    onColorChange,
    openByDefault = false,
    onSelectColorFromList = null,
  } = props;

  const { t } = useTranslation();

  const componentRef = useRef(null);
  const [isOpen, setIsOpen] = useState(openByDefault);

  const onButtonClicked = useCallback(() => {
    setIsOpen((prevState) => !prevState);
  }, [setIsOpen]);

  useEffect(() => {
    // Called when the user clicked outside the color picker. It should close it.
    const handleClickOutside = (event) => {
      if (
        componentRef.current &&
        !componentRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [componentRef, setIsOpen]);

  const onColorSelect = useCallback(
    (color) => {
      onColorChange(color.hex);
    },
    [onColorChange]
  );

  return (
    <div {...props}>
      <div ref={componentRef} className={styles.container}>
        <div className={styles.topLevelButton} onClick={onButtonClicked}>
          <div className={styles.valueContainer}>
            <ColorCircle color={value} />
            <span className={styles.colorText}>
              {onSelectColorFromList
                ? t(`colors.custom`)
                : value?.toUpperCase()}
            </span>
            <div style={{ flex: 1 }} />
          </div>
        </div>
        {isOpen && (
          <div className={styles.colorPicker}>
            {onSelectColorFromList && (
              <button
                className={styles.adminButton}
                onClick={onSelectColorFromList}
              >
                {t(`colorSelect.colorFromChart`)}
              </button>
            )}
            <ChromePicker
              color={value}
              onChangeComplete={onColorSelect}
              disableAlpha={true}
              width={"100%"}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ColorPicker;
