import { OrderDetail } from "../../types";
import { Action } from "../../types/redux";

export enum MY_PREORDERS_ACTION_ENUM {
  UPDATE_PREORDERS = "update_preorders",
  UPDATE_LOADING = "update_preorders_loading",
}

interface MyPreOrdersPayload {
  preorders: OrderDetail[];
}

const initialState = {
  preorders: [],
  loading: false,
  loaded: false,
};

const reducer = (
  state = initialState,
  action: Action<MY_PREORDERS_ACTION_ENUM, MyPreOrdersPayload>
) => {
  switch (action.type) {
    case MY_PREORDERS_ACTION_ENUM.UPDATE_PREORDERS:
      return { preorders: action.payload, loading: false, loaded: true };
    case MY_PREORDERS_ACTION_ENUM.UPDATE_LOADING:
      return { ...state, loading: true, loaded: false };
    default:
      return state;
  }
};

export default reducer;
