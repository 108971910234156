import _ from "lodash";
import { useCallback } from "react";
import { useStore } from "react-redux";
import { USER_INFO_ACTION_ENUM } from "../common/reducers/userInfo";
import AuthService from "../service/authService";
import { setAnalyticsUser } from "./Analytics";

export const useUserInfoFetcher = () => {
  const { dispatch } = useStore();

  const initUserInfo = useCallback(async () => {
    const data = await AuthService.getUserInfo();

    //If not set, means we are not logged in so don't need to update the store,
    //if we do, we would probably get a circular call because the App page has the selector of userInfos
    if (!data || _.isEmpty(data)) {
      return;
    }
    const userImageUrl = (await AuthService.getUserImageUrl()).data?.data;

    dispatch({
      type: USER_INFO_ACTION_ENUM.UPDATE_USER_INFO,
      payload: { ...data, imageUrl: userImageUrl },
    });

    //Set the Segment Identity since the user has changed
    setAnalyticsUser(data);
  }, [dispatch]);

  return { initUserInfo };
};
