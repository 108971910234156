import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMoment } from "./useMoment";

export const useTimeSince = (date: string | number | Date) => {
  const { t, i18n } = useTranslation();
  const { moment, formatShortDate } = useMoment();
  const [timeSince, setTimeSince] = useState("");

  useEffect(() => {
    setTimeSince(getTimeSince(date));
    const interval = setInterval(() => setTimeSince(getTimeSince(date)), 60000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    setTimeSince(getTimeSince(date));
  }, [i18n.language]);

  const getTimeSince = useCallback(
    (date: string | number | Date) => {
      const now = moment(new Date());
      const momentDate = moment(date);
      const diffInMonths = now.diff(momentDate, "month");
      const diffInWeeks = now.diff(momentDate, "week");
      const diffInDays = now.diff(momentDate, "day");
      const diffInHours = now.diff(momentDate, "hour");
      const diffInMinutes = now.diff(momentDate, "minute");

      if (diffInMonths >= 3) {
        return t("general.updatedAt", {
          date: formatShortDate(date),
        });
      }
      if (diffInMonths > 0) {
        return t("time.month", { count: diffInMonths });
      }
      if (diffInWeeks < 4 && diffInWeeks > 0) {
        return t("time.week", { count: diffInWeeks });
      }
      if (diffInDays < 7 && diffInDays > 0) {
        return t("time.day", { count: diffInDays });
      }
      if (diffInHours < 24 && diffInHours > 0) {
        return t("time.hour", { count: diffInHours });
      }
      if (diffInHours < 1 && diffInMinutes > 2) {
        return t("time.min", { count: diffInMinutes });
      }
      if (diffInMinutes < 2) {
        return t("time.just_now");
      }
      return t("general.updatedAt", {
        date: formatShortDate(date),
      });
    },
    [t, formatShortDate]
  );

  return { timeSince };
};
