import React from "react";
import { components } from "react-select";
import styles from "./Menu.module.scss";
import { useTranslation } from "react-i18next";

const ColorMenu = ({ children, ...props }) => {
  const { enableCustomColorSelection, onSelectCustomColor } = props;
  const { t } = useTranslation();

  return (
    <components.Menu {...props}>
      {children}
      {enableCustomColorSelection && (
        <div>
          <button className={styles.adminButton} onClick={onSelectCustomColor}>
            {t(`colorSelect.customColor`)}
          </button>
        </div>
      )}
    </components.Menu>
  );
};

export default ColorMenu;
