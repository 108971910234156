import { SxProps, Typography } from "@mui/material";
import { createStyles } from "@mui/styles";
import { CSSProperties, MouseEventHandler, isValidElement } from "react";
import { NavLinkProps, NavLink as RRDNavLink } from "react-router-dom";
import { palette } from "../../../styles/theme";
import { useThemeBreakpoints } from "../../../utils/themeUtils";
import { Box } from "../Box";
import { Icon } from "../Icon";
import { MaterialSymbolType } from "../Icon/IconConstant";

const styles = createStyles({
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    textDecoration: "none",
    userSelect: "none",
  },
  activeActionItem: {
    background: palette.blue.pale,
    "&:hover": {
      background: palette.blue.pale,
    },
  },
  actionItem: {
    width: "100%",
    padding: "8px",
    borderRadius: "4px",
    fontStyle: "normal",
    fontWeight: "normal",

    color: "black",
    display: "flex",
    flexDirection: "row",
    cursor: "pointer",

    alignItems: "center",
    gap: "8px",
    "&:hover": {
      background: palette.blue.paleEffect,
    },
  },
});

interface Props extends Omit<Omit<NavLinkProps, "onClick">, "content"> {
  startIcon?: MaterialSymbolType | JSX.Element;
  endIcon?: MaterialSymbolType | JSX.Element;
  onClick?: MouseEventHandler<HTMLDivElement | HTMLAnchorElement>;
  content: string | JSX.Element;
  contentStyles?: SxProps;
  noActiveStyle?: boolean;
  isActive?: boolean;
  clickableLink?: boolean;
  "data-test"?: string;
}

export const NavLink = ({
  startIcon,
  endIcon,
  content,
  contentStyles,
  noActiveStyle,
  isActive,
  clickableLink,
  ...props
}: Props) => {
  const { isMobile } = useThemeBreakpoints();

  const getContent = (_isActive: boolean) => (
    <Box
      sx={[
        styles.container,
        styles.actionItem,
        (_isActive || isActive) && !noActiveStyle
          ? styles.activeActionItem
          : null,
      ]}
      onClick={(e) => {
        !clickableLink && props.onClick?.(e);
      }}
      data-test={
        props.onClick && !clickableLink ? props["data-test"] : undefined
      }
    >
      {Boolean(startIcon) && (
        <Box>
          {isValidElement(startIcon) ? (
            startIcon
          ) : (
            <Icon
              icon={startIcon as MaterialSymbolType}
              size={isMobile ? 18 : 22}
            />
          )}
        </Box>
      )}
      <Box
        display="flex"
        justifyContent="space-between"
        width="100%"
        alignItems="center"
      >
        {typeof content === "string" ? (
          <Typography variant="actionSmallIcon" sx={contentStyles}>
            {content}
          </Typography>
        ) : (
          content
        )}
        {Boolean(endIcon) && (
          <Box>
            {isValidElement(endIcon) ? (
              endIcon
            ) : (
              <Icon
                icon={endIcon as MaterialSymbolType}
                size={isMobile ? 18 : 22}
              />
            )}
          </Box>
        )}
      </Box>
    </Box>
  );

  return props.onClick && !clickableLink ? (
    <Box sx={props.style as CSSProperties}>{getContent(false)}</Box>
  ) : (
    <RRDNavLink
      {...props}
      style={{ ...(styles.container as CSSProperties), ...props.style }}
    >
      {(opts) => getContent(opts.isActive)}
    </RRDNavLink>
  );
};
