import React, { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import ImageService from "../../../../service/ImageService";
import { DESIGN_CHANGE_ENUM } from "../../../../utils/ReactHooks";
import AssetPrintSideBox from "../../../../components/AssetSizeBox/AssetPrintSizeBox";
import AssetColorBox from "../../../../components/AssetColorBox";

const PrintSidebar = (props) => {
  const {
    decoration,
    teamId,
    onDesignChange,
    canUpdateArtworkPosition,
    canUpdateArtworkColor,
    setPreviewImageData,
    imageData,
    isAdmin = false,
    hideSizeValue = false,
  } = props;
  const { t } = useTranslation();

  useEffect(() => {
    // If the selected size is set (it could be that the decoration is in error or it's too small for the current position)
    // Select the longest side of the decoration as the size to give the asset-manager
    let asset_size = null;
    if (decoration.assetSize.selectedSize) {
      const decorationWidth = decoration.assetSize.selectedSize.widthInInches;
      const decorationHeight = decoration.assetSize.selectedSize.heightInInches;
      asset_size =
        decorationWidth > decorationHeight ? decorationWidth : decorationHeight;
    }
    if (decoration?.assetId) {
      const getAssetPromise = isAdmin
        ? ImageService.getAssetAsAdmin(
            decoration.assetId,
            decoration.assetVersionId,
            decoration.type,
            asset_size
          )
        : ImageService.getAsset(
            decoration.assetId,
            decoration.assetVersionId,
            teamId,
            decoration.type,
            asset_size
          );

      getAssetPromise
        .then((data) => {
          setPreviewImageData(data);
        })
        .catch(() => {
          setPreviewImageData(null);
        });
    } else {
      setPreviewImageData(null);
    }
  }, [
    decoration?.assetId,
    decoration?.assetVersionId,
    decoration?.type,
    decoration?.latestUpdate,
    teamId,
    decoration?.assetSize?.selectedSize,
    decoration?.assetSize?.selectedSize?.widthInInches,
    decoration?.assetSize?.selectedSize?.heightInInches,
    setPreviewImageData,
    isAdmin,
  ]); //latestUpdate is required here to fetch the new image on update

  // TODO: Use the API route for color codes
  const [colorCodes, setColorCodes] = useState([]);

  useEffect(() => {
    if (
      imageData &&
      imageData.assetType &&
      imageData.useColorExtractionAndMatching
    ) {
      ImageService.getColorCodes(imageData.assetType).then((res) => {
        setColorCodes(res);
      });
    }
  }, [
    imageData,
    imageData?.assetType,
    imageData?.useColorExtractionAndMatching,
  ]);

  const onAssetLogoColorChange = useCallback(
    (color, index, transparent) => {
      onDesignChange(DESIGN_CHANGE_ENUM.UPDATE_PRINT_COLOR, {
        color,
        transparent,
        index,
        decorationId: decoration.id,
      });
    },
    [onDesignChange, decoration]
  );

  return (
    <React.Fragment>
      <AssetPrintSideBox
        canUpdateArtworkPosition={canUpdateArtworkPosition}
        onDesignChange={onDesignChange}
        decoration={decoration}
        hideSizeValue={hideSizeValue}
      />

      {imageData && imageData.selectedColors && (
        <AssetColorBox
          colorBoxTitle={t("specs.colors")}
          selectedColors={imageData?.selectedColors.map((c) =>
            c.transparent ? "transparent" : c.chartColor.name
          )}
          allowTransparent={true}
          deprecateTransparency={true} // Remove when the transparency problem with the production is fixed
          availableColors={colorCodes}
          onColorChanged={onAssetLogoColorChange}
          active={true}
          canUpdateArtworkColor={canUpdateArtworkColor}
          isAdmin={isAdmin}
        />
      )}
    </React.Fragment>
  );
};

export default PrintSidebar;
