import classNames from "classnames";
import { ComponentProps, forwardRef } from "react";
import { MaterialSymbolType } from "./IconConstant";

interface Props extends ComponentProps<"span"> {
  icon: MaterialSymbolType;
  size?: number;
  filled?: boolean;
  className?: string;
}

export const Icon = forwardRef<HTMLSpanElement, Props>(
  ({ icon, filled, size = 20, color, className, ...props }, ref) => {
    return (
      <span
        {...props}
        className={classNames("material-symbols-outlined", className, {
          filled: filled,
        })}
        ref={ref}
        style={{
          display: "flex",
          cursor: props.onClick ? "pointer" : undefined,
          width: `${size}px`,
          height: `${size}px`,
          fontSize: `${size}px`,
          color: color,
          userSelect: "none",
          ...props.style,
        }}
      >
        {icon}
      </span>
    );
  }
);
