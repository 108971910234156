import React from "react";
// import { useTranslation } from "react-i18next";

const Filter = (props) => {
  // const { t } = useTranslation();
  // const {
  // } = props;

  return (
    <React.Fragment>
      <div>Filter: TODO</div>
    </React.Fragment>
  );
};

export default Filter;
