import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import DesignService from "../../../service/DesignService";
import { ROUTES } from "../../../utils/Constant";
import { routeFormatter } from "../../../utils/Formatter";

import styles from "./YourDetails.module.scss";

const YourDetails = (props) => {
  const navigate = useNavigate();
  const { selection, token } = props;
  const { t } = useTranslation();
  const [details, setDetails] = useState({
    firstName: "",
    lastName: "",
    email: "",
  });

  const onSubmit = async () => {
    await DesignService.postCollectSize({
      details,
      selection: selection.map((s) => ({
        id: s.id,
        size: s.size,
        quantity: s.quantity,
      })),
    });

    navigate(
      routeFormatter(ROUTES.COLLECT_SIZE_SUCCESS, {
        params: {
          token,
        },
      })
    );
    window.analytics.track("Sizes collected", {
      itemsCollected: selection.map((s) => ({
        id: s.id,
        size: s.size,
        quantity: s.quantity,
      })),
      customerDetails: details,
    });
  };

  const isDisabled = useMemo(() => {
    if (!selection.length) {
      return true;
    }
    if (!details.firstName || !details.lastName || !details.email) {
      return true;
    }

    return false;
  }, [details, selection]);

  return (
    <div className={styles.container}>
      <h4>{t("project.your_details")}</h4>

      <div key={"firstName"}>
        <b>{t(`project.firstName`)}</b>
        <input
          value={details["firstName"]}
          onChange={(e) => {
            const { value } = e.target;
            setDetails({
              firstName: value,
              lastName: details.lastName,
              email: details.email,
            });
          }}
          placeholder={t("project.firstName")}
          autoComplete="given-name"
          id="given-name"
          name="given-name"
          type="text"
          required
        />
      </div>
      <div key={"lastName"}>
        <b>{t(`project.lastName`)}</b>
        <input
          value={details["lastName"]}
          onChange={(e) => {
            const { value } = e.target;
            setDetails({
              firstName: details.firstName,
              lastName: value,
              email: details.email,
            });
          }}
          placeholder={t("project.lastName")}
          autoComplete="family-name"
          id="family-name"
          name="family-name"
          type="text"
          required
        />
      </div>
      <div key={"email"}>
        <b>{t(`project.email`)}</b>
        <input
          value={details["email"]}
          onChange={(e) => {
            const { value } = e.target;
            setDetails({
              firstName: details.firstName,
              lastName: details.lastName,
              email: value,
            });
          }}
          placeholder={t("project.email")}
          autoComplete="username"
          id="email"
          name="email"
          type="email"
          required
        />
      </div>
      <button onClick={onSubmit} disabled={isDisabled}>
        {t("project.submit")}
      </button>
    </div>
  );
};

export default YourDetails;
