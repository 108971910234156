import React from "react";
import Toolbar from "@mui/material/Toolbar";
import styles from "./techpackToolbar.module.scss";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";

const TechpackToolbar = (props) => {
  const {
    onClickDownload = null,
    onClickBackToCustomerOrder,
    actionText,
  } = props;
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Toolbar
        className={styles.toolbar}
        sx={{
          alignItems: "center",
          justifyContent: "space-between",
          backgroundColor: "#262626",
          //paddingTop: 5,
          //paddingBottom: 5
        }}
        disableGutters
      >
        <div className={styles.leftSection}>
          <div
            className={styles.backToCustomerOrders}
            onClick={onClickBackToCustomerOrder}
          >
            <NavigateBeforeIcon fontSize="medium" />
            <span>{t("techpack.back_to_customer_order")}</span>
          </div>
        </div>

        <div className={styles.rightSection}>
          <div className={styles.actionButtons}>
            <span className={styles.actionText}>{actionText}</span>
            {onClickDownload && (
              <Button
                variant="contained"
                disableElevation
                sx={{
                  marginRight: "10px",
                  backgroundColor: "#2484BA",
                  textTransform: "capitalize",
                }}
                onClick={onClickDownload}
              >
                {t("techpack.download")}
              </Button>
            )}
          </div>
        </div>
      </Toolbar>
    </React.Fragment>
  );
};
export default TechpackToolbar;
