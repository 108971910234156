import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useLocation } from "react-router-dom";
import DesignStateService from "../../service/DesignStateService";
import ProjectService from "../../service/ProjectService";
import styles from "./CollectSizeContainer.module.scss";
import Footer from "./Footer";
import CollectSizeHeader from "./Header";
import ImageService from "../../service/ImageService";
import Swag from "./Swag";
import YourDetails from "./YourDetails";
import YourSelection from "./YourSelection";
import queryString from "query-string";
import { setLanguage } from "../../utils/LocaleUtil";
import { useDesignFetcher } from "../../utils/DesignsFetcherHooks";
const CollectSizeContainer = () => {
  const { t } = useTranslation();
  const { token } = useParams();
  const location = useLocation();

  const { fetchProductDefinition } = useDesignFetcher();

  useEffect(() => {
    const query = queryString.parse(location.search); // returns the query object
    let lang = query.lang;
    if (lang) {
      setLanguage(lang);
    }
  }, [location.search]);

  const [isLoading, setIsLoading] = useState(true);
  const [collectSizeInfo, setCollectSizeInfo] = useState({
    project: null,
    designs: null,
  });
  const [selection, setSelection] = useState([]);

  const onAddToSelection = (design, size) => {
    setSelection((oldSelection) => {
      let newSelection = [...oldSelection];
      const index = oldSelection.findIndex(
        (s) => s.id === design.id && s.size === size
      );

      if (index === -1) {
        // Not found, add to selection
        newSelection.push({
          id: design.id,
          name: design.name || t("design.untitled_design"),
          images: design.images,
          size,
          quantity: 1,
        });
        window.analytics.track("Product added to collect sizes", {
          sizeSelected: size,
          designId: design.id,
        });
      } else {
        newSelection[index] = {
          ...newSelection[index],
          quantity: newSelection[index].quantity + 1,
        };
      }

      newSelection = newSelection
        .sort((a, b) => a.id > b.id)
        .sort((a, b) => a.size > b.size);

      return newSelection;
    });
  };

  const onChangeQuantity = (id, size, quantity) => {
    setSelection((oldSelection) => {
      if (quantity === 0) {
        window.analytics.track("Product removed from collect sizes", {
          sizeSelected: size,
          designId: id,
        });

        return [...oldSelection].filter(
          (selection) => !(selection.id === id && selection.size === size)
        );
      }

      const index = oldSelection.findIndex(
        (selection) => selection.id === id && selection.size === size
      );

      if (index === -1) {
        return oldSelection;
      }

      const newSelection = [...oldSelection];

      newSelection[index].quantity = quantity;

      return newSelection;
    });
  };

  const init = useCallback(async () => {
    const {
      data: { data },
    } = await ProjectService.getCollectSizeInfo(token);

    const designIds = data.designs.map((d) => d.id);

    const designsStates = await Promise.all(
      designIds.map(async (designId) => {
        const { data } = await DesignStateService.getDesignState(designId);
        const designState = data;

        const productDef = await fetchProductDefinition(
          designState.design.family,
          designState.design.product
        );
        designState.productDefinition = productDef.productDefinition;

        return {
          ...designState,
          productDefinition: productDef.productDefinition,
        };
      })
    );

    setCollectSizeInfo({
      project: data.project,
      designs: data.designs.map((d) => {
        const designState = designsStates.find((ds) => ds.designId === d.id);
        return {
          ...d,
          images: designState.images,
          sizes: designState.productDefinition.sizes,
          designState: designState, //Used to get design product name and description
        };
      }),
    });
    setIsLoading(false);
  }, [token, fetchProductDefinition]);

  //If the cover image uuid is set, then fetch the image
  const [coverPhotoData, setCoverPhotoData] = useState(null);
  useEffect(() => {
    if (collectSizeInfo?.project?.coverPhotoUuid) {
      ImageService.getPublicImage(collectSizeInfo.project.coverPhotoUuid)
        .then((data) => {
          setCoverPhotoData(data);
        })
        .catch(() => {
          setCoverPhotoData(null);
        });
    } else {
      setCoverPhotoData(null);
    }
  }, [collectSizeInfo?.project?.coverPhotoUuid]);

  //If the icon uuid is set, then fetch the image
  const [iconPhotoData, setIconPhotoData] = useState(null);
  useEffect(() => {
    if (collectSizeInfo?.project?.iconUuid) {
      ImageService.getPublicImage(collectSizeInfo.project.iconUuid)
        .then((data) => {
          setIconPhotoData(data);
        })
        .catch(() => {
          setIconPhotoData(null);
        });
    } else {
      setIconPhotoData(null);
    }
  }, [collectSizeInfo?.project?.iconUuid]);

  useEffect(() => {
    init();
  }, [init]);

  if (isLoading) {
    return <>Loading...</>;
  }

  let mainContainerStyleName = styles.container;
  const coverPhotoUrl = coverPhotoData?.fullSizeUrl;
  const iconUrl = iconPhotoData?.fullSizeUrl;

  if (!coverPhotoUrl) {
    if (!iconUrl) {
      mainContainerStyleName = styles.containerWithoutCover;
    } else {
      mainContainerStyleName = styles.containerWithoutCoverButWithIcon;
    }
  }

  let swagDivNeedPadding = true;
  if (!iconUrl) {
    swagDivNeedPadding = false;
  }

  return (
    <div className={mainContainerStyleName}>
      <CollectSizeHeader
        iconUrl={iconPhotoData?.fullSizeUrl}
        coverPhotoUrl={coverPhotoData?.fullSizeUrl}
      />
      <Swag
        title={collectSizeInfo.project.name}
        designs={collectSizeInfo.designs}
        onAddToSelection={onAddToSelection}
        topNeedExtraPadding={swagDivNeedPadding}
      />

      <YourSelection
        selection={selection}
        onChangeQuantity={onChangeQuantity}
      />

      <YourDetails selection={selection} token={token} />

      <Footer />
    </div>
  );
};

export default CollectSizeContainer;
