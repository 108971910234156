import ExpandLess from "@mui/icons-material/ArrowDropDown";
import ExpandMore from "@mui/icons-material/ArrowRight";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import Collapse from "@mui/material/Collapse";
import _ from "lodash";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useStore } from "react-redux";
import { CART_ACTION_ENUM } from "../../../common/reducers/cart";
import Message from "../../../components/Message";
import config from "../../../config";
import { SpecsContext } from "../../../context/Specs/SpecsContext";
import { CartItem, Currency, Size, SpecsProductVersion } from "../../../types";
import { useCurrency } from "../../../utils/Currencies";
import styles from "./CartItemSizeSelector.module.scss";

interface Props extends CartItem {
  onChange: () => void;
  onRemove: (id: string, productName: string) => void;
}

export const CartItemSizeCollector = (props: Props) => {
  const { t } = useTranslation();

  const { specs } = useContext(SpecsContext);

  const {
    collectedSizes,
    deprecatedDesign: deprecatedDesignFromCartItem,
    designName,
    id,
    onChange,
    onRemove,
    pricing,
    productImages,
    productName,
    versionName,
    sizes,
    designState,
  } = props;

  const [minimumPrice, setMinimumPrice] = useState(0);
  const [maximumPrice, setMaximumPrice] = useState(0);
  const [productDefinition, setProductDefinition] = useState<
    SpecsProductVersion | undefined
  >();

  const [isExpanded, setExpanded] = useState(false); //Collected sizes expanded or collapsed

  /**
   * Because the onChange will most likely be throttled and
   * the call to update sizes will be followed by a get of updated items,
   * We must use a local size that will be updated on size change (for the user to get feedback)
   * and when the get items will return (thus sizes from props will be updated), the local values will be updated if the backend didn't update to what the customer asked.
   * That way what will be displayed is at one point what the customer wants it to be and after what the server has stored
   */
  const [localSizes, setLocalSizes] = useState<Size[]>([]);
  useEffect(() => {
    setLocalSizes(_.cloneDeep(sizes));
  }, [sizes]);

  /**
   * Expand or collapse cli
   */
  const handleClick = useCallback(() => {
    setExpanded(!isExpanded);
  }, [isExpanded]);

  const { dispatch } = useStore();

  const { NarrowCurrencyFormatter, getCurrency } = useCurrency();

  //Get the product definition for that item
  useEffect(() => {
    const productDefinition = specs?.products.find(
      (product) => product.name === productName
    );
    const version = productDefinition?.versions.find(
      (v) => v.name === versionName
    );
    setProductDefinition(version);
  }, [productName, specs?.products, versionName]);

  const availableSizes = useMemo(() => {
    if (!productDefinition) {
      return [];
    }
    return productDefinition.sizes;
  }, [productDefinition]);

  const deprecatedDesign = useMemo(() => {
    return (
      (deprecatedDesignFromCartItem &&
        config.blockPaymentWhenDesignIsDeprecated) ||
      false
    );
  }, [deprecatedDesignFromCartItem]);

  /**
   * Calculate the min and max price to show
   */
  useEffect(() => {
    const currency = getCurrency() as Currency;

    if (!currency || !productDefinition) {
      return;
    }
    const pricingOfCurrentCurrency =
      productDefinition.pricing.pricingCurrency[currency];

    const priceRanges = pricingOfCurrentCurrency.pricingRange
      .map((priceRange) => [
        Number(priceRange.qty),
        Number(priceRange.price + pricing.priceOfExtraCreditsPerUnit),
      ])
      .sort((a, b) => a[1] - b[1]); //FYI: We sort by price, not by qty... that is why we use [1] and not [0]

    //TODO Need to add the price of customization (if needed)
    setMinimumPrice(priceRanges[0][1]);
    setMaximumPrice(priceRanges[priceRanges.length - 1][1]);
  }, [getCurrency, productDefinition, pricing]);

  /**
   * Compute the total sizes collected using the collect sizes feature
   */
  const sizeCollectedCount = useMemo(
    () => collectedSizes.reduce((prev, curr) => prev + curr.quantity, 0),
    [collectedSizes]
  );

  const onSizeChange = useCallback(
    (e: any, size: Size) => {
      const amount = Number(e.target.value) || 0;

      setLocalSizes((oldSizes) => {
        const newSizes = _.cloneDeep(oldSizes);
        const updatedSizeIndex = newSizes.findIndex(
          (oldsize) => oldsize.name === size.name
        );
        if (updatedSizeIndex > -1) {
          newSizes[updatedSizeIndex].qty = amount;
        }
        return newSizes;
      });

      dispatch({
        type: CART_ACTION_ENUM.UPDATE_ITEM_SIZE,
        payload: {
          id,
          size,
          amount,
        },
      });
      //dispatch({ type: CART_ACTION_ENUM.SET_SAVING, payload: {isLoading:true} });

      onChange();
    },
    [dispatch, id, onChange]
  );

  // FIXME: This needs to be revisited when we work on the collect size page.
  // Right now we check the timestamp to see if we should display on the same line
  // or on different lines. For all items received within 1 sec for the same designId,
  // we assume it should be displayed on the same line.
  const groupedBySameTimestamp = collectedSizes.reduce(
    (prev, curr) => {
      // Here, we check if the item should be displayed on the same line as a previous one.
      const lineToAdjust = prev.find(
        (c) =>
          c.email === curr.email &&
          c.first_name === curr.first_name &&
          c.last_name === curr.last_name &&
          c.designId === curr.designId &&
          Math.abs(
            new Date(c.created_at).getTime() -
              new Date(curr.created_at).getTime()
          ) < 1000
      ); // Sent within 1 sec

      if (lineToAdjust) {
        // When we get here, the item in question should be merged to an existing one.
        const quantityToAdjust = lineToAdjust.sizes.find(
          (s) => s.sizeName === curr.size
        );
        if (quantityToAdjust) {
          // If the user set twice the same size, adjust the quantity.
          quantityToAdjust.quantity += curr.quantity;
        } else {
          // Else add the new size to the list.
          lineToAdjust.sizes.push({
            sizeName: curr.size,
            quantity: curr.quantity,
          });
        }
      } else {
        // Different submission = new line
        const entry = {
          email: curr.email,
          first_name: curr.first_name,
          last_name: curr.last_name,
          designId: curr.designId,
          sizes: [{ sizeName: curr.size, quantity: curr.quantity }],
          created_at: curr.created_at,
        };
        prev.push(entry);
      }
      return prev;
    },
    [
      {
        email: collectedSizes[0]?.email,
        first_name: collectedSizes[0]?.first_name,
        last_name: collectedSizes[0]?.last_name,
        designId: collectedSizes[0]?.designId,
        sizes: [
          {
            sizeName: collectedSizes[0]?.size,
            quantity: collectedSizes[0]?.quantity,
          },
        ],
        created_at: collectedSizes[0]?.created_at,
      },
    ]
  );

  const collectedSizeTotal = groupedBySameTimestamp.reduce<
    { sizeName: string; total: number }[]
  >((prev, curr) => {
    for (const size of curr.sizes) {
      const totalToUpdate = prev.find((s) => s.sizeName === size.sizeName);
      if (totalToUpdate) {
        totalToUpdate.total += size.quantity;
      } else {
        prev.push({ sizeName: size.sizeName, total: size.quantity });
      }
    }
    return prev;
  }, []);

  return (
    <div className={styles.container}>
      {deprecatedDesign && (
        <Message
          message={t("generator.warnings.styleOrColorNoLongerAvailable")}
          messageIcon={<WarningAmberOutlinedIcon />}
          fullWidth={false}
        />
      )}
      <div className={styles.generalInfo}>
        <div className={styles.imgContainer}>
          {productImages?.map((image) => (
            <img
              src={image.url}
              alt={image.side}
              className={styles.img}
              key={image.side}
            />
          ))}
        </div>
        <div className={styles.nameAndDescription}>
          <h5 data-test={designName}>
            {designName || t("design.untitled_design")}
          </h5>
          {sizeCollectedCount > 0 && (
            <div className={styles.collectedSizes}>
              <PersonOutlineOutlinedIcon
                style={{ color: "#666666", transform: "scale(0.75)" }}
              />{" "}
              {sizeCollectedCount} {t("cart.size_collected")}
            </div>
          )}

          <div className={styles.priceContainer}>
            {t("pricing.price_range_each", {
              lbound: NarrowCurrencyFormatter?.format(minimumPrice),
              ubound: NarrowCurrencyFormatter?.format(maximumPrice),
            })}
          </div>
          <div className={styles.customizations}>
            <span>
              {t("pricing.credits_used_design", {
                creditsUsed:
                  designState?.customizationPricing?.creditsUsedInDesign,
              })}
              {/*TODO Make the translation plurial and singular check https://react.i18next.com/latest/trans-component#using-with-react-components*/}
            </span>
          </div>
        </div>
        <div className={styles.removeContainer}>
          <span
            className={styles.remove}
            onClick={() => onRemove(id, productName)}
          >
            {t("cart.remove")}
          </span>
        </div>
      </div>
      <hr />

      <div className={styles.sizeSelection}>
        <div className={styles.tableRow}>
          <div></div>
          {availableSizes?.map((availableSize, index) => (
            <div key={`${availableSize.name} ${index}`}>
              <b>{t(`cart.${availableSize.displayLabel}`)}</b>
            </div>
          ))}
        </div>
        <div className={styles.tableRow}>
          <div>
            <b>{t("cart.my_order")}</b>
          </div>
          {availableSizes?.map((availableSize, index) => (
            <div key={`${availableSize.name} ${index}`}>
              <input
                type="text"
                className={styles.input}
                // defaultValue={
                //   sizes.find((size) => size.name === availableSize.name)?.qty ||
                //   0
                // }
                value={
                  localSizes.find((size) => size.name === availableSize.name)
                    ?.qty || 0
                }
                onChange={(e) => onSizeChange(e, availableSize)}
              ></input>
            </div>
          ))}
        </div>
        {collectedSizes.length ? (
          <React.Fragment>
            <div className={styles.tableRowCollected}>
              <div className={styles.collapseActions} onClick={handleClick}>
                {isExpanded ? <ExpandLess /> : <ExpandMore />}
                <b>{t("cart.collected")}</b>
              </div>

              {availableSizes?.map((availableSize) => (
                <div key={availableSize.name}>
                  {collectedSizeTotal.find(
                    (t) => t.sizeName === availableSize.name
                  )?.total || 0}
                </div>
              ))}
            </div>
            <Collapse in={isExpanded} timeout="auto" unmountOnExit>
              {groupedBySameTimestamp.map((client, index) => (
                <div
                  className={`${styles.tableRowCollectedList} ${
                    index % 2 === 0 ? styles.odd : null
                  }`}
                  key={index}
                >
                  <div>{`${client.first_name} ${client.last_name}`}</div>
                  {availableSizes?.map((availableSize) => (
                    <div key={availableSize.name}>
                      {client.sizes.find(
                        (s) => s.sizeName === availableSize.name
                      )?.quantity || ""}
                    </div>
                  ))}
                </div>
              ))}
            </Collapse>
          </React.Fragment>
        ) : null}
      </div>
    </div>
  );
};
