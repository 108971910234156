import React from "react";
import { components } from "react-select";
import ColorCircle from "../ColorCircle";

import styles from "./Option.module.scss";

const ColorOption = ({ children, isSelected, ...props }) => {
  return (
    <components.Option {...props} className={styles.option}>
      <ColorCircle
        isSelected={isSelected}
        color={props.data.hexCode}
        colors={props.data.colorsHex}
        png={props.data.png}
        svg={props.data.svg}
        tooltip={props.data.label}
      />
    </components.Option>
  );
};

export default ColorOption;
