import * as React from "react";
import { useTranslation } from "react-i18next";
import styles from "./radioButton.module.scss";

export default function RowRadioButtonsGroup(props) {
  const { setLangVar } = props;
  const { t } = useTranslation();

  const en_extension = "?lang=en";
  const fr_extension = "?lang=fr";

  const [value, setValue] = React.useState(en_extension);
  const handleChange = (event) => {
    setValue(event.target.value);
    setLangVar(event.target.value);
  };

  return (
    <div>
      <div className={styles.radioButtonText}>{t("sizes.page_language")}</div>
      <div className={styles.radioButtonGroup}>
        <div className={styles.radioButton}>
          <input
            id={"en"}
            type={"radio"}
            value={en_extension}
            checked={value === en_extension}
            onChange={handleChange}
            name={"language"}
          />
          <label htmlFor={"en"}>English</label>
        </div>
        <div className={styles.radioButton}>
          <input
            id={"fr"}
            type={"radio"}
            value={fr_extension}
            checked={value === fr_extension}
            onChange={handleChange}
            name={"language"}
          />
          <label htmlFor={"fr"}>Francais</label>
        </div>
      </div>
    </div>
  );
}
