import React from "react";
import styles from "./AssetColorBox.module.scss";
import ColorSelect from "../../common/components/Select/ColorSelect";

const AssetColorBox = (props) => {
  const {
    colorBoxTitle,
    selectedColors,
    allowTransparent,
    deprecateTransparency,
    availableColors,
    onColorChanged,
    active,
    canUpdateArtworkColor,
    isAdmin = false,
  } = props;

  return (
    <React.Fragment>
      <h6>{colorBoxTitle}</h6>
      {selectedColors.map((selectedColorName, index) => (
        <div className={styles.colorContainer} key={selectedColorName}>
          <ColorSelect
            value={{ value: selectedColorName }}
            availableColors={availableColors}
            allowTransparent={allowTransparent}
            deprecateTransparency={deprecateTransparency}
            onChange={(c) => {
              const color = c.value;
              const transparent = c.value === "transparent";
              onColorChanged(color, index, transparent);
            }}
            active={active}
            disableAllColors={!canUpdateArtworkColor}
            isAdmin={isAdmin}
          />
        </div>
      ))}
    </React.Fragment>
  );
};

export default AssetColorBox;
