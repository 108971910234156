import React, { useCallback, useEffect, useMemo, useState } from "react";
import styles from "../StudioRightSidebar.module.scss";
import { DEPRECATION_TYPE_ENUM } from "../../../../utils/ReactHooks";
import RightSidebar from "../../../../common/components/RightSidebar";
import { useTranslation } from "react-i18next";
import ColorSelect from "../../../../common/components/Select/ColorSelect";
import ColorPicker from "../../../../common/components/Select/ColorPickerSelect";
import { DESIGN_CHANGE_ENUM } from "../../../../utils/ReactHooks";
import classNames from "classnames";
import DarkTooltip from "../../../../common/components/DarkTooltip";
import _ from "lodash";

const ElementsSidebar = (props) => {
  const [designValueOfSelectedElement, setDesignValueOfSelectedElement] =
    useState(null);
  const [selectedPartElementDef, setSelectedPartElementDef] = useState(null);

  const { t } = useTranslation();
  const {
    productName,
    onDesignChange,
    //isUpdating,
    canUpdateDesignStyles,
    canUpdateDesignColors,
    deprecatedDesignItems,
    colorBleedingItems,
    selection,
    isAdmin,
  } = props;

  const designValueOfSelectedElementFromProps =
    props.designValueOfSelectedElement;
  const selectedPartElementDefFromProps = props.selectedPartElementDef;

  useEffect(() => {
    setDesignValueOfSelectedElement(
      _.cloneDeep(designValueOfSelectedElementFromProps)
    );
  }, [designValueOfSelectedElementFromProps]);

  useEffect(() => {
    setSelectedPartElementDef(_.cloneDeep(selectedPartElementDefFromProps));
  }, [selectedPartElementDefFromProps]);

  const selectedStyle = useMemo(() => {
    if (
      !selectedPartElementDef ||
      !designValueOfSelectedElement ||
      _.isEmpty(designValueOfSelectedElement) ||
      !selectedPartElementDef?.styles
    ) {
      return;
    }

    return selectedPartElementDef.styles.find(
      (s) => s.name === designValueOfSelectedElement.style.name
    );
  }, [selectedPartElementDef, designValueOfSelectedElement]);

  const useColorCombo = useMemo(() => {
    return !!selectedStyle?.useColorCombo;
  }, [selectedStyle]);

  const availableCombos = useMemo(() => {
    if (!selectedStyle?.colorCombos || !useColorCombo) {
      return null;
    }

    const numberOfLayers = selectedStyle.layers.length;
    return selectedStyle?.colorCombos.map((combo) => {
      const colorNames = combo.colors;
      if (colorNames.length !== numberOfLayers) {
        // TODO: Problem. See what to do. Should not happen.
      }

      // Fill the colors details
      const colorsHex = colorNames.map((cn, i) => {
        const fabricName = selectedStyle.layers[i].fabricNames[0];
        const colorsAvailableInFabric = selectedPartElementDef.fabrics.find(
          (f) => f.name === fabricName
        ).colors;
        return colorsAvailableInFabric.find((c) => c.name === cn).hex;
      });

      return {
        ...combo,
        colorsHex,
      };
    });
  }, [selectedStyle, useColorCombo, selectedPartElementDef?.fabrics]);

  const currentCombo = useMemo(() => {
    if (!selectedStyle?.colorCombos || !useColorCombo) {
      return null;
    }
    return designValueOfSelectedElement.style.selectedColorCombo;
  }, [selectedStyle, useColorCombo, designValueOfSelectedElement]);

  // There might be a race condition between selectedStyle and designValueOfSelectedElement.
  const inSync = useMemo(() => {
    try {
      const selectedStyleLayerNames = selectedStyle.layers.map((l) => l.name);
      const designValueLayerNames =
        designValueOfSelectedElement.style.layers.map((l) => l.name);
      return (
        selectedStyleLayerNames.sort().join(",") ===
        designValueLayerNames.sort().join(",")
      );
    } catch (e) {
      return false;
    }
  }, [selectedStyle, designValueOfSelectedElement]);

  const availableStyles = useMemo(() => {
    return selectedPartElementDef?.styles?.filter((s) => !s.disabled);
  }, [selectedPartElementDef]);

  const styleDeprecated = useMemo(() => {
    return (
      deprecatedDesignItems?.find(
        (i) =>
          i.type === DEPRECATION_TYPE_ENUM.DEPRECATED_STYLE &&
          i.partName === selection?.selectedPart &&
          i.elementName === selection?.selectedElementName &&
          i.styleName === selectedStyle?.name
      ) || false
    );
  }, [selectedStyle, deprecatedDesignItems, selection]);

  const colorBleedingForStyle = useMemo(() => {
    return colorBleedingItems?.filter(
      (i) =>
        i.partName === selection?.selectedPart &&
        i.elementName === selection?.selectedElementName &&
        i.styleName === selectedStyle?.name
    );
  }, [colorBleedingItems, selection, selectedStyle]);

  const layerDisplayGroups = useMemo(() => {
    return selectedStyle?.layers
      .filter((l) => l.display.displayable)
      .map((l) => l.display.group)
      .filter((v, i, a) => a.indexOf(v) === i);
  }, [selectedStyle]);

  const onColorComboSelect = useCallback(
    (selectedCombo) => {
      const selectedComboName = selectedCombo.value;
      setDesignValueOfSelectedElement((oldDesignValueOfSelectedElement) => {
        const newDesignValueOfSelectedElement = {
          ...oldDesignValueOfSelectedElement,
        };

        // Update the local design so the frontend updates instantly
        _.set(
          newDesignValueOfSelectedElement,
          ["style", "selectedColorCombo"],
          selectedComboName
        );

        return newDesignValueOfSelectedElement;
      });

      onDesignChange(DESIGN_CHANGE_ENUM.SET_COLOR_COMBO, {
        styleName: designValueOfSelectedElement.style.name,
        colorComboName: selectedComboName,
      });
    },
    [onDesignChange, designValueOfSelectedElement?.style?.name]
  );

  const onColorSelect = useCallback(
    (layerName, selectedOption, triggeredAction) => {
      let selectedColorName = null;
      let selectedActive = true;
      if (triggeredAction?.action === "clear") {
        selectedColorName = "";
        selectedActive = false;
      } else {
        selectedColorName = selectedOption.value;
        selectedActive = true;
      }

      setDesignValueOfSelectedElement((oldDesignValueOfSelectedElement) => {
        const newDesignValueOfSelectedElement = {
          ...oldDesignValueOfSelectedElement,
        };

        const layerIndex =
          newDesignValueOfSelectedElement.style.layers.findIndex(
            (layer) => layer.name === layerName
          );

        _.set(
          newDesignValueOfSelectedElement,
          ["style", "layers", layerIndex, "colorName"],
          selectedColorName
        );
        _.set(
          newDesignValueOfSelectedElement,
          ["style", "layers", layerIndex, "active"],
          selectedActive
        );

        return newDesignValueOfSelectedElement;
      });

      onDesignChange(DESIGN_CHANGE_ENUM.SET_COLOR, {
        colorName: selectedColorName,
        layerName: layerName,
        styleName: designValueOfSelectedElement.style.name,
        active: selectedActive,
      });
    },
    [onDesignChange, designValueOfSelectedElement?.style?.name]
  );

  const onColorPicked = useCallback(
    (layerName, colorHex) => {
      setDesignValueOfSelectedElement((oldDesignValueOfSelectedElement) => {
        const newDesignValueOfSelectedElement = {
          ...oldDesignValueOfSelectedElement,
        };

        const layerIndex =
          newDesignValueOfSelectedElement.style.layers.findIndex(
            (layer) => layer.name === layerName
          );

        _.set(
          newDesignValueOfSelectedElement,
          ["style", "layers", layerIndex, "colorName"],
          colorHex
        );
        _.set(
          newDesignValueOfSelectedElement,
          ["style", "layers", layerIndex, "active"],
          true
        );

        return newDesignValueOfSelectedElement;
      });

      onDesignChange(DESIGN_CHANGE_ENUM.SET_COLOR, {
        colorName: colorHex,
        layerName: layerName,
        styleName: designValueOfSelectedElement.style.name,
        active: true,
      });
    },
    [onDesignChange, designValueOfSelectedElement?.style?.name]
  );

  return (
    <RightSidebar>
      {!selectedPartElementDef ||
      !designValueOfSelectedElement ||
      !(
        designValueOfSelectedElementFromProps?.name ===
        designValueOfSelectedElement?.name
      ) ||
      !availableStyles ? (
        <div className={styles.rightElementSectionContainer}></div>
      ) : //Styles if needed
      availableStyles.length > 1 || styleDeprecated ? (
        <React.Fragment>
          <div className={styles.rightElementSectionContainer}>
            <div className={styles.styleTitleContainer}>
              <h6 data-test="studio.rightBar.styleTitle">{t("specs.style")}</h6>
              {styleDeprecated && (
                <span className={styles.deprecatedValueDot}></span>
              )}
            </div>

            <div className={styles.styleIconsContainer}>
              {!_.isEmpty(designValueOfSelectedElement) &&
                availableStyles.map((s) => (
                  <DarkTooltip
                    title={t(`specs.${productName}.${s.name}`)}
                    key={s.name}
                  >
                    <div
                      className={classNames(
                        styles.styleIconContainer,
                        designValueOfSelectedElement.style.name === s.name
                          ? styles.styleSelected
                          : styles.styleNotSelected,
                        canUpdateDesignStyles ? styles.enabled : styles.disabled
                      )}
                    >
                      <div
                        className={classNames(
                          styles.iconContainer,
                          canUpdateDesignStyles
                            ? styles.enabled
                            : styles.disabled
                        )}
                        onClick={() =>
                          onDesignChange(DESIGN_CHANGE_ENUM.SET_STYLE, {
                            styleName: s.name,
                          })
                        }
                      >
                        <img alt="iconUrl" src={s.display.menuIconUrl} />
                      </div>
                    </div>
                  </DarkTooltip>
                ))}
            </div>
          </div>
        </React.Fragment>
      ) : null}
      {selectedStyle && !_.isEmpty(designValueOfSelectedElement) && inSync ? (
        <div className={styles.rightElementSectionContainer}>
          {
            // When use color combo is true, this is the priority. Otherwise check the layers to
            // know how to display the color selection.
            useColorCombo ? (
              <div className={styles.specsContainer}>
                <span className={styles.specsName}>
                  {t(`specs.${productName}.colorCombo`)}
                </span>
                <ColorSelect
                  className={styles.specsContainer}
                  value={{ value: currentCombo }}
                  onChange={(selectedCombo) =>
                    onColorComboSelect(selectedCombo)
                  }
                  active={true}
                  availableCombos={availableCombos}
                  disableAllColors={!canUpdateDesignColors}
                  isAdmin={isAdmin}
                  bleedingWarning={!!colorBleedingForStyle.length}
                />
              </div>
            ) : (
              layerDisplayGroups.map((group) => (
                <div key={group} className={styles.specsContainer}>
                  <span className={styles.specsName}>
                    {t(`specs.${productName}.${group}`)}
                  </span>
                  {selectedStyle?.layers
                    .filter((l) => l.display.displayable)
                    .filter((l) => l.display.group === group)
                    .map((l) => {
                      if (l.display.displayMethod === "colorPalette") {
                        // Get the current color set for that layer
                        let currentColor =
                          designValueOfSelectedElement.style.layers.find(
                            (layer) => layer.name === l.name
                          ).colorName;
                        let bleedingWarning = colorBleedingForStyle?.find(
                          (i) => i.layerName === l.name
                        );
                        return (
                          <ColorSelect
                            key={l.name}
                            className={styles.specsContainer}
                            value={{ value: currentColor }}
                            onChange={(selectedOption, triggeredAction) =>
                              onColorSelect(
                                l.name,
                                selectedOption,
                                triggeredAction
                              )
                            }
                            isClearable={
                              !l.required &&
                              designValueOfSelectedElement.style.layers.find(
                                (layer) => layer.name === l.name
                              ).active
                            }
                            active={
                              designValueOfSelectedElement.style.layers.find(
                                (layer) => layer.name === l.name
                              ).active
                            }
                            availableColors={
                              selectedPartElementDef.fabrics.find(
                                (f) => f.name === l.fabricNames[0]
                              ).colors
                            }
                            allowNoColors={
                              selectedPartElementDef.inherit &&
                              l.required &&
                              !l.excludeFromInheritance
                            }
                            disableAllColors={!canUpdateDesignColors}
                            isAdmin={isAdmin}
                            bleedingWarning={!!bleedingWarning}
                          />
                        );
                      } else if (l.display.displayMethod === "colorPicker") {
                        // Get the current color set for that layer
                        let currentColor =
                          designValueOfSelectedElement.style.layers.find(
                            (layer) => layer.name === l.name
                          ).colorName;
                        // If it's not in the same format, wait for it to refresh
                        if (currentColor && !currentColor.startsWith("#")) {
                          return <div></div>;
                        }
                        return (
                          <ColorPicker
                            value={currentColor}
                            onColorChange={(colorHex) =>
                              onColorPicked(l.name, colorHex)
                            }
                          />
                        );
                      } else {
                        return <div>Display method not supported</div>;
                      }
                    })}
                </div>
              ))
            )
          }
        </div>
      ) : null}
    </RightSidebar>
  );
};

export default ElementsSidebar;
