import { PropsWithChildren, cloneElement, isValidElement, memo } from "react";

interface Props extends PropsWithChildren {
  repeatCount: number;
}

const ContentRepeater = ({ repeatCount, children }: Props) => {
  return Array(repeatCount)
    .fill("")
    .map((_, index) =>
      isValidElement(children)
        ? cloneElement(children, { key: index })
        : undefined
    );
};

export default memo(ContentRepeater);
