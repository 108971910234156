import React from "react";
import { components } from "react-select";
import styles from "./Menu.module.scss";

const Menu = ({ children, ...props }) => {
  return (
    <components.Menu className={styles.menu} {...props}>
      <div>{children}</div>
    </components.Menu>
  );
};

export default Menu;
