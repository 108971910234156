import React from "react";
import styles from "./purchasedItem.module.scss";
import PurchasedItemDetails from "../PurchasedItemDetails";

const AdminPurchasedItem = (props) => {
  const {
    purchasedItem,
    purchasedItemIndex,
    purchasedItemCount,
    onGoToEditPurchasedDesign = null,
    onUpdateSizesQty = null,
    onRemoveDesign = null,
  } = props;

  const designName = purchasedItem.designName;
  const projectName = purchasedItem.projectName;

  return (
    <div className={styles.container}>
      <div className={styles.sizeSelection}>
        <PurchasedItemDetails
          key={purchasedItem.designId}
          purchasedItem={purchasedItem}
          onGoToEditPurchasedDesign={onGoToEditPurchasedDesign}
          onRemoveDesign={onRemoveDesign}
          onUpdateSizesQty={onUpdateSizesQty}
          title={`${projectName} - ${designName} (${purchasedItemIndex}/${purchasedItemCount})`}
        />
      </div>
    </div>
  );
};

export default AdminPurchasedItem;
