import React, { useCallback, useMemo } from "react";
import styles from "./techpackCustomColor.module.scss";
import { useTranslation } from "react-i18next";
import TechpackCustomColorForm from "./TechpackCustomColorForm";
import TechpackService from "../../../service/TechpackService";

const TechpackCustomColor = (props) => {
  const { customColors, setSaving } = props;

  const { t } = useTranslation();

  const formatedCustomColors = useMemo(() => {
    return customColors.map((c) => {
      return {
        id: c.id,
        fabricName: c.fabric.fabricName,
        fabricDescription: c.fabric.fabricDescription,
        hex: c.hex_color,
        usedOnTechpackPositions: c.used_on.map(
          (u) => u.techpackPosition || u.decoration
        ),
        techpackColorReference: c.custom_color_name,
      };
    });
  }, [customColors]);

  const onCustomColorUpdate = useCallback(
    async (id, techpackColorReference) => {
      // FIXME: See if debouncing is necessary
      setSaving(true);
      const data = {
        techpackItemCustomColorId: id,
        customColorReference: techpackColorReference,
      };
      await TechpackService.setCustomColorReference(data);
      setSaving(false);
    },
    [setSaving]
  );

  return (
    <div className={styles.container}>
      <div className={styles.containerTitle}>
        <span>{t(`techpack.customColor`)}</span>
      </div>

      <div className={styles.customColorsContainer}>
        {formatedCustomColors.map((c) => (
          <TechpackCustomColorForm
            customColor={c}
            onTeckpackColorReferenceUpdate={onCustomColorUpdate}
          />
        ))}
      </div>
    </div>
  );
};

export default TechpackCustomColor;
