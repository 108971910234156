import { Typography } from "@mui/material";
import { useRef, useState } from "react";
import { generatePath, useNavigate } from "react-router-dom";
import { Box, Icon, Link } from "../../../common/components";
import { useTimeSince } from "../../../common/hooks/useTimeSince";
import DesignService from "../../../service/DesignService";
import { Styles, palette, spacing } from "../../../styles/theme";
import { DesignState, DesignStateWithProductName } from "../../../types";
import { ROUTES } from "../../../utils/Constant";
import { useDesignFetcher } from "../../../utils/DesignsFetcherHooks";
import { useThemeBreakpoints } from "../../../utils/themeUtils";
import DesignCardMenu from "./DesignCardMenu";

export const DESIGN_CARD_MOBILE_MIN_WIDTH = "160px";
export const DESIGN_CARD_MOBILE_MAX_WIDTH = "246px";
export const DESIGN_CARD_DESKTOP_MIN_WIDTH = "200px";
export const DESIGN_CARD_DESKTOP_MAX_WIDTH = "246px";

const useStyles = (isFavorite: boolean): Styles => ({
  designContainer: {
    display: "flex",
    flexDirection: "column",
    maxWidth: {
      xs: DESIGN_CARD_MOBILE_MAX_WIDTH,
      md: DESIGN_CARD_DESKTOP_MAX_WIDTH,
    },
    position: "relative",
  },
  box: {
    minWidth: "100%",
    borderRadius: "12px",
    cursor: "pointer",
    mb: 1,
    border: `1px solid ${palette.grey.lightGrey}`,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    py: { xs: 2, lg: 3 },
    ":hover": {
      border: `1px solid ${palette.grey.midGrey}`,
    },
    ":active": {
      border: `1px solid ${palette.blue.secondary}`,
    },
  },
  favorite: {
    position: "absolute",
    top: "12px",
    right: "12px",
    "&>span": {
      userSelect: "none",
      color: isFavorite ? palette.misc.favourites : palette.grey.midGrey,
      ":hover": {
        color: isFavorite ? palette.misc.favourites : palette.grey.darkGrey,
      },
    },
  },
  designName: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  text: {
    minWidth: "100%",
    height: "17px",
  },
  img: {
    maxHeight: { xs: "60px", lg: "100px" },
    userSelect: "none",
  },
});

interface Props {
  design: DesignState | DesignStateWithProductName;
  hideProjectName?: boolean;
  onUpdate?: (hideLoading?: boolean) => Promise<void>;
  setIsLoading?: (isLoading: boolean) => void;
}

const DesignCard = ({
  design,
  hideProjectName,
  onUpdate,
  setIsLoading,
}: Props) => {
  const [isContextMenuOpen, setIsContextMenuOpen] = useState(false);
  const navigate = useNavigate();
  const { timeSince } = useTimeSince(design.updatedAt);
  const { isMobile } = useThemeBreakpoints();
  const [isFavorite, setIsFavorite] = useState(Boolean(design.isFavorite));
  const { fetchMyDesignsAndUpdateStore } = useDesignFetcher();
  const ref = useRef<HTMLSpanElement>(null);
  const styles = useStyles(isFavorite);

  const onFavoriteChange = async (_isFavorite: boolean) => {
    await DesignService.setFavoriteDesign(design.id, _isFavorite);

    await (onUpdate
      ? onUpdate(true)
      : fetchMyDesignsAndUpdateStore(design.projectId, true));
  };

  const onDesignClick = () => {
    navigate(
      generatePath(ROUTES.STUDIO, {
        projectId: design.projectId,
        designId: design.id,
      })
    );
  };

  return (
    <Box sx={styles.designContainer} data-test={`designCard.${design.name}`}>
      <Box sx={styles.box} onClick={onDesignClick}>
        <Box sx={styles.img} display={"flex"} gap={1.5}>
          {design?.images?.map((image, index) => (
            <Box
              component={"img"}
              sx={{
                ...styles.img,
                content: `url(${image.url})`,
              }}
              key={`${image.side}-${index}`}
            />
          ))}
        </Box>
      </Box>
      <Box sx={styles.favorite} data-test="designCard.favoriteIconContainer">
        <Icon
          data-test="designCard.favoriteIcon"
          icon="favorite"
          filled={isFavorite}
          size={18}
          onClick={() =>
            setIsFavorite((prev) => {
              onFavoriteChange(!prev);
              return !prev;
            })
          }
        />
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        mb={"4px"}
        alignItems={"center"}
      >
        <Link
          to={generatePath(ROUTES.STUDIO, {
            projectId: design.projectId,
            designId: design.id,
          })}
          style={{
            maxWidth: "calc(100% - 2px - 8px)",
            display: "flex",
          }}
        >
          <Typography variant="textSm" sx={styles.designName}>
            {design.name}
          </Typography>
        </Link>
        <Box>
          <Icon
            ref={ref}
            data-test="designCard.moreVertIcon"
            icon="more_vert"
            onClick={() => setIsContextMenuOpen((prev) => !prev)}
            style={{
              width: "2px",
              height: "10px",
              justifyContent: "center",
              alignItems: "center",
            }}
          />
          <DesignCardMenu
            isOpen={isContextMenuOpen}
            onClose={() => setIsContextMenuOpen(false)}
            target={ref.current}
            designId={design.id}
            projectId={design.projectId}
            designName={design.name}
            updatedAt={design.updatedAt}
            onUpdate={onUpdate}
            setIsLoading={setIsLoading}
          />
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection={"column"}
        gap={{ lg: spacing.xxsDesktop }}
        width={"100%"}
      >
        {!hideProjectName && "project" in design && (
          <Link
            to={generatePath(ROUTES.DESIGNS, {
              projectId: design.projectId,
            })}
            style={{
              color: palette.blue.secondary,
              display: "flex",
              maxWidth: "100%",
            }}
          >
            <Typography
              variant="textXs"
              sx={{
                color: palette.blue.secondary,
                "&:hover": { textDecoration: "underline" },
              }}
              maxWidth={"100%"}
              textOverflow={"ellipsis"}
              whiteSpace={"nowrap"}
              overflow={"hidden"}
              display={"block"}
            >
              {(design.project as any)?.name}
            </Typography>
          </Link>
        )}
        {!isMobile && (
          <Typography variant="textXs" maxWidth={"100%"}>
            {timeSince}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default DesignCard;
