import { useTheme } from "@mui/system";
import {
  Navigate,
  Outlet,
  RouteProps,
  generatePath,
  useOutlet,
  useParams,
} from "react-router-dom";
import { AppContainer } from "../common/components";
import AuthService from "../service/authService";
import { UserInfo } from "../types";
import { ROUTES } from "../utils/Constant";

type Props = RouteProps & {
  isMainScreen?: boolean;
};

export const PrivateRoutes = (props: Props) => {
  const { isMainScreen } = props;
  const token = AuthService.getJWT();
  const outlet = useOutlet();
  const params = useParams();
  const currentUser = AuthService.getUserInfo() as any as UserInfo;

  const muiThemeInstance = useTheme();

  const dateNow = new Date();
  //If there is no user, it should enter and token should be false so it will redirect to the login
  //If expired, send to login page
  if (!currentUser || (currentUser.exp ?? 0) * 1000 < dateNow.getTime()) {
    if (token) {
      // if (isMobile) {
      //   // TODO to be remove once he mobile is done
      //   return <NotAvailableMobileContainer />;
      // }
      if (outlet?.props) {
        return isMainScreen ? (
          <AppContainer isMainScreen>{outlet}</AppContainer>
        ) : (
          outlet
        );
      }
      return (
        <Navigate to={ROUTES.HOME} />

        //TODO - check if i need to fix this
        // <Navigate
        //   to={{
        //     pathname: routeFormatter(ROUTES.LOGIN_WITH_TOKEN, {
        //       params: { token },
        //     }),
        //     // state: { from: props.location },
        //     // search: props.location.search,
        //   }}
        // />
      );
    } else {
      return (
        <Navigate
          to={{
            pathname: generatePath(
              params.token ? ROUTES.LOGIN_WITH_TOKEN : ROUTES.LOGIN,
              { token: params.token }
            ),
            // state: { from: props.location },
            // search: props.location.search,
          }}
        />
      );
    }
  }

  if (!outlet?.props) {
    return <Navigate to={ROUTES.HOME} />;
  }

  return isMainScreen ? (
    <AppContainer isMainScreen>
      <Outlet />
    </AppContainer>
  ) : (
    <Outlet />
  );
};
