import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import Add from "@mui/icons-material/Add";
import CheckCircleOutlined from "@mui/icons-material/CheckCircleOutlined";

import styles from "./Design.module.scss";
import DesignService from "../../../service/DesignService";
import { useDesignFetcher } from "../../../utils/DesignsFetcherHooks";
import DarkTooltip from "../../../common/components/DarkTooltip";

const Design = (props) => {
  const { id, images, name, collectable_since } = props;

  const { t } = useTranslation();

  const displayedName = useMemo(
    () => name || t("design.untitled_design"),
    [name, t]
  );

  const { fetchMyDesignsAndUpdateStore } = useDesignFetcher();

  const updateDesigns = useCallback(async () => {
    fetchMyDesignsAndUpdateStore(props.projectId);
  }, [fetchMyDesignsAndUpdateStore, props.projectId]);

  const onAddToPage = async () => {
    await DesignService.updateCollectable({
      collectable: true,
      designId: id,
    });
    updateDesigns();
  };

  return (
    <div className={styles.container}>
      <div className={styles.preview}>
        {images.map((image) => (
          <img
            src={image.url}
            alt={image.side}
            className={styles.img}
            key={image.side}
          />
        ))}
      </div>
      <DarkTooltip enterDelay={1000} title={displayedName}>
        <div className={styles.bottom}>
          <span>{displayedName}</span>
          {collectable_since ? (
            <div className={styles.addedDiv}>
              <CheckCircleOutlined className={styles.icon} />
              <span>{t("design.added")}</span>
            </div>
          ) : (
            <div className={styles.addDiv} onClick={onAddToPage}>
              <Add />
              <span>{t("design.add_to_page")}</span>
            </div>
          )}
        </div>
      </DarkTooltip>
    </div>
  );
};

export default Design;
