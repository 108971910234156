import { getLanguage } from "../utils/LocaleUtil";

const REACT_APP_KLAVIYO_LIST_ID_FR_KEY =
  process.env.REACT_APP_KLAVIYO_LIST_ID_FR_KEY;
const REACT_APP_KLAVIYO_LIST_ID_EN_KEY =
  process.env.REACT_APP_KLAVIYO_LIST_ID_EN_KEY;

/**
 * Internal function
 * @param {*} userInfo
 * @param {*} userRole
 * @param {*} locale
 */
function _setAnalyticsUser(userInfo, userRole, locale) {
  window.analytics.identify(
    userInfo.id.toString(),
    {
      name: `${userInfo.firstName} ${userInfo.lastName}`,
      firstName: userInfo.firstName,
      lastName: userInfo.lastName,
      email: userInfo.email,
      role: userRole, // admin or user
      optNewsletter: userInfo.optNewsletter,
      locale: locale,
    },
    {
      integrations: {
        Klaviyo: {
          listId:
            locale === "fr"
              ? REACT_APP_KLAVIYO_LIST_ID_FR_KEY
              : REACT_APP_KLAVIYO_LIST_ID_EN_KEY,
        },
      },
    }
  );
}

export function setAnalyticsUser(userInfo) {
  const locale = getLanguage();
  let userRole = "user";
  if (userInfo.isAdmin || userInfo.isDesigner) {
    userRole = "admin";
  }

  _setAnalyticsUser(userInfo, userRole, locale);
}
