import PhoneInput, {
  Props as PhoneInputProps,
} from "react-phone-number-input/input";
import "react-phone-number-input/style.css";
import styles from "./PhoneNumber.module.scss";
import { useTranslation } from "react-i18next";
import { DefaultInputComponentProps } from "react-phone-number-input";
import { E164Number } from "libphonenumber-js";

type PhoneInputPropsType = PhoneInputProps<DefaultInputComponentProps>;
interface Props extends Omit<PhoneInputPropsType, "value"> {
  withExtension?: boolean;
  extension?: string;
  onExtensionChange?: (value: string) => void;
  value: string;
}

export const PhoneNumber = (props: Props) => {
  const { t } = useTranslation();
  const { withExtension = true, extension, onExtensionChange, ...rest } = props;

  return (
    <>
      <PhoneInput
        {...rest}
        onChange={props.onChange as PhoneInputPropsType["onChange"]}
        value={props.value as E164Number}
        key="phone"
        defaultCountry="CA"
        className={styles.phone}
        placeholder={t("general.phone")}
        autoComplete="tel"
      />
      {withExtension && (
        <input
          key="extension"
          onChange={(e) => onExtensionChange?.(e.currentTarget.value)}
          value={extension}
          placeholder={t("general.extension")}
          className={styles.organization}
          autoComplete="tel-extension"
          type="tel"
        />
      )}
    </>
  );
};
