import { UserInfo } from "../../types";
import { Action } from "../../types/redux";

const initialState: UserInfo = {
  exp: 0,
  isAdmin: false,
  isUser: false,
  isDesigner: false,
  canAddProject: false,
  canRenameProject: false,
  canDeleteProject: false,
  canAddTeam: false,
  canRenameTeam: false,
  canDeleteTeam: false,
  canAddDesign: false,
  canRenameDesign: false,
  canDeleteDesign: false,
  canMoveDesign: false,
  canUpdateDesignStyles: false,
  canUpdateDesignColors: false,
  canUpdateArtworkPosition: false,
  canUpdateArtworkColor: false,
  canAddArtwork: false,
  canDeleteArtwork: false,
  email: "",
  firstName: "",
  iat: 0,
  id: "",
  language: "",
  lastName: "",
  optNewsletter: false,
  imageUrl: "",
  roles: [],
};

export enum USER_INFO_ACTION_ENUM {
  UPDATE_USER_INFO = "update_user_infos",
  RESET_STATE = "reset_state",
}

const reducer = (
  state = initialState,
  action: Action<USER_INFO_ACTION_ENUM, UserInfo>
) => {
  switch (action.type) {
    case USER_INFO_ACTION_ENUM.UPDATE_USER_INFO:
      return action.payload;
    case USER_INFO_ACTION_ENUM.RESET_STATE:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
