import _ from "lodash";
import { Action } from "../../types/redux";
import { AddressFormType } from "../../types";

export enum BILLING_ACTION_ENUM {
  SET_SAVING = "set_saving",
  UPDATE_LOADING = "update_billing_address_loading",
  UPDATE_BILLING_ADDRESS = "update_billing_address",
  UPDATE_BILLING_ADDRESS_FIELD = "update_billing_address_field",
  UPDATE_BILLING_ADDRESS_SAME_AS_SHIPPING = "update_billing_address_same_as_shipping",
}

export type BillingAddressPayload = {
  billingAddress: AddressFormType;
  sameAsShippingAddress: boolean;
  field: keyof AddressFormType;
  value: string;
};

const initialState = {
  isSaving: false,
  billingAddress: {
    organization: "",
    firstName: "",
    lastName: "",
    address: "",
    appartment: "",
    city: "",
    country: "CA",
    state: "QC",
    zipcode: "",
    phone: "",
    phoneExt: "",
    email: "",
  },
  loading: false,
  loaded: false,
  sameAsShippingAddress: true,
};

const reducer = (
  state = initialState,
  action: Action<BILLING_ACTION_ENUM, BillingAddressPayload>
) => {
  switch (action.type) {
    case BILLING_ACTION_ENUM.UPDATE_BILLING_ADDRESS:
      return {
        ...state,
        billingAddress: _.cloneDeep(action.payload.billingAddress),
        sameAsShippingAddress: action.payload.sameAsShippingAddress,
        loaded: true,
        loading: false,
      };

    case BILLING_ACTION_ENUM.UPDATE_LOADING:
      return { ...state, loading: true, loaded: false };

    case BILLING_ACTION_ENUM.UPDATE_BILLING_ADDRESS_FIELD: {
      const { field, value } = action.payload;

      const newState = { ...state };
      _.set(newState, ["billingAddress", field], value);

      return newState;
    }

    case BILLING_ACTION_ENUM.SET_SAVING:
      return { ...state, isSaving: action.payload };

    case BILLING_ACTION_ENUM.UPDATE_BILLING_ADDRESS_SAME_AS_SHIPPING:
      return {
        ...state,
        sameAsShippingAddress: action.payload,
        loaded: true,
        loading: false,
      };

    default:
      return state;
  }
};

export default reducer;
