import _ from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  generatePath,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { AboutProject } from "../../components/Onboarding/AboutProject";
import { EmailOnboard } from "../../components/Onboarding/EmailOnboard";
import { Login } from "../../components/Onboarding/Login";
import { SignUp } from "../../components/Onboarding/SignUp";
import { OnboardingContext } from "../../context";
import AuthService from "../../service/authService";
import { UserOnboardingInfo } from "../../types";
import { ONBOARDING_STEPS, ROUTES } from "../../utils/Constant";
import { getLanguage } from "../../utils/LocaleUtil";
import { useProjectFetcher } from "../../utils/ProjectsFetcherHooks";
import { useTokenInfo } from "../../utils/ReactHooks";
import { useTeamFetcher } from "../../utils/TeamsFetcherHooks";
import { useUserInfoFetcher } from "../../utils/UserHooks";
import { OnboardingTemplate } from "./OnboardingTemplate";

export const OnboardingContainer = () => {
  const { token } = useParams();

  //Change the language if the URL param set a specific language
  const location = useLocation();
  const { t } = useTranslation();
  const navigate = useNavigate();

  //Query parameter campaign (use for analytics)
  const campaign = useMemo(() => {
    const query = new URLSearchParams(location.search);
    return query.get("campaign");
  }, [location.search]);

  const [currentStep, setCurrentStep] = useState<ONBOARDING_STEPS>(
    ONBOARDING_STEPS.EMAIL
  );

  const { fetchMyTeamsAndUpdateStore } = useTeamFetcher();
  const { fetchMyProjectsAndUpdateStore } = useProjectFetcher();
  const { initUserInfo } = useUserInfoFetcher();

  const [onboardingInfo, setOnboardingInfo] = useState<UserOnboardingInfo>({
    email: "",
    firstName: "",
    lastName: "",
    password: "",
    optNewsletter: true,
    language: "",
    forWho: "",
    projectSize: "",
    teamName: "",
    eventDate: undefined,
    campaign: "",
  });

  //Campaign url query parameter may not always be set
  useEffect(() => {
    setOnboardingInfo((infos) => ({
      ...infos,
      campaign: campaign || "",
    }));
  }, [campaign, setOnboardingInfo]);

  useEffect(() => {
    setOnboardingInfo((infos) => ({
      ...infos,
      language: getLanguage(),
    }));
  }, [setOnboardingInfo]);

  const [errorMsg, setErrorMsg] = useState("");
  const tokenInfo = useTokenInfo(token);
  /**
   *
   */
  const onCheckEmailNextStep = useCallback(
    async (email: string) => {
      //Check if user exits, if exists send to login step, if doesn't exists, send to signup step
      setOnboardingInfo((prev) => ({ ...prev, email }));
      const userExists = await AuthService.userExists(email)
        .catch(() => {
          return false;
        })
        .then((res) => {
          return !!res;
        });
      if (userExists) {
        setCurrentStep(ONBOARDING_STEPS.LOGIN);
      } else {
        setCurrentStep(ONBOARDING_STEPS.SIGNUP);
      }

      //Reset error message
      setErrorMsg("");
    },
    [setCurrentStep]
  );

  /**
   *
   */
  const onBackToEmailStep = useCallback(
    async (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      e.preventDefault();
      setCurrentStep(ONBOARDING_STEPS.EMAIL);
    },
    [setCurrentStep]
  );

  /**
   *
   */
  const onBackToUserInfoStep = useCallback(
    async (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      e.preventDefault();
      setCurrentStep(ONBOARDING_STEPS.SIGNUP);
    },
    [setCurrentStep]
  );

  /**
   *
   * @param {*} e
   * @param {*} email
   * @param {*} password
   * @returns
   */
  const onSignInClick = async (email: string, password: string) => {
    try {
      const res = await AuthService.login({
        email,
        password,
        inviteToken: _.isEmpty(token) ? null : token,
      }).catch((error) => {
        setErrorMsg(t("sign_in." + error.response.data.message));
        return { data: error.response.data };
      });

      if (res?.data?.status === "success") {
        AuthService.setLocalUser(
          (res as any).headers["x-access-token"],
          res.data.data.user.id.toString(),
          res.data.data.user.email
        );

        initUserInfo();

        window.analytics.track("Signed In");

        //if no invite token, then route to the user's projects
        if (!token) {
          navigate(ROUTES.HOME);
          return;
        }

        const teamId = res.data.data.joinedTeam.id;

        //If there is an invite token, get the teamId associated and route the user to the tean's page.
        // const {
        //   data: { data: teamId },
        // } = await TeamService.joinUserToTeamWithInviteToken(token);

        //Since we added the user to the team, we must refresh the teams's store
        fetchMyTeamsAndUpdateStore();
        fetchMyProjectsAndUpdateStore();

        navigate(generatePath(ROUTES.TEAM_PROJECTS, { teamId }));
      }
    } catch (error) {
      console.error(error);
    }
  };

  /**
   * Last step
   */
  const onSignUpGetStarted = useCallback(
    async (values?: UserOnboardingInfo) => {
      const formValues = values ?? onboardingInfo;
      //Dates can't be an empty string, so we must set eventDate to null if it is ""
      formValues.eventDate = undefined;

      formValues.inviteToken = _.isEmpty(token) ? undefined : token;

      //Do the .catch in the signUp call instead of a big try catch
      //On failure of the API (like 400 missing parameters),
      //Axios throws an error that must be catched
      const res = await AuthService.signUp(formValues).catch((error) => {
        return error.response;
      });

      const { status, data, message } = res.data;

      if (status !== "success") {
        if (message === "username_already_exists") {
          setErrorMsg(t("sign_up." + message, { email: formValues.email }));
          return;
        }
        setErrorMsg(t("sign_up." + message));
        return;
      }

      const { newUser, newProject, joinedTeam } = data;

      //Equivalent of login right after signup
      AuthService.setLocalUser(
        res.headers["x-access-token"],
        newUser.id.toString(),
        newUser.email,
        formValues.optNewsletter
      );

      initUserInfo();

      //Analytics
      if (_.isEmpty(formValues.forWho) || _.isEmpty(formValues.projectSize)) {
        window.analytics.track("Account Created", {
          invited: token ? true : false, // whether the user signed up via an invitation link
        });
      } else {
        window.analytics.track("Account Created", {
          invited: token ? true : false, // whether the user signed up via an invitation link
          forWho: formValues.forWho,
          projectSize: formValues.projectSize,
          label: `${formValues.forWho} / ${formValues.projectSize}`,
          campaign: formValues.campaign,
        });
      }

      await fetchMyProjectsAndUpdateStore();
      await fetchMyTeamsAndUpdateStore();
      ///if no invite token, then route to the newly created project and popup the create design
      if (!token) {
        navigate(
          generatePath(ROUTES.DESIGNS_SHOW_NEW, { projectId: newProject.id })
        );
        return;
      }

      //Since we added the user to the team, we must refresh the teams's store (team, project)

      //Push to joined team's page
      navigate(generatePath(ROUTES.TEAM_PROJECTS, { teamId: joinedTeam.id }));
    },
    [
      setErrorMsg,
      onboardingInfo,
      fetchMyProjectsAndUpdateStore,
      fetchMyTeamsAndUpdateStore,
      initUserInfo,
      t,
      navigate,
      token,
    ]
  );

  /**
   *
   */
  const onCheckUserInfoNextStep = useCallback(
    async (values: UserOnboardingInfo) => {
      setOnboardingInfo(values);
      //If the user was invited, we don't ask for the project and team info (since the user is joining a team...)
      //A default Team and project will still be created though...
      if (tokenInfo) {
        await onSignUpGetStarted(values);
        return;
      }
      setCurrentStep(ONBOARDING_STEPS.ABOUT_PROJECT);
    },
    [setCurrentStep, onSignUpGetStarted, tokenInfo]
  );

  return (
    <OnboardingContext.Provider value={{ errorMsg }}>
      <OnboardingTemplate
        hideMarquee={currentStep === ONBOARDING_STEPS.ABOUT_PROJECT}
      >
        {currentStep === ONBOARDING_STEPS.EMAIL && (
          <EmailOnboard
            onNextStep={onCheckEmailNextStep}
            email={onboardingInfo.email}
          />
        )}
        {currentStep === ONBOARDING_STEPS.LOGIN && !!onboardingInfo.email && (
          <Login
            onNextStep={onSignInClick}
            onPreviousStep={onBackToEmailStep}
            email={onboardingInfo.email}
          />
        )}
        {currentStep === ONBOARDING_STEPS.SIGNUP && (
          <SignUp
            onNextStep={onCheckUserInfoNextStep}
            onPreviousStep={onBackToEmailStep}
            userInfo={onboardingInfo}
            setUserInfo={setOnboardingInfo}
          />
        )}
        {currentStep === ONBOARDING_STEPS.ABOUT_PROJECT && (
          <AboutProject
            onNextStep={onSignUpGetStarted}
            onPreviousStep={onBackToUserInfoStep}
            onboardingInfo={onboardingInfo}
            setOnboardingInfo={setOnboardingInfo}
          />
        )}
      </OnboardingTemplate>
    </OnboardingContext.Provider>
  );
};
