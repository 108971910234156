import React from "react";
import { useTranslation } from "react-i18next";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitleWithCloseButton from "../../../components/DialogTitleWithCloseButton/DialogTitleWithCloseButton";
import { useCurrency } from "../../../utils/Currencies";
import styles from "./ExtraSizeModal.module.scss";
import { DialogTitle } from "@mui/material";
import BasicTable from "./Table";
const ExtraSizeModal = (props) => {
  const { open, onClose, includedSizesPerRange } = props;
  const { t } = useTranslation();

  const { NarrowCurrencyFormatter } = useCurrency();

  return (
    <Dialog
      open={open}
      className={styles.modal}
      onClose={onClose}
      fullWidth={false}
      maxWidth={"xs"}
      sx={{
        "& .MuiPaper-root": {
          width: "480px",
          maxWidth: "480px",
          minWidth: "480px",
        },
      }}
    >
      <DialogTitleWithCloseButton
        id="customized-dialog-title"
        onClose={onClose}
      >
        {""}
      </DialogTitleWithCloseButton>
      <DialogContent
        sx={{
          marginLeft: "60px",
          marginRight: "60px",
          padding: "0px",
          marginBottom: "60px",
        }}
      >
        <div className={styles.container}>
          <div className={styles.search}>
            <DialogTitle sx={{ marginTop: "10px", paddingLeft: "0px" }}>
              <h3 style={{ fontWeight: "bold" }}>
                {t("cart.size_modal_title")}
              </h3>
            </DialogTitle>
          </div>
          {includedSizesPerRange && (
            <BasicTable
              passedArray={includedSizesPerRange}
              orderTitle={t("cart.size_modal_order")}
              sizesTitle={t("cart.size_modal_sizes")}
            ></BasicTable>
          )}
        </div>
        <div style={{ marginTop: "30px" }}>
          <span
            style={{
              justifyContent: "space-evenly",
              fontSize: "1rem",
            }}
          >
            {includedSizesPerRange &&
              t("cart.size_modal_blurb", {
                priceWithCurrencySymbol: NarrowCurrencyFormatter.format(
                  includedSizesPerRange[0].extraSizePrice
                ),
              })}
          </span>
        </div>
      </DialogContent>
      <DialogActions></DialogActions>
    </Dialog>
  );
};

export default ExtraSizeModal;
