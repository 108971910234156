import axios from "axios";
import { FlattenTeam, Response, Team } from "../types";
import { getAuthHeader } from "../utils/AuthUtils";
const USER_API_BASE_URL = process.env.REACT_APP_BACKEND_URL;

const BASE_ROUTE = "/api/v1/teams";

interface SearchTeamEmailResponse {
  email: string;
  first_name: string;
  id: string;
  last_name: string;
  teams: { id: string; name: string }[];
}

type SearchTeamResponse = { teams: Team[]; emails: SearchTeamEmailResponse[] };

class TeamService {
  static async getMyTeams() {
    return axios.get<Team>(
      `${USER_API_BASE_URL}${BASE_ROUTE}/`,
      getAuthHeader()
    );
  }

  /**
   *
   * @returns Only designers and admin will be allowed to get all teams
   */
  static async getAllTeams() {
    return axios.get<Team[]>(
      `${USER_API_BASE_URL}${BASE_ROUTE}/all`,
      getAuthHeader()
    );
  }

  static async searchTeams(value: string) {
    const res = await axios.get<Response<SearchTeamResponse>>(
      `${USER_API_BASE_URL}${BASE_ROUTE}/search/${value}/50/0`,
      getAuthHeader()
    );

    return { ...res, data: flattenTeams(res.data.data) };
  }

  static async createTeam(data: { name: string }) {
    const res = await axios.post<{ data: Team; status: string }>(
      `${USER_API_BASE_URL}${BASE_ROUTE}/team`,
      data,
      getAuthHeader()
    );

    const {
      data: {
        data: { id },
        status,
      },
    } = res;

    if (status === "success" && id) {
      window.analytics.track("Team Created");
    }

    return res;
  }

  static async deleteTeam(data: { id: string }) {
    return axios.delete(
      `${USER_API_BASE_URL}${BASE_ROUTE}/team/${data.id}`,
      getAuthHeader()
    );
  }

  static async renameTeam(data: { id: string; name: string }) {
    return axios.put(
      `${USER_API_BASE_URL}${BASE_ROUTE}/team/name`,
      data,
      getAuthHeader()
    );
  }

  static async removeTeammate(data: { teamId: string; teammateId: string }) {
    return axios.delete(
      `${USER_API_BASE_URL}${BASE_ROUTE}/team/${data.teamId}/member/${data.teammateId}`,
      getAuthHeader()
    );
  }

  static async getInviteLink(teamId: string) {
    return axios.get(
      `${USER_API_BASE_URL}${BASE_ROUTE}/team/link?teamId=${teamId}`,
      getAuthHeader()
    );
  }

  static async getTokenInfo(token: string) {
    return axios.get(
      `${USER_API_BASE_URL}${BASE_ROUTE}/tokenInfo?token=${token}`
    );
  }

  static async joinUserToTeamWithInviteToken(token: string) {
    return axios.post(
      `${USER_API_BASE_URL}${BASE_ROUTE}/join`,
      { token },
      getAuthHeader()
    );
  }

  static async getProjectsInTeam(teamId: string) {
    return axios.get(
      `${USER_API_BASE_URL}${BASE_ROUTE}/team/${teamId}/projects`,
      getAuthHeader()
    );
  }
}

const flattenTeams = (data: SearchTeamResponse): FlattenTeam[] => {
  //TODO fix that, we might have duplicate

  const flattenTeamsList = [];
  if (Array.isArray(data.teams)) {
    for (const team of data.teams) {
      if (Array.isArray(team.teammates)) {
        for (const teammate of team.teammates) {
          flattenTeamsList.push({
            teamId: team.id,
            name: team.name,
            email: teammate.email,
            first_name: teammate.first_name,
            last_name: teammate.last_name,
            userId: teammate.id,
          });
        }
      }
    }
  }

  if (Array.isArray(data.emails)) {
    for (const email of data.emails) {
      if (Array.isArray(email.teams)) {
        for (const team of email.teams) {
          flattenTeamsList.push({
            teamId: team.id,
            name: team.name,
            email: email.email,
            first_name: email.first_name,
            last_name: email.last_name,
            userId: email.id,
          });
        }
      }
    }
  }

  return flattenTeamsList.sort((a, b) => {
    return `${a?.first_name} ${a?.last_name} ${a?.email}`.localeCompare(
      `${b?.first_name} ${b?.last_name} ${b?.email}`
    );
  });
};

export default TeamService;
