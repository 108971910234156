import React from "react";
import { useCurrency } from "../../../utils/Currencies";
import { TabPanel } from "./TabPanel";

import { useTranslation, Trans } from "react-i18next";

import Typography from "@mui/material/Typography";
import { PricingTabPanel } from "./PricingTabPanel";

import { makeStyles } from "@mui/styles";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {
  DesignPricing,
  SpecsProductPricing,
  Size,
  UserInfo,
} from "../../../types";

/**
 * Tabs bar full width
 *
 */

export interface CalculatePriceModalTabDataType {
  key: number;
  lbound: number;
  ubound: number;
  price: number;
  name: string;
  unit_price: number;
  shipping_price: number;
  total: number;
  customizationsListWithCredits: DesignPricing["customizationsListWithCredits"];
  customizationTotalPrice: number;
  singleCreditPrice: number;
  freeCreditsOfDesign: number;
  creditsUsedInDesign: number;
}

interface Props {
  designId: string;
  productName: string;
  designPricing: DesignPricing;
  pricingDef: SpecsProductPricing;
  sizesOption: Size[];
  userInfo: UserInfo;
}

export const FullWidthTabs = (props: Props) => {
  const { productName, designPricing, pricingDef, sizesOption, designId } =
    props;

  //This is the selected tab index value
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const handleChange = (
    event: React.SyntheticEvent,
    newSelectedIndex: number
  ) => {
    setSelectedIndex(newSelectedIndex);
  };

  const { t } = useTranslation();

  function a11yPropsTabButton(index: number) {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    };
  }

  const useStyles = makeStyles((theme) => ({
    tab: {
      "&:focus": {
        outline: "0px", //Super important here or else we have weird onFocus behaviour (outline is blue instead of no outlines)
      },
      minWidth: "50px",
      fontSize: "1.0rem",
      textTransform: "none",
    },
    tabs: {
      flexGrow: 1,
      color: "black",
      p: "8px 0",
    },
    selected: {},
  }));

  const classes = useStyles();

  const { getCurrency } = useCurrency();
  //Create the price information from the design object
  //First need to know how many tabs and the range of each tabs
  const tabsData: CalculatePriceModalTabDataType[] = [];
  const minQty = pricingDef.minQty;
  let lastQtyKey = minQty;
  const currency = getCurrency();
  const pricingCurrency = pricingDef.pricingCurrency[currency];

  const extraCredits =
    designPricing.creditsUsedInDesign - pricingDef.freeCredits > 0
      ? designPricing.creditsUsedInDesign - pricingDef.freeCredits
      : 0;

  for (const range of pricingCurrency.pricingRange) {
    tabsData.push({
      key: range.qty,
      lbound: lastQtyKey,
      ubound: range.qty - 1,
      price: range.price,
      name: t("products." + productName + ".name"),
      unit_price: range.price,
      shipping_price: range.shipping,
      total: pricingCurrency.priceOfExtraCredit * extraCredits + range.price,
      customizationsListWithCredits:
        designPricing.customizationsListWithCredits,
      customizationTotalPrice:
        pricingCurrency.priceOfExtraCredit * extraCredits,
      singleCreditPrice: pricingCurrency.priceOfExtraCredit,
      freeCreditsOfDesign: pricingDef.freeCredits,
      creditsUsedInDesign: designPricing.creditsUsedInDesign,
    });

    lastQtyKey = range.qty;
  }

  return (
    <React.Fragment>
      <Tabs
        className={classes.tabs}
        value={selectedIndex}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="inherit"
        aria-label="full width tabs example"
        variant="fullWidth"
      >
        <Tab
          key={0}
          className={classes.tab}
          label={t("pricing.price_range_less_than", {
            less_than: minQty,
          })}
          {...a11yPropsTabButton(0)}
        />
        {/*Loop pricing info and create dynamic tab with pricing data and credits info*/}
        {tabsData.map((tabData) => (
          <Tab
            key={tabData.key}
            className={classes.tab}
            label={t("pricing.price_range", {
              lbound: tabData.lbound,
              ubound: tabData.ubound,
            })}
            {...a11yPropsTabButton(0)}
          />
        ))}
        <Tab
          key={lastQtyKey}
          className={classes.tab}
          label={t("pricing.price_range_plus", {
            qty_max: lastQtyKey,
          })}
          {...a11yPropsTabButton(0)}
        />
      </Tabs>

      <TabPanel selectedIndex={selectedIndex} index={0}>
        <Typography paragraph>
          {t("pricing.min_qty", { min_qty: minQty })}
        </Typography>
      </TabPanel>

      {/*Loop pricing info and create dynamic tab with pricing data and credits info*/}
      {tabsData.map((tabData, i) => {
        const index = i + 1;
        return (
          <PricingTabPanel
            key={index}
            selectedIndex={selectedIndex}
            index={index}
            value={index}
            tabData={tabData}
            designId={designId}
            sizesOption={sizesOption}
            productName={productName}
          />
        );
      })}
      {/*Last range is special and customer need to contact us */}
      <TabPanel selectedIndex={selectedIndex} index={tabsData.length + 1}>
        <Typography paragraph>{t("pricing.contactus")}</Typography>
        <Typography paragraph>
          {/* Needed the Trans component to translate and keep the a href*/}
          <Trans i18nKey="pricing.goingbig">
            Contact <a href="mailto:info@jameo.com">info@jameo.com</a> for a
            tailored price and a dedicated Account Manager will be in touch
            soon.
          </Trans>
        </Typography>
      </TabPanel>
    </React.Fragment>
  );
};
