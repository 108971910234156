import MessageCard from "../Message/MessageCard";
import React from "react";
import { ReactComponent as StarIcon } from "../../assets/editor/stars.svg";
import RightArrow from "../../assets/editor/arrow2.svg";

import { useTranslation } from "react-i18next";
import styles from "./workWithADesignerMessage.module.scss";

const WorkWithADesignerMessage = (props) => {
  const { t } = useTranslation();

  return (
    <MessageCard title={t("design.meet_a_designer_title")}>
      <div className={styles.message}>
        {t("design.meet_a_designer_message")}
      </div>

      <span className={styles.rating}>
        <div className={styles.stars}>
          <StarIcon />
          <div className={styles.score}>4.8</div>
        </div>
        <div className={styles.numberOfReview}>
          {t("design.meet_a_designer_review")}
        </div>
      </span>

      <div className={styles.workWithADesigner}>
        <a
          href={t("HomePage.Designer_Link")}
          target={"_blank"}
          rel={"noreferrer"}
        >
          {t("design.meet_a_designer_link")}
          <img src={RightArrow} className={styles.arrow} alt={""} />
        </a>
      </div>
    </MessageCard>
  );
};

export default WorkWithADesignerMessage;
