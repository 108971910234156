import axios from "axios";
import PricingService from "./PricingService";

const REACT_APP_DESIGN_STATE_URL = process.env.REACT_APP_DESIGN_STATE_URL;
class DesignStateService {
  static getAuthHeader() {
    return {
      headers: { "x-api-key": process.env.REACT_APP_DESIGN_STATE_API_KEY },
    };
  }

  static async createDesignState(data) {
    const res = await axios.put(
      `${REACT_APP_DESIGN_STATE_URL}/design`,
      data,
      this.getAuthHeader()
    );

    return res;
  }

  static duplicateDesignState(data) {
    return axios.put(
      `${REACT_APP_DESIGN_STATE_URL}/duplicate`,
      data,
      this.getAuthHeader()
    );
  }
  /* TODO implement a soft delete for design state in DynamoDB
  static async deleteDesign(data) {
    return axios.delete(
      `${REACT_APP_DESIGN_STATE_URL}/design`,
      data,
      this.getAuthHeader()
    );
  }
*/

  static async getDesignState(designId) {
    return axios.get(
      `${REACT_APP_DESIGN_STATE_URL}/design/${designId}`,
      this.getAuthHeader()
    );
  }

  /**
   * Because of CORS preflight, updateDesign is not using x-api-key in the header, but in the payload
   * It is also not using application/json as content-type, but form data
   * With those modifications, CORS preflight won't occur and save 30ms on each call to update design
   * @param {*} data
   * @returns
   */
  static async updateDesign(data, product) {
    const formData = new FormData();
    formData.append("jsonPayload", JSON.stringify(data));
    formData.append("x-api-key", process.env.REACT_APP_DESIGN_STATE_API_KEY);
    const res = await axios.post(
      `${REACT_APP_DESIGN_STATE_URL}/design`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    const { status } = res;

    if (status === 200) {
      window.analytics.track("Design Changed", {
        product: product.name,
        action: data.action,
        designId: data.designId,
      });
    }

    return res;
  }

  static async getDesignStateFromList(data) {
    return axios.post(
      `${REACT_APP_DESIGN_STATE_URL}/designs`,
      data,
      this.getAuthHeader()
    );
  }

  static async getDesignAndPricingCartData(currency) {
    return await PricingService.getCartPricing({ currency });
  }

  /**
   * Get the product definition + tagOptions, printOptions, repeatingPatternsOptions
   * @param {*} family
   * @param {*} product
   * @returns
   */
  static async getProductDefinition(family, product) {
    const data = {
      family,
      product,
    };

    return axios.post(
      `${REACT_APP_DESIGN_STATE_URL}/productdefinition`,
      data,
      this.getAuthHeader()
    );
  }

  /**
   * @returns
   */
  static async getDecorationsOptionsDefinition() {
    return axios.get(
      `${REACT_APP_DESIGN_STATE_URL}/decorationoptionsdefinition`,
      this.getAuthHeader()
    );
  }

  /**
   * @returns
   */
  static async getFabricColors(fabricName) {
    const { data } = await axios.get(
      `${REACT_APP_DESIGN_STATE_URL}/fabriccolors/${fabricName}`,
      this.getAuthHeader()
    );
    return data.fabricColors;
  }

  /**
   * @returns
   */
  static async getSpecs() {
    const { data } = await axios.get(
      `${REACT_APP_DESIGN_STATE_URL}/allproductdefinition`,
      this.getAuthHeader()
    );
    return data.productDefinitions;
  }

  /**
   * @returns
   */
  static async getDesignsImages(designIds) {
    const { data } = await axios.post(
      `${REACT_APP_DESIGN_STATE_URL}/designs/thumbnails`,
      { designIdList: designIds },
      this.getAuthHeader()
    );
    return data;
  }
}

export default DesignStateService;
