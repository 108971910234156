import { memo } from "react";
import { Box, Icon } from "../../../components";

import { Typography } from "@mui/material";
import { Styles, palette } from "../../../../styles/theme";
import Avatar from "../../../components/Avatar/Avatar";
import { useSelector } from "../../../hooks/useSelector";

const styles: Styles = {
  profileContainer: {
    display: "flex",
    alignItems: "center",
    p: 1,
    justifyContent: "space-between",
    cursor: "pointer",
    borderRadius: "4px",
    "&:active": {
      background: palette.blue.paleEffect,
    },
  },
};

interface Props {
  onClick: () => void;
}

const UserProfileNavLink = ({ onClick }: Props) => {
  const userInfos = useSelector((state) => state.userInfo);

  return (
    <Box
      sx={styles.profileContainer}
      onClick={onClick}
      data-test="mobileLeftNavigation.userProfileNavLink"
    >
      <Box gap={1} display="flex">
        {userInfos?.firstName ? (
          <Avatar
            userId={userInfos.id}
            imageUrl={userInfos.imageUrl}
            initials={
              userInfos.firstName[0].toLocaleUpperCase() +
              userInfos.lastName[0].toLocaleUpperCase()
            }
            size={"sm"}
          />
        ) : undefined}
        <Typography variant="actionSmallIcon">{`${userInfos.firstName} ${userInfos.lastName}`}</Typography>
      </Box>
      <Icon icon="chevron_right" size={18} color={palette.grey.nearBlack} />
    </Box>
  );
};

export default memo(UserProfileNavLink);
