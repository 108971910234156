import { Box } from "@mui/material";
import React, { PropsWithChildren } from "react";
import Helmet from "react-helmet";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import onboardImage from "../../assets/onboarding/onboarding.jpg"; // Tell Webpack this JS file uses this image
import { Link } from "../../common/components";
import { JameoLogo, LOGO_SIZE_ENUM } from "../../components/JameoLogo";
import { OnboardingMarquee } from "../../components/Onboarding/OnboardingMarquee";
import { spacing } from "../../styles/theme";
import { useTokenInfo } from "../../utils/ReactHooks";
import { useThemeBreakpoints } from "../../utils/themeUtils";
import styles from "./Onboarding.module.scss";

interface Props extends PropsWithChildren {
  hideMarquee?: boolean;
}

export const OnboardingTemplate = ({ children, hideMarquee }: Props) => {
  const { token } = useParams();

  const { isMobile } = useThemeBreakpoints();

  //Change the language if the URL param set a specific language
  const { t, i18n } = useTranslation();

  const tokenInfo = useTokenInfo(token);

  return (
    <React.Fragment>
      <Helmet>
        <title>Jameo Studio</title>
        <meta charSet="utf-8" />
        <meta name="description" content={t("sign_in.description")} />
      </Helmet>
      <div className={isMobile ? styles.mobileContainer : styles.container}>
        <Box
          className={styles.leftContent}
          p={{ xs: 0, lg: spacing.largeDesktop }}
        >
          <div>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mb={{ xs: 0, lg: spacing.xlDesktop }}
              minHeight={isMobile ? 72 : undefined}
              px={{ xs: spacing.ctaSpacingMobile, lg: 0 }}
            >
              <JameoLogo size={LOGO_SIZE_ENUM.MEDIUM} />
              <Link
                to=""
                onClick={(e) => {
                  e.preventDefault();
                  i18n.changeLanguage(i18n.language === "en" ? "fr" : "en");
                }}
              >
                {t("sign_in.language")}
              </Link>
            </Box>

            {isMobile && !hideMarquee && (
              <Box mb={8}>
                <OnboardingMarquee />
              </Box>
            )}

            {tokenInfo && (
              <Box
                px={{ xs: spacing.ctaSpacingMobile, lg: 0 }}
                className={styles.invitationContainer}
              >
                <span className={styles.invitationTitle}>
                  {t("sign_in.invitation", {
                    name: tokenInfo?.invitorName,
                  })}
                </span>
                <span className={styles.invitationTeamName}>
                  {tokenInfo?.teamName}
                </span>
                .
              </Box>
            )}

            <Box
              width={"100%"}
              display={"flex"}
              justifyContent="center"
              alignItems="center"
              px={{ xs: spacing.wrapperMobile, lg: 0 }}
            >
              <Box maxWidth={"400px"} width={"100%"}>
                {children}
              </Box>
            </Box>
          </div>
        </Box>
        <div className={styles.rightContent}>
          <img src={onboardImage} alt="Logo" />;
        </div>
      </div>
    </React.Fragment>
  );
};
