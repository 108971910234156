import { configureStore, Tuple } from "@reduxjs/toolkit";

import createSagaMiddleware from "redux-saga";
import reducer from "./rootReducer";
import rootSaga from "./rootSaga";

const saga = createSagaMiddleware();
const middlewares = [saga];
const store = configureStore({
  reducer: reducer,
  middleware: () => new Tuple(...middlewares),
});

saga.run(rootSaga);
export default store;
