import { FormikConfig, FormikValues, useFormik } from "formik";
import { setLocale } from "yup";
import { FormikPropsWithCustomError } from "../../context/Form/FormContext";

setLocale({
  // use constant translation keys for messages without values
  // mixed: {
  //   default: 'field_invalid',
  // },
  // // use functions to generate an error object that includes the value from the schema
  // number: {
  //   min: ({ min }) => ({ key: 'field_too_short', values: { min } }),
  //   max: ({ max }) => ({ key: 'field_too_big', values: { max } }),
  // },
  mixed: {
    required: (custom) => {
      return {
        key: "general.errors.required.generic",
        values: { name: custom.path },
      };
    },
  },
});

export const useForm = <T extends FormikValues>(props: FormikConfig<any>) => {
  //TODO need to find a way to type that
  const formik = useFormik<T>({ ...props, validateOnChange: true });
  return formik as FormikPropsWithCustomError<T>;
};
