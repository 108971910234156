import axios from "axios";
import fileDownload from "js-file-download";
import AuthService from "./authService";
const USER_API_BASE_URL = process.env.REACT_APP_BACKEND_URL;

const BASE_ROUTE = "/api/v1/projects";

class ProjectService {
  static async getAllProjects() {
    return axios.get(
      `${USER_API_BASE_URL}${BASE_ROUTE}/`,
      this.getAuthHeader()
    );
  }

  static async getAllProjectsByTeamId(teamId) {
    return axios.post(
      `${USER_API_BASE_URL}${BASE_ROUTE}/`,
      { teamId },
      this.getAuthHeader()
    );
  }

  static async createProject(data) {
    const res = await axios.post(
      `${USER_API_BASE_URL}${BASE_ROUTE}/project`,
      data,
      this.getAuthHeader()
    );

    const {
      data: {
        data: { id },
        status,
      },
    } = res;

    if (status === "success" && id) {
      window.analytics.track("Project Created");
    }

    return res;
  }

  static async deleteProject(data) {
    return axios.delete(
      `${USER_API_BASE_URL}${BASE_ROUTE}/project/${data.id}`,
      this.getAuthHeader()
    );
  }

  static async updateProjectName(data) {
    return axios.put(
      `${USER_API_BASE_URL}${BASE_ROUTE}/project/name`,
      data,
      this.getAuthHeader()
    );
  }

  static async getCollectSizeInfo(token) {
    return axios.get(
      `${USER_API_BASE_URL}${BASE_ROUTE}/collectSizeInfo?token=${token}`,
      this.getAuthHeader()
    );
  }

  /**
   *
   * @param {Object} data // projectId + url
   */
  static async updateCoverPhoto(data) {
    return axios.put(
      `${USER_API_BASE_URL}${BASE_ROUTE}/project/coverPhoto`,
      data,
      this.getAuthHeader()
    );
  }
  static async updateIcon(data) {
    return axios.put(
      `${USER_API_BASE_URL}${BASE_ROUTE}/project/icon`,
      data,
      this.getAuthHeader()
    );
  }

  static async downloadLeadTemplate(projectId, projectName, language) {
    const resS3Url = await axios.post(
      `${USER_API_BASE_URL}${BASE_ROUTE}/project/${projectId}/generateSalesPresentation/${language}`,
      undefined,
      this.getAuthHeader()
    );

    const res = await axios.get(resS3Url.data.data, {
      responseType: "blob",
    });
    fileDownload(res.data, `${projectName}.pdf`);

    return res;
  }

  static async turnIntoTeam(projectId) {
    return axios.post(
      `${USER_API_BASE_URL}${BASE_ROUTE}/project/${projectId}/turnIntoTeam`,
      undefined,
      this.getAuthHeader()
    );
  }

  static async duplicate(projectId) {
    return axios.post(
      `${USER_API_BASE_URL}${BASE_ROUTE}/project/${projectId}/duplicate`,
      undefined,
      this.getAuthHeader()
    );
  }

  static getAuthHeader() {
    return { headers: { "x-access-token": AuthService.getJWT() } };
  }
}

export default ProjectService;
