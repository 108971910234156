import React from "react";
import DarkTooltip from "../../../../common/components/DarkTooltip";
import classNames from "classnames";
import styles from "./ModelSwitcher.module.scss";
import { DESIGN_CHANGE_ENUM } from "../../../../utils/ReactHooks";
import { useTranslation } from "react-i18next";
import { DesignFromGenerator } from "../../../../types";

interface Props {
  siblingProducts: DesignFromGenerator["siblingProducts"];
  currentProductName: string;
  onDesignChange: (action: DESIGN_CHANGE_ENUM, data: any) => Promise<void>;
}

export const ModelSwitcher = (props: Props) => {
  const { siblingProducts, currentProductName, onDesignChange } = props;

  const { t } = useTranslation();

  return (
    <React.Fragment>
      {siblingProducts && siblingProducts.length > 1 && (
        <div>
          <h6>{t("designer.general.models")}</h6>
          <div className={styles.modelIconsContainer}>
            {siblingProducts.map((s) => (
              <DarkTooltip title={t(`products.${s.name}.name`)} key={s.name}>
                <div
                  className={classNames(
                    styles.modelIconContainer,
                    s.name === currentProductName
                      ? styles.styleSelected
                      : styles.styleNotSelected
                  )}
                >
                  <div
                    className={styles.iconContainer}
                    onClick={() =>
                      onDesignChange(DESIGN_CHANGE_ENUM.SET_MODEL, {
                        model: { name: s.name, version: s.version },
                      })
                    }
                  >
                    <img alt="iconUrl" src={s.icon} />
                  </div>
                </div>
              </DarkTooltip>
            ))}
          </div>
        </div>
      )}
    </React.Fragment>
  );
};
