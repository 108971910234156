import { TableRow, TableCell, IconButton, Collapse } from "@mui/material";
import { Box } from "@mui/system";
import { t } from "i18next";
import React from "react";
import { Link } from "react-router-dom";
import { ROUTES } from "../../utils/Constant";
import { routeFormatter } from "../../utils/Formatter";
import { AdminPurchasedOrderCompact } from "../AdminPurchasedOrderCompact";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  AdminPurchasedOrdersColumn,
  AdminPurchasedOrdersRow,
} from "./AdminPurchasedOrders";

interface Props {
  columns: AdminPurchasedOrdersColumn[];
  row: AdminPurchasedOrdersRow;
}

export const AdminPurchasedOrdersCollapsibleRow = (props: Props) => {
  const { row, columns } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell key="id" padding={"none"}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        {columns.map((column) => {
          const value = row[column.id];
          return (
            <TableCell key={column.id} align={column.align} padding={"none"}>
              {value}
            </TableCell>
          );
        })}
        <TableCell key="techpack">
          {row.orderDetail.type === "order" &&
            row.orderDetail.details.techpack && (
              <Link
                to={routeFormatter(ROUTES.ADMIN_TECHPACK_ORDER, {
                  params: { orderId: row.orderDetail.details.order?.id },
                })}
                key={row.orderDetail.details.order?.id}
              >
                {t("order.actions.go_to_techpack")}
              </Link>
            )}
          {row.orderDetail.type === "preorder" &&
            row.orderDetail.details.techpack && (
              <Link
                to={routeFormatter(ROUTES.ADMIN_TECHPACK_PREORDER, {
                  params: { preorderId: row.orderDetail.details.preorder?.id },
                })}
                key={row.orderDetail.details.preorder?.id}
              >
                {t("order.actions.go_to_techpack")}
              </Link>
            )}
        </TableCell>
        <TableCell key="preorder">
          {row.orderDetail.type === "preorder" && row.orderDetail.details && (
            <Link
              to={routeFormatter(ROUTES.ADMIN_PREORDER, {
                params: { preorderId: row.orderDetail.details.preorder?.id },
              })}
              key={row.orderDetail.details.preorder?.id}
            >
              {t("order.actions.go_to_preorder")}
            </Link>
          )}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={columns.length + 3}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ marginTop: 2 }}>
              <AdminPurchasedOrderCompact
                orderDetail={row.orderDetail.details}
                type={row.orderDetail.type}
              />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};
