import React from "react";
import { useTranslation } from "react-i18next";
import { PhoneNumber } from "../../../common/components/Input";
import styles from "./address.module.scss";
import USStates from "../states.json";
import CAProvinces from "../provinces.json";

const Address = (props) => {
  const { t } = useTranslation();
  const { address, onAddressFieldChange, onAddressCountryChange } = props;

  return (
    <div className={styles.container} key="container">
      <div className={styles.deliveryForm} key="form">
        <input
          key="organization"
          onChange={(e) => onAddressFieldChange(e, "organization")}
          value={address.organization}
          placeholder={t("checkout.organization")}
          className={styles.organization}
          autoComplete="organization"
          required
        />
        <input
          key="firstName"
          onChange={(e) => onAddressFieldChange(e, "firstName")}
          value={address.firstName}
          placeholder={t("checkout.first_name")}
          className={styles.firstName}
          autoComplete="given-name"
          required
        />
        <input
          key="lastName"
          onChange={(e) => onAddressFieldChange(e, "lastName")}
          value={address.lastName}
          placeholder={t("checkout.last_name")}
          className={styles.lastName}
          autoComplete="family-name"
          required
        />
        <input
          key="email"
          onChange={(e) => onAddressFieldChange(e, "email")}
          value={address.email}
          placeholder={t("checkout.email")}
          className={styles.email}
          autoComplete="email"
          type={"email"}
          required
        />
        <PhoneNumber
          key="phone"
          defaultCountry="CA"
          value={address.phone}
          onChange={(newPhoneValue) =>
            onAddressFieldChange({ target: { value: newPhoneValue } }, "phone")
          }
          className={styles.phone}
          extension={address.phoneExt}
          onExtensionChange={(newPhoneExtValue) =>
            onAddressFieldChange(
              { target: { value: newPhoneExtValue } },
              "phoneExt"
            )
          }
        />
        {/* <input
          onChange={(e) => onAddressFieldChange(e, "phone")}
          value={address.phone}
          placeholder={t("checkout.phone")}
          className={styles.phone}
          autoComplete="tel"
          type="tel"
          required
        /> */}
        <input
          key="address"
          onChange={(e) => onAddressFieldChange(e, "address")}
          value={address.address}
          placeholder={t("checkout.address")}
          className={styles.address}
          autoComplete="street-address"
          required
        />
        <input
          key="appartment"
          onChange={(e) => onAddressFieldChange(e, "appartment")}
          value={address.appartment}
          placeholder={t("checkout.appartment")}
          className={styles.appartment}
        />
        <input
          key="city"
          onChange={(e) => onAddressFieldChange(e, "city")}
          value={address.city}
          placeholder={t("checkout.city")}
          className={styles.city}
          autoComplete="address-level2"
          required
        />
        <select
          key="country"
          onChange={(e) => onAddressCountryChange(e, "country")}
          value={address.country}
          placeholder={t("checkout.country")}
          className={styles.country}
          autoComplete="country"
        >
          {["CA", "US"].map((option) => (
            <option value={option} key={option}>
              {t(`countryCodes.${option}`)}
            </option>
          ))}
        </select>
        <select
          key="state"
          onChange={(e) => onAddressFieldChange(e, "state")}
          value={address.state}
          placeholder={
            address.country === "US"
              ? t("checkout.state")
              : t("checkout.province_region")
          }
          className={styles.state}
          autoComplete="address-level1"
        >
          {(address.country === "US" ? USStates : CAProvinces).map((option) => (
            <option value={option} key={option}>
              {t(`alphaCodes.${option}`)}
            </option>
          ))}
        </select>
        <input
          key="zipcode"
          onChange={(e) => onAddressFieldChange(e, "zipcode")}
          value={address.zipcode}
          placeholder={
            address.country === "US"
              ? t("checkout.zip_code")
              : t("checkout.postal_code")
          }
          className={styles.zipcode}
          autoComplete="postal-code"
          required
        />
      </div>
    </div>
  );
};

export default Address;
