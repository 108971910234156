import { CSSProperties, memo, useMemo } from "react";

import { Typography } from "@mui/material";
import { palette, Styles } from "../../../styles/theme";
import { getAvatarColor } from "../../../utils/themeUtils";
import { Box } from "../Box";

const useStyles = (noBorder?: boolean): Styles => ({
  container: {
    backgroundColor: "#555555",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    userSelect: "none",
    border: !noBorder ? `2px solid ${palette.white}` : undefined,
  },
  size_sm: {
    minHeight: "18px",
    minWidth: "18px",
    maxHeight: "18px",
    maxWidth: "18px",
    padding: "4px",

    "&>*": {
      fontSize: "8px !important",
      fontWeight: "600",
      lineHeight: "normal !important",
    },
  },
  size_md: {
    minHeight: "32px",
    minWidth: "32px",
    maxHeight: "32px",
    maxWidth: "32px",
    padding: "2px",
    "&>*": {
      fontSize: "12px !important",
      lineHeight: "normal !important",
    },
  },
  size_lg: {
    minHeight: "40px",
    minWidth: "40px",
    maxHeight: "40px",
    maxWidth: "40px",
    padding: "2px",
    "&>*": {
      fontSize: "14px !important",
      lineHeight: "normal !important",
    },
  },
  initials: {
    textAlign: "center",
    color: "#f1f1f1",
    fontStyle: "normal",
    fontWeight: "500",
  },
});

interface Props {
  initials: string;
  color?: string;
  imageUrl?: string;
  size?: "sm" | "md" | "lg";
  userId?: string;
  noBorder?: boolean;
}

const Avatar = ({
  color = `hsl(0, 40%, 60%)`,
  imageUrl,
  initials,
  size = "md",
  userId,
  noBorder,
}: Props) => {
  const styles = useStyles(noBorder);
  const avatarColor = useMemo(
    () => (userId ? getAvatarColor(userId) : color),
    [userId, color]
  );

  if (imageUrl) {
    return (
      <img
        style={
          {
            ...styles.container,
            ...styles[`size_${size}`],
            background: "transparent",
            padding: 0,
          } as CSSProperties
        }
        src={imageUrl}
        alt="Avatar"
      />
    );
  }

  return (
    <Box
      sx={
        {
          ...styles.container,
          ...styles[`size_${size}`],
        } as CSSProperties
      }
      style={{
        backgroundColor: avatarColor,
      }}
    >
      <Typography sx={styles.initials}>{initials || ""}</Typography>
    </Box>
  );
};

export default memo(Avatar);
