import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import styles from "./Swag.module.scss";

const Design = (props) => {
  const { t } = useTranslation();
  const { design, onAddToSelection } = props;

  const [selectedSize, setSelectedSize] = useState(null);

  const options = design.sizes.map((o) => ({
    value: o.name,
    label: t(`design.${o.displayLabel}`),
  }));

  return (
    <div className={styles.designContainer}>
      <div className={styles.imgContainer}>
        {design.images?.map((image) => (
          <img
            src={image.url}
            alt={image.side}
            className={styles.img}
            key={image.side}
          />
        ))}
      </div>
      <div className={styles.content}>
        <h4>{design.name || t("design.untitled_design")}</h4>
        <h6>
          {t("products." + design.designState.product.name + ".name")} -{" "}
          {t("products." + design.designState.product.name + ".description")}
        </h6>
        <p>{design.description}</p>
        {/* eslint-disable-next-line */}
        <a href={t("checkout.size_link")} target="_blank">
          {t("design.size_chart")}
        </a>
        <div>
          <select
            value={selectedSize || ""}
            onChange={(e) => {
              setSelectedSize(e.target.value);
            }}
          >
            <option key="empty" value={null}>
              {t("project.select_size")}
            </option>
            {options.map((o) => (
              <option key={o.value} value={o.value}>
                {o.label}
              </option>
            ))}
          </select>
          <button
            onClick={(e) => {
              onAddToSelection(design, selectedSize);
            }}
            disabled={selectedSize === null}
          >
            {t("design.add")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Design;
