import React, { useCallback, useMemo, useState } from "react";
import ReactSelect from "react-select";

// Custom components
import ValueContainer from "./ValueContainer";
import Menu from "./Menu";
import MenuList from "./MenuList";
import Option from "./Option";
import TransparentColor from "./ColorCircle/colors/transparent.png";
import ColorPicker from "../ColorPickerSelect";

const COLOR_SELECT = "colorSelect";
const COLOR_PICKER = "colorPicker";

const ColorSelect = (props) => {
  const {
    value,
    onChange,
    allowNoColors,
    allowTransparent = false,
    disableAllColors = false,
    deprecateTransparency = false,
    availableCombos = null,
    availableColors = null,
    isAdmin = false,
    enableCustomColorSelection = true,
    bleedingWarning = false,
  } = props;

  // Try to initialise the hex to the color selected. If unable, default to #FFFFFF
  let initialSelectedHexState = "#FFFFFF";
  const initialColorFromChart = availableColors?.find(
    (c) => c.name === value?.value
  );
  if (typeof value?.value === "string" && value?.value.startsWith("#")) {
    initialSelectedHexState = value.value;
  } else if (initialColorFromChart?.hex) {
    initialSelectedHexState = initialColorFromChart?.hex || "#FFFFFF";
  } else if (initialColorFromChart?.fallbackColor) {
    initialSelectedHexState = initialColorFromChart?.fallbackColor || "#FFFFFF";
  }

  const [selectedColorHex, setSelectedColorHex] = useState(
    initialSelectedHexState
  );
  const [colorSelector, setColorSelector] = useState(COLOR_SELECT);

  const themeFct = (theme) => {
    return {
      ...theme,
      borderRadius: 4,
      spacing: {
        ...theme.spacing,
        menuGutter: 0,
      },
      colors: {
        danger: "#DE350B",
        dangerLight: "#FFBDAD",
        neutral0: "hsl(0, 0%, 100%)",
        neutral5: "hsl(0, 0%, 95%)",
        neutral10: "hsl(0, 0%, 90%)",
        neutral20: "hsl(0, 0%, 80%)",
        neutral30: "hsl(0, 0%, 70%)",
        neutral40: "hsl(0, 0%, 60%)",
        neutral50: "hsl(0, 0%, 50%)",
        neutral60: "hsl(0, 0%, 40%)",
        neutral70: "hsl(0, 0%, 30%)",
        neutral80: "hsl(0, 0%, 20%)",
        neutral90: "hsl(0, 0%, 10%)",
        primary: "#EEE",
        primary25: "#EEE",
        primary50: "#EEE",
        primary75: "#EEE",
      },
    };
  };

  const options = useMemo(() => {
    if (availableCombos) {
      return availableCombos.map((ac) => {
        return {
          value: ac.name,
          label: ac.label,
          colorsHex: ac.colorsHex,
          isDisabled: disableAllColors,
          isDeprecated: ac.disabled || ac.hidden || false,
        };
      });
    }

    // Else do normal shit with color
    let result = availableColors.map((ac) => {
      if (ac.textureUrl) {
        return {
          value: ac.name,
          label: ac.label,
          png: ac.textureUrl,
          isDisabled: disableAllColors,
          isDeprecated: ac.disabled || ac.hidden || false,
        };
      } else {
        return {
          value: ac.name,
          label: ac.label,
          hexCode: ac.hex,
          isDisabled: disableAllColors,
          isDeprecated: ac.disabled || ac.hidden || false,
        };
      }
    });

    if (allowNoColors) {
      result = [
        ...result,
        {
          value: "inherited",
          label: "no_color",
          isDisabled: disableAllColors,
        },
      ];
    }

    if (allowTransparent) {
      result = [
        ...result,
        {
          value: "transparent",
          label: "transparent",
          isDisabled: disableAllColors,
          png: TransparentColor,
          isDeprecated: deprecateTransparency || false,
        },
      ];
    }

    return result;
  }, [
    availableColors,
    availableCombos,
    allowNoColors,
    allowTransparent,
    disableAllColors,
    deprecateTransparency,
  ]);

  const onChartColorSelection = useCallback(
    (color, triggeredAction) => {
      setSelectedColorHex(color?.hexCode || "#FFFFFF");
      onChange(color, triggeredAction);
    },
    [onChange]
  );

  const onCustomColorSelection = useCallback(
    (color) => {
      setSelectedColorHex(color);
      onChange({ value: color, label: "custom" });
    },
    [onChange]
  );

  const onSelectColorFromList = useCallback(() => {
    setColorSelector(COLOR_SELECT);
  }, []);

  const onSelectCustomColor = useCallback(() => {
    setColorSelector(COLOR_PICKER);
  }, []);

  const reactSelectStyles = {
    control: (css, { isDisabled }) => ({
      ...css,
      backgroundColor: disableAllColors ? "#F9FAFB" : "transparent", //can't use Disabled because it won't show the options if isDisabled=true for the control
      cursor: isDisabled ? "not-allowed" : "pointer",
    }),
    option: (css, { isDisabled }) => ({
      ...css,
      cursor: isDisabled ? "not-allowed" : "pointer",
    }),
    clearIndicator: (css, { isDisabled }) => ({
      ...css,
      cursor: isDisabled ? "not-allowed" : "pointer",
    }),
  };

  return colorSelector === COLOR_PICKER ? (
    <div>
      <ColorPicker
        value={selectedColorHex}
        onColorChange={onCustomColorSelection}
        onSelectColorFromList={onSelectColorFromList}
        openByDefault={true}
      />
    </div>
  ) : (
    <ReactSelect
      {...props}
      onChange={onChartColorSelection}
      components={{
        ValueContainer: (props) => (
          <ValueContainer {...props} bleedingWarning={bleedingWarning} />
        ),
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null,
        Menu: (props) => (
          <Menu
            {...props}
            enableCustomColorSelection={isAdmin && enableCustomColorSelection}
            onSelectCustomColor={onSelectCustomColor}
          />
        ),
        MenuList,
        Option,
      }}
      options={options}
      theme={themeFct}
      styles={reactSelectStyles}
      isSearchable={false}
    />
  );
};

export default ColorSelect;
