import { Skeleton, Typography } from "@mui/material";
import React, { memo } from "react";
import { Box, Icon } from "..";
import { Styles, palette } from "../../../styles/theme";
import { useGlobalLoading } from "../../../utils/ContextHooks";
import { useThemeBreakpoints } from "../../../utils/themeUtils";
import { MaterialSymbolType } from "../Icon/IconConstant";

const styles: Styles = {
  container: {
    p: { xs: "16px", lg: "24px 36px 24px 24px" },
    borderRadius: "12px",
    border: `1px solid ${palette.grey.midGrey}`,
    display: "flex",
    alignItems: "center",
    gap: 1.5,
    minHeight: { xs: "66px", lg: "72px" },
    maxHeight: { xs: "66px", lg: "72px" },
    cursor: "pointer",
    boxShadow: "2px 2px 4px 0px rgba(236, 236, 236, 0.40)",
    flex: { xs: "1 1 50%", sm: "unset" },
    "&:hover": {
      background: `linear-gradient(90deg, rgba(84, 172, 222, 0.04) 0%, rgba(169, 84, 222, 0.04) 100%),
        ${palette.borderGradientHover}`,
      border: "1px solid transparent",
    },
    "&:active": {
      // minus one on the padding to prevent content shifting cause be the increase of 1 px of border
      p: { xs: "15px", lg: "23px 35px 23px 23px" },
      background: palette.borderGradient,
      border: "2px solid transparent",
      ":hover": {
        background: `linear-gradient(90deg, rgba(84, 172, 222, 0.04) 0%, rgba(169, 84, 222, 0.04) 100%),
        ${palette.borderGradient}`,
        border: "2px solid transparent",
      },
    },
  },
  mainText: {
    fontSize: { xs: "14px", lg: "16px" },
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "24px",
    color: palette.grey.primary,
  },
  skeleton: {
    display: "flex",
    flex: { xs: "1 1 50%", sm: "unset" },
    width: {
      xs: 150,
      lg: 250,
    },
    p: { xs: "16px", lg: "24px 36px 24px 24px" },
    minHeight: { xs: "66px", lg: "100px" },
  },
};

interface Props {
  text: string;
  subtext?: string;
  icon: MaterialSymbolType;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
  isLoading?: boolean;
}

const GradientButton = (props: Props) => {
  const { text, subtext, icon, onClick, isLoading, ...rest } = props;
  const { isGloballyLoading } = useGlobalLoading();
  const { isMobile } = useThemeBreakpoints();

  return isGloballyLoading || isLoading ? (
    <Skeleton variant="rounded" sx={styles.skeleton} />
  ) : (
    <Box sx={styles.container} onClick={onClick} {...rest}>
      <Icon
        icon={icon}
        size={isMobile ? 16 : 24}
        color={palette.grey.primary}
      />
      <Box>
        <Typography variant={isMobile ? "textRegular" : "actionBigIcon"}>
          {text}
        </Typography>
        {subtext && <Typography>{subtext}</Typography>}
      </Box>
    </Box>
  );
};

export default memo(GradientButton);
