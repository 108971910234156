import React, { useCallback, useState, useEffect } from "react";
import styles from "../adminPreorderPriceAndShippingDetails.module.scss";
import { Link } from "react-router-dom";
import _ from "lodash";

const EditableInputTextField = (props) => {
  const {
    initialValue,
    onUpdate,
    titleLabel,
    editLabel,
    saveLabel,
    cancelLabel,
    emptyLabel,
  } = props;

  const [isEditing, setEditing] = useState(false);
  const [valueState, setValueState] = useState("");

  //Initial loading of invoice number
  useEffect(() => {
    setValueState(initialValue);
  }, [initialValue, setValueState]);

  const onClickEdit = useCallback(
    (e) => {
      e.preventDefault();

      setEditing(true);
    },
    [setEditing]
  );

  const onClickSave = useCallback(
    (e) => {
      e.preventDefault();

      onUpdate(valueState).then(() => {
        setEditing(false);
      });
    },
    [setEditing, onUpdate, valueState]
  );

  const onClickCancel = useCallback(
    (e) => {
      e.preventDefault();

      //Reset to original values
      setValueState(initialValue);

      setEditing(false);
    },
    [setValueState, setEditing, initialValue]
  );

  const onInputValueChange = useCallback(
    (e) => {
      setValueState(e.target.value || "");
    },
    [setValueState]
  );
  //End of Invoice number

  return (
    <div className={styles.editableFieldContainer}>
      <div className={styles.editableField} key="editField">
        <span className={styles.title}>{titleLabel} </span>
        {isEditing ? (
          <input
            type="text"
            className={styles.editableText}
            value={valueState}
            onChange={(e) => onInputValueChange(e)}
          />
        ) : (
          <span>{_.isEmpty(valueState) ? emptyLabel : valueState}</span>
        )}
      </div>
      <div className={styles.action}>
        {onUpdate && !isEditing && (
          <Link to="" onClick={(e) => onClickEdit(e)}>
            {editLabel}
          </Link>
        )}
        {isEditing && (
          <React.Fragment>
            <Link to="" onClick={(e) => onClickSave(e)}>
              {saveLabel}
            </Link>{" "}
            <Link to="" onClick={(e) => onClickCancel(e)}>
              {cancelLabel}
            </Link>
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

export default EditableInputTextField;
