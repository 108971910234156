import { Box, BoxProps } from "./Box";

export const CenteredColumnBox = (props: BoxProps) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      {...props}
    />
  );
};
