import { useTranslation } from "react-i18next";

import {
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";
import { useEffect } from "react";
import { object, string } from "yup";
import { Button, Form, Input } from "../../common/components";
import { useForm } from "../../common/hooks/useForm";
import { DialogTitleWithCloseButton } from "../DialogTitleWithCloseButton";

const validationSchema = object({
  name: string().required("general.errors.required.input"),
});

interface Props {
  open: boolean;
  onClose: () => void;
  onRename: (value: string) => void;
  oldName: string;
  title: string;
}

const RenameModal = ({
  open,
  onClose,
  onRename,
  title,
  oldName = "",
}: Props) => {
  const { t } = useTranslation();

  const formik = useForm({
    initialValues: { name: oldName },
    onSubmit: (values) => {
      onRename(values.name);
    },
    validationSchema,
  });

  useEffect(() => {
    formik.setFieldValue("name", oldName);
  }, [oldName]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth={"md"}>
      <Form formik={formik}>
        <DialogTitleWithCloseButton
          id="customized-dialog-title"
          onClose={onClose}
          sx={{ mb: 2 }}
        >
          {title}
        </DialogTitleWithCloseButton>
        <DialogContent sx={{ pr: 0 }}>
          <Input
            type="text"
            name="name"
            sx={{ maxWidth: "unset" }}
            value={formik.values["name"]}
            noMaxWidth
            data-test="rename.input"
          />
        </DialogContent>
        <DialogActions>
          <Button type="submit">
            <Typography variant="actionBoldBigIcon">
              {t("general.rename")}
            </Typography>
          </Button>
        </DialogActions>
      </Form>
    </Dialog>
  );
};

export default RenameModal;
