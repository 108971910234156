import React from "react";

import styles from "./Header.module.scss";

const CollectSizeHeader = (props) => {
  const { iconUrl } = props;

  return (
    <div className={styles.banner}>
      {/*<div className={styles.cover}>*/}
      {/*  <img src={coverPhotoUrl} alt={""}/>*/}
      {/*</div>*/}
      <div className={styles.companyLogo}>
        <img src={iconUrl} alt="" />
      </div>
    </div>
  );

  // if (iconUrl && coverPhotoUrl) {
  //   return (
  //     <div
  //       className={styles.banner}
  //       // style={{ backgroundImage: `url(${coverPhotoUrl}` }}
  //     >
  //       <div className={styles.cover}>
  //         <img src={coverPhotoUrl} alt={""}/>
  //       </div>
  //       <div className={styles.companyLogo}>
  //         <img src={iconUrl} alt="" />
  //       </div>
  //     </div>
  //   );
  // } else if (!coverPhotoUrl && iconUrl) {
  //   return (
  //     <div
  //       className={styles.noBackgroundContainer}
  //       style={{ backgroundImage: "" }}
  //     >
  //       {" "}
  //       <img src={iconUrl} alt="" />
  //     </div>
  //   );
  // } else if (coverPhotoUrl && !iconUrl) {
  //   return (
  //     <div
  //       className={styles.container}
  //       style={{ backgroundImage: `url(${coverPhotoUrl}` }}
  //     ></div>
  //   );
  // } else return null;
};

export default CollectSizeHeader;
