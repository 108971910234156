import { SxProps, useMediaQuery } from "@mui/system";
import seedrandom from "seedrandom";
import { theme } from "../styles/theme";

export const useThemeBreakpoints = () => {
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
  return { isMobile };
};

export const getConditionnalStyle = (condition: any, style: any) =>
  condition ? style : false;

export const getAvatarColor = (userId: string) => {
  const rng = seedrandom(userId);
  const hue = Math.floor(rng() * 360);
  return `hsla(${hue}, 68%, 46%, 1)`;
};

export const getScreenWidthMinusSpacing = (...args: string[]) => {
  const spacingJoined = args.join(" - ");
  return `calc(100vw - ${spacingJoined})`;
};

export const getScreenHeightMinusSpacing = (...args: string[]) => {
  const spacingJoined = args.join(" - ");
  return `calc(100vh - ${spacingJoined})`;
};

export const getRotationStyle = (rotate: boolean) => {
  return {
    transform: rotate ? "rotate(180deg)" : "rotate(0deg)",
    transition: "transform .05s ease",
  };
};

export const mergeSx = (...args: (SxProps | false | undefined | null)[]) => {
  return (args.filter((sx) => sx) as SxProps[]).reduce((acc, curr) => {
    if (!curr) {
      return acc;
    }
    const x = (Array.isArray(curr) ? { ...curr } : curr) as SxProps;
    acc = { ...acc, ...x } as SxProps;
    return acc;
  }, {} as SxProps) as SxProps;
};
