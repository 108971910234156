import Upload from "rc-upload";
import { UploadRequestOption } from "rc-upload/lib/interface";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Button } from "../../common/components/Button";
import { usePageTitle } from "../../common/hooks/usePageTitle";
import { useSelector } from "../../common/hooks/useSelector";
import Message from "../../components/Message";
import ImageService from "../../service/ImageService";
import ProjectService from "../../service/ProjectService";
import { DesignState } from "../../types";
import { useProjectFetcher } from "../../utils/ProjectsFetcherHooks";
import Design from "./Design";
import RowRadioButtonsGroup from "./radioButton";
import { SelectedDesign } from "./SelectedDesign";
import styles from "./SizeContainer.module.scss";
import { ReactComponent as UploadIcon } from "./UploadIcon.svg";

export const Sizes = () => {
  const { projectId } = useParams();
  const { t } = useTranslation();
  usePageTitle(`${t("project.title")} - Jameo Studio`);

  const designsReduxState = useSelector((state) => state.designs);
  const projectsState = useSelector((state) => state.projects);

  const project = useMemo(() => {
    return projectsState?.allProjects.find((p) => p.id === projectId);
  }, [projectsState, projectId]);

  const projectDesigns = useMemo(() => {
    return designsReduxState?.designs.filter((d) => d.project_id === projectId);
  }, [projectId, designsReduxState]);

  const [copied, setCopied] = useState(false);
  const [langVar, setLangVar] = useState("?lang=en");
  const [link, setLink] = useState(
    process.env.REACT_APP_CLIENT_URL +
      "/sizes/" +
      project?.shareable_token +
      langVar
  );
  const [selectedDesigns, setSelectedDesigns] = useState<DesignState[]>([]);

  const onCopy = () => {
    navigator.clipboard.writeText(link);
    setCopied(true);
  };

  const { fetchMyProjectsAndUpdateStore } = useProjectFetcher();

  //If the cover image uuid is set, then fetch the image
  //put back the states and use useEffect
  // const [coverPhotoData, setCoverPhotoData] = useState(null);
  // useEffect(() => {
  //   if (project?.cover_uuid) {
  //     ImageService.getPublicImage(project.cover_uuid)
  //       .then((data) => {
  //         setCoverPhotoData(data);
  //       })
  //       .catch(() => {
  //         setCoverPhotoData(null);
  //       });
  //   } else {
  //     setCoverPhotoData(null);
  //   }
  // }, [project?.cover_uuid]);

  //If the icon uuid is set, then fetch the image
  const [iconPhotoData, setIconPhotoData] = useState<any>(null);
  useEffect(() => {
    if (project?.icon_uuid) {
      ImageService.getPublicImage(project.icon_uuid)
        .then((data) => {
          setIconPhotoData(data);
        })
        .catch((e) => {
          setIconPhotoData(null);
        });
    } else {
      setIconPhotoData(null);
    }
  }, [project?.icon_uuid]);

  // const customRequestCoverPhoto = useCallback(
  //   ({ file }) => {
  //     // Upload to S3 and get back S3 url
  //     ImageService.uploadPublicImage(file, project?.team_id).then(
  //       ({ assetId, assetVersionId }) => {
  //         // Store the URL inside the DB
  //         ProjectService.updateCoverPhoto({
  //           uuid: assetId,
  //           assetVersionId,
  //           projectId,
  //         }).then((data) => {
  //           fetchMyProjectsAndUpdateStore();
  //         });
  //       }
  //     );
  //     //TODO handle errors and call onError, handle success with onSuccess...
  //     //see https://www.npmjs.com/package/rc-upload with customRequest to see what I mean
  //   },
  //   [projectId, project?.team_id, fetchMyProjectsAndUpdateStore]
  // );

  const customRequestIconPhoto = useCallback(
    ({ file }: UploadRequestOption<any>) => {
      // Upload to S3 and get back S3 url
      ImageService.uploadPublicImage(file, project?.team_id).then(
        ({ assetId, assetVersionId }) => {
          // Store the URL inside the DB
          ProjectService.updateIcon({
            uuid: assetId,
            assetVersionId,
            projectId,
          }).then((data) => {
            fetchMyProjectsAndUpdateStore();
          });
        }
      );
      //TODO handle errors and call onError, handle success with onSuccess...
      //see https://www.npmjs.com/package/rc-upload with customRequest to see what I mean
    },
    [projectId, project?.team_id, fetchMyProjectsAndUpdateStore]
  );

  useEffect(() => {
    setSelectedDesigns(projectDesigns?.filter((d) => d.collectable_since));
  }, [projectDesigns]);

  useEffect(() => {
    setLink(
      process.env.REACT_APP_CLIENT_URL +
        "/sizes/" +
        project?.shareable_token +
        langVar
    );
  }, [langVar, project]);

  return (
    <div className={styles.centerContainer}>
      <div className={styles.banner}>
        {/*<Upload*/}
        {/*  className={styles.coverSection}*/}
        {/*  accept=".jpg,.jpeg,.png,.eps,.svg,.ai"*/}
        {/*  customRequest={customRequestCoverPhoto}*/}
        {/*>*/}
        {/*  {coverPhotoData ? (*/}
        {/*    <img*/}
        {/*      className={styles.coverPhoto}*/}
        {/*      src={coverPhotoData.fullSizeUrl}*/}
        {/*      alt="cover"*/}
        {/*    />*/}
        {/*  ) : (*/}
        {/*    <div className={styles.uploadCoverPhoto}>*/}
        {/*      <UploadIcon />*/}
        {/*      {t("sizes.select_cover_image")}*/}
        {/*    </div>*/}
        {/*  )}*/}
        {/*</Upload>*/}
        <Upload
          className={styles.companyLogoSection}
          accept=".jpg,.jpeg,.png,.eps,.svg,.ai"
          customRequest={customRequestIconPhoto}
        >
          {iconPhotoData ? (
            <img
              className={styles.companyLogo}
              src={iconPhotoData?.fullSizeUrl}
              alt="icon"
            />
          ) : (
            <div className={styles.uploadCompanyLogo}>
              <UploadIcon />
            </div>
          )}
        </Upload>
      </div>
      <div className={styles.bottomSection}>
        <div className={styles.bottomLeftSection}>
          <div className={styles.selectedDesignsSection}>
            <div className={styles.projectName}>{project?.name}</div>
            {/* If no designs in the project, display a warning */}
            {(!projectDesigns || projectDesigns.length === 0) && (
              <div className={styles.noDesignInstructions}>
                {t("sizes.create_a_product_first")}
              </div>
            )}
            {/* If no designs selected for the size collection */}
            {projectDesigns.length > 0 && selectedDesigns.length === 0 && (
              <div className={styles.noDesignInstructions}>
                {t("sizes.add_product_to_get_started")}
              </div>
            )}
            {/* Display the designs selected */}
            {selectedDesigns.map((design) => (
              <SelectedDesign design={design} />
            ))}
          </div>
          <div className={styles.subtitle}>{t("sizes.your_designs")}</div>
          <div className={styles.designContainer}>
            {projectDesigns?.map((design) => <Design {...design} />)}
          </div>
        </div>
        <div className={styles.bottomRightSection}>
          <div className={styles.subtitle}>{t("sizes.collect_sizes")}</div>
          <div className={styles.collectSizeStep}>
            {t("sizes.collect_size_step_1")}
          </div>
          <div className={styles.collectSizeStep}>
            {t("sizes.collect_size_step_2")}
          </div>

          <div className={styles.collectSizePageSelectionDiv}>
            <RowRadioButtonsGroup
              setLangVar={setLangVar}
            ></RowRadioButtonsGroup>
            <div className={styles.copyLinkDiv}>
              <input type="text" value={link} readOnly />
              <Button onClick={onCopy} className={styles.copyButton}>
                {copied ? t("invite.copied") : t("invite.copy")}
              </Button>
            </div>
          </div>

          <Message
            title={t("sizes.collect_tips_title")}
            message={t("sizes.collect_tips_text")}
          ></Message>
        </div>
      </div>
    </div>
  );
};
