import { PropsWithChildren } from "react";
import { Styles, palette } from "../../../styles/theme";
import { useGlobalLoading } from "../../../utils/ContextHooks";
import { useSelector } from "../../hooks/useSelector";
import { Box } from "../Box";
import { LeftSideBarSkeleton } from "./LeftSideBarSkeleton";

const styles: Styles = {
  container: {
    minWidth: { xs: "300px", lg: "260px" },
    maxWidth: { xs: "300px", lg: "260px" },
    display: "flex",
    flexDirection: "row",
    borderRight: `1px solid ${palette.grey.lightGrey}`,
    p: "12px 0px 12px 12px",
  },
};

interface Props extends PropsWithChildren {}

export const LeftSideBar = ({ children }: Props) => {
  const isProjectsLoaded = useSelector(
    (state) => state.projects.allProjectsLoaded
  );
  const isTeamsLoaded = useSelector((state) => state.myTeams.loaded);
  const { isGloballyLoading } = useGlobalLoading();

  return (
    <Box sx={styles.container}>
      {!isTeamsLoaded || !isProjectsLoaded || isGloballyLoading ? (
        <LeftSideBarSkeleton />
      ) : (
        children
      )}
    </Box>
  );
};

export default LeftSideBar;
