import React, { useEffect, useState } from "react";
import styles from "./referralContainer.module.scss";
import { useTranslation } from "react-i18next";
import CopyButton from "../../components/CopyButton";
import referralImage from "./referral.png";
import ReferralCodeService from "../../service/ReferralCodeService";
import { usePageTitle } from "../../common/hooks/usePageTitle";

const ReferralContainer = (props) => {
  const { t } = useTranslation();
  usePageTitle(t("pages.referral.title"));

  const [referralCode, setReferralCode] = useState("");

  useEffect(() => {
    ReferralCodeService.getReferralCode().then((ref_code) => {
      setReferralCode(ref_code);
    });
  }, []);

  return (
    <div className={styles.centerContainer}>
      <div className={styles.textContainer}>
        <div className={styles.title}>{t("referral.title")}</div>
        <div className={styles.description}>{t("referral.description")}</div>

        {/* How it works steps */}
        <div className={styles.subtitle}>{t("referral.how_it_works")}</div>
        <div className={styles.stepsContainer}>
          <div className={styles.step}>
            <b>
              {t("referral.step_1.step_number")} {t("referral.step_1.action")}
            </b>{" "}
            - {t("referral.step_1.details")}
          </div>
          <div className={styles.copyButton}>
            <CopyButton textToCopy={referralCode}></CopyButton>
          </div>
          <div className={styles.step}>
            <b>
              {t("referral.step_2.step_number")} {t("referral.step_2.action")}
            </b>{" "}
            - {t("referral.step_2.details")}
          </div>{" "}
          {/*TODO: Add link in the text*/}
          <div className={styles.step}>
            <b>
              {t("referral.step_3.step_number")} {t("referral.step_3.action")}
            </b>{" "}
            - {t("referral.step_3.details")}
          </div>
        </div>

        {/* Terms and conditions */}
        <div className={styles.termsAndConditions}>
          <div className={styles.subtitle}>{t("referral.conditions")}</div>
          <p>{t("referral.terms_and_conditions_1")}</p>
          <p>{t("referral.terms_and_conditions_2")}</p>
          <p>{t("referral.terms_and_conditions_3")}</p>
          <p>{t("referral.terms_and_conditions_4")}</p>
        </div>
      </div>

      <div className={styles.imageContainer}>
        <img src={referralImage} alt={""} />
      </div>
    </div>
  );
};

export default ReferralContainer;
