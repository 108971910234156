import React from "react";
import PrintSidebar from "./PrintSidebar";
import EmbroideryPatchSidebar from "./EmbroideryPatchSidebar";
import LeatherTagSidebar from "./LeatherTagSidebar";
import FabricTagSidebar from "./FabricTagSidebar";
import { BEHAVES_LIKE } from "../../../../utils/Constant";

const ControlSidebar = (props) => {
  const {
    decoration,
    decorationDefinition,
    teamId,
    onDesignChange,
    canUpdateArtworkPosition,
    canUpdateArtworkColor,
    setPreviewImageData,
    imageData,
    isAdmin,
  } = props;

  const hideSizeValue = !!decorationDefinition?.display?.hideSizeValue;

  // Depending on the decoration type, return the right controls.
  if (
    decorationDefinition.behavesLike === BEHAVES_LIKE.SCREENPRINT_TYPE ||
    decorationDefinition.behavesLike === BEHAVES_LIKE.DETAILED_PRINT_TYPE
  ) {
    return (
      <PrintSidebar
        decoration={decoration}
        teamId={teamId}
        onDesignChange={onDesignChange}
        canUpdateArtworkPosition={canUpdateArtworkPosition}
        canUpdateArtworkColor={canUpdateArtworkColor}
        setPreviewImageData={setPreviewImageData}
        imageData={imageData}
        isAdmin={isAdmin}
        hideSizeValue={hideSizeValue}
      />
    );
  }
  if (decorationDefinition.behavesLike === BEHAVES_LIKE.LEATHER_TAG_TYPE) {
    return (
      <LeatherTagSidebar
        decoration={decoration}
        teamId={teamId}
        onDesignChange={onDesignChange}
        tagOptionsDef={decorationDefinition.tagOptions}
        canUpdateArtworkPosition={canUpdateArtworkPosition}
        canUpdateArtworkColor={canUpdateArtworkColor}
        setPreviewImageData={setPreviewImageData}
        isAdmin={isAdmin}
        hideSizeValue={hideSizeValue}
      />
    );
  }
  if (decorationDefinition.behavesLike === BEHAVES_LIKE.WOVEN_TAG_TYPE) {
    return (
      <FabricTagSidebar
        decoration={decoration}
        teamId={teamId}
        onDesignChange={onDesignChange}
        tagOptionsDef={decorationDefinition.tagOptions}
        canUpdateArtworkPosition={canUpdateArtworkPosition}
        canUpdateArtworkColor={canUpdateArtworkColor}
        setPreviewImageData={setPreviewImageData}
        imageData={imageData}
        isAdmin={isAdmin}
        hideSizeValue={hideSizeValue}
      />
    );
  }
  if (decorationDefinition.behavesLike === BEHAVES_LIKE.EMBROIDERY_PATCH_TYPE) {
    return (
      <EmbroideryPatchSidebar
        decoration={decoration}
        teamId={teamId}
        onDesignChange={onDesignChange}
        tagOptionsDef={decorationDefinition.tagOptions}
        canUpdateArtworkPosition={canUpdateArtworkPosition}
        canUpdateArtworkColor={canUpdateArtworkColor}
        setPreviewImageData={setPreviewImageData}
        imageData={imageData}
        isAdmin={isAdmin}
        hideSizeValue={hideSizeValue}
      />
    );
  }

  console.error("Decoration type is not defined", __filename);
  return (
    <React.Fragment>
      <div>UNABLE TO LOAD THE CONTROLS.</div>
    </React.Fragment>
  );
};

export default ControlSidebar;
