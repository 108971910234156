import axios from "axios";
import AuthService from "./authService";
const USER_API_BASE_URL = process.env.REACT_APP_BACKEND_URL;

const BASE_ROUTE = "/api/v1/purchaseddesigns";

class PurchasedDesignService {
  static async getMyDesigns() {
    return axios.get(
      USER_API_BASE_URL + BASE_ROUTE + "/",
      this.getAuthHeader()
    );
  }

  static async getDesignAsAdmin(designId) {
    return axios.get(
      USER_API_BASE_URL + BASE_ROUTE + "/admin/design/" + designId,
      this.getAuthHeader()
    );
  }

  static getAuthHeader() {
    return { headers: { "x-access-token": AuthService.getJWT() } };
  }
}

export default PurchasedDesignService;
