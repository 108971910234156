import { useCallback } from "react";
import { useStore } from "react-redux";
import { ALL_TEAMS_ACTION_ENUM } from "../common/reducers/allTeams";
import { MY_TEAMS_ACTION_ENUM } from "../common/reducers/myTeams";
import TeamService from "../service/TeamService";
import { useProjectFetcher } from "./ProjectsFetcherHooks";

export const useTeamFetcher = () => {
  const { dispatch } = useStore();

  // const navigate = useNavigate();

  const { fetchMyProjectsAndUpdateStore } = useProjectFetcher();
  /**
   *
   */
  const fetchMyTeamsAndUpdateStore = useCallback(async () => {
    dispatch({
      type: MY_TEAMS_ACTION_ENUM.UPDATE_MY_TEAMS_LOADING,
      payload: { loading: true },
    });

    const res = await TeamService.getMyTeams();

    dispatch({
      type: MY_TEAMS_ACTION_ENUM.UPDATE_MY_TEAMS,
      payload: res.data.data || [],
    });
  }, [dispatch]);

  /**
   *
   */
  const fetchAllTeamsAndUpdateStore = useCallback(async () => {
    const res = await TeamService.getAllTeams();

    dispatch({
      type: ALL_TEAMS_ACTION_ENUM.UPDATE_ALL_TEAMS,
      payload: res.data.data || [],
    });
  }, [dispatch]);

  /**
   *
   */
  const addToTeam = useCallback(
    async (
      teamId,
      updateTeamAndProjectStores = true,
      redirectToTeamPage = true
    ) => {
      const {
        data: { data: newToken },
      } = await TeamService.getInviteLink(teamId);

      await TeamService.joinUserToTeamWithInviteToken(newToken);

      if (updateTeamAndProjectStores) {
        //Since we added the user to the team, we must refresh the teams's store and projects's store
        fetchMyTeamsAndUpdateStore();
        fetchMyProjectsAndUpdateStore();
      }

      if (redirectToTeamPage) {
        // navigate(
        //   routeFormatter(ROUTES.TEAM_PROJECTS, { params: { id: teamId } })
        // );
      }
    },
    [
      fetchMyTeamsAndUpdateStore,
      fetchMyProjectsAndUpdateStore,
      // navigate,
    ]
  );

  return { fetchMyTeamsAndUpdateStore, fetchAllTeamsAndUpdateStore, addToTeam };
};
