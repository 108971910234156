import AuthService from "../service/authService";

export const getAuthHeader = (contentType = "application/json") => {
  return {
    headers: {
      "x-access-token": AuthService.getJWT(),
      "Content-Type": contentType,
    },
  };
};
