import { Order } from "../../types";
import { Action } from "../../types/redux";

export enum ALL_ORDERS_ACTION_ENUM {
  UPDATE_ALL_PURCHASED_ORDERS = "update_all_purchased_orders",
  UPDATE_LOADING = "update_all_purchased_orders_loading",
}

export interface AllOrderPayload {
  orders: Order[];
}

const initialState = {
  orders: [],
  loading: false,
  loaded: false,
};

const reducer = (
  state = initialState,
  action: Action<ALL_ORDERS_ACTION_ENUM, AllOrderPayload>
) => {
  switch (action.type) {
    case ALL_ORDERS_ACTION_ENUM.UPDATE_ALL_PURCHASED_ORDERS:
      return { orders: action.payload, loading: false, loaded: true };
    case ALL_ORDERS_ACTION_ENUM.UPDATE_LOADING:
      return { ...state, loading: true, loaded: false };

    default:
      return state;
  }
};

export default reducer;
