import { Cart, Size } from "../../types";
import { Action } from "../../types/redux";

export interface CartState {
  isSaving: boolean;
  pricingCartData: Cart;
  loading: boolean;
  loaded: boolean;
}

export interface CartPayload {
  id: string;
  size: Size;
  amount: number;
  isSaving: boolean;
  pricingCartData: CartState["pricingCartData"];
}

export enum CART_ACTION_ENUM {
  UPDATE_CART = "update_cart",
  UPDATE_ITEM_SIZE = "update_item_sizes",
  SET_SAVING = "set_saving",
  UPDATE_LOADING = "update_cart_loading",
}

const initialState: CartState = {
  isSaving: false,
  pricingCartData: { items: [] },
  loading: false,
  loaded: false,
};

const reducer = (
  state = initialState,
  action: Action<CART_ACTION_ENUM, CartPayload>
) => {
  switch (action.type) {
    case CART_ACTION_ENUM.UPDATE_CART:
      //return { ...state, pricingCartData: action.payload };
      return {
        ...state,
        pricingCartData: action.payload.pricingCartData,
        loaded: true,
        loading: false,
      };
    case CART_ACTION_ENUM.UPDATE_LOADING:
      return { ...state, loading: true, loaded: false };

    case CART_ACTION_ENUM.UPDATE_ITEM_SIZE:
      const { id, size, amount } = action.payload;
      state.pricingCartData.items.forEach((item) => {
        if (item.id === id) {
          item?.sizes.forEach((s) => {
            if (s.name === size.name) {
              s.qty = amount;
            }
          });
        }
      });
      return { ...state };
    case CART_ACTION_ENUM.SET_SAVING:
      return { ...state, isSaving: action.payload.isSaving };
    default:
      return state;
  }
};

export default reducer;
