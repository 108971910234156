import React, { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";
import Order from "../../components/Order";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import styles from "./OrdersContainer.module.scss";
import { useOrderFetcher } from "../../utils/OrdersFetcherHooks";
import { usePreOrderFetcher } from "../../utils/PreOrdersFetcherHooks";
import { Message, MessageTypes } from "../../components/Message";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { usePageTitle } from "../../common/hooks/usePageTitle";

const Orders = () => {
  const { t } = useTranslation();
  const params = useParams();
  usePageTitle(t("pages.orders.title"));

  //In case the page comes from checkout success of Stripe, we get the successful sessionId
  const sessionId = params.sessionId;

  //In case the page comes from checkout using preorder
  const preorderId = params.preorderId;

  //At load, fetch all orders to get the latest information
  const { fetchMyOrdersAndUpdateStore } = useOrderFetcher();
  useEffect(() => {
    fetchMyOrdersAndUpdateStore();
  }, [fetchMyOrdersAndUpdateStore]);

  //At load, fetch all preorders to get the latest information
  const { fetchMyPreOrdersAndUpdateStore } = usePreOrderFetcher();
  useEffect(() => {
    fetchMyPreOrdersAndUpdateStore();
  }, [fetchMyPreOrdersAndUpdateStore]);

  const ordersState = useSelector((state) => state.myOrders);
  const preordersState = useSelector((state) => state.myPreOrders);

  const orders = useMemo(() => {
    return ordersState?.orders;
  }, [ordersState]);

  const ordersLoaded = useMemo(() => {
    return ordersState?.loaded;
  }, [ordersState]);

  const preorders = useMemo(() => {
    return preordersState?.preorders;
  }, [preordersState]);

  const preordersLoaded = useMemo(() => {
    return preordersState?.loaded;
  }, [preordersState]);

  //Merge both orders and pre-orders
  const orderAndPreorderList = useMemo(() => {
    if (!preordersLoaded || !ordersLoaded) {
      return [];
    }

    const ordersArray = orders.map((order) => {
      return {
        type: "order",
        details: order,
        createdAt: order.order.created_at,
      };
    });
    const preordersArray = preorders.map((preorder) => {
      return {
        type: "preorder",
        details: preorder,
        createdAt: preorder.preorder.created_at,
      };
    });

    const mergedArray = [...ordersArray, ...preordersArray];

    const sortedMergedArray = mergedArray.sort((a, b) =>
      a.createdAt < b.createdAt ? 1 : -1
    );

    return sortedMergedArray;
  }, [orders, preorders, preordersLoaded, ordersLoaded]);

  //Analytics
  useEffect(() => {
    if (sessionId && ordersLoaded) {
      const orderFound = orders.find(
        (order) => order.invoice.checkoutSessionId === sessionId
      );
      if (orderFound) {
        const taxes = orderFound.invoice.taxes.reduce(
          (partialSum, taxItem) => partialSum + taxItem.amount,
          0
        );

        //Analytics
        window.analytics.track("Order Completed", {
          order_id: orderFound.order.id, // Or do we want the Stripe id (checkoutSesssion.id)?
          value: orderFound.invoice.subtotal + orderFound.invoice.shipping, // subtotal including shipping
          total: orderFound.invoice.total,
          revenue: orderFound.invoice.subtotal, // i.e. subtotal before shipping
          shipping: orderFound.invoice.shipping,
          currency: orderFound.invoice.currency, // USD or CAD
          tax: taxes,
          products: orderFound.cartItems
            .map((cartItem) => {
              const productItem = {
                product_id: cartItem.designState.product.name,
                sku: cartItem.designId,
                name: cartItem.designState.product.name,
                price: cartItem.pricing.pricePerUnit,
                quantity: cartItem.pricing.qty,
              };

              if (cartItem.pricing.priceOfExtraSizes > 0) {
                const productExtraSizes = {
                  product_id: "Extra sizes",
                  sku: "Extra sizes",
                  name: "Extra sizes",
                  price: cartItem.pricing.priceOfExtraSizes,
                  quantity: 1,
                };
                return [productExtraSizes, productItem];
              } else {
                return productItem;
              }
            })
            .flat(),
        });
      }
    }
  }, [sessionId, ordersLoaded, orders]);
  // End of Analytics
  return (
    <div className={styles.centerContainer}>
      <h4 className={styles.title}>{t("order.title")}</h4>
      <div className="main-inner-content">
        {sessionId && (
          <Message
            message={t("order.info.order_acknowledged.message")}
            messageIcon={<CheckCircleOutlineIcon />}
            fullWidth={true}
            alignCenter={false}
            messageType={MessageTypes.INFO}
          />
        )}
        {preorderId && (
          <Message
            message={t("order.info.request_acknowledged.message")}
            messageIcon={<CheckCircleOutlineIcon />}
            fullWidth={true}
            alignCenter={false}
            messageType={MessageTypes.INFO}
          />
        )}
        {ordersLoaded && preordersLoaded ? (
          !_.isEmpty(orderAndPreorderList) ? (
            orderAndPreorderList?.map((listItem, index) =>
              listItem.type === "order" ? (
                <Order
                  type={listItem.type}
                  orderDetail={listItem.details}
                  key={`${index}-${listItem.details.order.id}`}
                  shouldStartExpanded={
                    sessionId &&
                    listItem.details.invoice.checkoutSessionId === sessionId
                  }
                />
              ) : (
                <Order
                  type={listItem.type}
                  orderDetail={listItem.details}
                  key={`${index}-${listItem.details.preorder.id}`}
                  shouldStartExpanded={
                    preorderId && listItem.details.preorder.id === preorderId
                  }
                />
              )
            )
          ) : (
            <div className="page-title-box">
              <p className="page-title">{t("order.no_orders")}</p>
            </div>
          )
        ) : (
          <div className={styles.loadingProgress}>
            <CircularProgress />
          </div>
        )}
      </div>
    </div>
  );
};

export default Orders;
