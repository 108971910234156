import React, { useMemo } from "react";
import "./rightSidebar.scss";
import styles from "./RightSidebar.module.scss";
import classNames from "classnames";

const RightSidebar = ({ children, withHeader }) => {
  const className = useMemo(() => {
    if (withHeader) {
      return classNames(styles.containerWithHeader);
    }
    return classNames(styles.container);
  }, [withHeader]);
  return <div className={className}>{children}</div>;
};

export default RightSidebar;
