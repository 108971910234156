import React from "react";
import { Box } from "../Box";
import { createStyles } from "@mui/styles";
import { Icon } from "../Icon";
import { Typography } from "@mui/material";
import { BoxProps } from "../Box/Box";

interface Props {
  type: "success";
  message: string;
  boxProps?: BoxProps;
}

const styles = createStyles({
  container: {
    padding: 2,
    background: "#ECFEED",
    border: "1px solid #CFFCD1",
    borderRadius: 1,
    display: "flex",
    alignItems: "center",
  },
});

export const Alert = ({ type, message, boxProps }: Props) => {
  return (
    <Box sx={styles.container} gap={1.5} {...boxProps}>
      <Icon icon="check_circle" size={24} color="#05610A" />
      <Typography color="#05610A" variant="body2">
        {message}
      </Typography>
    </Box>
  );
};
