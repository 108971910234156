import { Typography } from "@mui/material";
import { memo, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { DashboardContext } from "../../../../context/Dashboard";
import { Styles, palette, spacing } from "../../../../styles/theme";
import { ROUTES } from "../../../../utils/Constant";
import { useCurrency } from "../../../../utils/Currencies";
import { isFrench, setEnglish, setFrench } from "../../../../utils/LocaleUtil";
import { Box, Icon, NavLink } from "../../../components";
import { useAuth } from "../../../hooks/useAuth";

const styles: Styles = {
  backItem: {
    display: "flex",
    alignItems: "center",
    px: spacing.wrapperMobile,
    py: 1.5,
    mb: spacing.wrapperMobile,
    gap: 0.5,
    cursor: "pointer",
    "&:active": {
      background: palette.blue.paleEffect,
    },
  },
  list: {
    px: spacing.wrapperMobile,
    gap: spacing.regularMobile,
    display: "flex",
    flexDirection: "column",
  },
};

interface Props {
  onBackClick: () => void;
}

const UserProfileDrawerContent = ({ onBackClick }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { toggleNavigationDrawerOpen } = useContext(DashboardContext);
  const { setUSD, setCAD, isCAD, isUSD } = useCurrency();
  const { logout } = useAuth();
  const isFr = useMemo(() => isFrench(), [isFrench()]);

  const onClick = (callback?: () => void) => () => {
    callback?.();
    onBackClick();
    toggleNavigationDrawerOpen?.();
  };

  return (
    <Box>
      <Box sx={styles.backItem} onClick={onBackClick}>
        <Icon icon="chevron_left" size={24} color={palette.grey.nearBlack} />
        <Typography variant="textRegularBold">
          {t("navigation.back_to_menu")}
        </Typography>
      </Box>
      <Box sx={styles.list}>
        <Box>
          <Typography variant="h2" mb={spacing.mediumMobile}>
            {t("profile.title")}
          </Typography>
          <NavLink
            content={t("profile.my_orders")}
            to={ROUTES.ORDERS}
            onClick={onClick(() => navigate(ROUTES.ORDERS))}
          />
          <NavLink
            content={t("general.logout")}
            data-test="mobileLeftNavigation.userProfileNavLink.logout"
            to={""}
            onClick={(e) => {
              e.preventDefault();
              logout();
            }}
          />
        </Box>
        <Box>
          <Typography variant="h2" mb={spacing.mediumMobile}>
            {t("profile.language")}
          </Typography>
          <NavLink
            content={t("profile.french")}
            to={""}
            endIcon={isFr ? "check" : undefined}
            onClick={onClick(() => setFrench())}
          />
          <NavLink
            content={t("profile.english")}
            to={""}
            endIcon={!isFr ? "check" : undefined}
            onClick={onClick(() => setEnglish())}
          />
        </Box>
        <Box>
          <Typography variant="h2" mb={spacing.mediumMobile}>
            {t("profile.currency_title")}
          </Typography>
          <NavLink
            content={t("profile.cad")}
            to={""}
            endIcon={isCAD() ? "check" : undefined}
            onClick={onClick(setCAD)}
          />
          <NavLink
            content={t("profile.usd")}
            to={""}
            endIcon={isUSD() ? "check" : undefined}
            onClick={onClick(setUSD)}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default memo(UserProfileDrawerContent);
