import { useTranslation } from "react-i18next";

import ArtworkMenuIcon from "../../../../../assets/editor/menuArtwork.svg";
import Menu from "../../../../../common/components/Menu/Menu";
import styles from "../../StudioLeftSidebarMenu.module.scss";
import SubMenuOption from "../../SubMenuOption";

const SideMenuDecorations = (props) => {
  const { addOptions = [], decorations = [] } = props;
  const { t } = useTranslation();

  return (
    <li className={styles.sideNavItem}>
      <div className={styles.blankBoxTop}>
        <div className={styles.labelWithMenuContainer}>
          <div className={styles.labelWithIconContainer}>
            <div className={styles.iconContainer}>
              <img alt="iconUrl" src={ArtworkMenuIcon} />
            </div>
            <span className={styles.specsLabel}>{t(`specs.decorations`)}</span>
          </div>

          {addOptions.length > 0 && (
            <Menu options={addOptions} menuIcon="AddMore" />
          )}
        </div>
      </div>
      <ul className={styles.sideNavSecondLevel}>
        {decorations.map(
          (o) =>
            //Only display displayable options ()
            o.display.displayable && <SubMenuOption {...o} key={o.id} />
        )}
      </ul>
    </li>
  );
};

export default SideMenuDecorations;
