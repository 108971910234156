import {
  OverlayScrollbarsComponent,
  OverlayScrollbarsComponentProps,
} from "overlayscrollbars-react";
import "overlayscrollbars/overlayscrollbars.css";
import { PropsWithChildren } from "react";
import "./OverlayScrollbarWrapper.scss";

const OverlayScrollbarWrapper = ({
  children,
  ...props
}: PropsWithChildren & OverlayScrollbarsComponentProps) => {
  return (
    <OverlayScrollbarsComponent
      defer
      {...props}
      options={{
        scrollbars: { autoHide: "leave", theme: "os-theme-dark" },
      }}
    >
      {children}
    </OverlayScrollbarsComponent>
  );
};

export default OverlayScrollbarWrapper;
