import { Skeleton, Typography } from "@mui/material";
import { memo } from "react";
import { Box, ContentRepeater } from "../../common/components";

import { Styles, palette } from "../../styles/theme";

import { mergeSx } from "../../utils/themeUtils";

export const PROJECT_CARD_MOBILE_MIN_WIDTH = "276px";
export const PROJECT_CARD_MOBILE_MAX_WIDTH = "572px";
export const PROJECT_CARD_DESKTOP_MIN_WIDTH = "276px";
export const PROJECT_CARD_DESKTOP_MAX_WIDTH = "572px";

const styles: Styles = {
  container: {
    background: palette.grey.lightBackground,
    borderRadius: "12px",
    minWidth: {
      xs: PROJECT_CARD_MOBILE_MIN_WIDTH,
      lg: PROJECT_CARD_DESKTOP_MIN_WIDTH,
    },
    maxWidth: {
      xs: PROJECT_CARD_MOBILE_MAX_WIDTH,
      lg: PROJECT_CARD_DESKTOP_MAX_WIDTH,
    },
    p: 1.5,
  },
  designContainer: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    gap: "12px",
    mb: 1.5,
  },
  design: {
    flexBasis: `calc(50% - 6px)`, //6px represent the half of the gap value, without the calc the box is to big
    height: "90px",
    minWidth: "120px",
    padding: "8px",
    borderRadius: "8px",
    userSelect: "none",
  },
  createDesignTile: {},
};

const ProjectCardSkeleton = () => {
  return (
    <Box sx={styles.container}>
      <Box sx={styles.designContainer}>
        <ContentRepeater repeatCount={4}>
          <Skeleton
            variant="rounded"
            sx={mergeSx(styles.design, styles.createDesignTile)}
          />
        </ContentRepeater>
      </Box>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="textSm">
          <Skeleton variant="text" width={130} />
        </Typography>
      </Box>
      <Typography variant="textXs">
        <Skeleton variant="text" width={100} />
      </Typography>
    </Box>
  );
};

export default memo(ProjectCardSkeleton);
