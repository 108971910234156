import { TextField, Typography } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { memo, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { generatePath, useNavigate } from "react-router-dom";
import { Box, Button, Icon } from "../../common/components";
import { useSelector } from "../../common/hooks/useSelector";
import DesignService from "../../service/DesignService";
import { palette, spacing } from "../../styles/theme";
import { Project } from "../../types";
import { ROUTES } from "../../utils/Constant";
import { useDesignFetcher } from "../../utils/DesignsFetcherHooks";
import { useThemeBreakpoints } from "../../utils/themeUtils";
import DialogTitleWithCloseButton from "../DialogTitleWithCloseButton/DialogTitleWithCloseButton";

type FlattenTeamProject = {
  key: string;
  id: string;
  name: string;
  type: string;
  projects: Project[];
  teamUrl: string;
  url: (projectId: string) => string;
};

interface Props {
  open: boolean;
  onClickClose: () => void;
  designId: string;
  projectId: string;
}

const MoveProjectModal = ({
  open,
  onClickClose,
  designId,
  projectId,
}: Props) => {
  const { t } = useTranslation();
  const { isMobile } = useThemeBreakpoints();
  const [selectedProject, setSelectedProject] =
    useState<FlattenTeamProject | null>(null);
  const projectsState = useSelector((state) => state.projects);

  const onClose = () => {
    setSelectedProject(null);
    onClickClose();
  };

  const projects = useMemo(() => {
    return projectsState?.allProjects;
  }, [projectsState]);

  const teamsState = useSelector((state) => state.myTeams);
  const teams = useMemo(() => {
    return teamsState?.teams;
  }, [teamsState]);

  const navigate = useNavigate();
  const { fetchMyDesignsAndUpdateStore } = useDesignFetcher();
  const onAddToProject = useCallback(
    async (newProjectId?: string) => {
      const moveProjObj = {
        designId: designId,
        newProjectId,
      };
      await DesignService.moveDesign(moveProjObj);

      await fetchMyDesignsAndUpdateStore(newProjectId);
      navigate(generatePath(ROUTES.DESIGNS, { projectId: newProjectId }));
    },
    [fetchMyDesignsAndUpdateStore, navigate, designId]
  );

  const teamProjects = useMemo(() => {
    const unFlattenedArray = teams.map((team) => {
      return {
        key: team.id,
        id: team.id,
        name: team.name,
        type: "team",
        projects: projects.filter((project) => project.team_id === team.id),
        teamUrl: generatePath(ROUTES.TEAM_PROJECTS, { teamId: team.id }),
        url: (projectId: string) => generatePath(ROUTES.DESIGNS, { projectId }),
      };
    });

    const flatObject: any = [];
    unFlattenedArray.forEach((team) => {
      team.projects.forEach((project) => {
        const individualProjectObj = {
          id: project.id,
          key: project.id,
          type: team.name,
          name: project.name,
          user_id: null,
          team_id: team.id,
        };
        return flatObject.push(individualProjectObj);
      });
    });
    return flatObject as FlattenTeamProject[];
  }, [projects, teams]);
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth={true}
      maxWidth={"md"}
      fullScreen={isMobile}
    >
      <DialogTitleWithCloseButton
        id="customized-dialog-title"
        onClose={onClose}
      >
        {t("move.title")}
      </DialogTitleWithCloseButton>
      <DialogContent>
        <Box
          display="flex"
          flexDirection="column"
          gap={{ xs: spacing.regularMobile, lg: spacing.regularDesktop }}
        >
          <Box>
            <Autocomplete
              id="moveProjects"
              options={teamProjects}
              groupBy={(option) => option.type}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => <TextField {...params} />}
              clearIcon={false}
              popupIcon={
                <Icon size={24} color={palette.grey.primary} icon="search" />
              }
              filterOptions={(options, state) => {
                const value = state.inputValue.toUpperCase();
                return options.filter(
                  (o) =>
                    o.type.toUpperCase().includes(value) ||
                    o.name.toUpperCase().includes(value)
                );
              }}
              value={selectedProject}
              onChange={(event, newValue) => {
                setSelectedProject(newValue);
              }}
              getOptionDisabled={(option) => {
                return option.id === projectId;
              }}
              renderOption={(props, option) => {
                return (
                  <li {...props} key={option.name}>
                    {option.name}
                  </li>
                );
              }}
              sx={{
                "& .MuiAutocomplete-popupIndicator": { transform: "none" },
              }}
            />
          </Box>
          {isMobile && (
            <Button
              onClick={() => onAddToProject(selectedProject?.id)}
              disabled={selectedProject === null}
              fullWidth
            >
              <Typography variant="actionBoldBigIcon">
                {t("move.action")}
              </Typography>
            </Button>
          )}
        </Box>
      </DialogContent>
      {!isMobile && (
        <DialogActions>
          <Button
            onClick={() => onAddToProject(selectedProject?.id)}
            disabled={selectedProject === null}
          >
            <Typography variant="actionBoldBigIcon">
              {t("move.action")}
            </Typography>
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default memo(MoveProjectModal);
