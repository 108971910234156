import React from "react";
import { useTranslation } from "react-i18next";
import { TimelineEvent } from "react-event-timeline";
import { getLanguage } from "../../utils/LocaleUtil";
import { useTimeSince } from "../../utils/dateUtils";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import DiscountIcon from "@mui/icons-material/Discount";
import BackspaceIcon from "@mui/icons-material/Backspace";
import DescriptionIcon from "@mui/icons-material/Description";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import CreateIcon from "@mui/icons-material/Create";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import FormatSizeIcon from "@mui/icons-material/FormatSize";

const AdminPreorderHistory = (props) => {
  const { t } = useTranslation();
  const { historyItem } = props;

  const { dateFormatter } = useTimeSince();

  const dateTimeOptions = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    timeZoneName: "short",
  };

  const user = historyItem?.User;
  const historyEvent = historyItem?.event;
  const eventName = historyEvent?.name;
  const historyEventData = historyItem?.event?.data;

  const title =
    user?.first_name +
    " " +
    user?.last_name +
    " " +
    t(`preorder.history_actions.${eventName}`);
  const createdAt = dateFormatter(historyItem.created_at).toLocaleString(
    getLanguage(),
    dateTimeOptions
  );

  let icon = "";
  let content = "";
  let color = "#6FBA3A";

  // Depending on the event, format correctly and add the appropriate icon.
  switch (eventName) {
    case "created":
      icon = <ShoppingCartIcon />;
      break;
    case "item_added":
      icon = <AddIcon />;
      break;
    case "item_removed":
      icon = <RemoveIcon />;
      break;
    case "size_updated":
      icon = <FormatSizeIcon />;
      break;
    case "purchased_design_updated":
      icon = <CreateIcon />;
      break;
    case "status_updated":
      icon = <AutorenewIcon />;
      break;
    case "invoice_updated":
      icon = <DescriptionIcon />;
      break;
    case "discount_updated":
      icon = <DiscountIcon />;
      break;
    case "discount_removed":
      icon = <BackspaceIcon />;
      break;
    case "currency_updated":
      const oldCurrency = historyEventData?.old?.currency || "undefined";
      const newCurrency = historyEventData?.new?.currency || "undefined";
      content =
        "The currency was changed from " + oldCurrency + " to " + newCurrency; // TODO: translation
      icon = <AttachMoneyIcon />;
      break;
    case "shipping_updated":
      icon = <LocalShippingIcon />;
      break;
    default:
      content = "";
  }

  return (
    <React.Fragment>
      <TimelineEvent
        title={title}
        createdAt={createdAt}
        collapsible={true}
        icon={icon}
        iconStyle={{ color }}
      >
        {content ? content : JSON.stringify(historyItem?.event?.data)}
      </TimelineEvent>
    </React.Fragment>
  );
};

export default AdminPreorderHistory;
