import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { debounce } from "lodash";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import TechpackItem from "../TechpackItem";
import styles from "./adminTechpack.module.scss";
import TechpackService from "../../service/TechpackService";
import { routeFormatter } from "../../utils/Formatter";
import { ROUTES } from "../../utils/Constant";

const AdminTechpack = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { techpackDetail, setSaving } = props;

  /**
   *
   */
  const onProjectChange = useCallback(
    async (event) => {
      const saveProject = debounce(
        async (e) => {
          const newProjectName = e.target.value || ""; //Null should be ""

          const data = {
            techpackId: techpackDetail.id,
            projectName: newProjectName,
          };
          await TechpackService.setProjectName(data);
          setSaving(false);
        },
        100,
        { leading: true }
      );

      setSaving(true);
      await saveProject(event);
      setSaving(false);
    },
    [techpackDetail.id, setSaving]
  );

  /**
   *
   */
  const onCustomerChange = useCallback(
    async (event) => {
      const saveCustomerName = debounce(
        async (e) => {
          const newCustomerName = e.target.value || ""; //Null should be ""

          const data = {
            techpackId: techpackDetail.id,
            customerName: newCustomerName,
          };
          await TechpackService.setCustomerName(data);
          setSaving(false);
        },
        100,
        { leading: true }
      );

      setSaving(true);
      await saveCustomerName(event);
      setSaving(false);
    },
    [techpackDetail.id, setSaving]
  );

  const onClickGoToPreorder = useCallback(() => {
    navigate(
      routeFormatter(ROUTES.ADMIN_PREORDER, {
        params: { preorderId: techpackDetail.order_id },
      })
    );
  }, [navigate, techpackDetail.order_id]);

  return (
    <React.Fragment>
      <div className={styles.container}>
        <h3>{t("techpack.project_title")}</h3>
        <div className={styles.projectHeader}>
          <div className={styles.editableField}>
            <span>{t("techpack.project_number")}</span>
            <input
              type="text"
              className={styles.projectNumber}
              defaultValue={techpackDetail.project_number}
              onChange={onProjectChange}
            />
          </div>
          <div className={styles.editableField}>
            <span>{t("techpack.customer")}</span>
            <input
              type="text"
              className={styles.customerNumber}
              defaultValue={techpackDetail.customer_number}
              onChange={onCustomerChange}
            />
          </div>
          {techpackDetail.order_type === "preorder" && (
            <div className={styles.actionContainer}>
              <Button
                variant="contained"
                disableElevation
                sx={{
                  marginRight: "10px",
                  backgroundColor: "#2484BA",
                  textTransform: "capitalize",
                }}
                onClick={onClickGoToPreorder}
              >
                {t("techpack.go_to_preorder")}
              </Button>
            </div>
          )}
        </div>
        <div className={styles.productList}>
          {techpackDetail.techpackitems
            .sort((a, b) => a.display_order - b.display_order)
            .map((techpackItem, techpackItemIndex) => (
              <TechpackItem
                key={techpackItem.id}
                techpackId={techpackDetail.id}
                techpackItem={techpackItem}
                techpackItemIndex={
                  techpackItemIndex + 1 /* Because we start at 1, not 0 */
                }
                techpackitemsCount={techpackDetail.techpackitems.length}
                setSaving={setSaving}
                orderId={techpackDetail.order_id} //Needed for the edit of items
                orderType={techpackDetail.order_type} //Needed for the edit of items
              />
            ))}
        </div>
      </div>
    </React.Fragment>
  );
};

export default AdminTechpack;
