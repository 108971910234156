import { useEffect, useState } from "react";
import { GlobalLoadingContext } from "./GlobalLoadingContext";

interface Props {
  children: JSX.Element;
}

export const GlobalLoadingProvider = ({ children }: Props) => {
  const [isGloballyLoading, setIsGloballyLoading] = useState(true);

  const handleLoading = () => {
    setIsGloballyLoading(false);
  };

  useEffect(() => {
    window.addEventListener("load", handleLoading);
    return () => window.removeEventListener("load", handleLoading);
  }, []);

  return (
    <GlobalLoadingContext.Provider value={{ isGloballyLoading }}>
      {children}
    </GlobalLoadingContext.Provider>
  );
};
