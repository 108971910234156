import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "../../components/Button";
import styles from "./copyButton.module.scss";

const CopyButton = (props) => {
  const { textToCopy } = props;
  const [copied, setCopied] = useState(false);

  const { t } = useTranslation();

  const onCopy = async () => {
    await navigator.clipboard.writeText(textToCopy);
    setCopied(true);
  };

  return (
    <React.Fragment>
      <div className={styles.copyButtonContainer}>
        <input
          type="text"
          value={textToCopy}
          className={styles.input}
          readOnly
        ></input>
        <Button
          onClick={onCopy}
          text={copied ? t("copyButton.copied") : t("copyButton.copy")}
        ></Button>
      </div>
    </React.Fragment>
  );
};

export default CopyButton;
