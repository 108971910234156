import React, { useCallback, useEffect, useState } from "react";
import ImageService from "../../../../service/ImageService";
import DesignStateService from "../../../../service/DesignStateService";
import { DESIGN_CHANGE_ENUM } from "../../../../utils/ReactHooks";
import { useTranslation } from "react-i18next";
import AssetEmbroideryPatchSizeBox from "../../../../components/AssetSizeBox/AssetEmbroideryPatchSizeBox";
import AssetColorBox from "../../../../components/AssetColorBox";

const FabricTagSidebar = (props) => {
  const { t } = useTranslation();
  const {
    decoration,
    teamId,
    onDesignChange,
    tagOptionsDef,
    canUpdateArtworkPosition,
    canUpdateArtworkColor,
    setPreviewImageData,
    imageData,
    isAdmin = false,
    hideSizeValue = false,
  } = props;

  useEffect(() => {
    if (decoration?.assetId) {
      ImageService.getEmbroideryPatchImage(
        decoration.assetId,
        decoration.assetVersionId,
        decoration.type,
        decoration.tagDetails.tagName,
        decoration.tagDetails.textureName,
        decoration.tagDetails.backgroundColorName,
        decoration.tagDetails.stitchColorName,
        decoration.tagDetails.logoColorNames || "FUTURE"
      )
        .then((data) => {
          // console.log(data);
          setPreviewImageData(data);
        })
        .catch(() => {
          setPreviewImageData(null);
        });
    } else {
      setPreviewImageData(null);
    }
  }, [
    decoration?.assetId,
    decoration?.assetVersionId,
    decoration?.type,
    decoration?.tagDetails?.tagName,
    decoration?.tagDetails?.textureName,
    decoration?.tagDetails?.backgroundColorName,
    decoration?.tagDetails?.stitchColorName,
    decoration?.tagDetails?.logoColorNames,
    teamId,
    setPreviewImageData,
  ]);

  // TODO: Use the API route for color codes
  const [colorCodesEmbroidery, setColorCodesEmbroidery] = useState([]);
  const [colorCodesBackground, setColorCodesBackground] = useState([]);

  useEffect(() => {
    if (imageData && imageData.useColorExtractionAndMatching) {
      DesignStateService.getFabricColors(
        decoration?.tagDetails?.backgroundFabricName
      ).then((res) => {
        setColorCodesBackground(res);
      });

      DesignStateService.getFabricColors(
        decoration?.tagDetails?.stitchFabricName
      ).then((res) => {
        setColorCodesEmbroidery(res);
      });
    }
  }, [
    imageData,
    decoration?.tagDetails?.backgroundFabricName,
    decoration?.tagDetails?.stitchFabricName,
  ]);

  const onAssetBackgroundColorChange = useCallback(
    (color, index, transparent) => {
      return onDesignChange(
        DESIGN_CHANGE_ENUM.SET_DECORATION_EMBROIDERY_PATCH_BACKGROUND_COLOR,
        {
          colorName: String(color),
          decorationId: decoration.id,
        }
      );
    },
    [onDesignChange, decoration]
  );

  const onAssetStitchColorChange = useCallback(
    (color, index, transparent) => {
      return onDesignChange(
        DESIGN_CHANGE_ENUM.SET_DECORATION_EMBROIDERY_PATCH_STITCH_COLOR,
        {
          colorName: String(color),
          decorationId: decoration.id,
        }
      );
    },
    [onDesignChange, decoration]
  );

  const onAssetLogoColorChange = useCallback(
    (color, index, transparent) => {
      onDesignChange(DESIGN_CHANGE_ENUM.UPDATE_PRINT_COLOR, {
        color,
        transparent,
        index,
        decorationId: decoration.id,
      });
    },
    [onDesignChange, decoration]
  );

  return (
    <React.Fragment>
      <AssetEmbroideryPatchSizeBox
        canUpdateArtworkPosition={canUpdateArtworkPosition}
        canUpdateArtworkColor={canUpdateArtworkColor}
        onDesignChange={onDesignChange}
        decoration={decoration}
        tagOptionsDef={tagOptionsDef}
        hideSizeValue={hideSizeValue}
      />

      <AssetColorBox
        colorBoxTitle={t("specs.background_color")}
        selectedColors={[decoration.tagDetails.backgroundColorName]}
        allowTransparent={false}
        availableColors={colorCodesBackground}
        onColorChanged={onAssetBackgroundColorChange}
        active={true}
        canUpdateArtworkColor={canUpdateArtworkColor}
        isAdmin={isAdmin}
      />

      <AssetColorBox
        colorBoxTitle={t("specs.stitch_color")}
        selectedColors={[decoration.tagDetails.stitchColorName]}
        allowTransparent={false}
        availableColors={colorCodesEmbroidery}
        onColorChanged={onAssetStitchColorChange}
        active={true}
        canUpdateArtworkColor={canUpdateArtworkColor}
        isAdmin={isAdmin}
      />

      {imageData && imageData.selectedColors && (
        <AssetColorBox
          colorBoxTitle={t("specs.colors")}
          selectedColors={imageData.selectedColors.map((c) =>
            c.transparent ? "transparent" : c.chartColor.name
          )}
          allowTransparent={true}
          deprecateTransparency={true} // Remove when the transparency problem with the production is fixed
          availableColors={colorCodesEmbroidery}
          onColorChanged={onAssetLogoColorChange}
          active={true}
          canUpdateArtworkColor={canUpdateArtworkColor}
          isAdmin={isAdmin}
        />
      )}
    </React.Fragment>
  );
};

export default FabricTagSidebar;
