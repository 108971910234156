import React, { useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import RightSidebar from "../../../../common/components/RightSidebar";
import ImageService from "../../../../service/ImageService";
import UploadImageButton from "../../../../components/UploadImage/UploadImageButton";

import { DESIGN_CHANGE_ENUM } from "../../../../utils/ReactHooks";
import styles from "../StudioRightSidebar.module.scss";
import _ from "lodash";
import { CircularProgress } from "@mui/material";

const UploadFileSidebar = (props) => {
  const {
    designId,
    decorationId,
    teamId,
    onDesignChange,
    availableDecorationDef,
    isAdmin,
    designTemplates,
  } = props;
  const { t } = useTranslation();

  const [loadingFileName, setLoadingFileName] = useState(null);
  const [errorCode, setErrorCode] = useState(null);

  const onStart = useCallback(
    ({ file }) => {
      setErrorCode(null);
    },
    [setErrorCode]
  );

  const createAssetFromFile = useCallback(
    async (assetId, etag, file) => {
      //Filter depending on the asset's file extension to see what asset type we need to create
      const fileName = file.name;
      const fileExtension = fileName.substr(fileName.lastIndexOf("."));

      const availableDecoDefAvailableForFileExtension =
        availableDecorationDef.find((decoType) => {
          return decoType.fileTypes.find((fileType) => {
            return fileType.extensions.find(
              (extension) =>
                extension.toLowerCase() === fileExtension.toLowerCase()
            );
          });
        });

      const assetType = availableDecoDefAvailableForFileExtension.id;

      //Create the asset type
      const { assetVersionId, errorKey } = await ImageService.createAsset(
        assetId,
        assetType,
        etag
      );

      if (errorKey) {
        setErrorCode(errorKey);
        //setUploadErrorMsg(errorKey);
        //setLoadingFileName(null);
        return;
      }

      return await onDesignChange(DESIGN_CHANGE_ENUM.SET_DECORATION_ASSET, {
        designId,
        decorationId,
        assetId,
        assetVersionId,
        type: assetType,
        name: availableDecoDefAvailableForFileExtension.displayName.en, // TODO: DYNAMICALLY CHOOSE THE CORRECT LANGUAGE
      });
    },
    [designId, decorationId, availableDecorationDef, onDesignChange]
  );

  const uploadFile = useCallback(
    async ({ file, onSuccess }) => {
      if (!file) {
        const errorKey = "noFileGiven";
        setErrorCode(errorKey);
        setLoadingFileName(null);
        return;
      }

      setLoadingFileName(file.name);

      const { assetId, etag } = isAdmin
        ? await ImageService.uploadAssetAsAdmin(file, teamId)
        : await ImageService.uploadAsset(file, teamId);

      await createAssetFromFile(assetId, etag, file);

      console.debug("Settng filename null");
      setLoadingFileName(null);
      onSuccess();
    },
    [teamId, isAdmin, setErrorCode, setLoadingFileName, createAssetFromFile]
  );

  const acceptedFileExtensions = _.uniq(
    availableDecorationDef.flatMap((decoType) => {
      return decoType.fileTypes.flatMap((fileType) => {
        return fileType.extensions;
      });
    })
  );

  const acceptedFileExtensionsName = _.uniq(
    availableDecorationDef.flatMap((decoType) => {
      return decoType.fileTypes.flatMap((fileType) => {
        return fileType.name;
      });
    })
  );
  const acceptedFileExtensionsNameTranslated = acceptedFileExtensionsName.map(
    (fileExtensionName) => {
      return t("specs.file_extensions." + fileExtensionName);
    }
  );
  const acceptedFileExtensionsTranslatedString =
    acceptedFileExtensionsNameTranslated.join(", ");
  const acceptedFilesTitle = t("general.supported_files", {
    supported_extensions: acceptedFileExtensionsTranslatedString,
  });

  return (
    <RightSidebar>
      <div className={styles.rightDecorationSectionContainer}>
        <h6>{t("general.file")}</h6>
        {loadingFileName ? (
          <div className={styles.imageInput}>
            <div className={styles.loadingProgress}>
              <CircularProgress size={16} />
            </div>
            <span>{loadingFileName}</span>
          </div>
        ) : (
          <React.Fragment>
            {designTemplates && (
              <div className={styles.designTemplatesContainer}>
                {designTemplates.map((template) => {
                  return (
                    <div>
                      <a href={template.url}>
                        {t("template." + template.name)}
                      </a>
                    </div>
                  );
                })}
              </div>
            )}
            <UploadImageButton
              onStart={onStart}
              onUpload={uploadFile}
              errorCode={errorCode}
              acceptedFilesTitle={acceptedFilesTitle}
              acceptedFileExtensions={acceptedFileExtensions}
            />
          </React.Fragment>
        )}
      </div>
    </RightSidebar>
  );
};

export default UploadFileSidebar;
