import styles from "./OnboardingMarquee.module.scss";
import Marquee_img_1 from "./../../../assets/images/marquee/marquee_img_1.jpg";
import Marquee_img_2 from "./../../../assets/images/marquee/marquee_img_2.jpg";
import Marquee_img_3 from "./../../../assets/images/marquee/marquee_img_3.jpg";
import Marquee_img_4 from "./../../../assets/images/marquee/marquee_img_4.jpg";
import Marquee_img_5 from "./../../../assets/images/marquee/marquee_img_5.jpg";
import Marquee_img_6 from "./../../../assets/images/marquee/marquee_img_6.jpg";
import Marquee_img_7 from "./../../../assets/images/marquee/marquee_img_7.jpg";
import Marquee from "react-fast-marquee";

const items = [
  {
    src: Marquee_img_1,
  },
  {
    src: Marquee_img_2,
  },
  {
    src: Marquee_img_3,
  },
  {
    src: Marquee_img_4,
  },
  {
    src: Marquee_img_5,
  },
  {
    src: Marquee_img_6,
  },
  {
    src: Marquee_img_7,
  },
];

export const OnboardingMarquee = () => {
  return (
    <Marquee className={styles.marqueeContainer}>
      {items.map((item, index) => (
        <img src={item.src} key={index} alt="" />
      ))}
    </Marquee>
  );
};
