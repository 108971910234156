import React from "react";
import { useTranslation } from "react-i18next";
import { useCurrency } from "../../utils/Currencies";
import styles from "./ordersummary.module.scss";

const OrderSummary = (props) => {
  const { t } = useTranslation();
  const { getCurrencyFormatter } = useCurrency();

  const {
    taxesExcluded,
    taxes,
    total,
    subtotal,
    currency,
    hasReachedMaximum,
    shipping = 0,
    discounts = [] /*, discount = 0, tax = 0*/,
  } = props;

  const currencyFormatter = getCurrencyFormatter(currency);

  return (
    <div className={styles.container}>
      <div className={styles.sumRow}>
        <div className={styles.sumLabel}>{t("order.subtotal")}</div>
        <div className={styles.sumValue}>
          {hasReachedMaximum
            ? t("order.tbd")
            : currencyFormatter.format(subtotal)}
        </div>
      </div>
      {/* {discount > 0 && (
        <div className={styles.sumRow}>
          <div className={styles.sumLabel}>{t("order.discount")}</div>
          <div className="sum-item">{currencyFormatter.format(discount)}</div>
        </div>
      )} */}
      {shipping > 0 && (
        <div className={styles.sumRow}>
          <div className={styles.sumLabel}>{t("order.shipping")}</div>
          <div className="sum-item">
            {hasReachedMaximum
              ? t("order.tbd")
              : currencyFormatter.format(shipping)}
          </div>
        </div>
      )}
      <div className={styles.sumRow}>
        <div className={styles.sumLabel}>{t("order.subtotal")}</div>
        <div className={styles.sumValue}>
          {hasReachedMaximum
            ? t("order.tbd")
            : currencyFormatter.format(subtotal + shipping)}
        </div>
      </div>
      {discounts.map((discount) => (
        <div className={styles.sumRow}>
          <div className={styles.sumLabel}>
            {t("preorder.discount")} ({discount.name})
          </div>
          <div className="sum-item">
            {currencyFormatter.format(discount.amount)}
          </div>
        </div>
      ))}
      {taxes.map((tax) => (
        <div className={styles.sumRow}>
          <div className={styles.sumLabel}>
            {t("order.tax")} {tax.jurisdiction} {tax.name} ({tax.percentage}%)
          </div>
          <div className="sum-item">{currencyFormatter.format(tax.amount)}</div>
        </div>
      ))}
      <div className={styles.sumRow}>
        <div className={styles.sumLabel}>
          {t("order.total")} ({currency.toUpperCase()})
        </div>
        <div className={styles.sumItem}>
          {hasReachedMaximum ? t("order.tbd") : currencyFormatter.format(total)}
        </div>
      </div>
      {taxesExcluded && (
        <div className={styles.sumRow}>
          <div className={styles.sumLabel}>{t("order.taxes_separate")}</div>
        </div>
      )}
    </div>
  );
};

export default OrderSummary;
