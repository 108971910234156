import { Box, CheckboxProps, Checkbox as MuiCheckbox } from "@mui/material";
import { Label } from "../Typography";
import { palette } from "../../../styles/theme";

interface Props extends CheckboxProps {
  label: string;
}

export const Checkbox = ({ label, ...props }: Props) => {
  return (
    <Box display="flex" alignItems="center" justifyItems="center" gap={1}>
      <MuiCheckbox {...props} />
      <Label htmlFor={props.id} sx={{ color: palette.black.headings }}>
        {label}
      </Label>
    </Box>
  );
};
