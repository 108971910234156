import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useStore } from "react-redux";
import { useNavigate } from "react-router-dom";
import Helmet from "react-helmet";
import "react-phone-number-input/style.css";
import { isValidPhoneNumber } from "react-phone-number-input";
import { CircularProgress } from "@mui/material";
import { CheckoutTemplate } from "../../common/views/Checkout";
import { getLanguage } from "../../utils/LocaleUtil";
import { useCurrency } from "../../utils/Currencies";
import PreOrderService from "../../service/PreOrderService";
import { routeFormatter } from "../../utils/Formatter";
import styles from "./shippingAdressContainer.module.scss";
import { SHIPPING_ACTION_ENUM } from "../../common/reducers/shippingAddress";
import { BILLING_ACTION_ENUM } from "../../common/reducers/billingAddress";
import CartPreview from "../../common/components/CartPreview";
import { Button } from "../../common/components/Button";
import USStates from "./states.json";
import CAProvinces from "./provinces.json";
import * as yup from "yup";
import { ROUTES } from "../../utils/Constant";
import { Message, MessageTypes } from "../../components/Message";
import Checkbox from "../../components/Checkbox";
import Address from "./Address";
import { useSelector } from "../../common/hooks/useSelector";
import { AddressFormType } from "../../types";
import { usePageTitle } from "../../common/hooks/usePageTitle";

export const ShippingAddressContainer = () => {
  const { dispatch } = useStore();
  const { t } = useTranslation();
  const navigate = useNavigate();
  usePageTitle(t("pages.cartAddress.title"));

  const [promoCode, setPromoCode] = useState<string | null>(null);
  const [preorderRequestSent, setPreorderRequestSent] = useState(false);
  const [finalDesignValidated, setFinalDesignValidated] = useState(false);
  const [termsAndConditionsAccepted, setTermsAndConditionsAccepted] =
    useState(false);

  const { shippingAddress } = useSelector((state) => state.shippingAddress);

  const { billingAddress, sameAsShippingAddress } = useSelector(
    (state) => state.billingAddress
  );

  const { loaded, pricingCartData } = useSelector((state) => state.cart);
  const items = useMemo(() => pricingCartData.items, [pricingCartData]);

  const { getCurrency, setUSD, setCAD } = useCurrency();

  //Can't use memo because shippingAddress object doesn't change, it's its fields that are changing
  const isValid = () => {
    const schema = yup.object().shape({
      organization: yup.string().required(),
      firstName: yup.string().required(),
      lastName: yup.string().required(),
      address: yup.string().required(),
      appartment: yup.string(),
      city: yup.string().required(),
      country: yup.string().required(),
      state: yup.string().required(),
      zipcode: yup.string().required(),
      phone: yup.string().required(),
      email: yup.string().required(),
    });

    return (
      schema.isValidSync(shippingAddress) &&
      isValidPhoneNumber(shippingAddress.phone) &&
      schema.isValidSync(billingAddress) &&
      isValidPhoneNumber(billingAddress.phone) &&
      finalDesignValidated &&
      termsAndConditionsAccepted
    );
  };

  const onBillingFieldChange = useCallback(
    (e: any, field: keyof AddressFormType) => {
      dispatch({
        type: BILLING_ACTION_ENUM.UPDATE_BILLING_ADDRESS_FIELD,
        payload: {
          field,
          value: e.target.value,
        },
      });

      //If country changes, the province/state must change also (using the first entry in the list)
      if (field === "country") {
        const stateList = e.target.value === "US" ? USStates : CAProvinces;
        const newCountryState = stateList[0];

        dispatch({
          type: BILLING_ACTION_ENUM.UPDATE_BILLING_ADDRESS_FIELD,
          payload: {
            field: "state",
            value: newCountryState,
          },
        });
      }
    },
    [dispatch]
  );

  const onShippingFieldChange = useCallback(
    (e: any, field: keyof AddressFormType) => {
      dispatch({
        type: SHIPPING_ACTION_ENUM.UPDATE_DELIVERY_ADDRESS_FIELD,
        payload: {
          field,
          value: e.target.value,
        },
      });

      //Propagate the field change to Billing if same as shipping
      if (sameAsShippingAddress) {
        onBillingFieldChange(e, field);
      }
    },
    [dispatch, onBillingFieldChange, sameAsShippingAddress]
  );

  const onShippingCountryChange = useCallback(
    (e: any) => {
      const newCountry = e.target.value;
      dispatch({
        type: SHIPPING_ACTION_ENUM.UPDATE_DELIVERY_ADDRESS_FIELD,
        payload: {
          field: "country",
          value: newCountry,
        },
      });

      //Propagate the field change to Billing if same as shipping
      if (sameAsShippingAddress) {
        onBillingFieldChange(e, "country");
      }

      // Update the states and select the first one
      const stateList = newCountry === "US" ? USStates : CAProvinces;
      const newCountryState = stateList[0];

      dispatch({
        type: SHIPPING_ACTION_ENUM.UPDATE_DELIVERY_ADDRESS_FIELD,
        payload: {
          field: "state",
          value: newCountryState,
        },
      });
      //Propagate the field change to Billing if same as shipping
      if (sameAsShippingAddress) {
        onBillingFieldChange(e, "country");
        onBillingFieldChange({ target: { value: newCountryState } }, "state");
      }

      if (newCountry === "US") {
        setUSD();
      } else {
        setCAD();
      }
    },
    [dispatch, onBillingFieldChange, sameAsShippingAddress, setCAD, setUSD]
  );

  const onSameAddressAsShipping = useCallback(
    (sameAsShippingAddress: boolean) => {
      dispatch({
        type: BILLING_ACTION_ENUM.UPDATE_BILLING_ADDRESS,
        payload: {
          billingAddress: shippingAddress,
          sameAsShippingAddress,
        },
      });
    },
    [shippingAddress, dispatch]
  );

  const onPreOrder = async () => {
    const itemsToPreOrder = items.map((item) => {
      return { designId: item.designId, sizes: item.sizes };
    });

    const preOrderData = {
      shippingAddress: shippingAddress,
      billingAddress: billingAddress,
      items: itemsToPreOrder,
      locale: getLanguage(),
      currency: getCurrency(),
      promoCode,
    };
    setPreorderRequestSent(true);
    const checkoutRes = await PreOrderService.addPreOrder(preOrderData);
    setPreorderRequestSent(false);

    //TODO: Error message to user when failing
    if (checkoutRes.status !== 200) {
      console.error("Something somewhere went wrong!", checkoutRes);
      return;
    }

    const preorderId = checkoutRes.data.data.id;

    navigate(
      routeFormatter(ROUTES.ORDERS_PREORDERS, {
        params: { preorderId: preorderId },
      })
    );
  };

  const leftPanel = (
    <div className={styles.container} key="container">
      {loaded ? (
        items.length > 0 ? (
          <div className={styles.addresses}>
            <div className={styles.address}>
              <div className={styles.header} key="header">
                <h5>{t("checkout.delivery_address")}</h5>
              </div>
              <Address
                onAddressFieldChange={onShippingFieldChange}
                onAddressCountryChange={onShippingCountryChange}
                address={shippingAddress}
              />
            </div>
            <div className={styles.address}>
              <div className={styles.header} key="header">
                <h5>{t("checkout.billing_address")}</h5>

                <Checkbox
                  className={styles.sameAsShippingCheckbox}
                  setChecked={onSameAddressAsShipping}
                  text={t("checkout.same_as_shipping")}
                  checkedByDefault={sameAsShippingAddress}
                />
              </div>
              {!sameAsShippingAddress && (
                <Address
                  onAddressCountryChange={onBillingFieldChange}
                  onAddressFieldChange={onBillingFieldChange}
                  address={billingAddress}
                />
              )}
            </div>
          </div>
        ) : (
          <div className={styles.emptyCart} key="empty">
            <span>{t("checkout.cart_empty")} </span>
          </div>
        )
      ) : (
        <div className={styles.loadingProgress} key="progress">
          <CircularProgress />
        </div>
      )}
    </div>
  );

  const rightPanel = (
    <div key="preview">
      <CartPreview />
      <div className={styles.promoCode}>
        <div className={styles.subtitle}>{t("checkout.promo_code")}</div>
        <input
          key="promoCode"
          onChange={(e) => setPromoCode(e.target.value)}
          placeholder={t("checkout.promo_code_placeholder")}
        />
      </div>
      <div className={styles.nextSteps}>
        <div className={styles.subtitle}>
          {t("checkout.next_steps_bank.title")}
        </div>
        <Message
          messageType={MessageTypes.PLAIN}
          message={t("checkout.next_steps_bank.message")}
          alignCenter={false}
        />
      </div>
      <div className={styles.checkboxes}>
        <div className={styles.subtitle}>{t("checkout.review_order")}</div>
        <Checkbox
          setChecked={setFinalDesignValidated}
          text={t("checkout.final_design")}
          checkedByDefault={finalDesignValidated}
        />
        <Checkbox
          setChecked={setTermsAndConditionsAccepted}
          text={t("checkout.termsAndConditions.text")}
          linkUrl={t("checkout.termsAndConditions.linkUrl")}
          textUrl={t("checkout.termsAndConditions.textUrl")}
          checkedByDefault={termsAndConditionsAccepted}
        />
      </div>
      <Button
        disabled={!isValid() || !loaded || preorderRequestSent}
        onClick={onPreOrder}
      >
        {preorderRequestSent
          ? t("checkout.completing_pre_order")
          : t("checkout.continue_to_pre_order")}
      </Button>
    </div>
  );

  return (
    <CheckoutTemplate
      activeIndex={2}
      leftPanel={leftPanel}
      rightPanel={rightPanel}
    />
  );
};
