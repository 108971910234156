import { Divider } from "@mui/material";
import { CSSProperties } from "react";
import { useTranslation } from "react-i18next";
import { EXTERNAL_ROUTE, ROUTES } from "../../../utils/Constant";
import { Box, NavLink } from "../../components";
import { LeftSideBar } from "../../components/LeftSideBar";
import { OverlayScrollbarWrapper } from "../../components/OverlayScrollbar";
import { useSelector } from "../../hooks/useSelector";
import { AddTeam } from "./CreateTeam";
import cssStyles from "./LeftNavigation.module.scss";
import { SearchTeams } from "./SearchTeams";
import { TeamProjects } from "./TeamProjects";

const styles: { [key: string]: CSSProperties } = {
  link: {
    paddingRight: "12px",
  },
};

export const LeftNavigation = () => {
  const userInfos = useSelector((state) => state.userInfo);
  const { t } = useTranslation();

  return (
    <LeftSideBar>
      <Box className={cssStyles.container}>
        <NavLink
          to={ROUTES.HOME}
          startIcon={"home"}
          content={t("navigation.home")}
          style={styles.link}
        />

        <NavLink
          to={t("navigation.work_with_designer.linkUrl")}
          startIcon="palette"
          content={t("navigation.work_with_designer.linkText")}
          target="_blank"
          style={styles.link}
        />

        <NavLink
          to={EXTERNAL_ROUTE.SUPPORT}
          content={t("navigation.support")}
          startIcon="help"
          target="_blank"
          style={styles.link}
        />

        {userInfos.canAddTeam && <AddTeam />}

        {userInfos && userInfos.isDesigner && <SearchTeams />}
        <Divider sx={{ my: "8px !important" }} />

        <OverlayScrollbarWrapper style={{ marginBottom: "48px" }}>
          <div className={cssStyles.teams} data-test="teamProjects.container">
            <TeamProjects canAddProject={userInfos.canAddProject} />
          </div>
        </OverlayScrollbarWrapper>
      </Box>
    </LeftSideBar>
  );
};
