import React, { useMemo } from "react";

import styles from "./MultiplayerCursor.module.scss";

const MultiplayerCursor = ({
  xCoordinates,
  yCoordinates,
  color,
  previewContainerRef,
}) => {
  /**
   * We received a x and y in terms of % of the preview container ref of the other user
   * So we need to multiply it by our own preview container ref width and hight to get the right position
   * on our screen
   */
  const x = useMemo(() => {
    if (!previewContainerRef.current) {
      return -1;
    }

    return (
      xCoordinates * previewContainerRef.current.getBoundingClientRect().width +
      previewContainerRef.current.getBoundingClientRect().left
    );
  }, [xCoordinates, previewContainerRef]);

  /**
   * We received a x and y in terms of % of the preview container ref of the other user
   * So we need to multiply it by our own preview container ref width and hight to get the right position
   * on our screen
   */

  const y = useMemo(() => {
    if (!previewContainerRef.current) {
      return -1;
    }
    return (
      yCoordinates *
        previewContainerRef.current.getBoundingClientRect().height +
      previewContainerRef.current.getBoundingClientRect().top
    );
  }, [yCoordinates, previewContainerRef]);

  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={styles.dot}
      style={{ left: x, top: y, fill: color }}
    >
      <g filter="url(#filter0_d)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.14601 4.14635C6.21755 4.07486 6.30908 4.02675 6.40852 4.00836C6.50796 3.98997 6.61064 4.00217 6.70301 4.04335L19.431 9.70035C19.52 9.73987 19.5956 9.80453 19.6483 9.88639C19.7011 9.96825 19.7288 10.0637 19.7281 10.1611C19.7273 10.2585 19.6982 10.3536 19.6442 10.4346C19.5901 10.5157 19.5136 10.5792 19.424 10.6173L14.658 12.6583L12.616 17.4253C12.5776 17.5146 12.514 17.5908 12.433 17.6445C12.352 17.6982 12.2572 17.7271 12.16 17.7278C12.0628 17.7284 11.9675 17.7007 11.8858 17.6481C11.8042 17.5954 11.7396 17.5201 11.7 17.4313L6.04301 4.70335C6.00206 4.6111 5.98998 4.50862 6.00837 4.40939C6.02675 4.31015 6.07473 4.2188 6.14601 4.14735V4.14635Z"
          fill="#485DA8"
        />
        <path
          d="M5.14601 3.73192L5.43916 3.43899C5.65376 3.22453 5.92836 3.0802 6.22668 3.02503C6.52464 2.96994 6.83231 3.00637 7.10916 3.12954L5.14601 3.73192ZM5.14601 3.73192V3.85493C5.08992 3.97257 5.0491 4.09766 5.02509 4.22723C4.96994 4.52495 5.00618 4.83238 5.12903 5.10911L5.1292 5.10949L10.7862 17.8375L10.7866 17.8385C10.9053 18.1048 11.0991 18.3307 11.3441 18.4886C11.5892 18.6466 11.875 18.7297 12.1665 18.7278C12.4581 18.7258 12.7427 18.639 12.9857 18.4779C13.2287 18.3167 13.4194 18.0883 13.5346 17.8205L13.5352 17.8191L15.4196 13.42L19.8158 11.5374C19.8162 11.5372 19.8166 11.537 19.817 11.5369C20.0853 11.4223 20.3144 11.232 20.4763 10.9893C20.6383 10.7461 20.7258 10.461 20.7281 10.1688C20.7303 9.87659 20.6471 9.59011 20.4888 9.34454C20.3305 9.09905 20.1041 8.90512 19.8372 8.78654C19.837 8.78649 19.8369 8.78644 19.8368 8.78639L7.11016 3.12998L5.14601 3.73192Z"
          stroke="white"
          strokeWidth="2"
        />
      </g>
      <defs>
        <filter
          id="filter0_d"
          x="0"
          y="0"
          width="25.7281"
          height="25.7278"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default MultiplayerCursor;
