import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { GlassMagnifier } from "react-image-magnifiers";
import classNames from "classnames";
import styles from "./PreviewGlassMagnifier.module.scss";
import { MicroColorCircle } from "../../../../../common/components/Select/MicroColorCircle/MicroColorCircle";
import { GLASS_MAGNIFIER_BACKGROUND_COLORS } from "../../../../../types";

interface Props {
  imageUrl?: string;
  imageQuality?: string;
  onBackgroundColorChange?: (color: GLASS_MAGNIFIER_BACKGROUND_COLORS) => void;
  backgroundColor?: GLASS_MAGNIFIER_BACKGROUND_COLORS;
  noBackgroundColor?: boolean;
  title?: string;
  allowWhite?: boolean;
}

export const PreviewGlassMagnifier = (props: Props) => {
  const { t } = useTranslation();
  const {
    imageUrl,
    imageQuality,
    onBackgroundColorChange,
    backgroundColor = GLASS_MAGNIFIER_BACKGROUND_COLORS.LIGHT,
    noBackgroundColor = false,
    title = "",
    allowWhite = false,
  } = props;

  const [backgroundColorState, setBackgroundColorState] = useState<
    GLASS_MAGNIFIER_BACKGROUND_COLORS | undefined
  >(undefined);

  //Initial loading
  useEffect(() => {
    if (backgroundColor) {
      setBackgroundColorState(backgroundColor);
    }
  }, [backgroundColor]);

  /**
   *
   */
  const backgroundColorChange = useCallback(
    (newColor: GLASS_MAGNIFIER_BACKGROUND_COLORS) => {
      setBackgroundColorState(newColor);
      onBackgroundColorChange?.(newColor);
    },
    [onBackgroundColorChange]
  );

  const statusColor = useMemo(() => {
    const IMAGE_QUALITY = [
      {
        quality: "poor",
        color: " #E60E0E",
      },
      {
        quality: "acceptable",
        color: "	#E69D0E",
      },
      {
        quality: "great",
        color: "	#12A006",
      },
    ];

    if (!imageQuality) {
      return styles.statusNA;
    }
    const imageQualityFound = IMAGE_QUALITY.find(
      (item) => item.quality === imageQuality
    );

    if (!imageQualityFound) {
      return styles.statusNA;
    }

    return imageQualityFound.color;
  }, [imageQuality]);

  return (
    <div className={styles.container}>
      <div className={styles.actionContainer}>
        <div className={styles.status} style={{ color: statusColor }}>
          <span className={styles.title}>{title}</span>
          <span>
            {imageQuality && t(`specs.decoration_quality.${imageQuality}`)}
          </span>
        </div>
        {!noBackgroundColor && (
          <div className={styles.changeColor}>
            <div
              className={styles.color}
              onClick={() =>
                backgroundColorChange(GLASS_MAGNIFIER_BACKGROUND_COLORS.LIGHT)
              }
            >
              <MicroColorCircle
                color={GLASS_MAGNIFIER_BACKGROUND_COLORS.LIGHT}
                tooltip={t("preview.background_light")}
                isSelected={
                  backgroundColorState ===
                  GLASS_MAGNIFIER_BACKGROUND_COLORS.LIGHT
                }
              />
            </div>
            <div
              className={styles.color}
              onClick={() =>
                backgroundColorChange(GLASS_MAGNIFIER_BACKGROUND_COLORS.DARK)
              }
            >
              <MicroColorCircle
                color={GLASS_MAGNIFIER_BACKGROUND_COLORS.DARK}
                tooltip={t("preview.background_dark")}
                isSelected={
                  backgroundColorState ===
                  GLASS_MAGNIFIER_BACKGROUND_COLORS.DARK
                }
              />
            </div>

            {allowWhite && (
              <div
                className={styles.color}
                onClick={() =>
                  backgroundColorChange(GLASS_MAGNIFIER_BACKGROUND_COLORS.WHITE)
                }
              >
                <MicroColorCircle
                  color={GLASS_MAGNIFIER_BACKGROUND_COLORS.WHITE}
                  tooltip={t("preview.background_white")}
                  isSelected={
                    backgroundColorState ===
                    GLASS_MAGNIFIER_BACKGROUND_COLORS.WHITE
                  }
                />
              </div>
            )}
          </div>
        )}
      </div>
      <div className={styles.glassMagnifierContainer}>
        {imageUrl && (
          <GlassMagnifier
            imageSrc={imageUrl}
            allowOverflow={true}
            magnifierSize={"120%"}
            square={false}
            imageAlt="preview"
            className={classNames(
              styles.glassMagnifier,
              noBackgroundColor
                ? styles.noBackground
                : backgroundColorState ===
                    GLASS_MAGNIFIER_BACKGROUND_COLORS.DARK
                  ? styles.darkBackground
                  : backgroundColorState ===
                      GLASS_MAGNIFIER_BACKGROUND_COLORS.LIGHT
                    ? styles.lightBackground
                    : styles.whiteBackground
            )}
          />
        )}
      </div>
    </div>
  );
};
