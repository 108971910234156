import { Typography } from "@mui/material";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { object, string } from "yup";
import {
  Box,
  Button,
  CenteredColumnBox,
  Form,
  Icon,
  Input,
} from "../../common/components";
import { useForm } from "../../common/hooks/useForm";
import { OnboardingTemplate } from "../../containers/OnboardingContainer";
import AuthService from "../../service/authService";
import { palette, spacing } from "../../styles/theme";
import { ROUTES } from "../../utils/Constant";
import { testPassword } from "../../utils/FormValidationsUtils";
import { OnboardingTitle } from "../Onboarding/Component/OnboardingTitle";

const validationSchema = object({
  password: string().test(testPassword),
});

export const UpdatePassword = () => {
  const { t } = useTranslation();

  const params = useParams();
  const navigate = useNavigate();
  const [showPasswordAsText, setShowPasswordAsText] = useState(false);
  const [errorMsg, setErrorMsg] = useState<string | undefined>();

  const formik = useForm({
    initialValues: { password: "" },
    onSubmit: (values) => {
      onClickReset(values?.password);
    },
    validationSchema: validationSchema,
  });

  const onClickReset = (password: string) => {
    const token = params.token;

    AuthService.updatePassword(password, token)
      .then((res) => {
        if (res.data.status === "success") {
          navigate(ROUTES.LOGIN, { state: { fromResetPassword: true } });
        }
      })
      .catch(() => {
        setErrorMsg(t("forgot_password.expired_link"));
      });
  };

  const togglePasswordVisibility = useCallback(() => {
    setShowPasswordAsText((prev) => !prev);
  }, []);

  return (
    <Box>
      <OnboardingTemplate>
        <Form formik={formik}>
          <OnboardingTitle title={t("forgot_password.update_password.title")}>
            <Typography variant="body1" fontSize={{ xs: 12, sm: 16 }}>
              {t("forgot_password.update_password.description")}
            </Typography>
          </OnboardingTitle>
          <CenteredColumnBox
            gap={{ xs: spacing.regularMobile, lg: spacing.regularDesktop }}
            fullWidth
          >
            <Input
              type={showPasswordAsText ? "text" : "password"}
              name="password"
              label={t("forgot_password.update_password.label")}
              error={errorMsg}
              autoFocus
              fullWidth
              endAdornment={
                <Icon
                  style={{
                    cursor: "pointer",
                  }}
                  icon={showPasswordAsText ? "visibility" : "visibility_off"}
                  onClick={togglePasswordVisibility}
                  size={20}
                  color={palette.black.headings}
                />
              }
            />
            <CenteredColumnBox fullWidth>
              <Button type="submit" fullWidth>
                {t("forgot_password.update_password.button")}
              </Button>
            </CenteredColumnBox>
          </CenteredColumnBox>
        </Form>
      </OnboardingTemplate>
    </Box>
  );
};
