import axios from "axios";

class TechpackDownloadService {
  static async downloadTechpack(url) {
    return axios.get(url, {
      responseType: "blob",
    });
  }
}

export default TechpackDownloadService;
