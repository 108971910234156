import { set } from "lodash";
import { DesignState, Image } from "../../types";
import { Action } from "../../types/redux";

export enum DESIGNS_ACTION_ENUM {
  UPDATE_DESIGNS = "update_designs",
  UPDATE_LOADING = "update_designs_loading",
  UPDATE_DESIGN_THUMBNAIL = "update_design_thumbnail",
}

export interface DesignPayload {
  designs: DesignState[];
  id: string;
  images: Image;
}

interface State {
  designs: DesignPayload["designs"];
  loading: boolean;
  loaded: boolean;
}

const initialState: State = {
  designs: [],
  loading: false,
  loaded: false,
};

const reducer = (
  state = initialState,
  action: Action<DESIGNS_ACTION_ENUM, DesignPayload>
): State => {
  switch (action.type) {
    case DESIGNS_ACTION_ENUM.UPDATE_DESIGNS:
      return { designs: action.payload.designs, loading: false, loaded: true };
    case DESIGNS_ACTION_ENUM.UPDATE_LOADING:
      return { designs: [...state.designs], loading: true, loaded: false };
    //DP FIXME: I don't think UPDATE_DESIGN_THUMBNAIL is really needed, need to remove it (and make sur nothing breaks:))
    case DESIGNS_ACTION_ENUM.UPDATE_DESIGN_THUMBNAIL:
      const index = state.designs.findIndex((s) => s.id === action.payload.id);
      set(state.designs, [index, "images"], action.payload.images);
      return state;
    default:
      return state;
  }
};

export default reducer;
