import axios from "axios";
import AuthService from "./authService";
const USER_API_BASE_URL = process.env.REACT_APP_BACKEND_URL;

const BASE_ROUTE = "/api/v1/user";

class ReferralCodeService {
  static async getReferralCode() {
    const res = await axios.get(
      `${USER_API_BASE_URL}${BASE_ROUTE}/referral_code`,
      this.getAuthHeader()
    );
    return res?.data?.data;
  }
  static async getUserFromReferralCode(data) {
    const res = await axios.post(
      `${USER_API_BASE_URL}${BASE_ROUTE}/from_referral_code`,
      data,
      this.getAuthHeader()
    );
    return res?.data?.data;
  }

  static getAuthHeader() {
    return { headers: { "x-access-token": AuthService.getJWT() } };
  }
}

export default ReferralCodeService;
