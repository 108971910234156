import React, { useMemo } from "react";
import classNames from "classnames";
import { useSelector } from "react-redux";
import DesignPill from "../../../common/components/DesignPill";
import Add from "@mui/icons-material/Add";
import { useTranslation } from "react-i18next";
import { ReactComponent as JameoIcon } from "../../../assets/images/jameoIcon.svg";
import styles from "./ProjectColumn.module.scss";
import { generatePath, useNavigate } from "react-router-dom";
import { routeFormatter } from "../../../utils/Formatter";
import { ROUTES } from "../../../utils/Constant";

const ProjectColumn = (props) => {
  const { onAddButtonClick, projectId, selectedDesignId, canAddDesign } = props;

  const navigate = useNavigate();
  const { t } = useTranslation();

  const designsReduxState = useSelector((state) => state.designs);
  const designs = useMemo(() => {
    return designsReduxState?.designs;
  }, [designsReduxState]);

  const logoRoute = routeFormatter(ROUTES.HOME, {
    params: {},
  });

  const shownDesigns = useMemo(() => {
    if (!projectId) {
      return [];
    }

    return designs
      ?.filter((d) => d.project_id === projectId)
      .sort((a, b) => {
        if (a.latestUpdate < b.latestUpdate) {
          return 1;
        } else {
          return -1;
        }
      });
  }, [projectId, designs]);

  return (
    <div className={styles.projectColumn}>
      <DesignPill
        className={`${styles.designPill} ${styles.jameoIconPill}`}
        onClick={() => {
          navigate(logoRoute);
        }}
        tooltip={t("studio_toolbar.back_to_home")}
      >
        <JameoIcon className={styles.logo} />
      </DesignPill>
      {canAddDesign && (
        <DesignPill
          onClick={onAddButtonClick}
          className={styles.designPill}
          tooltip={t("design.new_design")}
        >
          <Add style={{ color: "#20497A" }} />
        </DesignPill>
      )}
      {shownDesigns.map((d) =>
        //If the design is in error, then we don't allow to use it
        d.errorKey ? (
          <DesignPill
            active={false}
            key={d.id}
            design={d}
            className={classNames(
              styles.designPill,
              styles.designPillDeactivated
            )}
            tooltip={t("design.design_with_error")}
          />
        ) : (
          <DesignPill
            active={selectedDesignId === d.id}
            key={d.id}
            design={d}
            className={styles.designPill}
            tooltip={d.name}
            onClick={() =>
              navigate(
                generatePath(ROUTES.STUDIO, {
                  designId: d.id,
                  projectId,
                })
              )
            }
          />
        )
      )}
    </div>
  );
};

export default ProjectColumn;
