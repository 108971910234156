import React from "react";
import classNames from "classnames";
import styles from "./checkbox.module.scss";

const Checkbox = (props) => {
  const { setChecked, text, textUrl, linkUrl, checkedByDefault, className } =
    props;

  return (
    <React.Fragment>
      <div className={classNames(styles.container, className)}>
        <label className={styles.label}>
          <input
            className={styles.inputCheckbox}
            onChange={(e) => {
              setChecked(e.target.checked);
            }}
            type="checkbox"
            checked={checkedByDefault}
          />
          <span className={styles.text}>{text}</span>
          {linkUrl && textUrl && (
            <a
              href={linkUrl}
              rel={"noreferrer"}
              target={"_blank"}
              className={styles.url}
            >
              {textUrl}
            </a>
          )}
        </label>
      </div>
    </React.Fragment>
  );
};

export default Checkbox;
