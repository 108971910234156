import Check from "@mui/icons-material/Check";
import { CircularProgress } from "@mui/material";
import _ from "lodash";
import { useCallback, useContext, useEffect, useMemo } from "react";
import Helmet from "react-helmet";
import { useTranslation } from "react-i18next";
import { useStore } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Box, CenteredColumnBox } from "../../common/components";
import { Button } from "../../common/components/Button";
import CartPreview from "../../common/components/CartPreview";
import { useSelector } from "../../common/hooks/useSelector";
import { CART_ACTION_ENUM } from "../../common/reducers/cart";
import { CheckoutTemplate } from "../../common/views/Checkout";
import DeliveryInfoMessage from "../../components/Message/DeliveryInfoMessage";
import config from "../../config";
import { SpecsContext } from "../../context/Specs/SpecsContext";
import { useCart } from "../../utils/CartHooks";
import { ROUTES } from "../../utils/Constant";
import { useCurrency } from "../../utils/Currencies";
import { CartItemSizeCollector } from "./CartItemSizeSelector";
import styles from "./SizeSelectorContainer.module.scss";

// !!! IMPORTANT IF YOU WANT TO BRING BACK STRIPE !!!
// FIXME: See commit e71a4964daa11de3ec441d308b396182da198f6a to see how the
//  stripe checkout was managed before being removed.

export const SizeSelectorContainer = () => {
  const { dispatch } = useStore();
  const userInfos = useSelector((state) => state.userInfo);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { specs } = useContext(SpecsContext);

  const { isSaving, loaded, pricingCartData } = useSelector(
    (state) => state.cart
  );
  const items = useMemo(() => pricingCartData.items, [pricingCartData]);

  const { getCurrency } = useCurrency();

  const { removeFromCart, updateCartSizes, fetchCartItemsAndUpdateStore } =
    useCart();

  //At load, fetch the cart to get the latest collected sizes information
  useEffect(() => {
    const currency = getCurrency();
    if (currency) {
      fetchCartItemsAndUpdateStore(getCurrency()); //Because deleting a Project and it's designs can impact the cart
    }
  }, [fetchCartItemsAndUpdateStore, getCurrency]);

  const onPreOrder = useCallback(async () => {
    navigate(ROUTES.SHIPPING_ADDRESS);
  }, [navigate]);

  //We get warning React Hook useCallback received a function whose dependencies are unknown
  //TODO fix later using this: https://kyleshevlin.com/debounce-and-throttle-callbacks-with-react-hooks
   
  const saveItems = useCallback(
    _.throttle(
      async () => {
        await updateCartSizes(items, getCurrency());

        dispatch({
          type: CART_ACTION_ENUM.SET_SAVING,
          payload: { isSaving: false },
        });
      },
      1000,
      { leading: false }
    ),
    [items, dispatch, updateCartSizes]
  );

  /**
   * Remove from cart action
   */
  const onItemRemove = useCallback(
    (id: string, productName: string) => {
      removeFromCart(id, getCurrency(), productName);
    },
    [removeFromCart, getCurrency]
  );

  /**
   * Can't use useEffect here because the sizes are updated inside the items array, the item array itself is not modified
   */
  const canContinueToPayment =
    !isSaving &&
    items &&
    items.length > 0 &&
    ((pricingCartData.hasMinimum === true &&
      pricingCartData.hasMinimumPerSize === true) ||
      ((userInfos.isAdmin || userInfos.isDesigner) &&
        items.some((item) =>
          item.sizes.some((size) => (size.qty ?? 0) > 0)
        ))) &&
    //pricingCartData.hasReachedMaximum === false && //We allow orders above maximum
    !(
      items.map((i) => i.deprecatedDesign).some((e) => e) &&
      config.blockPaymentWhenDesignIsDeprecated
    );

  //Analytics
  useEffect(() => {
    if (loaded && pricingCartData && userInfos) {
      window.analytics.track("Cart Viewed", {
        cart_id: userInfos.id,
        products: pricingCartData?.items.map((cartItem) => {
          return {
            product_id: cartItem.productName,
            sku: cartItem.designId,
            name: cartItem.productName,
            quantity: cartItem.pricing.qty,
          };
        }),
      });
    }
  }, [loaded, pricingCartData, userInfos]);

  const itemsByDeliveryDate = useMemo(() => {
    const deliveryDates = items.map((item) => {
      const productDefinition = specs?.products.find(
        (product) => product.name === item.productName
      );
      const version = productDefinition?.versions.find(
        (v) => v.name === item.versionName
      );
      return version?.deliveryTime;
    });

    // Keep unique delivery dates.
    const uniqueDeliveryDates = new Map();
    for (const deliveryDate of deliveryDates) {
      const deliveryDateKey =
        deliveryDate?.minWeeks + "-" + deliveryDate?.maxWeeks;
      if (!uniqueDeliveryDates.has(deliveryDateKey)) {
        uniqueDeliveryDates.set(deliveryDateKey, deliveryDate);
      }
    }
    // Switch back to an array of obj
    const groupsOfDeliveryDates = Array.from(uniqueDeliveryDates.values());

    const groupedItems = [];
    for (const deliveryDate of groupsOfDeliveryDates) {
      const itemsWithSameDeliveryDate = items.filter((i) => {
        const productDefinition = specs?.products.find(
          (product) => product.name === i.productName
        );
        const version = productDefinition?.versions.find(
          (v) => v.name === i.versionName
        );

        return (
          version?.deliveryTime?.minWeeks === deliveryDate?.minWeeks &&
          version?.deliveryTime?.maxWeeks === deliveryDate?.maxWeeks
        );
      });
      groupedItems.push({
        items: itemsWithSameDeliveryDate,
        deliveryDate,
      });
    }
    return groupedItems;
  }, [items, specs?.products]);

  const leftPanel = useMemo(
    () => (
      <div className={styles.container} key="sizeCollection">
        <Helmet>
          <title>{`${t("pages.cart.title")}`}</title>
        </Helmet>
        {loaded ? (
          items.length > 0 ? (
            <div className={styles.header} key="view_sizes">
              <div className={styles.viewSizeDiv}>
                <a
                  href={t("checkout.size_link")}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Button color="secondary" size="small">
                    {t("checkout.view_sizes")}
                  </Button>
                </a>
                <div>
                  {isSaving ? (
                    <p className={styles.saved}>{t("checkout.saving")}</p>
                  ) : (
                    <p className={styles.saved}>
                      <Check className={styles.check} /> {t("checkout.saved")}
                    </p>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div className={styles.emptyCart}>
              <span>{t("checkout.cart_empty")} </span>
            </div>
          )
        ) : (
          <div className={styles.loadingProgress}>
            <CircularProgress />
          </div>
        )}
        <div className={styles.cartItems} key="cartItems">
          {itemsByDeliveryDate?.map((itemByDeliveryDate, index) => {
            return (
              <div key={index}>
                <DeliveryInfoMessage
                  minWeeks={itemByDeliveryDate.deliveryDate?.minWeeks}
                  maxWeeks={itemByDeliveryDate.deliveryDate?.maxWeeks}
                />
                {itemByDeliveryDate.items.map((cartItem) => (
                  <div key={cartItem.id}>
                    <CartItemSizeCollector
                      key={cartItem.id}
                      {...cartItem}
                      onChange={saveItems}
                      onRemove={onItemRemove}
                    />
                  </div>
                ))}
              </div>
            );
          })}
        </div>
      </div>
    ),
    [
      isSaving,
      items.length,
      itemsByDeliveryDate,
      loaded,
      navigate,
      onItemRemove,
      saveItems,
      t,
    ]
  );

  const rightPanel = useMemo(
    () => (
      <Box key="preview">
        <CartPreview />
        <CenteredColumnBox>
          <Button disabled={!canContinueToPayment} onClick={onPreOrder}>
            {isSaving
              ? t("checkout.calculating")
              : t("checkout.continue_to_shipping")}
          </Button>
        </CenteredColumnBox>
      </Box>
    ),
    [canContinueToPayment, isSaving, onPreOrder, t]
  );

  return (
    <CheckoutTemplate
      activeIndex={1}
      leftPanel={leftPanel}
      rightPanel={rightPanel}
    />
  );
};
