import { Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { SymbolCodepoints } from "react-material-symbols";
import { GradientBorderedBox, Icon } from "../../../common/components";
import { palette, spacing } from "../../../styles/theme";
import { PROJECT_TYPES } from "../../../utils/Constant";

const iconList: {
  [key in PROJECT_TYPES]: {
    baseIcon: SymbolCodepoints;
    activeIcon: SymbolCodepoints;
  };
} = {
  [PROJECT_TYPES.COMPANY]: {
    baseIcon: "work_outline",
    activeIcon: "trip",
  },

  [PROJECT_TYPES.STUDENT]: {
    baseIcon: "group",
    activeIcon: "groups_2",
  },
  [PROJECT_TYPES.PERSONAL]: {
    baseIcon: "sentiment_satisfied_alt",
    activeIcon: "sentiment_very_satisfied",
  },
  [PROJECT_TYPES.OTHER]: {
    baseIcon: "category",
    activeIcon: "interests",
  },
};

interface Props {
  active: boolean;
  type: PROJECT_TYPES;
  onClick: () => void;
}

export const ProjectTypeListItem = ({ active, onClick, type }: Props) => {
  const { t } = useTranslation();
  const [isHovering, setIsHovering] = useState(false);
  const icon =
    active || isHovering ? iconList[type].activeIcon : iconList[type].baseIcon;
  return (
    <GradientBorderedBox
      display="flex"
      alignItems="center"
      gap={{ xs: spacing.smallMobile, lg: spacing.smallDesktop }}
      showGradient={active}
      onClick={onClick}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      fullWidth
      data-test={`onboard.projectType.${type}`}
    >
      <Icon icon={icon} size={20} color={palette.darkGray} />
      <Typography variant="body2" lineHeight="24px">
        {t(`onboard.project_type.${type}`)}
      </Typography>
    </GradientBorderedBox>
  );
};
