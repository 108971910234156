import { useCallback } from "react";
import { useStore } from "react-redux";
import { useSelector } from "../common/hooks/useSelector";
import { PROFILE_INFO_ACTION_ENUM } from "../common/reducers/profile";
import { Currency } from "../types";
import { isFrench } from "./LocaleUtil";

const DEFAULT_CURRENCY: Currency = "cad";
const CURRENCY_STORAGE_ITEM = "userCurrency";

export const useCurrency = () => {
  const { dispatch } = useStore();

  const { currency: currencyState } = useSelector((state) => state.profile);
  //console.debug("In useCurrency, currency", currencyState);
  /**
   * TODO: We should probably put the currency in the user's profile (and also the language...)
   * @returns
   */
  function getCurrencyFromLocal() {
    return localStorage.getItem(CURRENCY_STORAGE_ITEM) || DEFAULT_CURRENCY;
  }

  /**
   * This function should be used only in the App component, when initializing the application
   */
  const initCurrency = useCallback(() => {
    const currency = getCurrencyFromLocal();

    localStorage.setItem(CURRENCY_STORAGE_ITEM, currency);

    dispatch({
      type: PROFILE_INFO_ACTION_ENUM.UPDATE_USER_PREFERENCE_CURRENCY,
      payload: currency,
    });
  }, [dispatch]);

  const getCurrency = useCallback((): Currency => {
    if (!currencyState) {
      getCurrencyFromLocal();
    }
    return currencyState;
  }, [currencyState]);

  /**
   * Set the currency state and set it in local storage
   * TODO: We should probably put the currency in the user's profile (and also the language...)
   * @param {*} currency see enum CURRENCY_ENUM for values
   * @returns
   */
  const setCurrency = useCallback(
    (currency: Currency) => {
      localStorage.setItem(CURRENCY_STORAGE_ITEM, currency);

      dispatch({
        type: PROFILE_INFO_ACTION_ENUM.UPDATE_USER_PREFERENCE_CURRENCY,
        payload: currency,
      });
    },
    [dispatch]
  );

  const setUSD = useCallback(() => {
    setCurrency("usd");
  }, [setCurrency]);

  const setCAD = useCallback(() => {
    setCurrency("cad");
  }, [setCurrency]);

  const isCAD = useCallback(() => {
    return currencyState === "cad";
  }, [currencyState]);

  const isUSD = useCallback(() => {
    return currencyState === "usd";
  }, [currencyState]);

  const getFullCurrencyFormatter = useCallback(() => {
    return getCurrencyFormatter(currencyState, "symbol");
  }, [currencyState]);

  /**
   * Generic currency formatter with currency as input
   * @param {*} currency
   * @returns
   */
  const getCurrencyFormatter = (
    currency: Currency,
    currencyDisplay: any = "narrowSymbol"
  ) => {
    let formatter = undefined;

    const localCurrency = isFrench() ? "fr-CA" : "en-CA";

    //Default is CAD, if currencyState is not yet defined
    if (!currency || currency === "cad") {
      //'en-US' is not an error, is to make sure the current prefix is CA$
      formatter = new Intl.NumberFormat(localCurrency, {
        style: "currency",
        currency: "CAD",
        currencyDisplay: currencyDisplay,
        // These options are needed to round to whole numbers if that's what you want.
        minimumFractionDigits: 2, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
      });
    } else if (currency === "usd") {
      //'en-CA' is not an error, is to make sure the current prefix is US$
      formatter = Intl.NumberFormat(localCurrency, {
        style: "currency",
        currency: "USD",
        currencyDisplay: currencyDisplay,
        // These options are needed to round to whole numbers if that's what you want.
        minimumFractionDigits: 2, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
      });
    }

    return formatter;
  };

  const getNarrowCurrencyFormatter = useCallback(() => {
    return getCurrencyFormatter(currencyState, "narrowSymbol");
  }, [currencyState]);

  const getCurrencySymbol = useCallback(() => {
    const formatter = getCurrencyFormatter(currencyState, "code");
    const parts = formatter?.formatToParts(99); // number doesn't matter here
    const currencyIndex = parts?.findIndex((part) => part.type === "currency");

    return parts?.[currencyIndex ?? 0].value;
  }, [currencyState]);

  const FullCurrencyFormatter = getFullCurrencyFormatter();
  const NarrowCurrencyFormatter = getNarrowCurrencyFormatter();

  return {
    initCurrency,
    isCAD,
    isUSD,
    setUSD,
    setCAD,
    FullCurrencyFormatter,
    NarrowCurrencyFormatter,
    getCurrency,
    getCurrencySymbol,
    getCurrencyFormatter,
  };
};
