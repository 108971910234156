import { useCallback } from "react";
import { useStore } from "react-redux";

import { PROJECT_ACTION_ENUM } from "../common/reducers/projects";
import ProjectService from "../service/ProjectService";
import TeamService from "../service/TeamService";

export const useProjectFetcher = () => {
  const { dispatch } = useStore();

  const fetchMyProjectsAndUpdateStore = useCallback(async () => {
    dispatch({
      type: PROJECT_ACTION_ENUM.UPDATE_ALL_PROJECTS_LOADING,
      payload: { loading: true },
    });

    const res = await ProjectService.getAllProjects();

    dispatch({
      type: PROJECT_ACTION_ENUM.UPDATE_ALL_PROJECTS,
      payload: res.data.data || [],
    });
  }, [dispatch]);

  const fetchTeamProjectsAndUpdateStore = useCallback(
    async (teamId: string) => {
      dispatch({
        type: PROJECT_ACTION_ENUM.UPDATE_TEAM_PROJECTS_LOADING,
        payload: { loading: true },
      });

      const res = await TeamService.getProjectsInTeam(teamId);

      dispatch({
        type: PROJECT_ACTION_ENUM.UPDATE_TEAM_PROJECTS,
        payload: res.data.data || [],
      });
    },
    [dispatch]
  );

  const resetTeamProjectsAndUpdateStore = useCallback(async () => {
    dispatch({
      type: PROJECT_ACTION_ENUM.UPDATE_TEAM_PROJECTS,
      payload: [],
    });
  }, [dispatch]);

  return {
    fetchMyProjectsAndUpdateStore,
    fetchTeamProjectsAndUpdateStore,
    resetTeamProjectsAndUpdateStore,
  };
};
