import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Helmet from "react-helmet";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import ProductImage from "../../../components/ProductImage";
import StudioLeftSidebar from "../../StudioSidebarContainer/StudioLeftSidebar";
import StudioRightSidebar from "../../StudioSidebarContainer/StudioRightSidebar";
import PurchasedStudioToolbar from "../../../components/StudioToolbar/PurchasedStudioToolbar";
import styles from "../StudioContainer.module.scss";
import { useDesignState } from "../../../utils/ReactHooks";
import Modal from "../../../components/Modal";
import CreatingCanvaModal from "../../../components/CreatingCanvaModal";
import { ROUTES } from "../../../utils/Constant";
import PurchasedDesignProjectColumn from "../ProjectColumn/PurchasedDesignProjectColumn";
import PreOrderService from "../../../service/PreOrderService";
import { usePurchasedDesignFetcher } from "../../../utils/PurchasedDesignsFetcherHooks";

const PurchasedDesignStudioContainer = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    designId,
    orderId,
    orderType,
    historyFrom = "techpack",
  } = useParams();

  const [purchasedDesign, setPurchasedDesign] = useState(null);
  const [purchasedDesignLoaded, setPurchasedDesignLoaded] = useState(false);

  const userInfo = useSelector((state) => state.userInfo);

  const { fetchPurchasedDesignAsAdmin } = usePurchasedDesignFetcher();

  useEffect(() => {
    async function fetchPurchasedDesign() {
      const fetchedPurchasedDesign =
        await fetchPurchasedDesignAsAdmin(designId);
      setPurchasedDesign(fetchedPurchasedDesign);
      setPurchasedDesignLoaded(true);
    }

    fetchPurchasedDesign();
  }, [designId, fetchPurchasedDesignAsAdmin]);

  //No multiplayer when editing purchased designs
  const socketRef = null;
  const previewContainerRef = null;

  const {
    designDecorationsFromDesignState,
    images,
    designWarning,
    colorWarning,
    deprecatedDesignItems,
    colorBleedingItems,
    isLoading,
    isUpdating,
    hasLoadingFailed,
    loadingFailedErrorKey,
    onDesignChange,
    onAssetTypeChange,
    partsDefinition,
    siblingProducts,
    availableDecorationsDefinition,
    designValueOfSelectedElement,
    selectedPartElementDef,
    designFromDesignState,
    selection,
    onSelectionChange,
  } = useDesignState(socketRef, designId);

  const onDesignChangeAlsoSyncWithOrder = useCallback(
    async (action, data) => {
      onDesignChange(action, data).then(async () => {
        const event = {
          name: "purchased_design_updated", //This needs to be same as the HISTORY_EVENT_ENUM.PURCHASED_DESIGN_UPDATED in backend
          data: {
            new: {
              designId: designId,
              action: action,
              data: data,
            },
          },
        };
        if (orderType === "preorder") {
          await PreOrderService.syncPurchasedPreOrderWithDesign(orderId, event);
        }
      });
    },
    [onDesignChange, orderId, orderType, designId]
  );

  const productName = designFromDesignState?.product.name;
  const designName = purchasedDesign?.original_design_name;

  //If design is not found in backend database, then we go home
  useEffect(() => {
    if (purchasedDesignLoaded && !purchasedDesign) {
      toast.warn(t("design.not_found"));
      navigate(ROUTES.HOME);
      return;
    }
  }, [purchasedDesign, purchasedDesignLoaded, navigate, t]);

  //If loading has failed from designState, we go to home page and let user know there was an issue
  useEffect(() => {
    if (hasLoadingFailed) {
      toast.error(t(`toast.${loadingFailedErrorKey}`), {
        toastId: loadingFailedErrorKey,
      });
      navigate(ROUTES.HOME);
      return;
    }
  }, [hasLoadingFailed, loadingFailedErrorKey, t, navigate]);

  return (
    <div className={styles.container}>
      <Helmet>
        <title>{`${t("pages.design.title", {
          id: designName /*t("products." + productName + ".name")*/,
        })}`}</title>
      </Helmet>
      <div style={{ gridArea: "header" }}>
        <PurchasedStudioToolbar
          className={styles.studioToolBar}
          designName={purchasedDesign?.original_design_name}
          orderId={orderId}
          orderType={orderType}
          historyFrom={historyFrom}
        />
      </div>
      <div style={{ gridArea: "projects" }}>
        <PurchasedDesignProjectColumn
          designs={
            purchasedDesign && images ? [{ ...purchasedDesign, images }] : []
          } //For now we only set 1 design, later we could give all designs of the order...
          selectedDesignId={designId}
          orderId={orderId}
          orderType={orderType}
          historyFrom={historyFrom}
        />
      </div>
      <div className={styles.designContainer}>
        {isLoading || !purchasedDesignLoaded ? (
          <Modal show>
            <CreatingCanvaModal />
          </Modal>
        ) : (
          <React.Fragment>
            {/* {cursorsOfUsersConnected.map((c) => (
              <MultiplayerCursor
                previewContainerRef={previewContainerRef}
                xCoordinates={c.coordinates.x}
                yCoordinates={c.coordinates.y}
                color={c.color}
                key={c.userId}
              />
            ))} */}
            <StudioLeftSidebar
              decorations={designDecorationsFromDesignState}
              designId={designId}
              productName={productName}
              partsDefinition={partsDefinition}
              availableDecorationsDefinition={availableDecorationsDefinition}
              onDesignChange={onDesignChangeAlsoSyncWithOrder}
              onSelectionChange={onSelectionChange}
              designFromDesignState={designFromDesignState}
              selection={selection}
              canUpdateArtworkPosition={true}
              canAddArtwork={true}
              canDeleteArtwork={true}
              deprecatedDesignItems={deprecatedDesignItems}
              colorBleedingItems={colorBleedingItems}
              siblingProducts={siblingProducts}
            />
            <ProductImage
              images={images}
              previewContainerRef={previewContainerRef}
              isUpdating={isUpdating}
              designWarning={designWarning}
              migrationNeeded={false} // Since only designers can access this page, we trust them
              colorWarning={colorWarning}
              onDesignChange={onDesignChange}
            />
            <StudioRightSidebar
              designId={designId}
              productName={productName}
              teamId={purchasedDesign?.original_team_id} //Need to use that team id if we upload a new asset when editing a purchased design
              onDesignChange={onDesignChangeAlsoSyncWithOrder}
              onAssetTypeChange={onAssetTypeChange}
              designValueOfSelectedElement={designValueOfSelectedElement}
              selectedPartElementDef={selectedPartElementDef}
              selection={selection}
              decorations={designDecorationsFromDesignState}
              availableDecorationsDefinition={availableDecorationsDefinition}
              isUpdating={isUpdating}
              canUpdateDesignStyles={true}
              canUpdateDesignColors={true}
              canUpdateArtworkPosition={true}
              canUpdateArtworkColor={true}
              deprecatedDesignItems={deprecatedDesignItems}
              colorBleedingItems={colorBleedingItems}
              isAdmin={userInfo.isAdmin || userInfo.isDesigner}
            />
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

export default PurchasedDesignStudioContainer;
