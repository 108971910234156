import axios from "axios";
import AuthService from "./authService";
const USER_API_BASE_URL = process.env.REACT_APP_BACKEND_URL;

const BASE_ROUTE = "/api/v1/cart";

class CartService {
  static async postCollectSize(data) {
    return axios.post(`${USER_API_BASE_URL}${BASE_ROUTE}/collectSize`, data);
  }

  static async addToCart(data) {
    return axios.post(
      USER_API_BASE_URL + BASE_ROUTE + "/item",
      data,
      this.getAuthHeader()
    );
  }

  static async updateCollectable(data) {
    return axios.put(
      USER_API_BASE_URL + BASE_ROUTE + "/collectable",
      data,
      this.getAuthHeader()
    );
  }

  static async getCartItems() {
    return axios.get(
      `${USER_API_BASE_URL}${BASE_ROUTE}/items`,
      this.getAuthHeader()
    );
  }

  static async updateCartSizes(data) {
    return axios.put(
      `${USER_API_BASE_URL}${BASE_ROUTE}/items/sizes`,
      data,
      this.getAuthHeader()
    );
  }

  static async removeCartItem(id) {
    return axios.delete(
      `${USER_API_BASE_URL}${BASE_ROUTE}/item/${id}`,
      this.getAuthHeader()
    );
  }

  static getAuthHeader() {
    return { headers: { "x-access-token": AuthService.getJWT() } };
  }
}

export default CartService;
