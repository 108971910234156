import React, { useCallback, useEffect, useMemo, useState } from "react";
import styles from "./purchasedtemSizesDetails.module.scss";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import _ from "lodash";

const PurchasedItemSizesDetails = (props) => {
  const { onUpdateSizesQty = null, sizes } = props;

  const [isEditing, setEditing] = useState(false);
  const [sizesState, setSizesState] = useState([]);

  //Initial loading
  useEffect(() => {
    setSizesState(sizes);
  }, [sizes, setSizesState]);

  //DP TODO: Not sure if we want to use sizes or sizeState here
  const qty = useMemo(() => {
    return sizesState.reduce((cumul, size) => cumul + parseInt(size.qty), 0);
  }, [sizesState]);

  const { t } = useTranslation();

  const onClickEditSizesQty = useCallback(
    (e) => {
      e.preventDefault();

      setEditing(true);
    },
    [setEditing]
  );

  /**
   *
   */
  const onClickSaveSizesQty = useCallback(
    (e) => {
      e.preventDefault();

      onUpdateSizesQty(sizesState).then(() => {
        setEditing(false);
      });
    },
    [setEditing, onUpdateSizesQty, sizesState]
  );

  /**
   *
   */
  const onClickCancelEdit = useCallback(
    (e) => {
      e.preventDefault();

      //Reset to original values
      setSizesState(sizes);

      setEditing(false);
    },
    [setEditing, setSizesState, sizes]
  );

  const onSizeChange = useCallback(
    (sizeName, sizeQty) => {
      setSizesState((oldSizes) => {
        const newSizes = _.cloneDeep(oldSizes);

        const sizeToUpdate = newSizes.find((size) => size.name === sizeName);
        sizeToUpdate.qty = parseInt(sizeQty);

        return newSizes;
      });
    },
    [setSizesState]
  );

  return (
    <div className={styles.sizeSelection}>
      <div className={styles.tableRow}>
        <div className={styles.quantityAndPrice}>
          <span>
            {onUpdateSizesQty && !isEditing && (
              <Link to="" onClick={(e) => onClickEditSizesQty(e)}>
                {t("preorder.edit_sizes")}
              </Link>
            )}
            {isEditing && (
              <React.Fragment>
                <Link to="" onClick={(e) => onClickSaveSizesQty(e)}>
                  {t("preorder.save_sizes")}
                </Link>{" "}
                <Link to="" onClick={(e) => onClickCancelEdit(e)}>
                  {t("preorder.cancel_edit")}
                </Link>
              </React.Fragment>
            )}
          </span>
          <span>
            {t("order.total")} {qty} {t("order.units")}
          </span>
        </div>
        {sizes.map((size) => (
          <div className={styles.sizeField} key={size.name}>
            <div className={styles.sizeName}>
              <b style={{ textTransform: "uppercase" }}>{size.name}</b>
            </div>
            {isEditing ? (
              <div className={styles.sizeQty} key={size.name}>
                <input
                  type="number"
                  min="0"
                  style={styles.editableSize}
                  defaultValue={size.qty}
                  onChange={(event) =>
                    onSizeChange(size.name, event.target.value)
                  }
                />
              </div>
            ) : (
              <div className={styles.sizeQty}>{size.qty}</div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default PurchasedItemSizesDetails;
