import { useCallback } from "react";
import PurchasedDesignService from "../service/PurchasedDesignService";

export const usePurchasedDesignFetcher = () => {
  const fetchPurchasedDesignAsAdmin = useCallback(async (designId) => {
    const {
      data: { data: fetchedPurchasedDesign },
    } = await PurchasedDesignService.getDesignAsAdmin(designId);

    return fetchedPurchasedDesign;
  }, []);

  return {
    fetchPurchasedDesignAsAdmin,
  };
};
