import { Skeleton } from "@mui/material";
import { Box } from "../Box";
import { ContentRepeater } from "../ContentRepeater";

export const LeftSideBarSkeleton = () => {
  return (
    <Box width={"100%"} display="flex" flexDirection="column" mr={1}>
      <ContentRepeater repeatCount={3}>
        <Box mb={1}>
          <Skeleton variant="text" sx={{ width: "85%" }} />
          <Skeleton variant="text" sx={{ width: "100%" }} />
          <Skeleton variant="text" sx={{ width: "65%" }} />
          <Skeleton variant="text" sx={{ width: "75%" }} />
        </Box>
      </ContentRepeater>
    </Box>
  );
};
