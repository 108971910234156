import React, { PropsWithChildren } from "react";
import { FormContext } from "../../../context";
import { FormikPropsWithCustomError } from "../../../context/Form/FormContext";

interface Props extends PropsWithChildren {
  formik: FormikPropsWithCustomError;
  formProps?: React.DetailedHTMLProps<
    React.FormHTMLAttributes<HTMLFormElement>,
    HTMLFormElement
  >;
}

export const Form = ({ formik, children, formProps }: Props) => {
  return (
    <FormContext.Provider value={{ formik }}>
      <form {...formProps} onSubmit={formik.handleSubmit}>
        {children}
      </form>
    </FormContext.Provider>
  );
};
