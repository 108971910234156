import React from "react";
import { components } from "react-select";

import styles from "./Input.module.scss";

const Input = ({ children, ...props }) => {
  return <components.Input {...props} className={styles.input} />;
};

export default Input;
