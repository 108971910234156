import axios from "axios";
import authService from "./authService";
import { Cart, Currency } from "../types";
const USER_API_BASE_URL = process.env.REACT_APP_BACKEND_URL;

const BASE_ROUTE = "/api/v1/pricing";

class PricingService {
  async getCartPricing(data: { currency: Currency }) {
    const result = await axios.post(
      `${USER_API_BASE_URL}${BASE_ROUTE}/cart`,
      data,
      authService.getAuthHeader()
    );
    return result.data.data as Cart;
  }
}

const instance = new PricingService();
export default instance;
