import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
/**
 * Create a Tooltip with custom style
 */
// const DarkTooltip = withStyles((theme) => ({
//   tooltip: {
//     backgroundColor: "#222222",
//     color: "#F9F9F9",
//     //boxShadow: theme.shadows[1],
//     fontSize: "0.75rem",
//   },
// }))(Tooltip);

const DarkTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));
export default DarkTooltip;
