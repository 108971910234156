export const routeFormatter = (route, options = {}) => {
  let formattedRoute = route;

  const { query = {}, params = {} } = options;

  formattedRoute = Object.entries(params).reduce(
    (prev, curr) => prev.replace(`:${curr[0]}`, curr[1]),
    formattedRoute
  );

  formattedRoute = Object.entries(query).reduce(
    (prev, curr, index) =>
      `${prev}${index === 0 ? "?" : "&"}${curr[0]}=${curr[1]}`,
    formattedRoute
  );

  return formattedRoute;
};
