import { CircularProgress } from "@mui/material";
import React, { useMemo, useCallback, useState, useEffect } from "react";
import fileDownload from "js-file-download";
import { useTranslation } from "react-i18next";
import { DESIGN_CHANGE_ENUM } from "../../../../utils/ReactHooks";
import RightSidebar from "../../../../common/components/RightSidebar";
import UploadFileSidebar from "./UploadFileSidebar";
import ImageService from "../../../../service/ImageService";
import styles from "../StudioRightSidebar.module.scss";
import AssetFileBox from "../../../../components/AssetFileBox";
import AssetPositionBox from "../../../../components/AssetPositionBox";
import { PreviewGlassMagnifier } from "./PreviewGlassMagnifier";
import AssetTypeDropdownSelector from "../../../../components/AssetTypeDropdownSelector";
import ControlSidebar from "./ControlSidebar";
import RepeatingPrintSidebar from "./RepeatingPrintSidebar";

const DecorationRightSidebar = (props) => {
  const {
    designId,
    teamId,
    decorations,
    onDesignChange,
    onAssetTypeChange,
    selection,
    availableDecorationsDefinition,
    canUpdateArtworkPosition,
    canUpdateArtworkColor,
    isAdmin,
  } = props;

  const { t } = useTranslation();

  const { selectedElementName } = selection;
  const [loadingAsset, setLoadingAsset] = useState(true);
  const [imageData, setImageData] = useState(null);
  const [previewImageData, setPreviewImageData] = useState(null);

  const decoration = useMemo(() => {
    return decorations.find((d) => d.id === selectedElementName);
  }, [decorations, selectedElementName]);

  const decorationDefinition = useMemo(() => {
    // If the type is defined, we can display all the controls
    if (decoration?.type) {
      return availableDecorationsDefinition.find(
        (d) => d.id === decoration.type
      );
    }
    // Otherwise, we should
    return null;
  }, [availableDecorationsDefinition, decoration?.type]);

  const availableDecorationsDefVisible = useMemo(() => {
    if (availableDecorationsDefinition && decoration) {
      const availableDecorationDefInGroup = availableDecorationsDefinition
        .filter((d) => d.group === decoration?.group)
        .sort((a, b) => a?.display?.listOrder - b?.display?.listOrder);
      if (isAdmin) {
        return availableDecorationDefInGroup;
      }
      return availableDecorationDefInGroup.filter(
        (decoType) => !decoType?.adminOnly
      );
    }
    return [];
  }, [availableDecorationsDefinition, decoration, isAdmin]);

  useEffect(() => {
    if (decoration?.assetId) {
      setLoadingAsset(true);

      const getAssetPromise = isAdmin
        ? ImageService.getAssetAsAdmin(
            decoration.assetId,
            decoration.assetVersionId,
            decoration.type,
            null
          )
        : ImageService.getAsset(
            decoration.assetId,
            decoration.assetVersionId,
            teamId,
            decoration.type,
            null
          );

      getAssetPromise
        .then((data) => {
          setImageData(data);
        })
        .catch(() => {
          setImageData(null);
        })
        .finally(() => setLoadingAsset(false));
    } else {
      setLoadingAsset(false);
      setImageData(null);
    }
  }, [
    decoration?.assetId,
    decoration?.assetVersionId,
    decoration?.type,
    decoration?.latestUpdate,
    teamId,
    isAdmin,
  ]); //latestUpdate is required here to fetch the new image on update

  const onAssetResetChange = useCallback(
    (decoration) => {
      return onDesignChange(DESIGN_CHANGE_ENUM.ON_REINITIALIZE, {
        decorationId: decoration.id,
      });
    },
    [onDesignChange]
  );

  const onDownloadOriginal = useCallback((decoration) => {
    if (decoration?.assetId && decoration?.assetVersionId) {
      ImageService.getAssetAsAdmin(
        decoration.assetId,
        decoration.assetVersionId,
        "original"
      )
        .then((data) => {
          ImageService.getS3ImageContent(data.originalUrl)
            .then((res) => {
              fileDownload(
                res.data,
                data.assetName,
                res.headers["content-type"]
              );
            })
            .catch((e) => {
              console.error("Unable to download the image from the link");
            });
          // -------------------------------------------------------------------
          // // I tried using js-file-download. It worked locally,
          // // but for some reason, it corrupts the first byte on png and jpeg when
          // // on the server... (commit b6ff545647ac789d6c898fb7093ef1cb17a926e2)
          // const link = document.createElement("a");
          // link.href = data.originalUrl;
          // link.download = data.assetName;
          // link.target = "_blank";
          // link.click();
          // link.parentNode.removeChild(link);
        })
        .catch((e) => {
          console.error("Error getting the download link. Are you a designer?");
        });
    }
  }, []);

  const onAssetTypeSelectionChange = useCallback(
    async ({ assetType, assetId, decorationId, name }) => {
      setLoadingAsset(true);

      const retAssetTypeChange = await onAssetTypeChange({
        assetType,
        assetId,
        decorationId,
        name,
      });
      setLoadingAsset(false);
      if (retAssetTypeChange?.errorKey) {
        //DP Todo, where do we put the error?
      }
    },
    [onAssetTypeChange]
  );

  if (!decoration) {
    return (
      <RightSidebar>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            paddingTop: "2rem",
          }}
        >
          <CircularProgress />
        </div>
      </RightSidebar>
    );
  }

  //If no decoration is set, then we show the file upload sidebar
  if (!decoration.assetId) {
    //For now we kinda hardcode and check for design templates only if there is 1 availableDecorationsDefVisible
    let designTemplates = [];
    if (
      availableDecorationsDefVisible.length === 1 &&
      availableDecorationsDefVisible[0]?.designTemplates
    ) {
      designTemplates = availableDecorationsDefVisible[0]?.designTemplates;
    }

    return (
      <UploadFileSidebar
        decorationId={decoration?.id}
        designId={designId}
        teamId={teamId}
        onDesignChange={onDesignChange}
        availableDecorationDef={availableDecorationsDefVisible}
        isAdmin={isAdmin}
        designTemplates={designTemplates}
      />
    );
  } else {
    // Else we show the decoration.

    let designTemplates = [];
    const decoTypeDef = availableDecorationsDefVisible.find(
      (decoDef) => decoDef.id === decoration.type
    );
    if (decoTypeDef.designTemplates) {
      designTemplates = decoTypeDef.designTemplates;
    }

    // The top of the decoration sidebar is always the same regardless of the deco type.
    return (
      <RightSidebar>
        <div className={styles.rightDecorationSectionContainer}>
          {/* If the asset is loading, show the circular progress */}
          {loadingAsset ? (
            <div className={styles.loadingProgress}>
              <CircularProgress />
            </div>
          ) : (
            // When the asset is done loading, show the asset controls.
            <React.Fragment>
              <AssetFileBox
                canUpdateArtworkPosition={canUpdateArtworkPosition}
                thumbnailUrl={imageData?.fullSizeUrl}
                assetName={imageData?.assetName}
                onDesignChange={onDesignChange}
                onAssetResetChange={onAssetResetChange}
                decoration={decoration}
                onDownloadOriginal={onDownloadOriginal}
                isAdmin={isAdmin}
                designTemplates={designTemplates}
              ></AssetFileBox>

              {/* Only display in case there is an actual selection possible */}
              {availableDecorationsDefVisible.length > 1 && (
                <AssetTypeDropdownSelector
                  assetFileExtension={imageData?.assetName.substr(
                    imageData?.assetName.lastIndexOf(".")
                  )}
                  decoration={decoration}
                  availableDecorationsDefinition={
                    availableDecorationsDefVisible
                  }
                  onAssetTypeSelectionChange={onAssetTypeSelectionChange}
                  isAdmin={isAdmin}
                />
              )}

              <AssetPositionBox
                canUpdateArtworkPosition={canUpdateArtworkPosition}
                onDesignChange={onDesignChange}
                decoration={decoration}
              ></AssetPositionBox>

              {!!decorationDefinition.repeatingPatternOptions && (
                <RepeatingPrintSidebar
                  decoration={decoration}
                  teamId={teamId}
                  onDesignChange={onDesignChange}
                  decorationDefinition={decorationDefinition}
                  canUpdateArtworkPosition={canUpdateArtworkPosition}
                  canUpdateArtworkColor={canUpdateArtworkColor}
                  setPreviewImageData={setPreviewImageData}
                  isAdmin={isAdmin}
                />
              )}

              {/* Controls specific to the decoration type */}
              <ControlSidebar
                decoration={decoration}
                decorationDefinition={decorationDefinition}
                teamId={teamId}
                onDesignChange={onDesignChange}
                canUpdateArtworkPosition={canUpdateArtworkPosition}
                canUpdateArtworkColor={canUpdateArtworkColor}
                setPreviewImageData={setPreviewImageData}
                imageData={imageData}
                isAdmin={isAdmin}
              />

              {/* Preview */}
              <div className={styles.preview}>
                <h6>{t("specs.preview")}</h6>
                <PreviewGlassMagnifier
                  imageUrl={
                    previewImageData?.url || previewImageData?.fullSizeUrl
                  } // Depending on the decoration, either url or fullSizeUrl will be set
                  imageQuality={previewImageData?.quality || "acceptable"}
                  noBackgroundColor={false}
                />
              </div>
            </React.Fragment>
          )}
        </div>
      </RightSidebar>
    );
  }
};

export default DecorationRightSidebar;
