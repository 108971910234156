import { useCallback } from "react";
import TechpackService from "../service/TechpackService";

export const useTechpackFetcher = () => {
  //Will fetch techpack of the order request with colors expanded and with decorations' assets' urls
  const fetchPreOrderTechpack = useCallback(async (preorderId) => {
    const res = await TechpackService.getPreOrderTechpack({
      preorderId,
      includeImagesUrl: true,
    });

    return res.data.data;
  }, []);

  //Will fetch techpack of the order request with colors expanded and with decorations' assets' urls
  const fetchOrderTechpack = useCallback(async (orderId) => {
    const res = await TechpackService.getOrderTechpack({
      orderId,
      includeImagesUrl: true,
    });

    return res.data.data;
  }, []);

  const fetchTechpackPdfLink = useCallback(async (techpackId) => {
    const res = await TechpackService.getTechpackPdfLink({
      techpackId,
    });

    return res.data.data;
  }, []);

  return {
    fetchPreOrderTechpack,
    fetchOrderTechpack,
    fetchTechpackPdfLink,
  };
};
