import axios from "axios";
import authService from "./authService";
const FormData = require("form-data");

const UPLOAD_URL = "/upload_url";
const UPLOAD_URL_AS_ADMIN = "/upload_url_with_admin";

const MAX_FILE_SIZE = 1024 * 1024 * 100;

class ImageService {
  static async uploadPublicImage(file, teamId) {
    if (!file) {
      throw new Error("Please select an image");
    }

    if (file.size > MAX_FILE_SIZE) {
      throw new Error("File is too big");
    }
    const filename = file.name;

    // 1. Call pour le presigned URL + le UUID
    let postUploadUrlFormdata = new FormData();
    postUploadUrlFormdata.append("filename", filename);
    postUploadUrlFormdata.append("team_id", teamId);
    postUploadUrlFormdata.append("public", true);

    const {
      data: { url, assetId },
    } = await axios.post(
      `${process.env.REACT_APP_ASSET_MANAGER_URL}${UPLOAD_URL}`,
      postUploadUrlFormdata,
      authService.getAuthHeader("application/x-www-form-urlencoded")
    );

    // 2. Upload à S3 de l'image avec le presigned URL
    const fetchResponse = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": file.type,
        processData: false,
      },
      body: file,
    });

    if (fetchResponse.status !== 200) {
      throw new Error("Image upload has failed");
    }

    const etag = fetchResponse.headers.get("etag");

    // 3. Prendre le UUID et l'envoyer au Design State
    let form = new FormData();
    form.append("asset_id", assetId);
    form.append("asset_type", "screenprint");
    form.append("etag", etag);
    const retCreate = await fetch(
      `${process.env.REACT_APP_ASSET_MANAGER_URL}/create_asset`,
      {
        body: form,
        method: "POST",
      }
    ).then((response) => response.json());

    const assetVersionId = retCreate.assetVersionId;

    return { assetId, assetVersionId };
  }

  static async getAsset(assetId, assetVersionId, teamId, type, size) {
    const params = {
      asset_id: assetId,
      asset_version_id: assetVersionId,
      team_id: teamId,
    };
    if (size) {
      params.size = size;
    }

    const headers = authService.getAuthHeader();
    headers.params = params;
    const res = await axios.get(
      `${process.env.REACT_APP_ASSET_MANAGER_URL}/asset/${type}`,
      headers
    );
    return res.data;
  }

  static async getAssetAsAdmin(assetId, assetVersionId, type, size) {
    const params = {
      asset_id: assetId,
      asset_version_id: assetVersionId,
      size: size,
    };

    const headers = authService.getAuthHeader();
    headers.params = params;
    const res = await axios.get(
      `${process.env.REACT_APP_ASSET_MANAGER_URL}/asset_with_admin/${type}`,
      headers
    );
    return res.data;
  }

  static async getS3ImageContent(url) {
    const res = await axios.get(url, {
      responseType: "blob",
    });
    return res;
  }

  static async getRepeatingPrintImage(
    assetId,
    assetVersionId,
    assetType,
    patternName,
    horizontalSpacingInches,
    verticalSpacingInches,
    maxImageWidthInches,
    maxImageHeightInches,
    horizontalOffsetInches,
    verticalOffsetInches,
    horizontalOddRowOffset
  ) {
    const data = {
      assetId,
      assetVersionId,
      assetType,
      repeatingDetails: {
        patternName,
        horizontalSpacingInches,
        verticalSpacingInches,
        maxImageWidthInches,
        maxImageHeightInches,
        horizontalOffsetInches,
        verticalOffsetInches,
        horizontalOddRowOffset,
      },
      regenerate: false,
    };

    const res = await axios.post(
      `${process.env.REACT_APP_ASSET_MANAGER_GENERATOR_URL}/generate/repeatingpattern`,
      data,
      authService.getAuthHeader()
    );
    return res.data;
  }

  static async getTagImage(
    assetId,
    assetVersionId,
    assetType,
    tagName,
    textureName,
    backgroundColorName = null,
    planeAngle = null
  ) {
    const data = {
      assetId,
      assetVersionId,
      assetType,
      tagDetails: {
        tagName,
        textureName,
        backgroundColorName,
        planeAngle,
      },
    };

    const res = await axios.post(
      `${process.env.REACT_APP_ASSET_MANAGER_GENERATOR_URL}/generate/tag`,
      data,
      authService.getAuthHeader()
    );
    return res.data;
  }

  static async getEmbroideryPatchImage(
    assetId,
    assetVersionId,
    assetType,
    tagName,
    textureName,
    backgroundColorName,
    stitchColorName,
    logoColorNames
  ) {
    const data = {
      assetId,
      assetVersionId,
      assetType,
      tagDetails: {
        tagName,
        textureName,
        backgroundColorName,
        stitchColorName,
        logoColorNames,
      },
    };

    const res = await axios.post(
      `${process.env.REACT_APP_ASSET_MANAGER_GENERATOR_URL}/generate/embroiderypatch`,
      data,
      authService.getAuthHeader()
    );
    return res.data;
  }

  static async getPublicImage(assetId) {
    const params = {
      asset_id: assetId,
    };
    const res = await axios.get(
      `${process.env.REACT_APP_ASSET_MANAGER_URL}/public_image`,
      { params }
    );
    return res.data;
  }

  static async uploadAsset(file, teamId) {
    const filename = file.name;

    // 1. Call pour le presigned URL + le UUID
    let postUploadUrlFormdata = new FormData();
    postUploadUrlFormdata.append("filename", filename);
    postUploadUrlFormdata.append("team_id", teamId);
    postUploadUrlFormdata.append("public", false);

    const {
      data: { url, assetId },
    } = await axios.post(
      `${process.env.REACT_APP_ASSET_MANAGER_URL}${UPLOAD_URL}`,
      postUploadUrlFormdata,
      authService.getAuthHeader("application/x-www-form-urlencoded")
    );

    // 2. Upload à S3 de l'image avec le presigned URL
    const fetchUploadResponse = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": file.type,
        processData: false,
      },
      body: file,
    });

    const etag = fetchUploadResponse.headers.get("etag");

    return { assetId, etag };
  }

  static async uploadAssetAsAdmin(file, teamId) {
    const filename = file.name;

    // 1. Call pour le presigned URL + le UUID
    let postUploadUrlFormdata = new FormData();
    postUploadUrlFormdata.append("filename", filename);
    postUploadUrlFormdata.append("public", false);
    postUploadUrlFormdata.append("team_id", teamId);
    const {
      data: { url, assetId },
    } = await axios.post(
      `${process.env.REACT_APP_ASSET_MANAGER_URL}${UPLOAD_URL_AS_ADMIN}`,
      postUploadUrlFormdata,
      authService.getAuthHeader( "application/x-www-form-urlencoded")
    );

    // 2. Upload à S3 de l'image avec le presigned URL
    const fetchUploadResponse = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": file.type,
        processData: false,
      },
      body: file,
    });

    const etag = fetchUploadResponse.headers.get("etag");

    return { assetId, etag };
  }

  //DP FIXME: Devrait etre getOrcreate? et le assetmanager devrait aussi avoir un getorcreate?
  static async createAsset(assetId, assetType, etag) {
    let form = new FormData();
    form.append("asset_id", assetId);
    form.append("asset_type", assetType);
    form.append("etag", etag);

    const createAssetRet = await fetch(
      `${process.env.REACT_APP_ASSET_MANAGER_URL}/create_asset`,
      {
        body: form,
        method: "POST",
      }
    ).then((response) => response.json());

    return {
      assetId,
      assetType,
      assetVersionId: createAssetRet.assetVersionId,
      errorKey: createAssetRet.errorKey,
    };
  }

  static async getColorCodes(decorationType) {
    const { data } = await axios.get(
      `${process.env.REACT_APP_ASSET_MANAGER_URL}/color_palette/${decorationType}`,
      authService.getAuthHeader()
    );

    return data.map((d) => ({
      name: d.name,
      hex: d.hex,
      label: d.label,
      techpackColorName: d.techpackColorName,
      chartName: d.chartName,
      disabled: d.disabled,
    }));
  }
}

export default ImageService;
