import React from "react";
import SideMenuElements from "./SideMenuElements";

const Elements = (props) => {
  const {
    partDefinition,
    onSelectionChange,
    selection: { selectedPart, selectedElementName },
    designFromDesignState,
    productName,
    deprecatedDesignItems,
    colorBleedingItems,
  } = props;

  //find the part in the design
  const partDesign = designFromDesignState.parts.find(
    (p) => p.name === partDefinition.name
  );

  const partElements = partDefinition.elements
    //.filter((a) => a.required === true)
    .map((elementDef) => {
      //See if the the element is in the partDesign (should be), and see if it's enabled or not
      let isEnabled = true;
      let reasonForBeingDisabled = { msg: "" };
      let onClickFunction = () =>
        onSelectionChange(partDefinition.name, elementDef.name);
      if (partDesign) {
        const elementDesign = partDesign.elements.find(
          (elementDesign) => elementDesign.name === elementDef.name
        );

        if (elementDesign) {
          isEnabled = elementDesign.enabled;
        }

        if (!isEnabled) {
          onClickFunction = null;
          //For now we simplify and only show the first reason why disabled
          if (
            elementDesign.reasonForBeingDisabled &&
            elementDesign.reasonForBeingDisabled.length > 0
          ) {
            reasonForBeingDisabled = elementDesign.reasonForBeingDisabled[0];
          }
        }
      }

      const deprecatedChildItems = deprecatedDesignItems?.filter(
        (d) => d.elementName === elementDef.name
      );

      const colorBleedingChildItems = colorBleedingItems?.filter(
        (d) => d.elementName === elementDef.name
      );

      return {
        onClick: onClickFunction,
        productName: productName,
        name: elementDef.name,
        labelKey: `specs.${productName}.${elementDef.name}`,
        isSelected:
          partDefinition.name === selectedPart &&
          elementDef.name === selectedElementName,
        display: elementDef.display,
        enabled: isEnabled,
        reasonForBeingDisabled: reasonForBeingDisabled,
        deprecatedDesignItems: deprecatedChildItems,
        colorBleedingItems: colorBleedingChildItems,
      };
    });

  //filteredElements is all elements except the main element
  const filteredElements = partElements.filter(
    (e) => e.name !== partDefinition.mainElement
  );
  const mainElement = partElements.find(
    (e) => e.name === partDefinition.mainElement
  );

  return (
    <SideMenuElements
      productName={productName}
      partDefinitionName={partDefinition.name}
      elements={filteredElements}
      mainElement={mainElement}
    />
  );
};

export default Elements;
