import { Action } from "../../types/redux";
import { Currency } from "../../types/index";

export enum PROFILE_INFO_ACTION_ENUM {
  UPDATE_USER_PREFERENCE_CURRENCY = "update_currency",
}

interface ProfileState {
  currency: Currency;
}

const initialState: ProfileState = {
  currency: "cad",
};

const reducer = (
  state = initialState,
  action: Action<PROFILE_INFO_ACTION_ENUM, Currency>
) => {
  switch (action.type) {
    case PROFILE_INFO_ACTION_ENUM.UPDATE_USER_PREFERENCE_CURRENCY:
      return { ...state, currency: action.payload };
    default:
      return state;
  }
};

export default reducer;
