import React, { useCallback, useEffect, useState } from "react";
import fileDownload from "js-file-download";
import styles from "./techpackArtwork.module.scss";
import { useTranslation } from "react-i18next";
import { ARTWORK_TYPES, BEHAVES_LIKE } from "../../../utils/Constant";
import TechpackArtworkDescription from "./TechpackArtworkDescription";
import TechpackArtworkImage from "./TechpackArtworkImage";
import TechpackService from "../../../service/TechpackService";
import ImageService from "../../../service/ImageService";
import TechpackCustomColor from "../TechpackCustomColor";

const TechpackArtwork = (props) => {
  const {
    techpackId,
    techpackItemId,
    techpackItemArtwork,
    techpackItemArtworkIndex,
    techpackItemArtworkCount,
    productName,
    decoration,
    decorationDefinition,
    setSaving,
  } = props;

  const { t } = useTranslation();

  const [errorCode, setErrorCode] = useState(null);
  const [productionDecoration, setProductionDecoration] = useState(null);

  const techpackItemArtworkId = techpackItemArtwork.id;
  const customColors = techpackItemArtwork.techpackitemartworkcustomcolors;

  //Initial load of the productionDecoration
  useEffect(() => {
    setProductionDecoration(techpackItemArtwork.production_decoration);
  }, [techpackItemArtwork.production_decoration]);

  /**
   *
   */
  const productionBackgroundColorUpdate = useCallback(
    async (newBackgroundColor) => {
      const productionArtwork = {
        techpackId,
        techpackItemId,
        techpackItemArtworkId: techpackItemArtwork.id,
        background: { color: newBackgroundColor },
      };

      setSaving(true);
      const prodUpdated =
        await TechpackService.updateProductionArtworkBackgroundColor(
          productionArtwork
        );
      setSaving(false);
      return prodUpdated;
      //return prodUpdated.data.data.production_decoration;
    },
    [techpackId, techpackItemId, techpackItemArtwork.id, setSaving]
  );

  /**
   *
   */
  const previewBackgroundColorUpdate = useCallback(
    async (newBackgroundColor) => {
      const productionArtwork = {
        techpackId,
        techpackItemId,
        techpackItemArtworkId: techpackItemArtwork.id,
        background: { color: newBackgroundColor },
      };

      setSaving(true);
      const prodUpdated =
        await TechpackService.updatePreviewArtworkBackgroundColor(
          productionArtwork
        );
      setSaving(false);
      return prodUpdated;
      //return prodUpdated.data.data.production_decoration;
    },
    [techpackId, techpackItemId, techpackItemArtwork.id, setSaving]
  );

  /**
   *
   */
  const originalBackgroundColorUpdate = useCallback(
    async (newBackgroundColor) => {
      const productionArtwork = {
        techpackId,
        techpackItemId,
        techpackItemArtworkId: techpackItemArtwork.id,
        background: { color: newBackgroundColor },
      };

      setSaving(true);
      const prodUpdated =
        await TechpackService.updateOriginalArtworkBackgroundColor(
          productionArtwork
        );
      setSaving(false);
      return prodUpdated;
      //return prodUpdated.data.data.production_decoration;
    },
    [techpackId, techpackItemId, techpackItemArtwork.id, setSaving]
  );

  /**
   *
   */
  const createProductionAssetFromFile = useCallback(
    async (assetId, etag, file) => {
      const assetType = ARTWORK_TYPES.TECHPACK_PRODUCTION;

      //Create the asset type
      const { assetVersionId, errorKey } = await ImageService.createAsset(
        assetId,
        assetType,
        etag
      );

      if (errorKey) {
        throw new Error(errorKey);
      }

      const productionArtwork = {
        techpackId,
        techpackItemId,
        techpackItemArtworkId,
        assetId,
        assetVersionId,
        assetType,
        includeImagesUrl: true,
      };

      const prodUpdated = await TechpackService.updateProductionArtwork(
        productionArtwork
      );

      return prodUpdated.data.data.production_decoration;
    },
    [techpackId, techpackItemId, techpackItemArtworkId]
  );

  /**
   *
   */
  const uploadFileAndCreateProductionAsset = useCallback(
    async ({ file, onSuccess }) => {
      if (!file) {
        const errorKey = "noFileGiven";
        setErrorCode(errorKey);
        return;
      }

      setSaving(true);

      const { assetId, etag } = await ImageService.uploadAssetAsAdmin(file);
      try {
        const newProductionDecoration = await createProductionAssetFromFile(
          assetId,
          etag,
          file
        );
        setProductionDecoration(newProductionDecoration);
        setErrorCode(null);
      } catch (e) {
        setProductionDecoration(null);
        setErrorCode(e.message);
        //setUploadErrorMsg(errorKey);
      } finally {
        setSaving(false);
      }

      onSuccess();
    },
    [
      setErrorCode,
      createProductionAssetFromFile,
      setProductionDecoration,
      setSaving,
    ]
  );

  /**
   *
   */
  const downloadUrl = useCallback((imageUrl, assetName) => {
    ImageService.getS3ImageContent(imageUrl)
      .then((res) => {
        fileDownload(res.data, assetName, res.headers["content-type"]);
      })
      .catch((e) => {
        console.error("Unable to download the image from the link");
      });
  }, []);

  /**
   *
   */
  const openInNewTab = useCallback((url) => {
    window.open(url, "_blank");
  }, []);

  /**
   * For upload
   */
  const acceptedFilesTitle = t("general.supported_files", {
    supported_extensions: ".eps, .svg",
  });
  const acceptedFileExtensions = [".eps", ".svg", ".png"];

  return (
    <div className={styles.decorationContainer}>
      <div className={styles.decorationTitle}>
        <span className={styles.decorationName}>
          {t(`techpack.artwork`)}{" "}
          {`(${techpackItemArtworkIndex + 1}/${techpackItemArtworkCount})`}
        </span>
        {/* &nbsp;
        <span className={styles.decorationFilename}>
          {decoration.assetOriginal.assetName}
        </span> */}
      </div>
      <div className={styles.decorationRow}>
        <TechpackArtworkDescription
          decoration={decoration}
          productName={productName}
          decorationDefinition={decorationDefinition}
          productionDecoration={productionDecoration}
          calibrationColorsEnabled={!!productionDecoration}
          techpackId={techpackId}
          techpackItemId={techpackItemId}
          techpackItemArtwork={techpackItemArtwork}
          setSaving={setSaving}
        />

        <div className={styles.decorationSource}>
          <TechpackArtworkImage
            techpackId={techpackId}
            techpackItemId={techpackItemId}
            techpackItemArtworkId={techpackItemArtworkId}
            imageUrl={decoration.assetOriginal.fullSizeUrl}
            downloadUrl={decoration.assetOriginal.originalUrl}
            assetName={decoration.assetOriginal.assetName}
            title={t("techpack.source_file")}
            backgroundColor={
              techpackItemArtwork?.original_decoration_background?.color
            }
            allowDownload={true}
            onDownloadUrl={() =>
              downloadUrl(
                decoration.assetOriginal.originalUrl,
                decoration.assetOriginal.assetName
              )
            }
            onBackgroundColorChange={originalBackgroundColorUpdate}
          />
        </div>

        <div className={styles.decorationPreview}>
          {(decorationDefinition.behavesLike === BEHAVES_LIKE.WOVEN_TAG_TYPE ||
            decorationDefinition.behavesLike ===
              BEHAVES_LIKE.LEATHER_TAG_TYPE ||
            decorationDefinition.behavesLike ===
              BEHAVES_LIKE.EMBROIDERY_PATCH_TYPE) &&
          decoration.assetGeneratedTag ? (
            <TechpackArtworkImage
              techpackId={techpackId}
              techpackItemId={techpackItemId}
              techpackItemArtworkId={techpackItemArtworkId}
              imageUrl={decoration.assetGeneratedTag.url}
              title={t("techpack.preview_file")}
              backgroundColor={
                techpackItemArtwork?.preview_decoration_background?.color
              }
              allowOpenInNewTab={true}
              noBackgroundColor={true}
              onOpenInNewTab={() =>
                openInNewTab(decoration.assetGeneratedTag.url)
              }
              onBackgroundColorChange={previewBackgroundColorUpdate}
            />
          ) : (
            <TechpackArtworkImage
              techpackId={techpackId}
              techpackItemId={techpackItemId}
              techpackItemArtworkId={techpackItemArtworkId}
              imageUrl={decoration.asset.fullSizeUrl}
              title={t("techpack.preview_file")}
              backgroundColor={
                techpackItemArtwork?.preview_decoration_background?.color
              }
              allowOpenInNewTab={true}
              onOpenInNewTab={() => openInNewTab(decoration.asset.fullSizeUrl)}
              onBackgroundColorChange={previewBackgroundColorUpdate}
            />
          )}
        </div>

        <div className={styles.decorationProduction}>
          <TechpackArtworkImage
            techpackId={techpackId}
            techpackItemId={techpackItemId}
            techpackItemArtworkId={techpackItemArtworkId}
            imageUrl={productionDecoration?.asset.fullSizeUrl}
            downloadUrl={productionDecoration?.assetOriginal.originalUrl}
            title={t("techpack.production_file")}
            errorCode={errorCode}
            backgroundColor={
              techpackItemArtwork?.production_decoration_background?.color
            }
            allowDownload={productionDecoration ? true : false}
            allowReplace={true}
            setSaving={setSaving}
            acceptedFilesTitle={acceptedFilesTitle}
            acceptedFileExtensions={acceptedFileExtensions}
            onUpload={uploadFileAndCreateProductionAsset}
            onDownloadUrl={() =>
              downloadUrl(
                productionDecoration?.assetOriginal.originalUrl,
                productionDecoration?.assetOriginal.assetName
              )
            }
            onBackgroundColorChange={productionBackgroundColorUpdate}
          />
        </div>
      </div>
      {customColors && (
        <div className={styles.customColorsRow}>
          <TechpackCustomColor
            customColors={customColors}
            setSaving={setSaving}
          />
        </div>
      )}
    </div>
  );
};

export default TechpackArtwork;
