import React, { useState } from "react";

import RCUpload from "rc-upload";

import styles from "./UploadImage.module.scss";
import { useTranslation } from "react-i18next";
import Message from "../../Message";
import { Button } from "../../../common/components/Button/Button";

const UploadImageButton = (props) => {
  const { t, i18n } = useTranslation();
  const {
    onStart,
    onUpload,
    onSuccess,
    errorCode,
    title = t("general.upload_file"),
    acceptedFilesTitle,
    acceptedFileExtensions = [".eps, .svg"],
  } = props;
  //TODO, we need another state also that said "Upload successfull", instead of just putting back the button
  //Or make the loading state a string with various enums...
  const [loading, setLoading] = useState(false);

  const acceptedFileExtensionsString = acceptedFileExtensions.join(", ");
  const rcUploadProps = {
    multiple: false,
    accept: acceptedFileExtensionsString,
    onStart: async (file) => {
      setLoading(true);

      if (onStart) {
        await onStart(file);
      }
    },
    onSuccess: async (res, file) => {
      if (onSuccess) {
        await onSuccess(res, file);
      }
      setLoading(false);
    },
    onError: (err) => {
      setLoading(false);
    },
    customRequest: onUpload,
    component: "div",
    className: styles.rcUpload,
  };

  return (
    <React.Fragment>
      {loading ? (
        <span>{t("general.loading")}</span>
      ) : (
        <React.Fragment>
          <span className={styles.supported_file}>{acceptedFilesTitle}</span>
          {errorCode && (
            <Message
              title={t("assetManager.errors." + errorCode + ".title")}
              message={t("assetManager.errors." + errorCode + ".message")}
              linkText={
                i18n.exists("assetManager.errors." + errorCode + ".linkText") &&
                t("assetManager.errors." + errorCode + ".linkText")
              }
              linkUrl={
                i18n.exists("assetManager.errors." + errorCode + ".linkUrl") &&
                t("assetManager.errors." + errorCode + ".linkUrl")
              }
            />
          )}
          <RCUpload {...rcUploadProps}>
            <Button className={styles.button}>{title}</Button>
          </RCUpload>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default UploadImageButton;
