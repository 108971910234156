import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { DESIGN_CHANGE_ENUM } from "../../../utils/ReactHooks";
import { reformatTheInches } from "../../../utils/LocaleUtil";
import StyledInchSlider from "../../../components/StyledInchSlider";
import styles from "../AssetSizeBox.module.scss";
import Message from "../../Message";
import CollapsableMessage from "../../Message/CollapsableMessage";

const AssetPrintSizeBox = (props) => {
  const {
    canUpdateArtworkPosition,
    onDesignChange,
    decoration,
    hideSizeValue = false,
  } = props;

  // Warnings
  const IMAGE_WIDTH_LIMITED = "fileWidthLimited";
  //const IMAGE_HEIGHT_LIMITED = "fileHeightLimited";

  //Errors
  const NO_SIZE_AVAILABLE = "noSizeAvailable";

  const { t, i18n } = useTranslation();

  const [errorCode, setErrorCode] = useState(null);
  const [sizeIndex, setSizeIndex] = useState(0);
  const [minSize, setMinSize] = useState(0);
  const [maxSize, setMaxSize] = useState(0);
  const [availableWidthSizes, setAvailableWidthSizes] = useState([]);

  useEffect(() => {
    if (decoration.inError && decoration.message === NO_SIZE_AVAILABLE) {
      setErrorCode(NO_SIZE_AVAILABLE);
    }

    if (decoration.assetSize.availableSizes) {
      setAvailableWidthSizes(decoration.assetSize.availableSizes);
      const drivingSide = decoration.assetSize.availableSizes[0]?.drivingSide;
      if (drivingSide) {
        const minAvailableSize = decoration.assetSize.availableSizes[0];
        const maxAvailableSize = decoration.assetSize.availableSizes.at(-1);
        if (drivingSide === "width") {
          setMinSize(minAvailableSize.widthInInches);
          setMaxSize(maxAvailableSize.widthInInches);
        }
        if (drivingSide === "height") {
          setMinSize(minAvailableSize.heightInInches);
          setMaxSize(maxAvailableSize.heightInInches);
        }
      }

      if (
        decoration.assetSize.selectedSize &&
        decoration.assetSize.selectedSize.id
      ) {
        const index = decoration.assetSize.availableSizes.findIndex(
          (size) => decoration.assetSize.selectedSize.id === size.id
        );

        if (index >= 0) {
          setSizeIndex(index);
        } else {
          setSizeIndex(0);
        }
      } else {
        setSizeIndex(0);
      }
    } else {
      setAvailableWidthSizes([]);
      setSizeIndex(0);
    }
  }, [decoration]);

  const onSliderChange = useCallback((e) => {
    const index = Number(e.target.value);
    setSizeIndex(index);
  }, []);

  const onSliderChangeCommitted = useCallback(
    (e) => {
      onDesignChange(DESIGN_CHANGE_ENUM.SET_SIZE, {
        size: availableWidthSizes[sizeIndex].id,
        decorationId: decoration.id,
      });
    },
    [decoration.id, onDesignChange, sizeIndex, availableWidthSizes]
  );

  return (
    <React.Fragment>
      {errorCode && (
        <Message
          title={t("generator.errors." + errorCode + ".title")}
          message={t("generator.errors." + errorCode + ".message")}
          linkText={
            i18n.exists("generator.errors." + errorCode + ".linkText") &&
            t("generator.errors." + errorCode + ".linkText")
          }
          linkUrl={
            i18n.exists("generator.errors." + errorCode + ".linkUrl") &&
            t("generator.errors." + errorCode + ".linkUrl")
          }
        />
      )}
      {availableWidthSizes.length > 1 &&
        sizeIndex >= 0 &&
        availableWidthSizes[sizeIndex]?.heightInInches &&
        availableWidthSizes[sizeIndex]?.widthInInches && (
          <div className={styles.sizeContainer}>
            <h6>
              {hideSizeValue
                ? t("specs.size")
                : t("specs.size") +
                  t("specs.width") +
                  " " +
                  reformatTheInches(
                    availableWidthSizes[sizeIndex].widthInInches
                  ) +
                  " " +
                  t("specs.height") +
                  " " +
                  reformatTheInches(
                    availableWidthSizes[sizeIndex].heightInInches
                  )}
            </h6>
            {/*//TODO make the slider use productDef's logoOptions to set min, max and step*/}
            <StyledInchSlider
              min={0}
              max={availableWidthSizes.length - 1}
              step={1}
              value={sizeIndex}
              //TODO isssue here onChange always triggers instead of only when mouse is released (Current bug of Chrome)
              onChange={onSliderChange}
              onChangeCommitted={onSliderChangeCommitted}
              minLabel={reformatTheInches(minSize)}
              passedLabelText={reformatTheInches(
                availableWidthSizes[sizeIndex].widthInInches
              )}
              maxLabel={reformatTheInches(maxSize)}
              disabled={!canUpdateArtworkPosition}
              hideSizeValue={hideSizeValue}
            />
            {decoration.inWarning &&
              decoration.warningMessages.includes(IMAGE_WIDTH_LIMITED) && (
                <CollapsableMessage
                  title={t(
                    `assetManager.warnings.${IMAGE_WIDTH_LIMITED}.title`
                  )}
                  message={t(
                    `assetManager.warnings.${IMAGE_WIDTH_LIMITED}.message`
                  )}
                  linkText={t(
                    `assetManager.warnings.${IMAGE_WIDTH_LIMITED}.linkText`
                  )}
                  linkUrl={t(
                    `assetManager.warnings.${IMAGE_WIDTH_LIMITED}.linkUrl`
                  )}
                />
              )}
            {decoration.type && decoration.type === "embroidery" && (
              // TODO: Make this message conditional based on the score of the image
              <CollapsableMessage
                message={t(
                  `assetManager.warnings.embroideryLimitedDetails.message`
                )}
              />
            )}
          </div>
        )}
    </React.Fragment>
  );
};

export default AssetPrintSizeBox;
