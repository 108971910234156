import { getErrorMessage } from "../../../utils/FormValidationsUtils";
import { useTranslation } from "react-i18next";
import { Dictionary } from "lodash";
import { FormLabel } from "@mui/material";

interface Props {
  error?:
    | string
    | {
        key: string;
        values: Dictionary<string>;
      };
}

export const ErrorLabel = ({ error }: Props) => {
  const { t } = useTranslation();
  if (!error) {
    return null;
  }

  return (
    <FormLabel color="error" error>
      {getErrorMessage(t, error)}
    </FormLabel>
  );
};
