import React from "react";

import Box from "@mui/material/Box";
import { BoxProps } from "@mui/system";

/** 
  Each individual panel will be a box with it's children in it
  And will be visible only if it's "value" is the same as the current tab index selected
*/
interface Props extends BoxProps {
  children: JSX.Element | JSX.Element[];
  selectedIndex: number;
  index: number;
}

export const TabPanel = (props: Props) => {
  const { children, selectedIndex, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={selectedIndex !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      py={5}
      {...other}
    >
      {index === selectedIndex && <React.Fragment>{children}</React.Fragment>}
    </Box>
  );
};
