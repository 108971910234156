import React from "react";
import styles from "./adminPurchasedOrderCompact.module.scss";
import { useTranslation } from "react-i18next";

import { PreviewGlassMagnifier } from "../../containers/StudioSidebarContainer/StudioRightSidebar/DecorationSidebar/PreviewGlassMagnifier";
import { AddressBox } from "../AddressBox";
import OrderSummary from "../OrderSummary";
import PurchasedItemSizesDetails from "../PurchasedItemSizesDetails";
import { OrderDetail, OrderType } from "../../types";

interface Props {
  orderDetail: OrderDetail;
  type: OrderType;
}

const AdminPurchasedOrderCompact = ({ orderDetail, type }: Props) => {
  const { t } = useTranslation();

  const orderId =
    orderDetail?.preorder?.id || orderDetail?.order?.id || "Unknown";

  return (
    <React.Fragment>
      <div className={styles.container}>
        <div className={styles.orderReview}>
          <div className={styles.orderInfo}>
            <span>
              <strong>{t("order.order_details")}</strong>
            </span>
            <span>{orderId}</span>
            {orderDetail?.invoice?.invoice_number && (
              <span>
                {t("order.invoice_no")} {orderDetail.invoice.invoice_number}
              </span>
            )}
          </div>
          <div className={styles.shipping}>
            <span>
              <strong>{t("order.billing")}</strong>
            </span>
            <AddressBox address={orderDetail.invoice.customer} />
          </div>
          <div className={styles.shipping}>
            <span>
              <strong>{t("order.delivery")}</strong>
            </span>
            <AddressBox address={orderDetail.shipping} />
          </div>
          <div className={styles.paymentSummary}>
            <strong>{t("order.order_summary")}</strong>
            <OrderSummary
              taxes={orderDetail.invoice.taxes}
              total={orderDetail.invoice.total}
              subtotal={orderDetail.invoice.subtotal}
              shipping={orderDetail.invoice.shipping}
              currency={orderDetail.invoice.currency}
              discounts={orderDetail.invoice.discounts}
              hasReachedMaximum={orderDetail.invoice.hasReachedMaximum}
              taxesExcluded={type === "preorder" ? true : false}
            />
          </div>
        </div>

        {
          //Because of legacy orders (before march 2023), we need to use cartItems because they didn't have purchasedItems
          (orderDetail.purchasedItems && orderDetail.purchasedItems.length > 0
            ? orderDetail.purchasedItems
            : orderDetail.cartItems
          ).map(
            (purchasedItem, purchasedItemIndex) => {
              const images = purchasedItem.productImages;
              return (
                <div className={styles.productItem} key={purchasedItem.id}>
                  <div className={styles.imgContainer}>
                    {images?.map((image, imageIndex) => (
                      // <img
                      //   src={image.url}
                      //   alt={image.side}
                      //   className={styles.img}
                      //   key={image.side}
                      // />
                      <div className={styles.img} key={imageIndex}>
                        <PreviewGlassMagnifier
                          imageUrl={image.url}
                          key={image.side}
                          noBackgroundColor={true}
                        />
                      </div>
                    ))}
                  </div>
                  <div className={styles.sideContainer}>
                    <div className={styles.projectName}>
                      <span className={styles.projectNameTitle}>
                        {purchasedItem.projectName} - {purchasedItem.designName}
                      </span>
                      <span className={styles.projectNumber}>
                        ({purchasedItemIndex + 1}/
                        {orderDetail.purchasedItems.length})
                      </span>
                    </div>
                    <div className={styles.sizesContainer}>
                      <PurchasedItemSizesDetails sizes={purchasedItem.sizes} />
                    </div>
                  </div>
                </div>
              );
            }

            //   <OrderItem
            //     key={item.designId}
            //     price={item?.pricing?.price}
            //     unitPrice={item?.pricing?.pricePerUnit}
            //     qty={item?.pricing?.qty}
            //     sizes={item.sizes}
            //     designState={item.designState}
            //     description={""} //TODO: Validate where description is
            //     images={item.designState.images}
            //     projectName={item.projectName}
            //     designName={item.designName} //TODO: validate
            //     currency={orderDetail.currency}
            //     showPriceInfo={true} //{type === "order" ? true : false}
            //   />
            // ))}

            // <div className={styles.purchasedItemContainer}>
            //   <PurchasedItemDetails
            //     key={purchasedItem.designId}
            //     purchasedItem={purchasedItem}
            //     title={`${purchasedItem.projectName} - ${
            //       purchasedItem.designName
            //     } (${purchasedItemIndex + 1}/${
            //       (orderDetail.purchasedItems &&
            //         orderDetail.purchasedItems.length > 0
            //           ? orderDetail.purchasedItems
            //           : orderDetail.cartItems
            //       ).length
            //     })`}
            //   />
            // </div>
          )
        }
      </div>
    </React.Fragment>
  );
};

export default AdminPurchasedOrderCompact;
