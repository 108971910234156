import React from "react";

const YourContactBox = (props) => {
  const { userDetails } = props;

  return (
    <React.Fragment>
      <span>
        {userDetails && `${userDetails.first_name} ${userDetails.last_name}`}{" "}
      </span>
      <span>{userDetails && `${userDetails.email}`}</span>
    </React.Fragment>
  );
};

export default YourContactBox;
