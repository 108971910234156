import * as React from "react";
import { styled, alpha, Box } from "@mui/system";
import { Slider, SliderProps, sliderClasses } from "@mui/material";

const StyledSlider = styled(Slider)(
  ({ theme }) => `
  color: ${theme.palette.mode === "light" ? "#1976d2" : "#90caf9"};
  border-radius: 0px;
  height: 20px;
  width: 98%;
  padding: 8px 0 !important;
  display: inline-block;
  position: relative;
  cursor: pointer;
  margin-left: 1%;
  margin-right: 1%;
  touch-action: none;
  -webkit-tap-highlight-color: transparent;
  opacity: 0.75;
  &:hover {
    opacity: 1;
  }

  & .MuiSlider-rail {
    display: block;
    position: absolute;
    width: 100%;
    height: 2px;
    border-radius: 2px;
    background-color: currentColor;
    background-color: #6c757d;
    opacity: 0.38;
  }

  & .MuiSlider-track {
    display: block;
    position: absolute;
    height: 0px; //This needs to change if you want the track to show
    border-radius: 2px;
    background-color: #6c757d;
    opacity: 0.38;
    color: #6c757d;
  }

  & .MuiSlider-thumb {
    position: absolute;
    width: 14px;
    height: 14px;
    box-sizing: border-box;
    border-radius: 50%;
    outline: 0;

    border: 2px solid currentColor;
    background-color: #fff;
  }

    & .${sliderClasses.valueLabel} { 
      position: relative;  
    }
    
    
  & .${sliderClasses.valueLabelLabel} {
    font-weight: bold;
    color: white;
    transform: translateY(50%);

  }
    
  :hover,
  &.Mui-focusVisible {
    box-shadow: 0 0 0 0.25rem ${alpha(
      theme.palette.mode === "light" ? "#1976d2" : "#90caf9",
      0.15
    )};
  }

  &.Mui-active {
    box-shadow: 0 0 0 0.25rem ${alpha(
      theme.palette.mode === "light" ? "#1976d2" : "#90caf9",
      0.3
    )};
  }


  & .${sliderClasses.mark} {
    position: absolute;
    width: 2px;
    height: 8px;
    border-radius: 2px;
    background-color: black;
    top: 42%;
    opacity: 0.7;
    transform: translateX(-50%);
  }

  & .${sliderClasses.markActive} {
    background-color: black;
  }

  & .${sliderClasses.markLabel} {
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-size: 12px;
    position: absolute;
    top: 20px;
    transform: translateX(-50%);
  }

`
);

interface Props extends SliderProps {
  minLabel: string;
  maxLabel: string;
  passedLabelText: string;
  hideSizeValue: boolean;
}

export default function StyledInchSlider(props: Props) {
  const {
    min,
    max,
    step,
    onChange,
    onChangeCommitted,
    value,
    disabled,
    minLabel,
    maxLabel,
    passedLabelText,
    marks,
    hideSizeValue = false,
  } = props;

  const [minTrue, setMinTrue] = React.useState(false);
  const [maxTrue, setMaxTrue] = React.useState(false);

  function isLast(
    slideValue: string,
    numberValue: number | readonly number[] | undefined,
    minValue: number | undefined,
    maxValue: number | undefined
  ) {
    if (minValue === numberValue) {
      setMinTrue(true);
      return "";
    }
    if (maxValue === numberValue) {
      setMaxTrue(true);
      return "";
    }

    setMaxTrue(false);
    setMinTrue(false);
    return slideValue;
  }

  return (
    <Box>
      <div style={{ flexDirection: "row" }}>
        <span style={{ justifyContent: "spaceBetween" }}>
          <StyledSlider
            aria-label="Default"
            min={min}
            max={max}
            step={step}
            onChange={onChange}
            onChangeCommitted={onChangeCommitted}
            value={value}
            disabled={disabled}
            getAriaValueText={() => isLast(passedLabelText, value, min, max)}
            valueLabelFormat={() => isLast(passedLabelText, value, min, max)}
            valueLabelDisplay="auto"
            defaultValue={value}
            marks={marks}
          />
        </span>
        {!hideSizeValue && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <span
              style={
                minTrue
                  ? { color: "black", fontWeight: "bold" }
                  : { color: "#6c757d" }
              }
            >
              {minLabel}
            </span>
            <span
              style={
                maxTrue
                  ? { color: "black", fontWeight: "bold" }
                  : { color: "#6c757d" }
              }
            >
              {maxLabel}
            </span>
          </div>
        )}
      </div>
    </Box>
  );
}
