import { useCallback } from "react";
import { useStore } from "react-redux";

import { MY_PREORDERS_ACTION_ENUM } from "../common/reducers/myPreOrders";
import { ALL_PREORDERS_ACTION_ENUM } from "../common/reducers/allPreOrders";
import PreOrderService from "../service/PreOrderService";

export const usePreOrderFetcher = () => {
  const { dispatch } = useStore();

  /**
   * Local user's orders
   */
  const fetchMyPreOrdersAndUpdateStore = useCallback(async () => {
    dispatch({
      type: MY_PREORDERS_ACTION_ENUM.UPDATE_LOADING,
      payload: { loading: true },
    });

    const res = await PreOrderService.getMyPreOrder();

    dispatch({
      type: MY_PREORDERS_ACTION_ENUM.UPDATE_PREORDERS,
      payload: res.data.data || [],
    });
  }, [dispatch]);

  /**
   * All customer's preorders (for admins and designers)
   */
  const fetchPurchasedPreOrdersAndUpdateStore = useCallback(
    async (page, itemsPerPage = 20) => {
      dispatch({
        type: ALL_PREORDERS_ACTION_ENUM.UPDATE_LOADING,
        payload: { loading: true },
      });

      const res = await PreOrderService.getPurchasedPreOrders(
        page,
        itemsPerPage
      );

      const { data } = res.data;

      dispatch({
        type: ALL_PREORDERS_ACTION_ENUM.UPDATE_ALL_PURCHASED_PREORDERS,
        payload: { preOrders: data.preOrders || [], count: data.count },
      });
    },
    [dispatch]
  );

  const fetchPreOrderDetail = useCallback(async (preorderId) => {
    const res = await PreOrderService.getPurchasedPreOrder(preorderId);

    return res.data.data;
  }, []);

  return {
    fetchMyPreOrdersAndUpdateStore,
    fetchPurchasedPreOrdersAndUpdateStore,
    fetchPreOrderDetail,
  };
};
