import { CircularProgress } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

import styles from "./CreatingCanvaModal.module.scss";

const CreatingCanvaModal = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.container}>
      <CircularProgress size={16} />
      <b>{t("products.getting_ready")}</b>
      <span>{t("products.have_fun")}</span>
    </div>
  );
};

export default CreatingCanvaModal;
