import { useFormik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { object, string } from "yup";
import {
  Box,
  Button,
  CenteredColumnBox,
  Form,
  Input,
  Link,
} from "../../../common/components";
import { spacing } from "../../../styles/theme";
import { UserOnboardingInfo } from "../../../types";
import { OnboardingTitle } from "../Component/OnboardingTitle";

interface Props {
  onPreviousStep: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  setOnboardingInfo: React.Dispatch<React.SetStateAction<UserOnboardingInfo>>;
  onNextStep: () => void;
  onboardingInfo: UserOnboardingInfo;
}

const validationSchema = object({
  teamName: string().required("general.errors.required.input"),
});

export const TeamName = ({
  onPreviousStep,
  setOnboardingInfo,
  onNextStep,
  onboardingInfo,
}: Props) => {
  const { t } = useTranslation();
  const formik = useFormik({
    initialValues: onboardingInfo,
    onSubmit: (values) => {
      setOnboardingInfo(values);
      onNextStep();
    },
    validationSchema: validationSchema,
  });
  return (
    <Form formik={formik}>
      <Box fullWidth mt={{ xs: spacing.xlMobile, lg: 0 }}>
        <OnboardingTitle title={t("onboard.team_name.title")} />
        <CenteredColumnBox>
          <Input
            id="teamName"
            name="teamName"
            label={t("onboard.team_name.label")}
            data-test="onboard.teamName.input"
          />
        </CenteredColumnBox>
        <CenteredColumnBox
          gap={{ xs: spacing.ctaSpacingMobile, lg: spacing.ctaSpacingDesktop }}
          mt={{ xs: spacing.regularMobile, lg: spacing.regularDesktop }}
          fullWidth
        >
          <Button
            type="submit"
            fullWidth
            data-test="onboard.teamName.submitButton"
          >
            {t("sign_in.continue")}
          </Button>
          <Link to="" onClick={(e) => onPreviousStep(e)}>
            {t("onboard.back")}
          </Link>
        </CenteredColumnBox>
      </Box>
    </Form>
  );
};
