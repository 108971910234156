import React, { useCallback, useEffect } from "react";
import { CircularProgress } from "@mui/material";
import AdminPreorder from "../../components/AdminPreorder";
import TechpackToolbar from "../../components/TechpackToolbar";
import Helmet from "react-helmet";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import styles from "./AdminPreorderContainer.module.scss";
import { ROUTES } from "../../utils/Constant";
import { usePreOrderFetcher } from "../../utils/PreOrdersFetcherHooks";
import PreOrderService from "../../service/PreOrderService";
import { routeFormatter } from "../../utils/Formatter";
import TechpackService from "../../service/TechpackService";
import { usePageTitle } from "../../common/hooks/usePageTitle";

const AdminPreorderContainer = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { preorderId } = useParams();
  usePageTitle(`${t("preorder.title")} - Jameo Studio`);

  const { fetchPreOrderDetail } = usePreOrderFetcher();
  // useEffect(() => {
  //   fetchPreOrderDetail();
  // }, [fetchPreOrderDetail]);

  // const { fetchOrderTechpack, fetchPreOrderTechpack, fetchTechpackPdfLink } =
  //   useTechpackFetcher();
  //const { fetchDecorationsOptionsDefinition } = useDesignFetcher();
  const [preorderDetail, setPreorderDetail] = React.useState(null);
  const [isLoaded, setLoaded] = React.useState(false);
  const [isSaving, setSaving] = React.useState(false);

  const [needPreorderRefresh, setNeedPreorderRefresh] = React.useState(true);

  useEffect(() => {
    if (!needPreorderRefresh) {
      return;
    }
    setLoaded(false);
    fetchPreOrderDetail(preorderId).then((preorder) => {
      setPreorderDetail(preorder);
      setLoaded(true);
      setNeedPreorderRefresh(false);
    });
  }, [preorderId, fetchPreOrderDetail, needPreorderRefresh]);

  const onGoToTechpack = useCallback(
    (e) => {
      e.preventDefault();

      navigate(
        routeFormatter(ROUTES.ADMIN_TECHPACK_PREORDER, {
          params: { preorderId: preorderId },
        })
      );
    },
    [navigate, preorderId]
  );

  /**
   *
   */
  const onClickBackToCustomerOrder = useCallback(
    (e) => {
      e.preventDefault();
      navigate(ROUTES.ADMIN_PURCHASED_ORDERS);
    },
    [navigate]
  );

  /**
   *
   */
  const onGoToEditPurchasedDesign = useCallback(
    (designId) => {
      navigate(
        routeFormatter(ROUTES.STUDIO_PURCHASED_DESIGN, {
          params: {
            designId: designId,
            orderType: "preorder",
            orderId: preorderId,
            historyFrom: "adminpurchased",
          },
        })
      );
    },
    [navigate, preorderId]
  );

  /**
   *
   */
  const onUpdateSizesQty = useCallback(
    async (designId, sizes) => {
      setSaving(true);
      const res = await PreOrderService.updatePurchasedItemSizes(
        preorderId,
        designId,
        sizes
      );

      setSaving(false);

      //setNeedPreorderRefresh(true);//This refreshes the whole page VS setPreorderDetail updates content without full page refresh
      setPreorderDetail(res.data.data);
    },
    [preorderId, setPreorderDetail]
  );

  /**
   *
   */
  const onUpdateInvoiceNumber = useCallback(
    async (invoiceNumber) => {
      setSaving(true);
      const res = await PreOrderService.updateExternalInvoiceNumber(
        preorderId,
        invoiceNumber
      );

      setSaving(false);

      //setNeedPreorderRefresh(true);//This refreshes the whole page VS setPreorderDetail updates content without full page refresh
      setPreorderDetail(res.data.data);
    },
    [preorderId, setPreorderDetail]
  );

  const onUpdateProjectNumber = useCallback(
    async (projectNumber) => {
      setSaving(true);
      const data = {
        techpackId: preorderDetail.techpack.id,
        projectName: projectNumber,
      };
      await TechpackService.setProjectName(data);

      setSaving(false);

      //setNeedPreorderRefresh(true);//This refreshes the whole page VS setPreorderDetail updates content without full page refresh
      //setPreorderDetail(res.data.data);
    },
    [preorderDetail?.techpack.id /*, setPreorderDetail*/]
  );

  const onUpdateStatus = useCallback(
    async (status) => {
      setSaving(true);
      const res = await PreOrderService.updateStatus(preorderId, status);

      setSaving(false);

      //setNeedPreorderRefresh(true);//This refreshes the whole page VS setPreorderDetail updates content without full page refresh
      setPreorderDetail(res.data.data);
    },
    [preorderId, setPreorderDetail]
  );

  const onUpdateCurrency = useCallback(
    async (currency) => {
      setSaving(true);
      const res = await PreOrderService.updateCurrency(preorderId, currency);

      setSaving(false);

      //setNeedPreorderRefresh(true);//This refreshes the whole page VS setPreorderDetail updates content without full page refresh
      setPreorderDetail(res.data.data);
    },
    [preorderId, setPreorderDetail]
  );

  const onUpdateShippingAddress = useCallback(
    async (shippingAddress) => {
      setSaving(true);
      const currency = preorderDetail.invoice.currency;
      const res = await PreOrderService.updateShippingAddressAndCurrency(
        preorderId,
        shippingAddress,
        currency
      );

      setSaving(false);

      //setNeedPreorderRefresh(true);//This refreshes the whole page VS setPreorderDetail updates content without full page refresh
      setPreorderDetail(res.data.data);
    },
    [preorderId, preorderDetail?.invoice?.currency, setPreorderDetail]
  );

  const onUpdateBillingAddress = useCallback(
    async (billingAddress) => {
      setSaving(true);
      const res = await PreOrderService.updateBillingAddress(
        preorderId,
        billingAddress
      );

      setSaving(false);

      //setNeedPreorderRefresh(true);//This refreshes the whole page VS setPreorderDetail updates content without full page refresh
      setPreorderDetail(res.data.data);
    },
    [preorderId, setPreorderDetail]
  );

  const onUpdateDiscounts = useCallback(
    async (discounts) => {
      setSaving(true);

      const res = await PreOrderService.upsertDiscounts(preorderId, discounts);

      setSaving(false);

      //setNeedPreorderRefresh(true);//This refreshes the whole page VS setPreorderDetail updates content without full page refresh
      setPreorderDetail(res.data.data);
    },
    [preorderId, setPreorderDetail]
  );

  const onRemoveDesign = useCallback(
    async (designId) => {
      setSaving(true);

      const res = await PreOrderService.removeDesign(preorderId, designId);

      setSaving(false);

      //setNeedPreorderRefresh(true);//This refreshes the whole page VS setPreorderDetail updates content without full page refresh
      setPreorderDetail(res.data.data);
    },
    [preorderId, setPreorderDetail]
  );
  /**
   *
   */
  const onAddNewDesign = useCallback(
    async (designId, sizes) => {
      setSaving(true);

      const res = await PreOrderService.addDesign(preorderId, designId, sizes);
      console.debug("!!!res", res);

      if (res.response) {
        console.debug("!!!res.response", res.response);
      }

      setSaving(false);

      //setNeedPreorderRefresh(true);//This refreshes the whole page VS setPreorderDetail updates content without full page refresh
      setPreorderDetail(res.data.data);

      return { preorderDetail: res.data.data, error: {} };
    },
    [preorderId /*setPreorderDetail*/]
  );

  return (
    <div className={styles.container}>
      <TechpackToolbar
        //onClickSave={(e) => {}}
        onClickDownload={null}
        //onClickCancel,
        onClickBackToCustomerOrder={onClickBackToCustomerOrder}
        //onDirty,
        actionText={
          !isLoaded
            ? t("techpack.loading")
            : isSaving
              ? t("techpack.saving")
              : t("techpack.saved")
        }
      />
      <div className={styles.dashboardContainer}>
        <div className={styles.centerContainer}>
          {isLoaded ? (
            preorderDetail === null ? (
              <div className="page-title-box">
                <p className="page-title">{t("techpack.not_found")}</p>
              </div>
            ) : (
              <div className="main-inner-content">
                <AdminPreorder
                  preorderDetail={preorderDetail}
                  key={preorderId}
                  //setNeedPreorderRefresh={setNeedPreorderRefresh}
                  //setSaving={setSaving}
                  onAddNewDesign={onAddNewDesign}
                  onGoToTechpack={
                    preorderDetail?.techpack ? onGoToTechpack : null
                  }
                  onGoToEditPurchasedDesign={onGoToEditPurchasedDesign}
                  onUpdateSizesQty={onUpdateSizesQty}
                  onUpdateInvoiceNumber={onUpdateInvoiceNumber}
                  onUpdateProjectNumber={
                    preorderDetail?.techpack ? onUpdateProjectNumber : null
                  }
                  onUpdateStatus={onUpdateStatus}
                  onUpdateShippingAddress={onUpdateShippingAddress}
                  onUpdateBillingAddress={onUpdateBillingAddress}
                  onUpdateDiscounts={onUpdateDiscounts}
                  onUpdateCurrency={onUpdateCurrency}
                  onRemoveDesign={
                    preorderDetail.purchasedItems.length > 1
                      ? onRemoveDesign
                      : null
                  } //We can't have an order with no purchased items
                />
              </div>
            )
          ) : (
            <div className={styles.loadingProgress}>
              <CircularProgress />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminPreorderContainer;
