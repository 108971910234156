import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import { alpha, styled } from "@mui/material/styles";
import style from "./AssetTypeDropdownSelector.module.scss";
import CollapsableMessage from "../Message/CollapsableMessage";
import { Typography } from "@mui/material";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: "center",
  whiteSpace: "nowrap",
  fontSize: "8px",
  fontWeight: "600",
  backgroundColor: "#B8D8EB",
  padding: "1px 3px",
}));

const AssetTypeDropdownSelector = (props) => {
  const {
    availableDecorationsDefinition,
    decoration,
    assetFileExtension,
    onAssetTypeSelectionChange,
    isAdmin,
  } = props;

  // Warnings
  const IMAGE_TYPE_LIMITED = "fileTypeLimited";

  const { t } = useTranslation();

  const hasAtLeastOneTypeDisabled = useMemo(() => {
    if (!assetFileExtension) {
      return false;
    }
    return !_.isEmpty(
      availableDecorationsDefinition.find((decoType) => {
        const fileTypesForFileExtensionDef = decoType.fileTypes.find(
          (fileType) => {
            return fileType.extensions.find(
              (extension) =>
                extension.toLowerCase() === assetFileExtension.toLowerCase()
            );
          }
        );
        return _.isEmpty(fileTypesForFileExtensionDef);
      })
    );
  }, [availableDecorationsDefinition, assetFileExtension]);

  //TODO: way to show error if asset type fails

  return (
    <div className={style.container}>
      {hasAtLeastOneTypeDisabled && (
        <CollapsableMessage
          title={t(`assetManager.warnings.${IMAGE_TYPE_LIMITED}.title`)}
          message={t(`assetManager.warnings.${IMAGE_TYPE_LIMITED}.message`)}
          linkText={t(`assetManager.warnings.${IMAGE_TYPE_LIMITED}.linkText`)}
          linkUrl={t(`assetManager.warnings.${IMAGE_TYPE_LIMITED}.linkUrl`)}
        />
      )}
      <span className={style.title}>{t("specs.decoration_types.title")}</span>
      <Select
        variant="outlined"
        sx={{
          "& legend": { display: "none" },
          "& fieldset": { top: 0 },
        }}
        fullWidth
        value={decoration.type}
        SelectDisplayProps={{ style: { display: "flex", padding: "6px 16px" } }}
        onChange={(e) =>
          onAssetTypeSelectionChange({
            assetType: e.target.value,
            assetId: decoration.assetId,
            decorationId: decoration.id,
            name: t("specs.decoration_types." + e.target.value + ".title"),
          })
        }
      >
        {assetFileExtension &&
          availableDecorationsDefinition.map((decoType) => {
            const fileTypesForFileExtensionDef = decoType.fileTypes.find(
              (fileType) => {
                return fileType.extensions.find(
                  (extension) =>
                    extension.toLowerCase() === assetFileExtension.toLowerCase()
                );
              }
            );
            const disabledBecauseNotAdmin = decoType?.adminOnly && !isAdmin;
            const disabled =
              _.isEmpty(fileTypesForFileExtensionDef) ||
              disabledBecauseNotAdmin;

            const decorationImage = decoType.display?.colorImgUrl || null;

            return (
              <MenuItem
                key={decoType.id}
                disabled={disabled}
                value={decoType.id}
                sx={{
                  color: "#000000",
                  margin: "5px",
                  borderRadius: "4px",
                  borderWidth: "1px",
                  borderStyle: "solid",
                  borderColor: "#dddddd",
                  ":hover": {
                    backgroundColor: "#d6e4f1",
                  },
                  "&.Mui-disabled": {
                    backgroundColor: "#F4F5F7",
                    color: alpha("#333333", 0.6),
                    opacity: "1",
                  },
                  "&.Mui-selected": {
                    borderColor: "#3F70AA",
                    borderWidth: "1px",
                    backgroundColor: "transparent",
                    display: "flex",
                    flexDirection: "row",
                  },
                }}
              >
                {/*TODO*/}
                <ListItemIcon sx={{ width: 56, height: 56, maxWidth: 56 }}>
                  <img src={decorationImage} alt={"logo"} />
                </ListItemIcon>
                <ListItemText
                  sx={{ my: 0, paddingLeft: "10px" }}
                  primary={
                    <Typography
                      sx={{
                        fontSize: 14,
                        fontWeight: "400",
                        letterSpacing: 0,
                      }}
                    >
                      {t(`specs.decoration_types.${decoType.id}.title`)}
                    </Typography>
                  }
                  disableTypography
                  secondary={
                    <React.Fragment>
                      <Typography
                        sx={{
                          fontSize: 11,
                          fontWeight: "400",
                          letterSpacing: 0,
                          color: alpha("#333333", disabled ? 0.6 : 1.0),
                        }}
                      >
                        {disabledBecauseNotAdmin &&
                          t(`specs.decoration_types.notAdminWarning`)}
                        {!disabledBecauseNotAdmin &&
                          t(
                            `specs.decoration_types.${decoType.id}.description`
                          )}
                      </Typography>
                      <Stack direction="row" spacing="2px" paddingTop="5px">
                        {decoType.fileTypes.map((fileType) => {
                          return (
                            <Item
                              key={fileType.name}
                              elevation={0}
                              sx={{
                                color: disabled ? "#666666" : "#074174",
                                backgroundColor: disabled
                                  ? "#E1E5EA"
                                  : "#B8D8EB",
                              }}
                            >
                              {fileType.name.toUpperCase()}
                            </Item>
                          );
                        })}

                        {decoType.useColorExtraction && (
                          <Item
                            key="maxColorsToExtact"
                            elevation={0}
                            sx={{
                              color: disabled ? "#666666" : "#074174",
                              backgroundColor: disabled ? "#E1E5EA" : "#B8D8EB",
                            }}
                          >
                            {t("specs.colors_to_extract", {
                              maxColor: decoType.maxColorsToExtact,
                            })}
                          </Item>
                        )}
                      </Stack>
                    </React.Fragment>
                  }
                />
              </MenuItem>
            );
          })}
      </Select>
    </div>
  );
};

export default AssetTypeDropdownSelector;
