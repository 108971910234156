import React from "react";
import styles from "./techpackArtworkImage.module.scss";
import { useTranslation } from "react-i18next";
import { Button } from "../../../../common/components/Button";
import { PreviewGlassMagnifier } from "../../../../containers/StudioSidebarContainer/StudioRightSidebar/DecorationSidebar/PreviewGlassMagnifier";
import UploadImageButton from "../../../UploadImage/UploadImageButton";
import UploadImageDropZone from "../../../UploadImage/UploadImageDropZone";

const TechpackArtworkImage = (props) => {
  const {
    imageUrl,
    title,
    backgroundColor,
    errorCode,
    onDownloadUrl,
    onUpload,
    onOpenInNewTab,
    acceptedFileExtensions,
    onBackgroundColorChange,
    allowDownload = false,
    allowOpenInNewTab = false,
    allowReplace = false,
    noBackgroundColor = false,
  } = props;

  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      {imageUrl ? (
        <div className={styles.zoomableImage}>
          <PreviewGlassMagnifier
            imageUrl={imageUrl}
            title={title}
            allowWhite={true}
            backgroundColor={backgroundColor}
            noBackgroundColor={noBackgroundColor}
            onBackgroundColorChange={onBackgroundColorChange}
          />
        </div>
      ) : (
        allowReplace && (
          <div className={styles.uploadImage}>
            <span>{title}</span>
            <UploadImageDropZone
              onUpload={onUpload}
              errorCode={errorCode}
              acceptedFilesTitle={""}
              acceptedFileExtensions={acceptedFileExtensions}
            />
          </div>
        )
      )}
      <div className={styles.action}>
        {allowDownload && onDownloadUrl && (
          <Button className={styles.button} onClick={onDownloadUrl}>
            {t("techpack.download")}
          </Button>
        )}
        {imageUrl && allowOpenInNewTab && (
          <Button
            className={styles.button}
            onClick={() => onOpenInNewTab(imageUrl)}
          >
            {t("techpack.open_new_tab")}
          </Button>
        )}
        {imageUrl && allowReplace && (
          <div className={styles.uploadButtonContainer}>
            <UploadImageButton
              title={t("techpack.replace")}
              onUpload={onUpload}
              errorCode={errorCode}
              acceptedFilesTitle={""}
              acceptedFileExtensions={acceptedFileExtensions}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default TechpackArtworkImage;
