import axios from "axios";
import authService from "./authService";
const USER_API_BASE_URL = process.env.REACT_APP_BACKEND_URL;

const BASE_ROUTE = "/api/v1/preorders";

class PreOrderService {
  addPreOrder(data) {
    return axios.put(
      `${USER_API_BASE_URL}${BASE_ROUTE}`,
      data,
      authService.getAuthHeader()
    );
  }

  getMyPreOrder() {
    return axios.get(
      `${USER_API_BASE_URL}${BASE_ROUTE}`,
      authService.getAuthHeader()
    );
  }

  getPurchasedPreOrders(pageNumber, itemsPerPage = 20) {
    const data = {
      itemsPerPage: itemsPerPage,
      pageNumber,
    };

    return axios.post(
      `${USER_API_BASE_URL}${BASE_ROUTE}/purchasedpreorders`,
      data,
      authService.getAuthHeader()
    );
  }

  /**
   * Should be used for Admin only
   * @returns
   */
  getPurchasedPreOrder(preorderId) {
    const data = {
      preorderId,
    };

    return axios.post(
      `${USER_API_BASE_URL}${BASE_ROUTE}/admin/purchasedpreorder`,
      data,
      authService.getAuthHeader()
    );
  }
  /**
   * Should be used for Admin only
   * @returns
   */
  syncPurchasedPreOrderWithDesign(preorderId, event = null) {
    const data = {
      preorderId,
      event,
    };

    return axios.post(
      `${USER_API_BASE_URL}${BASE_ROUTE}/admin/purchasedpreorder/sync`,
      data,
      authService.getAuthHeader()
    );
  }

  /**
   * Using the designId and not the purchasedItemId because older purchased items under preorders before march 2023 don't have an id field
   * @param {*} preorderId
   * @param {*} designId
   * @param {*} sizes
   * @returns
   */
  updatePurchasedItemSizes(preorderId, designId, sizes) {
    const data = {
      preorderId: preorderId,
      designId: designId,
      sizes: sizes,
    };

    return axios.post(
      `${USER_API_BASE_URL}${BASE_ROUTE}/admin/design/sizes`,
      data,
      authService.getAuthHeader()
    );
  }

  updateExternalInvoiceNumber(preorderId, externalInvoice) {
    const data = {
      preorderId: preorderId,
      externalInvoice: externalInvoice,
    };

    return axios.post(
      `${USER_API_BASE_URL}${BASE_ROUTE}/admin/externalinvoice`,
      data,
      authService.getAuthHeader()
    );
  }

  updateStatus(preorderId, status) {
    const data = {
      preorderId: preorderId,
      status: status,
    };

    return axios.post(
      `${USER_API_BASE_URL}${BASE_ROUTE}/admin/status`,
      data,
      authService.getAuthHeader()
    );
  }

  updateShippingAddressAndCurrency(preorderId, shippingAddress, currency) {
    const data = {
      preorderId: preorderId,
      shippingAddress: shippingAddress,
      currency: currency,
    };

    return axios.put(
      `${USER_API_BASE_URL}${BASE_ROUTE}/admin/shipping`,
      data,
      authService.getAuthHeader()
    );
  }

  updateBillingAddress(preorderId, billingAddress) {
    const data = {
      preorderId: preorderId,
      billingAddress: billingAddress,
    };

    return axios.put(
      `${USER_API_BASE_URL}${BASE_ROUTE}/admin/billing`,
      data,
      authService.getAuthHeader()
    );
  }

  upsertDiscounts(preorderId, discounts) {
    const data = {
      preorderId: preorderId,
      discounts,
    };

    return axios.put(
      `${USER_API_BASE_URL}${BASE_ROUTE}/admin/discounts`,
      data,
      authService.getAuthHeader()
    );
  }

  updsertDiscount(preorderId, discount) {
    const data = {
      preorderId: preorderId,
      discount,
    };

    return axios.put(
      `${USER_API_BASE_URL}${BASE_ROUTE}/admin/discount`,
      data,
      authService.getAuthHeader()
    );
  }

  updateCurrency(preorderId, currency) {
    const data = {
      preorderId: preorderId,
      currency,
    };

    return axios.put(
      `${USER_API_BASE_URL}${BASE_ROUTE}/admin/currency`,
      data,
      authService.getAuthHeader()
    );
  }

  addDesign(preorderId, designId, sizes, isPurchasedDesign = false) {
    const data = {
      preorderId: preorderId,
      designId,
      sizes,
      isPurchasedDesign,
    };

    return axios.put(
      `${USER_API_BASE_URL}${BASE_ROUTE}/admin/item`,
      data,
      authService.getAuthHeader()
    );
  }

  removeDesign(preorderId, designId) {
    return axios.delete(
      `${USER_API_BASE_URL}${BASE_ROUTE}/admin/preorder/${preorderId}/design/${designId}`,
      authService.getAuthHeader()
    );
  }
}

const instance = new PreOrderService();
export default instance;
