import { Team, UserInfo } from "../types";

export const isCurrentUser = (userInfo: UserInfo, teammateUserId: string) => {
  if (userInfo.id === teammateUserId) {
    return true;
  }
  return false;
};

export const canDeleteUser = (team: Team) => (team?.teammates.length ?? 0) > 1;
//If admin or designer, we allow to see the email of the teammates
export const canShowEmail = (userInfo: UserInfo) => {
  return userInfo.isDesigner || userInfo.isAdmin ? true : false;
};
