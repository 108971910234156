import React, { useMemo } from "react";
import MaterialToolbar from "@mui/material/Toolbar";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import styles from "../StudioToolbar.module.scss";
import "../studioToolbar.scss";

import { routeFormatter } from "../../../utils/Formatter";
import { ROUTES } from "../../../utils/Constant";
import DarkTooltip from "../../../common/components/DarkTooltip";

const PurchasedStudioToolbar = (props) => {
  const { t } = useTranslation();
  const { designName, orderId, orderType, historyFrom } = props;

  const navigate = useNavigate();

  const backPageRoute = useMemo(() => {
    if (historyFrom === "techpack") {
      if (orderType === "order") {
        return routeFormatter(ROUTES.ADMIN_TECHPACK_ORDER, {
          params: { orderId: orderId },
        });
      }
      if (orderType === "preorder") {
        return routeFormatter(ROUTES.ADMIN_TECHPACK_PREORDER, {
          params: { preorderId: orderId },
        });
      }
    } else if (historyFrom === "adminpurchased") {
      if (orderType === "preorder") {
        return routeFormatter(ROUTES.ADMIN_PREORDER, {
          params: { preorderId: orderId },
        });
      }
    }
  }, [orderId, orderType, historyFrom]);

  const backPageLabel = useMemo(() => {
    if (historyFrom === "techpack") {
      return t("studio_toolbar.back_to_techpack");
    } else if (historyFrom === "adminpurchased") {
      if (orderType === "preorder") {
        return t("studio_toolbar.back_to_preorder");
      } else {
        return "Return to ???";
      }
    }
  }, [orderType, historyFrom, t]);

  return (
    <React.Fragment>
      <MaterialToolbar disableGutters={true} className="studio-main-header">
        <div className={styles.leftSection}>
          <div className={styles.breadcrumbs}>
            <DarkTooltip title={backPageLabel} followCursor>
              <span
                className={styles.projectTitle}
                onClick={() => {
                  navigate(backPageRoute);
                }}
              >
                {backPageLabel}
              </span>
            </DarkTooltip>
            <span>&nbsp;/&nbsp;</span>
            <span className={styles.designTitle}>{designName}</span>
          </div>
        </div>

        <div className={styles.middleSection}>
          <React.Fragment />
        </div>

        <div className={styles.rightSection}>
          <React.Fragment />
        </div>
      </MaterialToolbar>
    </React.Fragment>
  );
};
export default PurchasedStudioToolbar;
