import styles from "./messageCard.module.scss";
import React, { useCallback, useState } from "react";
import CloseButton from "../../../assets/editor/X.svg";

export const MessageCard = (props) => {
  const { title, titleIcon = null } = props;

  const [visible, setVisible] = useState(true);

  const onClickCallback = useCallback(() => {
    setVisible(false);
  }, [setVisible]);

  return (
    <React.Fragment>
      {visible && (
        <div className={styles.message}>
          <div className={styles.topSection}>
            {title && (
              <span className={styles.titleLine}>
                {titleIcon && (
                  <img src={titleIcon} alt={""} className={styles.icon} />
                )}
                <div className={styles.title}>{title}</div>
              </span>
            )}
            <div className={styles.closeButton} onClick={onClickCallback}>
              <img
                src={CloseButton}
                alt={"Close"}
                className={styles.closeButton}
              />
            </div>
          </div>
          <div className={styles.content}>{props.children}</div>
        </div>
      )}
    </React.Fragment>
  );
};

export default MessageCard;
