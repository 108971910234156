import React from "react";
import DecorationRightSidebar from "./DecorationSidebar";
import ElementsSidebar from "./ElementsSideBar";

const StudioRightSidebar = (props) => {
  const {
    selection: { selectedPart },
  } = props;

  if (selectedPart === "Decoration") {
    return <DecorationRightSidebar {...props} />;
  }

  return <ElementsSidebar {...props} />;
};

export default StudioRightSidebar;
