import _ from "lodash";
import { AddressFormType } from "../../types";
import { Action } from "../../types/redux";

export enum SHIPPING_ACTION_ENUM {
  SET_SAVING = "set_saving",
  UPDATE_LOADING = "update_shipping_address_loading",
  UPDATE_DELIVERY_ADDRESS = "update_shipping_address",
  UPDATE_DELIVERY_ADDRESS_FIELD = "update_delivery_address_field",
}

export type ShippingAddressPayload = {
  shippingAddress: AddressFormType;
  field: keyof AddressFormType;
  value: string;
};

const initialState = {
  isSaving: false,
  shippingAddress: {
    organization: "",
    firstName: "",
    lastName: "",
    address: "",
    appartment: "",
    city: "",
    country: "CA",
    state: "QC",
    zipcode: "",
    phone: "",
    phoneExt: "",
    email: "",
  },
  loading: false,
  loaded: false,
};

const reducer = (
  state = initialState,
  action: Action<SHIPPING_ACTION_ENUM, ShippingAddressPayload>
) => {
  switch (action.type) {
    case SHIPPING_ACTION_ENUM.UPDATE_DELIVERY_ADDRESS:
      return {
        ...state,
        shippingAddress: action.payload,
        loaded: true,
        loading: false,
      };
    case SHIPPING_ACTION_ENUM.UPDATE_LOADING:
      return { ...state, loading: true, loaded: false };

    case SHIPPING_ACTION_ENUM.UPDATE_DELIVERY_ADDRESS_FIELD: {
      const { field, value } = action.payload;

      const newState = { ...state };
      _.set(newState, ["shippingAddress", field], value);

      return newState;
    }
    case SHIPPING_ACTION_ENUM.SET_SAVING:
      return { ...state, isSaving: action.payload };
    default:
      return state;
  }
};

export default reducer;
