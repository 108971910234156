import { Skeleton } from "@mui/material";
import { memo } from "react";
import { Box, ContentRepeater } from "../../../common/components";
import { Styles } from "../../../styles/theme";
import {
  DESIGN_CARD_DESKTOP_MAX_WIDTH,
  DESIGN_CARD_MOBILE_MAX_WIDTH,
} from "./DesignCard";

const styles: Styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    maxWidth: {
      xs: DESIGN_CARD_MOBILE_MAX_WIDTH,
      md: DESIGN_CARD_DESKTOP_MAX_WIDTH,
    },
    position: "relative",
  },
  box: {
    minWidth: "100%",
    minHeight: "148px",
    borderRadius: "12px",
    mb: 1.25,
  },
  text: {
    minWidth: "100%",
    height: "17px",
  },
};

const DesignCardSkeleton = () => {
  return (
    <ContentRepeater repeatCount={3}>
      <Box sx={styles.container}>
        <Skeleton variant="rectangular" sx={styles.box} />
        <Skeleton variant="text" sx={styles.text} />
        <Skeleton variant="text" sx={styles.text} />
      </Box>
    </ContentRepeater>
  );
};

export default memo(DesignCardSkeleton);
