import { Typography } from "@mui/material";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { object, string } from "yup";
import {
  Button,
  CenteredColumnBox,
  Form,
  Input,
  Link,
} from "../../../common/components";
import { Box } from "../../../common/components/Box/Box";
import { useForm } from "../../../common/hooks/useForm";
import { OnboardingTemplate } from "../../../containers/OnboardingContainer/OnboardingTemplate";
import AuthService from "../../../service/authService";
import { spacing } from "../../../styles/theme";
import { Language } from "../../../types";
import { ROUTES } from "../../../utils/Constant";
import { testEmail } from "../../../utils/FormValidationsUtils";
import { getLanguage, setLanguage } from "../../../utils/LocaleUtil";
import { OnboardingTitle } from "../Component/OnboardingTitle";

const validationSchema = object({
  email: string().test(testEmail),
});

export const ResetPassword = () => {
  const { t } = useTranslation();
  const [errorMsg, setErrorMsg] = useState<string | undefined>();
  const [emailSent, setEmailSent] = useState(false);

  const formik = useForm({
    initialValues: { email: "" },
    onSubmit: (values) => {
      onClickReset(values.email);
    },
    validationSchema: validationSchema,
  });

  const onClickReset = (email: string) => {
    const data = { email: email, language: getLanguage() };
    AuthService.resetPasswordLink(data).then((res) => {
      if (res.data.status === "success") {
        setEmailSent(true);
      } else {
        res.data.message === "Invalid Email" && lang === "en"
          ? setErrorMsg(res.data.message)
          : setErrorMsg(t("forgot_password.invalid"));
      }
    });
  };

  const location = useLocation();

  const lang = useMemo(() => {
    const query = new URLSearchParams(location.search);
    let lang = query.get("lang") as Language;
    if (lang) {
      setLanguage(lang);
    } else {
      lang = getLanguage();
    }

    return lang;
  }, [location.search]);

  return (
    <Box>
      <OnboardingTemplate>
        {emailSent ? (
          <CenteredColumnBox>
            <OnboardingTitle title={t("forgot_password.email_sent")}>
              <Typography
                variant="body1"
                fontSize={{ xs: 12, sm: 16 }}
                data-test="resetPassword.confirmationTitle"
              >
                {t("forgot_password.email_sent_description")}
              </Typography>
            </OnboardingTitle>
            <Link to={ROUTES.LOGIN}>{t("forgot_password.back_login")}</Link>
          </CenteredColumnBox>
        ) : (
          <Form formik={formik}>
            <OnboardingTitle title={t("forgot_password.forgot_password")}>
              <Typography variant="body1" fontSize={{ xs: 12, sm: 16 }}>
                {t("forgot_password.forgot_text")}
              </Typography>
            </OnboardingTitle>
            <CenteredColumnBox
              gap={{ xs: spacing.regularMobile, lg: spacing.regularDesktop }}
              fullWidth
            >
              <Input
                type="email"
                name="email"
                className="form-control"
                autoComplete="username"
                fullWidth
                label={t("general.email")}
                error={errorMsg}
                data-test="resetPassword.email"
              />
              <CenteredColumnBox
                gap={{
                  xs: spacing.ctaSpacingMobile,
                  lg: spacing.ctaSpacingMobile,
                }}
                fullWidth
                data-test="resetPassword.submitButton"
              >
                <Button type="submit" fullWidth>
                  {t("general.continue")}
                </Button>
                <Link to={ROUTES.LOGIN}>{t("forgot_password.back_login")}</Link>
              </CenteredColumnBox>
            </CenteredColumnBox>
          </Form>
        )}
      </OnboardingTemplate>
    </Box>
  );
};
