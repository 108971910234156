import styles from "./collapsableMessage.module.scss";
import React from "react";
import classNames from "classnames";

import IconButton from "@mui/material/IconButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import { styled } from "@mui/material/styles";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const Message = (props) => {
  const {
    title,
    message,
    linkText,
    linkUrl,
    openInANewPage = true,
    fullWidth = true,
    alignCenter = true,
  } = props;

  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <React.Fragment>
      <div
        className={classNames(
          styles.CollapsableMessage,
          fullWidth ? styles.FullWidth : null,
          alignCenter ? styles.AlignCenter : null
        )}
      >
        {title && (
          <span onClick={handleExpandClick}>
            <b>{title}</b>
            <ExpandMore
              expand={expanded}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </ExpandMore>
          </span>
        )}
        <Collapse
          in={expanded}
          classes={{
            wrapperInner: classNames(
              styles.CollapsableInnerMessage,
              alignCenter ? styles.AlignCenter : null
            ),
          }}
        >
          <span>{message}</span>
          {linkUrl && (
            <a href={linkUrl} target={openInANewPage ? "_blank" : "_self"} rel="noreferrer">
              {linkText || linkUrl}
            </a>
          )}
        </Collapse>
      </div>
    </React.Fragment>
  );
};

export default Message;
