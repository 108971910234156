import { PropsWithChildren, memo, useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import DashboardToolbar from "../../../components/DashboardToolbar/DashboardToolbar";
import { DashboardContext } from "../../../context/Dashboard";
import { Styles } from "../../../styles/theme";
import { useThemeBreakpoints } from "../../../utils/themeUtils";
import {
  LeftNavigation,
  LeftNavigationMobile,
} from "../../views/LeftNavigation";
import { Box } from "../Box";

const styles: Styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    overflow: "hidden",
  },
  dashboardContainer: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "white",
    height: "100%",
  },
};

interface Props extends PropsWithChildren {
  isMainScreen?: boolean;
}

const AppContainer = ({ children, isMainScreen }: Props) => {
  const { designId } = useParams();
  const { isMobile } = useThemeBreakpoints();

  const [isNavigationDrawerOpen, setIsNavigationDrawerOpen] = useState(false);

  const toggleNavigationDrawerOpen = useCallback(() => {
    setIsNavigationDrawerOpen((prev) => !prev);
  }, [setIsNavigationDrawerOpen]);

  //prevent render when the user is in studio
  if (designId) {
    return children;
  }

  return (
    <DashboardContext.Provider
      value={{
        isNavigationDrawerOpen,
        toggleNavigationDrawerOpen,
      }}
    >
      <Box sx={styles.container}>
        {isMainScreen && <DashboardToolbar />}
        <Box sx={styles.dashboardContainer}>
          {isMainScreen && !isMobile && <LeftNavigation />}
          {children}
        </Box>
        {isMobile && <LeftNavigationMobile />}
      </Box>
    </DashboardContext.Provider>
  );
};

export default memo(AppContainer);
