import { OrderDetail } from "../../types";
import { Action } from "../../types/redux";

export enum ALL_PREORDERS_ACTION_ENUM {
  UPDATE_ALL_PURCHASED_PREORDERS = "update_all_purchased_preorders",
  UPDATE_LOADING = "update_all_purchased_preorders_loading",
}

export interface AllPreOrderPayload {
  preOrders: OrderDetail[];
  count: number;
}

const initialState = {
  preorders: [],
  count: 0,
  loading: false,
  loaded: false,
};

const reducer = (
  state = initialState,
  action: Action<ALL_PREORDERS_ACTION_ENUM, AllPreOrderPayload>
) => {
  switch (action.type) {
    case ALL_PREORDERS_ACTION_ENUM.UPDATE_ALL_PURCHASED_PREORDERS:
      return {
        preorders: action.payload.preOrders,
        count: action.payload.count,
        loading: false,
        loaded: true,
      };
    case ALL_PREORDERS_ACTION_ENUM.UPDATE_LOADING:
      return { ...state, loading: true, loaded: false };
    default:
      return state;
  }
};

export default reducer;
