const initialState = {
  productDefinitions: [],
};

export const PRODUCT_DEFINITIONS_ACTION_ENUM = {
  ADD_PRODUCT_DEFINITION: "add_product_definition",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case PRODUCT_DEFINITIONS_ACTION_ENUM.ADD_PRODUCT_DEFINITION:
      return {
        productDefinitions: [...state.productDefinitions, action.payload],
      };
    default:
      return state;
  }
};

export default reducer;
