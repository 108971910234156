import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import { t } from "i18next";
import { AdminPurchasedOrdersCollapsibleRow } from "./AdminPurchasedOrdersCollapsibleRow";
import {
  AdminPurchasedOrdersColumn,
  AdminPurchasedOrdersRow,
} from "./AdminPurchasedOrders";

interface Props {
  columns: AdminPurchasedOrdersColumn[];
  rows: AdminPurchasedOrdersRow[];
}

export const AdminPurchasedOrdersCollapsibleTable = ({
  columns,
  rows,
}: Props) => {
  return (
    <TableContainer sx={{ height: "100%" }} component={Paper}>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            <TableCell
              key="id"
              align={"left"}
              style={{ minWidth: 50, backgroundColor: "#d6e4f1" }}
            >
              {""}
            </TableCell>
            {columns.map((column) => (
              <TableCell
                key={column.id}
                align={column.align || "center"}
                style={{
                  minWidth: column.minWidth,
                  backgroundColor: "#d6e4f1",
                  paddingLeft: "0px",
                  paddingRight: "0px",
                }}
              >
                {column.label}
              </TableCell>
            ))}
            <TableCell
              colSpan={2}
              key="actions"
              align={"center"}
              style={{ minWidth: 50, backgroundColor: "#d6e4f1" }}
            >
              {t("order.actions.title")}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <AdminPurchasedOrdersCollapsibleRow
              key={row.id}
              row={row}
              columns={columns}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
