import momentUnLocalized from "moment";
import { useTranslation } from "react-i18next";

export const useMoment = () => {
  const { i18n } = useTranslation();

  const moment = (inp?: moment.MomentInput) => {
    const momentLocale = momentUnLocalized(inp);
    momentLocale.locale(i18n.language);
    return momentLocale;
  };

  const formatShortDate = (inp?: moment.MomentInput) => {
    return moment(inp)
      .locale(i18n.language)
      .format(i18n.language === "fr" ? "D MMM, YYYY" : "MMM D, YYYY");
  };

  return { moment, formatShortDate };
};
