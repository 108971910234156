import React, { useCallback, useEffect } from "react";
import { CircularProgress } from "@mui/material";
import AdminTechpack from "../../components/AdminTechpack";
import TechpackToolbar from "../../components/TechpackToolbar";
import Helmet from "react-helmet";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import styles from "./AdminTechpackContainer.module.scss";
import { useTechpackFetcher } from "../../utils/useTechpackFetcher";
import ImageService from "../../service/ImageService";
import TechpackDownloadService from "../../service/TechpackDownloadService";
import { ROUTES } from "../../utils/Constant";
import { useDesignFetcher } from "../../utils/DesignsFetcherHooks";
import fileDownload from "js-file-download";

const AdminTechpackOrder = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { fetchOrderTechpack, fetchPreOrderTechpack, fetchTechpackPdfLink } =
    useTechpackFetcher();
  const { fetchDecorationsOptionsDefinition } = useDesignFetcher();
  const [techpackDetail, setTechpackDetail] = React.useState(null);
  const [isLoaded, setLoaded] = React.useState(false);
  const [isSaving, setSaving] = React.useState(false);
  const { orderId, preorderId } = useParams();

  const [needTechpackRefresh, setNeedTechpackRefresh] = React.useState(true);

  useEffect(() => {
    if (!needTechpackRefresh) {
      return;
    }

    //setLoaded(false);

    //TODO: We should do this in the backend or have a specific service to keep the fabric info!
    const fetchPromise = orderId
      ? fetchOrderTechpack(orderId)
      : fetchPreOrderTechpack(preorderId);

    fetchPromise
      .then(async (techpack) => {
        if (techpack.techpackitems) {
          //TODO: We should load the decorations options at the start of the app...
          //Get decoration Definitions
          const definitions = await fetchDecorationsOptionsDefinition();

          await Promise.all(
            techpack.techpackitems.map(async (techpackItem) => {
              techpackItem.decorationDefinitions =
                definitions.decorationDefinitions;

              await Promise.all(
                techpackItem.techpackitemartworks.map(
                  async (techpackItemArtwork) => {
                    const decoration = techpackItemArtwork.decoration;
                    console.log(decoration);
                    if (
                      decoration.assetId &&
                      decoration.active &&
                      !decoration.inError &&
                      decoration.asset.useColorExtractionAndMatching
                    ) {
                      decoration.fabricColorCodes =
                        await ImageService.getColorCodes(decoration.type);
                    }
                  }
                )
              );
            })
          );
        }
        return techpack;
      })
      .then((techpack) => {
        setTechpackDetail(techpack);
        setLoaded(true);
        setNeedTechpackRefresh(false);
      });
  }, [
    preorderId,
    orderId,
    fetchOrderTechpack,
    fetchPreOrderTechpack,
    fetchDecorationsOptionsDefinition,
    needTechpackRefresh,
  ]);

  const onClickDownload = useCallback(
    async (e) => {
      e.preventDefault();
      const techpackId = techpackDetail.id;
      const techpackFilename =
        "techpack_" + (techpackDetail.project_number || techpackId) + ".zip";
      fetchTechpackPdfLink(techpackId).then((resPdf) => {
        const signedUrl = resPdf.signedUrl;
        TechpackDownloadService.downloadTechpack(signedUrl)
          .then((res) => {
            fileDownload(res.data, techpackFilename);
          })
          .catch((e) => {
            console.error("Unable to download the techpack pdf from the link");
          });
      });
    },
    [techpackDetail, fetchTechpackPdfLink]
  );

  const onClickBackToCustomerOrder = useCallback(
    (e) => {
      e.preventDefault();
      navigate(ROUTES.ADMIN_PURCHASED_ORDERS);
    },
    [navigate]
  );

  return (
    <div className={styles.container}>
      <Helmet>
        <title>{`${t("techpack.title")} - Jameo Studio`}</title>
      </Helmet>
      <TechpackToolbar
        //onClickSave={(e) => {}}
        onClickDownload={onClickDownload}
        //onClickCancel,
        onClickBackToCustomerOrder={onClickBackToCustomerOrder}
        //onDirty,
        actionText={
          !isLoaded
            ? t("techpack.loading")
            : isSaving
              ? t("techpack.saving")
              : t("techpack.saved")
        }
      />
      <div className={styles.dashboardContainer}>
        <div className={styles.centerContainer}>
          {isLoaded ? (
            techpackDetail === null ? (
              <div className="page-title-box">
                <p className="page-title">{t("techpack.not_found")}</p>
              </div>
            ) : (
              <div className="main-inner-content">
                <AdminTechpack
                  techpackDetail={techpackDetail}
                  key={orderId || preorderId}
                  setNeedTechpackRefresh={setNeedTechpackRefresh}
                  setSaving={setSaving}
                />
              </div>
            )
          ) : (
            <div className={styles.loadingProgress}>
              <CircularProgress />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminTechpackOrder;
