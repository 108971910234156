import {
  AutocompleteProps,
  Autocomplete as MUIAutocomplete,
  TextField,
} from "@mui/material";
import { AxiosResponse } from "axios";
import { debounce } from "lodash";
import { useState } from "react";
import { palette } from "../../../styles/theme";
import { MIN_CHAR_AUTO_COMPLETE } from "../../../types/constants";
import { Icon } from "../Icon";

type Option = { value: string; label: string };

interface Props<T>
  extends Omit<
    Omit<AutocompleteProps<T, false, false, false>, "renderInput">,
    "options"
  > {
  apiCall: (value: string) => Promise<AxiosResponse<T[], any>>;
  formatOption?: (item: T) => Option;
  label: string;
}

export const Autocomplete = <T,>({
  apiCall,
  formatOption,
  label,
  ...rest
}: Props<T>) => {
  const [options, setOptions] = useState<T[]>([]);
  const onChange = async (value: string) => {
    if (value?.length >= MIN_CHAR_AUTO_COMPLETE) {
      const res = (await apiCall(value)).data;
      setOptions(res);
    } else {
      setOptions([]);
    }
  };

  const onChangeDebounced = debounce(onChange, 300);

  return (
    <MUIAutocomplete
      {...rest}
      options={options}
      onInputChange={(e, value) => onChangeDebounced(value)}
      renderInput={(params) => <TextField {...params} label={label} />}
      popupIcon={<Icon size={24} color={palette.grey.primary} icon="search" />}
      forcePopupIcon={true}
      sx={{
        ...rest.sx,
        "& .MuiAutocomplete-popupIndicator": { transform: "none" },
      }}
    />
  );
};
