import React, { useMemo } from "react";
import { useTranslation, Trans } from "react-i18next";
import { Message, MessageTypes } from "../../Message";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import { getShortDateFormatter } from "../../../utils/LocaleUtil";

const DeliveryInfoMessage = (props) => {
  const {
    onlySidePaddingAndMargin,
    useShortText,
    minWeeks = 3,
    maxWeeks = 10,
  } = props;

  const { t } = useTranslation();
  // const { i18n } = useTranslation();

  const weeksFromNow = (weeks) => {
    const shortDateFormatter = getShortDateFormatter();
    const weeksFromNow = new Date();
    weeksFromNow.setDate(weeksFromNow.getDate() + 7 * weeks + 5); //+5 is for the validation
    return shortDateFormatter.format(weeksFromNow);
  };

  const from = useMemo(() => {
    return weeksFromNow(minWeeks);
  }, [minWeeks]);

  const to = useMemo(() => {
    return weeksFromNow(maxWeeks);
  }, [maxWeeks]);

  const translationKey = useShortText
    ? "cart.info.delivery_estimate_short"
    : "cart.info.delivery_estimate";

  return (
    <Message
      title={t("cart.info.delivery_estimate.title")}
      message={
        <Trans
          i18nKey={`${translationKey}.message`}
          values={{ from, to, minWeeks, maxWeeks }}
        >
          Estimated delivery date:{" "}
          <strong>
            {{ from }} - {{ to }}
          </strong>{" "}
          ({{ minWeeks }}-{{ maxWeeks }} weeks after design validation)
        </Trans>
      }
      // Uncomment for christmas guarantee
      // linkText={
      //   i18n.exists("cart.info.delivery_estimate.linkText") &&
      //   t("cart.info.delivery_estimate.linkText")
      // }
      // linkUrl={
      //   i18n.exists("cart.info.delivery_estimate.linkUrl") &&
      //   t("cart.info.delivery_estimate.linkUrl")
      // }
      alignCenter={false}
      messageIcon={<LocalShippingOutlinedIcon />}
      messageType={MessageTypes.INFO}
      onlySidePaddingAndMargin={onlySidePaddingAndMargin}
      allOnOneLine={true}
    />
  );
};

export default DeliveryInfoMessage;
