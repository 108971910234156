import styles from "./message.module.scss";
import React from "react";
import classNames from "classnames";

export const MessageTypes = Object.freeze({
  INFO: "info",
  WARNING: "warning",
  ALERT: "alert",
  PLAIN: "plain",
});

export const Message = (props) => {
  const {
    title,
    message,
    linkText,
    linkUrl,
    titleIcon = null,
    messageIcon = null,
    openInANewPage = true,
    fullWidth = true,
    alignCenter = true,
    messageType = MessageTypes.WARNING,
    onlySidePaddingAndMargin = false,
    allOnOneLine = false,
  } = props;

  return (
    <React.Fragment>
      <div
        className={classNames(
          styles.Message,
          fullWidth ? styles.FullWidth : null,
          alignCenter ? styles.AlignCenter : null,
          allOnOneLine ? styles.Horizontal : styles.Vertical,
          messageType === MessageTypes.PLAIN
            ? styles.NoMargins
            : onlySidePaddingAndMargin
              ? styles.OnlySidePaddingAndMargin
              : styles.FullPaddingAndMargin,
          messageType === MessageTypes.WARNING
            ? styles.MessageTypeWarning
            : null,
          messageType === MessageTypes.INFO ? styles.MessageTypeInfo : null,
          messageType === MessageTypes.ALERT ? styles.MessageTypeAlert : null,
          messageType === MessageTypes.PLAIN ? styles.MessageTypePlain : null
        )}
      >
        {title && (
          <span>
            {titleIcon && (
              <img src={titleIcon} alt={""} className={styles.Icon} />
            )}
            <b>{title}</b>
          </span>
        )}
        <div>
          {message && (
            <span>
              {/* {messageIcon && ( */}
              {/* <img src={messageIcon} alt={""} className={styles.Icon} /> */}
              {messageIcon}
              {/* )} */}
              {message}
            </span>
          )}
          {linkUrl && (
            <a href={linkUrl} target={openInANewPage ? "_blank" : "_self"} rel="noreferrer">
              {linkText || linkUrl}
            </a>
          )}
        </div>
        {props.children}
      </div>
    </React.Fragment>
  );
};

export default Message;
