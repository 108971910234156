import classNames from "classnames";
import { Link } from "react-router-dom";
import { ROUTES } from "../../utils/Constant";
import { ReactComponent as JameoLogoSVG } from "./../../assets/images/jameo_logo.svg";
import styles from "./JameoLogo.module.scss";

export enum LOGO_SIZE_ENUM {
  SMALL = "small",
  MEDIUM = "medium",
  LARGE = "large",
}

interface Props {
  size?: LOGO_SIZE_ENUM;
  className?: string;
}

export const JameoLogo = ({
  size = LOGO_SIZE_ENUM.SMALL,
  className,
}: Props) => {
  return (
    <Link to={ROUTES.HOME}>
      <JameoLogoSVG
        className={classNames(styles.logo, styles[size], className)}
      />
    </Link>
  );
};
