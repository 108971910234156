import { Box as MuiBox, BoxProps as MuiBoxProps } from "@mui/material";
import { forwardRef } from "react";

export interface BoxProps extends MuiBoxProps {
  fullWidth?: boolean;
}

export const Box = forwardRef(({ fullWidth, ...props }: BoxProps, ref) => {
  return <MuiBox ref={ref} width={fullWidth ? "100%" : undefined} {...props} />;
});
