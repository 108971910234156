import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { UserInfo, UserOnboardingInfo } from "../types";
import { Language } from "../types/index";

const USER_API_BASE_URL = process.env.REACT_APP_BACKEND_URL;

const ROLES_ENUM = {
  ADMIN: "admin",
  USER: "user",
  DESIGNER: "designer",
};
class AuthService {
  setLocalUser(
    accessToken: string,
    userId: string,
    userEmail: string,
    optNewsletter?: boolean
  ) {
    localStorage.setItem("userInfo", accessToken);
    localStorage.setItem("userId", userId);
    localStorage.setItem("userEmail", userEmail);
  }

  clearLocalUser() {
    localStorage.removeItem("userInfo");
    localStorage.removeItem("userId");
    localStorage.removeItem("userEmail");
  }

  login(credentials: {
    email: string;
    password: string;
    inviteToken?: string | null;
  }) {
    return axios.post(USER_API_BASE_URL + "/api/v1/user/login", credentials);
  }

  resetPasswordLink(data: { email: string; language: Language }) {
    return axios.post(USER_API_BASE_URL + "/api/v1/user/reset_password", data);
  }

  updatePassword(password: string, token: string | undefined) {
    return axios.post(
      USER_API_BASE_URL + `/api/v1/user/update_password/${token}`,
      { password }
    );
  }

  signUp(credentials: UserOnboardingInfo) {
    return axios.post(USER_API_BASE_URL + "/api/v1/user/signup", credentials);
  }

  userExists(email: string) {
    return axios.get(USER_API_BASE_URL + "/api/v1/user/exists/" + email);
  }

  async getUserImageUrl() {
    return await axios.get(
      USER_API_BASE_URL + "/api/v1/user/profileImage",
      this.getAuthHeader()
    );
  }

  getJWT() {
    return localStorage.getItem("userInfo");
  }

  getUserInfo() {
    const jwt = this.getJWT();
    return this.getUserInfoFromJWT(jwt);
  }

  getUserInfoFromJWT(jwt: string | null) {
    const userInfo = jwt ? jwtDecode<UserInfo>(jwt) : null;

    //In case it's a new user not yet logged in, userInfo will be null
    if (userInfo) {
      userInfo.isAdmin = false;
      userInfo.isUser = false;
      userInfo.isDesigner = false;

      if (userInfo.roles) {
        if (
          userInfo.roles.some((roleObj) => roleObj.role === ROLES_ENUM.ADMIN)
        ) {
          userInfo.isAdmin = true;

          userInfo.canAddProject = true;
          userInfo.canRenameProject = true;
          userInfo.canDeleteProject = true;

          userInfo.canAddTeam = true;
          userInfo.canRenameTeam = true;
          userInfo.canDeleteTeam = true;

          userInfo.canAddDesign = true;
          userInfo.canRenameDesign = true;
          userInfo.canDeleteDesign = true;
          userInfo.canMoveDesign = true;
          userInfo.canUpdateDesignStyles = true;
          userInfo.canUpdateDesignColors = true;
          userInfo.canUpdateArtworkPosition = true;
          userInfo.canUpdateArtworkColor = true;
          userInfo.canAddArtwork = true;
          userInfo.canDeleteArtwork = true;
        }

        if (
          userInfo.roles.some((roleObj) => roleObj.role === ROLES_ENUM.DESIGNER)
        ) {
          userInfo.isDesigner = true;

          userInfo.canAddProject = true;
          userInfo.canRenameProject = true;
          userInfo.canDeleteProject = true;

          userInfo.canAddTeam = true;
          userInfo.canRenameTeam = true;
          userInfo.canDeleteTeam = true;

          userInfo.canAddDesign = true;
          userInfo.canRenameDesign = true;
          userInfo.canDeleteDesign = true;
          userInfo.canMoveDesign = true;
          userInfo.canUpdateDesignStyles = true;
          userInfo.canUpdateDesignColors = true;
          userInfo.canUpdateArtworkPosition = true;
          userInfo.canUpdateArtworkColor = true;
          userInfo.canAddArtwork = true;
          userInfo.canDeleteArtwork = true;
        }

        if (
          userInfo.roles.some((roleObj) => roleObj.role === ROLES_ENUM.USER)
        ) {
          userInfo.isUser = true;

          userInfo.canAddProject = true;
          userInfo.canRenameProject = true;
          userInfo.canDeleteProject = true;

          userInfo.canAddTeam = true;
          userInfo.canRenameTeam = true;
          userInfo.canDeleteTeam = true;

          userInfo.canAddDesign = true;
          userInfo.canRenameDesign = true;
          userInfo.canDeleteDesign = true;
          userInfo.canMoveDesign = true;
          userInfo.canUpdateDesignStyles = true;
          userInfo.canUpdateDesignColors = true;
          userInfo.canUpdateArtworkPosition = true;
          userInfo.canUpdateArtworkColor = true;
          userInfo.canAddArtwork = true;
          userInfo.canDeleteArtwork = true;
        }
      } else {
        //Should never happen...
        userInfo.isUser = true;
      }
    }

    // console.debug(userInfo);
    return userInfo;
  }

  getAuthHeader(contentType = "application/json") {
    return {
      headers: { "x-access-token": this.getJWT(), "Content-Type": contentType },
    };
  }

  async logOut() {
    //Segment Analytics
    window.analytics.track("Signed Out");
    window.analytics.reset();

    //Tell the backend that user signed out
    try {
      await axios.post(
        USER_API_BASE_URL + "/api/v1/user/logout",
        {},
        this.getAuthHeader()
      );
    } catch (e) {
      console.error("Error trying to logout from backend server", e);
    }

    //Clear local
    this.clearLocalUser();

    return;
  }

  getAPIBaseUrl() {
    return USER_API_BASE_URL;
  }
}

const instance = new AuthService();
export default instance;
