import DialogTitle, { DialogTitleProps } from "@mui/material/DialogTitle";
import { memo } from "react";
import { Icon } from "../../common/components";

interface Props extends DialogTitleProps {
  onClose: () => void;
}

const DialogTitleWithCloseButton = (props: Props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle
      {...other}
      sx={{
        ...other.sx,
        fontSize: "24px",
        p: 0,
      }}
      variant="title"
    >
      {children}
      {onClose ? (
        <Icon
          icon="close"
          size={24}
          color={"#222222"}
          onClick={onClose}
          data-test="closeModal.icon"
        />
      ) : null}
    </DialogTitle>
  );
};

export default memo(DialogTitleWithCloseButton);
