/**
 *  ButtonGroup only accepts Button, not IconButton, because it forwards some props that other components don't really have
 *  see https://github.com/mui-org/material-ui/issues/17226
 *
 * This alternative allows us to create an IconButton that will work with ButtonGroup
 */
import React from "react";
import IconButton from "@mui/material/IconButton";
import DarkTooltip from "../../components/DarkTooltip";

const IconButtonForButtonGroup = (props) => {
  // intercept props only implemented by `Button`
  const {
    disableElevation,
    fullWidth,
    variant,
    toolTipTitle,
    ...iconButtonProps
  } = props;

  if (toolTipTitle) {
    //For tooltip with disabled buttons, we need a div see https://github.com/mui-org/material-ui/issues/8416
    return (
      <DarkTooltip title={toolTipTitle}>
        <div>
          <IconButton {...iconButtonProps} />
        </div>
      </DarkTooltip>
    );
  } else {
    return <IconButton {...iconButtonProps} />;
  }
};

export default IconButtonForButtonGroup;
