import axios from "axios";
import authService from "./authService";
const USER_API_BASE_URL = process.env.REACT_APP_BACKEND_URL;

const ORDER_BASE_ROUTE = "/api/v1/orders/techpack";
const PREORDER_BASE_ROUTE = "/api/v1/preorders/techpack";
const TECHPACK_BASE_ROUTE = "/api/v1/techpacks";

class TechpackService {
  getTechpackPdfLink(data) {
    return axios.post(
      `${USER_API_BASE_URL}${TECHPACK_BASE_ROUTE}/techpack/generate/pdf`,
      data,
      authService.getAuthHeader()
    );
  }

  getPreOrderTechpack(data) {
    return axios.post(
      `${USER_API_BASE_URL}${PREORDER_BASE_ROUTE}`,
      data,
      authService.getAuthHeader()
    );
  }

  getOrderTechpack(data) {
    return axios.post(
      `${USER_API_BASE_URL}${ORDER_BASE_ROUTE}`,
      data,
      authService.getAuthHeader()
    );
  }

  updateProductionArtworkCalibrationColors(data) {
    return axios.put(
      `${USER_API_BASE_URL}${TECHPACK_BASE_ROUTE}/item/artwork/production/calibration`,
      data,
      authService.getAuthHeader()
    );
  }

  updateProductionArtworkBackgroundColor(data) {
    return axios.put(
      `${USER_API_BASE_URL}${TECHPACK_BASE_ROUTE}/item/artwork/production/background`,
      data,
      authService.getAuthHeader()
    );
  }

  updatePreviewArtworkBackgroundColor(data) {
    return axios.put(
      `${USER_API_BASE_URL}${TECHPACK_BASE_ROUTE}/item/artwork/preview/background`,
      data,
      authService.getAuthHeader()
    );
  }

  updateOriginalArtworkBackgroundColor(data) {
    return axios.put(
      `${USER_API_BASE_URL}${TECHPACK_BASE_ROUTE}/item/artwork/original/background`,
      data,
      authService.getAuthHeader()
    );
  }

  updateProductionArtwork(data) {
    return axios.put(
      `${USER_API_BASE_URL}${TECHPACK_BASE_ROUTE}/item/artwork/production/asset`,
      data,
      authService.getAuthHeader()
    );
  }

  setProjectName(data) {
    return axios.put(
      `${USER_API_BASE_URL}${TECHPACK_BASE_ROUTE}/techpack/project`,
      data,
      authService.getAuthHeader()
    );
  }

  setVersion(data) {
    return axios.put(
      `${USER_API_BASE_URL}${TECHPACK_BASE_ROUTE}/item/version`,
      data,
      authService.getAuthHeader()
    );
  }

  setCustomerName(data) {
    return axios.put(
      `${USER_API_BASE_URL}${TECHPACK_BASE_ROUTE}/techpack/customer`,
      data,
      authService.getAuthHeader()
    );
  }

  setSize(data) {
    return axios.post(
      `${USER_API_BASE_URL}${TECHPACK_BASE_ROUTE}/item/sizes`,
      data,
      authService.getAuthHeader()
    );
  }

  setCustomColorReference(data) {
    return axios.put(
      `${USER_API_BASE_URL}${TECHPACK_BASE_ROUTE}/item/customColorReference`,
      data,
      authService.getAuthHeader()
    );
  }
}

const instance = new TechpackService();
export default instance;
