import { useStore } from "react-redux";
import { useNavigate } from "react-router-dom";
import AuthService from "../../service/authService";
import { ROUTES } from "../../utils/Constant";
import { ALL_TEAMS_ACTION_ENUM } from "../reducers/allTeams";
import { MY_TEAMS_ACTION_ENUM } from "../reducers/myTeams";
import { PROJECT_ACTION_ENUM } from "../reducers/projects";
import { USER_INFO_ACTION_ENUM } from "../reducers/userInfo";

export const useAuth = () => {
  const navigate = useNavigate();
  const { dispatch } = useStore();

  const resetState = () => {
    dispatch({
      type: USER_INFO_ACTION_ENUM.RESET_STATE,
    });
    dispatch({
      type: PROJECT_ACTION_ENUM.RESET_STATE,
    });
    dispatch({
      type: MY_TEAMS_ACTION_ENUM.RESET_STATE,
    });
    dispatch({
      type: ALL_TEAMS_ACTION_ENUM.RESET_STATE,
    });
  };

  const logout = async () => {
    await AuthService.logOut();
    resetState();
    navigate(ROUTES.LOGIN);
  };

  return { logout };
};
