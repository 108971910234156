import React from "react";
import { useTranslation } from "react-i18next";

import styles from "./Footer.module.scss";

const Footer = (props) => {
  const { t } = useTranslation();
  // TODO: Add Jameo logo
  return (
    <div className={styles.container}>
      <span>{t("project.footer")}</span>
    </div>
  );
};

export default Footer;
