import React from "react";
import { components } from "react-select";
import styles from "./MenuList.module.scss";

const MenuList = ({ children, ...props }) => {
  // Order defined in specs
  const rows = [];
  const itemsByRow = 7;
  const colorsCircleOptionComponent = children.filter(
    (c) => !c?.props?.data?.isDeprecated
  );
  for (let i = 0; i < colorsCircleOptionComponent.length; i += itemsByRow) {
    rows.push(colorsCircleOptionComponent.slice(i, i + itemsByRow));
  }

  return (
    <div>
      <components.MenuList {...props}>
        {rows.map((row, index) => (
          <div className={styles.row} key={index}>
            {row}
          </div>
        ))}
      </components.MenuList>
    </div>
  );
};

export default MenuList;
