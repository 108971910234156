import { useCallback, useContext, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";

import { useTeamFetcher } from "../../../../utils/TeamsFetcherHooks";

import { generatePath, useNavigate } from "react-router-dom";
import SearchTeamsModal from "../../../../components/SearchTeamsModal/SearchTeamsModal";
import { DashboardContext } from "../../../../context/Dashboard";
import { spacing } from "../../../../styles/theme";
import { ROUTES } from "../../../../utils/Constant";
import { NavLink } from "../../../components";
import { useSelector } from "../../../hooks/useSelector";

export const SearchTeams = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { toggleNavigationDrawerOpen } = useContext(DashboardContext);
  const { fetchMyTeamsAndUpdateStore } = useTeamFetcher();

  const { addToTeam } = useTeamFetcher();
  const teamsState = useSelector((state) => state.myTeams);
  const myTeams = useMemo(() => {
    return teamsState?.teams;
  }, [teamsState]);

  const [showSearchModal, setShowSearchModal] = useState(false);

  const onSearchCloseClick = () => {
    toggleNavigationDrawerOpen?.();
    setShowSearchModal(false);
  };

  const onClick = useCallback(() => {
    setShowSearchModal(true);
  }, [setShowSearchModal]);

  const onAddToTeam = useCallback(
    async (teamId: string) => {
      const result = await addToTeam(teamId);
      await fetchMyTeamsAndUpdateStore();
      navigate(generatePath(ROUTES.TEAM_PROJECTS, { teamId }));
      return result;
    },
    [addToTeam, navigate]
  );

  return (
    <>
      <NavLink
        onClick={onClick}
        to=""
        content={t("navigation.search_team")}
        startIcon="search"
        style={{ marginRight: spacing.mediumDesktop }}
      />
      <SearchTeamsModal
        open={showSearchModal}
        myTeams={myTeams}
        onClickClose={onSearchCloseClick}
        onAddToTeam={onAddToTeam}
      />
    </>
  );
};
