import { combineReducers } from "redux";
import cart from "../common/reducers/cart";
import designs from "../common/reducers/designs";
import projects from "../common/reducers/projects";
import myTeams from "../common/reducers/myTeams";
import allTeams from "../common/reducers/allTeams";
import userInfo from "../common/reducers/userInfo";
import profile from "../common/reducers/profile";
import myOrders from "../common/reducers/myOrders";
import allOrders from "../common/reducers/allOrders";
import myPreOrders from "../common/reducers/myPreOrders";
import allPreOrders from "../common/reducers/allPreOrders";
import shippingAddress from "../common/reducers/shippingAddress";
import billingAddress from "../common/reducers/billingAddress";
import productDefinitions from "../common/reducers/productDefinitions";

const rootReducer = combineReducers({
  cart,
  designs,
  projects,
  myTeams,
  allTeams,
  userInfo,
  profile,
  myOrders,
  allOrders,
  myPreOrders,
  allPreOrders,
  shippingAddress,
  billingAddress,
  productDefinitions,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
