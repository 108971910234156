import React from "react";
import "./modal.scss";
import Backdrop from "../Backdrop";

const Modal = (props) => {
  const { show, className, closeModal, children } = props;
  return (
    <div>
      <Backdrop show={show} clicked={closeModal} />
      <div
        className={`Modal ${className}`}
        style={{
          display: show ? "block" : "none",
          isOpaqueType: show ? "1" : "0",
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default Modal;
