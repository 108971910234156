import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useTimeSince } from "../../../utils/dateUtils";
import "../design.scss";

import { useTranslation } from "react-i18next";

import styles from "../Design.module.scss";

import DarkTooltip from "../../../common/components/DarkTooltip";
import DesignMenu from "../DesignMenu/DesignMenu";

const DesignWithErrorsCard = (props) => {
  const { t } = useTranslation();

  const { timeSince, dateFormatter } = useTimeSince();

  const { design, project, canDeleteDesign } = props;

  const [updatedTime, setUpdatedTime] = useState("");

  useEffect(() => {
    if (!design.latestUpdate) return;
    let timeInterval = setInterval(() => {
      const timestamp = design.latestUpdate;
      let timeSinceUpdated = timeSince(timestamp);
      if (timeSinceUpdated.includes("second")) {
        timeSinceUpdated = t("time.just_now");
      }
      setUpdatedTime(timeSinceUpdated);
    }, [1000]);
    return () => {
      clearInterval(timeInterval);
    };
  }, [design, t, dateFormatter, timeSince]);

  return (
    <React.Fragment>
      <div className={classNames(styles.container, styles.containerDisabled)}>
        <div className={styles.imgContainer}></div>
        <div className={styles.cardActions}>
          <DarkTooltip title={t("design.design_with_error")} enterDelay={1000}>
            <div className={styles.dataLeft}>
              <span className={styles.title}>
                {t("design.in_error")}{" "}
                {design.name || t("design.untitled_design")}
              </span>
              <span className={styles.time}>{updatedTime}</span>
            </div>
          </DarkTooltip>
          <div className={styles.dataRight}>
            <DesignMenu
              designId={design.id}
              designName={design.name}
              projectId={project.id}
              canDeleteDesign={canDeleteDesign}
              canRenameDesign={false}
              canMoveDesign={false}
              canAddToCart={false}
              canDuplicateDesign={false}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DesignWithErrorsCard;
