import React, { useCallback, useEffect, useState } from "react";
import ImageService from "../../../../service/ImageService";
import { DESIGN_CHANGE_ENUM } from "../../../../utils/ReactHooks";
import { useTranslation } from "react-i18next";
import AssetTagSizeBox from "../../../../components/AssetSizeBox/AssetTagSizeBox";
import AssetColorBox from "../../../../components/AssetColorBox";

const FabricTagSidebar = (props) => {
  const { t } = useTranslation();
  const {
    decoration,
    teamId,
    onDesignChange,
    tagOptionsDef,
    canUpdateArtworkPosition,
    canUpdateArtworkColor,
    setPreviewImageData,
    imageData,
    isAdmin = false,
    hideSizeValue = false,
  } = props;

  useEffect(() => {
    if (decoration?.assetId) {
      ImageService.getTagImage(
        decoration.assetId,
        decoration.assetVersionId,
        decoration.type,
        decoration.tagDetails.tagName,
        decoration.tagDetails.textureName,
        decoration.tagDetails.backgroundColorName,
        decoration.location.selectedLocation.planeAngle
      )
        .then((data) => {
          setPreviewImageData(data);
        })
        .catch(() => {
          setPreviewImageData(null);
        });
    } else {
      setPreviewImageData(null);
    }
  }, [
    decoration?.assetId,
    decoration?.assetVersionId,
    decoration?.type,
    decoration?.tagDetails?.tagName,
    decoration?.tagDetails?.textureName,
    decoration?.tagDetails?.backgroundColorName,
    decoration?.location?.selectedLocation?.planeAngle,
    teamId,
    setPreviewImageData,
  ]);

  // TODO: Use the API route for color codes
  const [colorCodes, setColorCodes] = useState([]);

  useEffect(() => {
    if (imageData && imageData.useColorExtractionAndMatching) {
      ImageService.getColorCodes(imageData.assetType).then((res) => {
        setColorCodes(res);
      });
    }
  }, [imageData]);

  const onAssetBackgroundColorChange = useCallback(
    (color, index, transparent) => {
      return onDesignChange(
        DESIGN_CHANGE_ENUM.SET_DECORATION_TAG_BACKGROUND_COLOR,
        {
          colorName: String(color),
          decorationId: decoration.id,
        }
      );
    },
    [onDesignChange, decoration]
  );

  const onAssetLogoColorChange = useCallback(
    (color, index, transparent) => {
      onDesignChange(DESIGN_CHANGE_ENUM.UPDATE_PRINT_COLOR, {
        color,
        transparent,
        index,
        decorationId: decoration.id,
      });
    },
    [onDesignChange, decoration]
  );

  return (
    <React.Fragment>
      <AssetTagSizeBox
        canUpdateArtworkPosition={canUpdateArtworkPosition}
        canUpdateArtworkColor={canUpdateArtworkColor}
        onDesignChange={onDesignChange}
        decoration={decoration}
        tagOptionsDef={tagOptionsDef}
        hideSizeValue={hideSizeValue}
      />
      <AssetColorBox
        colorBoxTitle={t("specs.background_color")}
        selectedColors={[decoration.tagDetails.backgroundColorName]}
        allowTransparent={false}
        availableColors={colorCodes}
        onColorChanged={onAssetBackgroundColorChange}
        active={true}
        canUpdateArtworkColor={canUpdateArtworkColor}
        isAdmin={isAdmin}
      />
      {imageData && imageData.selectedColors && (
        <AssetColorBox
          colorBoxTitle={t("specs.colors")}
          selectedColors={imageData?.selectedColors.map((c) =>
            c.transparent ? "transparent" : c.chartColor.name
          )}
          allowTransparent={true}
          deprecateTransparency={true} // Remove when the transparency problem with the production is fixed
          availableColors={colorCodes}
          onColorChanged={onAssetLogoColorChange}
          active={true}
          canUpdateArtworkColor={canUpdateArtworkColor}
          isAdmin={isAdmin}
        />
      )}
    </React.Fragment>
  );
};

export default FabricTagSidebar;
