import React, { useMemo } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { generatePath, useNavigate } from "react-router-dom";

import DesignPill from "../../../../common/components/DesignPill";
import { ReactComponent as JameoIcon } from "../../../../assets/images/jameoIcon.svg";
import styles from "../ProjectColumn.module.scss";
import { routeFormatter } from "../../../../utils/Formatter";
import { ROUTES } from "../../../../utils/Constant";

const PurchasedDesignProjectColumn = (props) => {
  const { selectedDesignId, designs, orderType, orderId, historyFrom } = props;

  const navigate = useNavigate();
  const { t } = useTranslation();

  const logoRoute = routeFormatter(ROUTES.HOME, {
    params: {},
  });

  const shownDesigns = useMemo(() => {
    return designs.sort((a, b) => {
      if (a.latestUpdate < b.latestUpdate) {
        return 1;
      } else {
        return -1;
      }
    });
  }, [designs]);

  return (
    <div className={styles.projectColumn}>
      <DesignPill
        className={`${styles.designPill} ${styles.jameoIconPill}`}
        onClick={() => {
          navigate(logoRoute);
        }}
        tooltip={t("studio_toolbar.back_to_home")}
      >
        <JameoIcon className={styles.logo} />
      </DesignPill>

      {shownDesigns.map((d) =>
        //If the design is in error, then we don't allow to use it
        d.errorKey ? (
          <DesignPill
            active={false}
            key={d.id}
            design={d}
            className={classNames(
              styles.designPill,
              styles.designPillDeactivated
            )}
            tooltip={t("design.design_with_error")}
          />
        ) : (
          <DesignPill
            active={selectedDesignId === d.id}
            key={d.id}
            design={d}
            className={styles.designPill}
            tooltip={d.name}
            onClick={() =>
              navigate(
                generatePath(ROUTES.STUDIO_PURCHASED_DESIGN, {
                  designId: d.id,
                  orderId: orderId,
                  orderType: orderType, //Needed for the edit of items
                  historyFrom: historyFrom,
                })
              )
            }
          />
        )
      )}
    </div>
  );
};

export default PurchasedDesignProjectColumn;
