import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

const LocalizedComponent = ({
  component: Component,
  language,
  ...componentProps
}) => {
  const { i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language, i18n]);

  return <Component {...componentProps} />;
};

export default LocalizedComponent;
