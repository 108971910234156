import { createContext } from "react";
import { Specs } from "../../types";

type SpecsContextType = {
  specs?: Specs;
};

export const SpecsContext = createContext<SpecsContextType>({
  specs: undefined,
});
