import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { DESIGN_CHANGE_ENUM } from "../../utils/ReactHooks";
import Select from "../../common/components/Select";

const AssetPositionBox = (props) => {
  const { canUpdateArtworkPosition, onDesignChange, decoration } = props;

  const { t } = useTranslation();

  // LEGACY NO DROPDOWN LABELS
  // //TODO: Add the display for spot taken
  // const availablePositions = useMemo(
  //   () =>
  //     decoration && decoration.assetId
  //       ? decoration.location.availableLocations.map((p) => ({
  //           value: p.positionName,
  //           label: p.inUse
  //             ? `${t(`positions.${p.positionName}`)} (${t("positions.inUse")})`
  //             : p.spotTaken
  //             ? `${t(`positions.${p.positionName}`)} (${t("positions.taken")})`
  //             : p.imageTooSmall
  //             ? `${t(`positions.${p.positionName}`)} (${t(
  //                 "positions.imageTooSmall"
  //               )})`
  //             : t(`positions.${p.positionName}`),
  //           disabled: p.inUse || p.spotTaken || p.imageTooSmall,
  //         }))
  //       : [],
  //   [decoration, t]
  // );

  const groupedAvailablePositions = useMemo(() => {
    if (!decoration || !decoration.assetId) {
      return [];
    }

    const dropdownLabels = decoration.location.availableLocations.map(
      (p) => p.dropdownLabel
    );
    const uniqueDropdownLabels = [...new Set(dropdownLabels)];
    const groupedPositions = [];
    for (const dropdownLabel of uniqueDropdownLabels) {
      const positionsUnderLabel = decoration.location.availableLocations
        .filter((p) => p.dropdownLabel === dropdownLabel)
        .map((p) => ({
          value: p.positionName,
          label: p.inUse
            ? `${t(`positions.${p.positionName}`)} (${t("positions.inUse")})`
            : p.spotTaken
            ? `${t(`positions.${p.positionName}`)} (${t("positions.taken")})`
            : p.imageTooSmall
            ? `${t(`positions.${p.positionName}`)} (${t(
                "positions.imageTooSmall"
              )})`
            : t(`positions.${p.positionName}`),
          disabled: p.inUse || p.spotTaken || p.imageTooSmall,
        }));

      if (dropdownLabel) {
        groupedPositions.push({
          label: `${t(`positionDropdownLabels.${dropdownLabel}`)}`,
          options: positionsUnderLabel,
        });
      } else {
        groupedPositions.push({
          label: "",
          options: positionsUnderLabel,
        });
      }
    }
    return groupedPositions;
  }, [decoration, t]);

  return (
    <React.Fragment>
      <h6>{t("specs.position")}</h6>
      <Select
        options={groupedAvailablePositions}
        onChange={(e) =>
          onDesignChange(DESIGN_CHANGE_ENUM.SET_DECORATION_LOCATION, {
            positionName: e.value,
            decorationId: decoration.id,
          })
        }
        value={{
          value: decoration.location.selectedLocation?.positionName,
          label: decoration.location.selectedLocation?.positionName
            ? t(
                `positions.${decoration.location.selectedLocation?.positionName}`
              )
            : t("positions.select"),
        }}
        disableAll={!canUpdateArtworkPosition}
      />
    </React.Fragment>
  );
};

export default AssetPositionBox;
