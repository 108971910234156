import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { object, string } from "yup";
import {
  Box,
  Button,
  Checkbox,
  Form,
  Icon,
  Input,
  Link,
} from "../../../common/components";
import { useForm } from "../../../common/hooks/useForm";
import { OnboardingContext } from "../../../context";
import { palette, spacing } from "../../../styles/theme";
import { UserOnboardingInfo } from "../../../types";
import { testEmail, testPassword } from "../../../utils/FormValidationsUtils";
import { OnboardingTitle } from "../Component/OnboardingTitle";

interface Props {
  onNextStep: (userInfo: UserOnboardingInfo) => void;
  onPreviousStep: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  userInfo: UserOnboardingInfo;
  setUserInfo: Dispatch<SetStateAction<UserOnboardingInfo>>;
}

const validationSchema = object({
  email: string().test(testEmail),
  firstName: string().required(),
  lastName: string().required(),
  password: string().required().test(testPassword),
});

export const SignUp = (props: Props) => {
  const { t } = useTranslation();
  const { onNextStep, onPreviousStep, userInfo } = props;
  const { errorMsg } = useContext(OnboardingContext);

  const [showPasswordAsText, setShowPasswordAsText] = useState(false);

  const formik = useForm({
    initialValues: userInfo,
    onSubmit: (values) => {
      onNextStep(values);
    },
    validationSchema: validationSchema,
  });

  const togglePasswordVisibility = useCallback(() => {
    setShowPasswordAsText((prev) => !prev);
  }, [setShowPasswordAsText]);

  return (
    <Box>
      <OnboardingTitle title={t("sign_in.create_account")} />
      <Form formik={formik}>
        <Box
          gap={{ xs: spacing.groupingMobile, lg: spacing.groupingDesktop }}
          display="flex"
          flexDirection="column"
          justifyItems="center"
          fullWidth
        >
          <Input
            id="email"
            disabled
            fullWidth
            name="email"
            autoComplete="username"
            label={t("general.email")}
            data-test="signup.email"
            link={
              <Link to="" onClick={onPreviousStep} smallFont>
                {t("sign_in.change_email")}
              </Link>
            }
          />
          <Box
            display="flex"
            gap={{ xs: spacing.groupingMobile, lg: spacing.groupingDesktop }}
            flexDirection={{ xs: "column", lg: "row" }}
          >
            <Input
              type="text"
              label={t("sign_in.first_name")}
              autoComplete="given-name"
              id="given-name"
              name="firstName"
              data-test="signup.firstName"
              autoFocus
            />
            <Input
              type="text"
              label={t("sign_in.last_name")}
              autoComplete="family-name"
              id="family-name"
              name="lastName"
              data-test="signup.lastName"
            />
          </Box>

          <Input
            type={showPasswordAsText ? "text" : "password"}
            label={t("sign_in.password")}
            autoComplete="new-password"
            id="new-password"
            name="password"
            data-test="signup.password"
            fullWidth
            error={errorMsg || undefined}
            endAdornment={
              <Icon
                style={{
                  cursor: "pointer",
                }}
                size={20}
                icon={showPasswordAsText ? "visibility" : "visibility_off"}
                onClick={togglePasswordVisibility}
                color={palette.black.headings}
              />
            }
          />

          <Checkbox
            onChange={(e) => {
              const optNewsletter = e.target.checked;
              formik.setFieldValue("optNewsletter", optNewsletter);
            }}
            sx={{ padding: 0 }}
            checked={formik.values.optNewsletter}
            id="optNewsletter"
            name="optNewsletter"
            label={t("sign_in.optNewsletter")}
            data-test="signup.optNewsletter"
          />
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          gap={{ xs: spacing.ctaSpacingMobile, lg: spacing.ctaSpacingDesktop }}
          mt={{ xs: spacing.regularMobile, lg: spacing.regularDesktop }}
          fullWidth
        >
          <Button type="submit" fullWidth data-test="signup.submitButton">
            {t("sign_in.continue")}
          </Button>
          <Link to="" onClick={(e) => onPreviousStep(e)}>
            {t("onboard.back")}
          </Link>
        </Box>
      </Form>
    </Box>
  );
};
