import {
  IconButton,
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  SxProps,
  Typography,
} from "@mui/material";
import { PropsWithChildren, isValidElement, memo, useState } from "react";
import { Styles, palette } from "../../../styles/theme";
import {
  getConditionnalStyle,
  useThemeBreakpoints,
} from "../../../utils/themeUtils";
import { Icon } from "../../components";

const styles: Styles = {
  accordion: {
    boxShadow: "none",
    width: "100%",
  },
  accordionSummary: {
    minHeight: "unset",
    borderRadius: "4px !important",
    "&>div": {
      margin: 0,
    },
    width: "100%",
    padding: "8px",
    fontStyle: "normal",
    fontWeight: "normal",

    color: "black",
    display: "flex",
    flexDirection: "row",
    cursor: "pointer",

    alignItems: "center",
    gap: "8px",
  },
  accordionDetails: {
    // padding: "0px",
    "& hr": {
      border: "none",
      borderTop: `1px solid ${palette.black.lines}`,
      mx: "28px",
      p: 0,
      my: "0",
    },
    "&>*:not(hr)": {
      p: "8px 28px !important",
    },
    "&>a": {
      "&>div": {
        p: 0,
      },
    },
  },
};

interface Props extends PropsWithChildren {
  summaryText: string | JSX.Element;
  startIcon?: JSX.Element;
  accordionSummarySxProps?: SxProps;
  accordionDetailsSxProps?: SxProps;
}

const Accordion = ({
  summaryText,
  startIcon,
  accordionSummarySxProps,
  accordionDetailsSxProps,
  children,
}: Props) => {
  const [expanded, setExpanded] = useState(false);
  const { isMobile } = useThemeBreakpoints();

  return (
    <MuiAccordion
      sx={styles.accordion}
      disableGutters
      expanded={expanded}
      onChange={() => undefined}
    >
      <MuiAccordionSummary
        expandIcon={
          <IconButton
            disableRipple
            className="accordionExpandButton"
            onClick={(e) => {
              e.preventDefault();
              setExpanded((prev) => !prev);
            }}
            sx={{ p: 0 }}
          >
            <Icon size={isMobile ? 18 : 22} icon="expand_more" />
          </IconButton>
        }
        aria-controls="panel1-content"
        id="panel1-header"
        sx={[
          styles.accordionSummary,
          getConditionnalStyle(
            accordionSummarySxProps,
            accordionSummarySxProps
          ),
        ]}
      >
        {startIcon}
        {isValidElement(summaryText) ? (
          summaryText
        ) : (
          <Typography
            color={palette.grey.nearBlack}
            variant="body2"
            minWidth="100%"
            fontSize={{ xs: 12, lg: 14 }}
            lineHeight={{ xs: "18px", lg: "22px" }}
          >
            {summaryText}
          </Typography>
        )}
      </MuiAccordionSummary>
      <MuiAccordionDetails
        sx={[
          styles.accordionDetails,
          getConditionnalStyle(
            accordionDetailsSxProps,
            accordionDetailsSxProps
          ),
        ]}
      >
        {children}
      </MuiAccordionDetails>
    </MuiAccordion>
  );
};

export default memo(Accordion);
