import React from "react";

import styles from "./StudioLeftSidebar.module.scss";

import LeftSideBar from "../../../common/components/LeftSideBar/LeftSideBar";
import { ModelSwitcher } from "./ModelSwitcher";
import Specs from "./Elements";
import classNames from "classnames";
import Decorations from "./Decorations";
import WorkWithADesignerMessage from "../../../components/WorkWithADesignerMessage";

const StudioLeftSidebar = (props) => {
  const {
    decorations,
    designId,
    productName,
    designFromDesignState,
    onSelectionChange,
    onDesignChange,
    partsDefinition,
    availableDecorationsDefinition,
    selection,
    canUpdateArtworkPosition,
    canAddArtwork,
    canDeleteArtwork,
    deprecatedDesignItems,
    colorBleedingItems,
    siblingProducts,
  } = props;

  return (
    <LeftSideBar>
      <div className={classNames(styles.sidebarNav)}>
        <ul>
          <ModelSwitcher
            siblingProducts={siblingProducts}
            currentProductName={productName}
            onDesignChange={onDesignChange}
          />
          {partsDefinition.map((p, index) => (
            <Specs
              partDefinition={p}
              productName={productName}
              key={index}
              onSelectionChange={onSelectionChange}
              selection={selection}
              designFromDesignState={designFromDesignState}
              deprecatedDesignItems={deprecatedDesignItems?.filter(
                (d) => d.partName === p.name
              )}
              colorBleedingItems={colorBleedingItems?.filter(
                (d) => d.partName === p.name
              )}
            />
          ))}
          {/* Add assets here */}

          <Decorations
            selection={selection}
            decorations={decorations}
            designId={designId}
            onSelectionChange={onSelectionChange}
            onDesignChange={onDesignChange}
            availableDecorationsDefinition={availableDecorationsDefinition}
            canUpdateArtworkPosition={canUpdateArtworkPosition}
            canAddArtwork={canAddArtwork}
            canDeleteArtwork={canDeleteArtwork}
          />
        </ul>
        <div className={classNames(styles.bottom)}>
          <WorkWithADesignerMessage />
        </div>
      </div>
    </LeftSideBar>
  );
};

export default StudioLeftSidebar;
