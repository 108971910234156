import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { ReactComponent as ArrowRight } from "../../../assets/collect_size_success/arrow_right.svg";
import Grid1 from "../../../assets/collect_size_success/grid_1.png";
import Grid2 from "../../../assets/collect_size_success/grid_2.png";
import Grid3 from "../../../assets/collect_size_success/grid_3.png";
import Grid4 from "../../../assets/collect_size_success/grid_4.png";
import Grid5 from "../../../assets/collect_size_success/grid_5.png";
import Grid6 from "../../../assets/collect_size_success/grid_6.png";
import Grid7 from "../../../assets/collect_size_success/grid_7.png";
import Grid8 from "../../../assets/collect_size_success/grid_8.png";
import Grid9 from "../../../assets/collect_size_success/grid_9.png";
import Curve1 from "../../../assets/collect_size_success/curve_1.png";
import Curve2 from "../../../assets/collect_size_success/curve_2.png";
import ProjectService from "../../../service/ProjectService";
import styles from "./CollectSizeSuccess.module.scss";
import { ROUTES } from "../../../utils/Constant";
import { JameoLogo, LOGO_SIZE_ENUM } from "../../../components/JameoLogo";
import { ReactComponent as TadaIcon } from "../../../assets/collect_size_success/tada.svg";

const CollectSizeSuccess = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { token } = useParams();
  // This page will be updated in the next task
  // TODO TYPED that
  const [tokenInfo, setTokenInfo] = useState<any>(null);
  //todo write function to detect if french

  const init = useCallback(async () => {
    const {
      data: { data },
    } = await ProjectService.getCollectSizeInfo(token);

    setTokenInfo(data);
  }, [token]);

  useEffect(() => {
    window.scrollTo(0, 0);
    init();
  }, [init]);
  return (
    <div className={styles.container}>
      <div
        className={styles.header}
        style={{ backgroundImage: `url(${Curve1})` }}
      >
        <JameoLogo className={styles.jameoLogo} size={LOGO_SIZE_ENUM.MEDIUM} />

        <p>
          <TadaIcon />
        </p>
        <h3>{t("collect_size_success.thank_you")}</h3>
        <span>{tokenInfo?.project.name || "Team Swag"}</span>
      </div>
      <div className={styles.cool}>
        <h2>{t("collect_size_success.cool_projects_cool_people")}</h2>
        <p>
          {t("collect_size_success.worked_creators_organizations")}
          <br />
          {t("collect_size_success.lets_make_something_amazing")}
        </p>
        <div>
          <img src={Grid1} alt="grid1" />
          <img src={Grid2} alt="grid2" />
          <img src={Grid3} alt="grid3" />
          <img src={Grid4} alt="grid4" />
          <img src={Grid5} alt="grid5" />
          <img src={Grid6} alt="grid6" />
          <img src={Grid7} alt="grid7" />
          <img src={Grid8} alt="grid8" />
          <img src={Grid9} alt="grid9" />
        </div>
      </div>
      <div
        className={styles.canva}
        style={{ backgroundImage: `url(${Curve2})` }}
      >
        <h2>{t("collect_size_success.pick_a_canva_make_it_sing")}</h2>
        <p>{t("collect_size_success.join_now")}</p>
        <button
          className={styles.signupButton}
          onClick={() => navigate(ROUTES.SIGN_UP)}
        >
          {t("collect_size_success.sign_up_now")} <ArrowRight />
        </button>
      </div>
      <div className={styles.footer}>
        <JameoLogo size={LOGO_SIZE_ENUM.MEDIUM} />
        <span>{t("collect_size_success.all_rights_reserved_privacy")}</span>
      </div>
    </div>
  );
};

export default CollectSizeSuccess;
