import React, { useState } from "react";
import { ProjectTypeList } from "./ProjectTypeList";
import { ABOUT_PROJECT_STEPS } from "../../../utils/Constant";
import { UserOnboardingInfo } from "../../../types";
import { TeamName } from "./TeamName";
import { ProjectSize } from "./ProjectSize";

interface Props {
  onNextStep: (values: UserOnboardingInfo) => void;
  setOnboardingInfo: React.Dispatch<React.SetStateAction<UserOnboardingInfo>>;
  onboardingInfo: UserOnboardingInfo;
  onPreviousStep: (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => Promise<void>;
}

export const AboutProject = ({
  onNextStep,
  onPreviousStep,
  onboardingInfo,
  setOnboardingInfo,
}: Props) => {
  const [currentStep, setCurrentStep] = useState(
    ABOUT_PROJECT_STEPS.PROJEC_TYPE
  );

  const onBack = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    switch (currentStep) {
      case ABOUT_PROJECT_STEPS.PROJEC_TYPE:
        onPreviousStep(e);
        break;
      case ABOUT_PROJECT_STEPS.TEAM_NAME:
        setCurrentStep(ABOUT_PROJECT_STEPS.PROJEC_TYPE);
        break;
      case ABOUT_PROJECT_STEPS.PROJECT_SIZE:
        setCurrentStep(ABOUT_PROJECT_STEPS.TEAM_NAME);
        break;

      default:
        break;
    }
  };

  return (
    <>
      {currentStep === ABOUT_PROJECT_STEPS.PROJEC_TYPE && (
        <ProjectTypeList
          onPreviousStep={onBack}
          setOnboardingInfo={setOnboardingInfo}
          onboardingInfo={onboardingInfo}
          onNextStep={() => setCurrentStep(ABOUT_PROJECT_STEPS.TEAM_NAME)}
        />
      )}
      {currentStep === ABOUT_PROJECT_STEPS.TEAM_NAME && (
        <TeamName
          onPreviousStep={onBack}
          setOnboardingInfo={setOnboardingInfo}
          onboardingInfo={onboardingInfo}
          onNextStep={() => setCurrentStep(ABOUT_PROJECT_STEPS.PROJECT_SIZE)}
        />
      )}
      {currentStep === ABOUT_PROJECT_STEPS.PROJECT_SIZE && (
        <ProjectSize
          onPreviousStep={onBack}
          setOnboardingInfo={setOnboardingInfo}
          onboardingInfo={onboardingInfo}
          onNextStep={onNextStep}
        />
      )}
    </>
  );
};
