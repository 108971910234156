import React, { useMemo } from "react";

import styles from "./DesignPill.module.scss";
import classNames from "classnames";
import DarkTooltip from "../DarkTooltip";

const DesignPill = ({
  active,
  children,
  className,
  design,
  tooltip = "",
  onClick,
}) => {
  const containerClassName = useMemo(() => {
    if (active) {
      return classNames(className, styles.container, styles.active);
    }

    return classNames(className, styles.container);
  }, [active, className]);

  return (
    <DarkTooltip title={tooltip} followCursor>
      <div className={containerClassName} onClick={onClick}>
        {design && !design.errorKey ? (
          <React.Fragment>
            {design?.images?.map((image) => (
              <img
                src={image.url}
                alt={image.side}
                className={styles.img}
                key={image.side}
              />
            ))}
          </React.Fragment>
        ) : (
          <React.Fragment>{children}</React.Fragment>
        )}
      </div>
    </DarkTooltip>
  );
};

export default DesignPill;
