import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";
import { useCurrency } from "../../../utils/Currencies";
import CartItemPreview from "../CartItemPreview";
import styles from "./CartPreview.module.scss";
import config from "../../../config";

const CartPreview = (props) => {
  const { t } = useTranslation();
  const {
    NarrowCurrencyFormatter,
    getCurrencySymbol,
    isCAD,
    isUSD,
    setUSD,
    setCAD,
  } = useCurrency();

  const setUSDCurrency = () => {
    setUSD();
  };

  const setCADCurrency = () => {
    setCAD();
  };

  const { pricingCartData, loaded } = useSelector((state) => state.cart);
  const items = pricingCartData.items;

  const containsDeprecatedDesign = useMemo(() => {
    return (
      (items.map((i) => i?.designState?.design?.deprecated).some((e) => e) &&
        config.blockPaymentWhenDesignIsDeprecated) ||
      false
    );
  }, [items]);

  return (
    <div className={styles.items}>
      {loaded ? (
        items.length > 0 ? (
          items.map((item) => <CartItemPreview {...item} key={item.id} />)
        ) : (
          <div className={styles.emptyCart}>
            <span>{t("checkout.cart_empty")} </span>
          </div>
        )
      ) : (
        <div className={styles.loadingProgress}>
          <CircularProgress />
        </div>
      )}
      <hr className={styles.separator} />

      <div className={styles.total}>
        <div className={styles.rows} key="shipping">
          <div className={styles.shipping}>
            <span>{t("checkout.shipping")}</span>
          </div>
          <div className={styles.price}>
            {!containsDeprecatedDesign &&
            pricingCartData.hasMinimum &&
            pricingCartData.hasMinimumPerSize &&
            !pricingCartData.hasReachedMaximum ? (
              <span>
                {NarrowCurrencyFormatter.format(
                  pricingCartData.priceOfShipping
                )}
              </span>
            ) : (
              <span>
                <b>{t("checkout.tbd")}</b>
              </span>
            )}
          </div>
        </div>
        <div className={styles.rows} key="taxes">
          {t("checkout.taxes")}
          <div className={styles.taxes}>
            <span>{t("checkout.tbd_taxes")}</span>
          </div>
        </div>
        <div className={styles.rows} key="total">
          <b>
            {t("checkout.subtotal")}
            <span>{" (" + getCurrencySymbol() + ") "}</span>
          </b>
          <div className={styles.price}>
            {!containsDeprecatedDesign &&
            pricingCartData.hasMinimum &&
            pricingCartData.hasMinimumPerSize &&
            !pricingCartData.hasReachedMaximum ? (
              <span>
                <b>{NarrowCurrencyFormatter.format(pricingCartData.price)}</b>
              </span>
            ) : (
              <span>
                <b>{t("checkout.tbd")}</b>
              </span>
            )}
          </div>
        </div>
        <div className={styles.rows} key="changeCurrency">
          {isUSD() && (
            <span
              className={styles.currencyLink}
              onClick={setCADCurrency}
              key="setCAD"
            >
              {t("pricing.change_currency_cad")}
            </span>
          )}
          {isCAD() && (
            <span
              className={styles.currencyLink}
              onClick={setUSDCurrency}
              key="setUSD"
            >
              {t("pricing.change_currency_usd")}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default CartPreview;
