import { CircularProgress } from "@mui/material";
import _ from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { usePageTitle } from "../../common/hooks/usePageTitle";
import AdminPurchasedOrders from "../../components/AdminPurchasedOrders/AdminPurchasedOrders";
import Button from "../../components/Button";
import { useOrderFetcher } from "../../utils/OrdersFetcherHooks";
import { usePreOrderFetcher } from "../../utils/PreOrdersFetcherHooks";
import styles from "./AdminOrdersContainer.module.scss";

const AdminOrders = (props) => {
  const { t } = useTranslation();
  usePageTitle(`${t("all_purchased_order.title")} - Jameo Studio`);

  const itemsPerPage = 50;
  const [currentPage, setCurrentPage] = useState(1);
  const [disablePrevButton, setDisablePrevButton] = useState(true);
  const [disableNextButton, setDisableNextButton] = useState(false);
  const [displayPreorders, setDisplayPreorders] = useState(true);

  //At load, fetch all orders to get the latest information
  const { fetchAllPurchasedOrdersAndUpdateStore } = useOrderFetcher();
  const ordersState = useSelector((state) => state.allOrders);

  // Support legacy orders. TODO: When we'll bring them back, we'll need to refactor so that
  // the paging mechanism work with orders too.
  useEffect(() => {
    fetchAllPurchasedOrdersAndUpdateStore();
  }, [fetchAllPurchasedOrdersAndUpdateStore]);

  const orders = useMemo(() => {
    const ordersFromState = ordersState?.orders || [];
    return ordersFromState.map((order) => {
      return {
        type: "order",
        details: order,
        createdAt: order?.order?.created_at,
      };
    });
  }, [ordersState?.orders]);

  const ordersLoaded = useMemo(() => {
    return ordersState?.loaded;
  }, [ordersState?.loaded]);

  const ordersLoading = useMemo(() => {
    return ordersState?.loading;
  }, [ordersState?.loading]);

  //At load, fetch all preorders to get the latest information
  const { fetchPurchasedPreOrdersAndUpdateStore } = usePreOrderFetcher();
  useEffect(() => {
    fetchPurchasedPreOrdersAndUpdateStore(currentPage, itemsPerPage);
  }, [fetchPurchasedPreOrdersAndUpdateStore, currentPage, itemsPerPage]);

  const preordersState = useSelector((state) => state.allPreOrders);

  const preorders = useMemo(() => {
    const preordersFromState = preordersState?.preorders || [];
    return preordersFromState.map((preorder) => {
      return {
        type: "preorder",
        details: preorder,
        createdAt: preorder?.preorder?.created_at,
      };
    });
  }, [preordersState?.preorders]);

  const preordersLoaded = useMemo(() => {
    return preordersState?.loaded;
  }, [preordersState?.loaded]);

  const preordersLoading = useMemo(() => {
    return preordersState?.loading;
  }, [preordersState?.loading]);

  const totalNumberOfPage = useMemo(() => {
    return Math.ceil(preordersState?.count / itemsPerPage) || 1;
  }, [preordersState?.count, itemsPerPage]);

  const setPage = useCallback(
    (pageNumber) => {
      setDisablePrevButton(false);
      setDisableNextButton(false);
      if (pageNumber <= 1) {
        setCurrentPage(1);
        setDisablePrevButton(true);
      } else if (pageNumber >= totalNumberOfPage) {
        setCurrentPage(totalNumberOfPage);
        setDisableNextButton(true);
      } else {
        setCurrentPage(pageNumber);
      }
    },
    [totalNumberOfPage]
  );

  return (
    <div className={styles.centerContainer}>
      <div className={styles.titleContainer}>
        <h4 className={styles.title}>{t("all_purchased_order.title")}</h4>
        <Button
          text={
            displayPreorders
              ? t("all_purchased_order.loadOrders")
              : t("all_purchased_order.loadPreOrders")
          }
          onClick={() => setDisplayPreorders((prevState) => !prevState)}
        />
      </div>
      <div className="main-inner-content">
        {displayPreorders ? (
          !preordersLoading && preordersLoaded ? (
            !_.isEmpty(preorders) ? (
              <AdminPurchasedOrders ordersDetails={preorders} />
            ) : (
              <div className="page-title-box">
                <p className="page-title">{t("order.no_orders")}</p>
              </div>
            )
          ) : (
            <div className={styles.loadingProgress}>
              <CircularProgress />
            </div>
          )
        ) : !ordersLoading && ordersLoaded ? (
          !_.isEmpty(orders) ? (
            <AdminPurchasedOrders ordersDetails={orders} />
          ) : (
            <div className="page-title-box">
              <p className="page-title">{t("order.no_orders")}</p>
            </div>
          )
        ) : (
          <div className={styles.loadingProgress}>
            <CircularProgress />
          </div>
        )}
      </div>
      {displayPreorders && (
        <div className={styles.loadMoreContainer}>
          <Button
            text={t("all_purchased_order.firstPage")}
            onClick={() => setPage(1)}
          />
          <Button
            text={t("all_purchased_order.prev")}
            onClick={() => setPage(currentPage - 1)}
            disabled={disablePrevButton}
          />
          <Button
            text={t("all_purchased_order.next")}
            onClick={() => setPage(currentPage + 1)}
            disabled={disableNextButton}
          />
          <Button
            text={t("all_purchased_order.lastPage")}
            onClick={() => setPage(totalNumberOfPage)}
          />
        </div>
      )}
    </div>
  );
};

export default AdminOrders;
