import React, { useMemo } from "react";
import ReactSelect from "react-select";

import DropdownIndicator from "./DropdownIndicator";
import IndicatorSeparator from "./IndicatorSeparator";
import Input from "./Input";
import Menu from "./Menu";
import Option from "./Option";

const Select = (props) => {
  const { disableAll = false, options } = props;

  //Disable all options if required
  const optionsEnhanced = useMemo(() => {
    return options.map((o) => {
      o.disabled = o.disabled || disableAll;
      return o;
    });
  }, [options, disableAll]);

  const styles = {
    control: (css, state) => ({
      ...css,
      backgroundColor: disableAll ? "#F9FAFB" : "transparent", //can't use Disabled because it won't show the options if isDisabled=true for the control
      borderColor: "#CCC",
      cursor: state.isDisabled ? "not-allowed" : "pointer",
    }),
    option: (css, state) => ({
      ...css,
      color: state.isDisabled ? "#aaa" : "black",
      cursor: state.isDisabled ? "not-allowed" : "pointer",
    }),
  };
  const themeFct = (theme) => {
    return {
      ...theme,
      borderRadius: 4,
      spacing: {
        ...theme.spacing,
        menuGutter: 0,
      },
      colors: {
        danger: "#DE350B",
        dangerLight: "#FFBDAD",
        neutral0: "hsl(0, 0%, 100%)",
        neutral5: "hsl(0, 0%, 95%)",
        neutral10: "hsl(0, 0%, 90%)",
        neutral20: "hsl(0, 0%, 80%)",
        neutral30: "hsl(0, 0%, 70%)",
        neutral40: "hsl(0, 0%, 60%)",
        neutral50: "hsl(0, 0%, 50%)",
        neutral60: "hsl(0, 0%, 40%)",
        neutral70: "hsl(0, 0%, 30%)",
        neutral80: "hsl(0, 0%, 20%)",
        neutral90: "hsl(0, 0%, 10%)",
        primary: "#EEE",
        primary25: "#EEE",
        primary50: "#EEE",
        primary75: "#EEE",
      },
    };
  };
  return (
    <ReactSelect
      {...props}
      components={{
        DropdownIndicator,
        IndicatorSeparator,
        Input,
        Menu,
        Option,
      }}
      isOptionDisabled={(option) => option.disabled}
      styles={styles}
      theme={themeFct}
      options={optionsEnhanced}
    />
  );
};

export default Select;
