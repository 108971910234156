import { ROUTES } from "../../../utils/Constant";
import BackToBreadcrumbs from "../../components/Breadcrumbs/BackToBreadcrumbs";

import styles from "./Checkout.module.scss";

interface Props {
  activeIndex: number;
  leftPanel: JSX.Element;
  rightPanel: JSX.Element;
}

export const CheckoutTemplate = ({
  activeIndex,
  leftPanel,
  rightPanel,
}: Props) => {
  const breadcrumbs = [
    {
      labelKey: "checkout.back_designs",
      to: ROUTES.HOME,
    },
    {
      labelKey: "checkout.my_cart",
      to: ROUTES.CART,
    },
    {
      labelKey: "checkout.delivery",
      to: ROUTES.SHIPPING_ADDRESS,
    },
  ];

  return (
    <div className={styles.container}>
      <div className={styles.centerContainer}>
        <div className={styles.leftPanel}>
          <BackToBreadcrumbs
            breadcrumbs={breadcrumbs}
            activeIndex={activeIndex}
          />
          {leftPanel}
        </div>
        <div className={styles.rightPanel}>{rightPanel}</div>
      </div>
    </div>
  );
};
