import i18next, { i18n } from "i18next";
import { Language, UserInfo } from "../types";
import { setAnalyticsUser } from "./Analytics";

export function isFrench(i18Instance?: i18n) {
  return (i18Instance ?? i18next).language.startsWith("fr");
}

export function isEnglish(i18Instance?: i18n) {
  return (i18Instance ?? i18next).language.startsWith("en");
}

export function setFrench(userInfo: UserInfo | null = null) {
  setLanguage("fr", userInfo);
}

export function setEnglish(userInfo: UserInfo | null = null) {
  setLanguage("en", userInfo);
}

export function toggleLanguage() {
  isFrench() ? setLanguage("en") : setLanguage("fr");
}

export const getLanguage = (): Language => {
  if (i18next.language) {
    return i18next.language.substring(0, 2) as Language;
  } else {
    return "en";
  }
};

export function setLanguage(lang: Language, userInfo: UserInfo | null = null) {
  if (lang === "en" || lang === "fr") {
    i18next.changeLanguage(lang);

    if (userInfo) {
      setAnalyticsUser(userInfo);
    }
  } else {
    console.error(
      "The language " +
        lang +
        " is not 'en' nor 'fr', those are the only 2 accepted languages at the moment"
    );
  }
}

function getNumberFormatter(
  maximumFractionDigits: number,
  style: any = "decimal",
  unit: string | undefined = undefined
) {
  const localCurrency = isFrench() ? "fr-CA" : "en-CA";

  return new Intl.NumberFormat(localCurrency, {
    maximumFractionDigits: maximumFractionDigits,
    style: style,
    unit: unit,
  });
}

export function getInchesFormatter() {
  return getNumberFormatter(2, "unit", "inch");
}

export function reformatTheInches(passedInch: number) {
  const result = passedInch - Math.floor(passedInch) !== 0;
  if (result) {
    const remainder = passedInch % 1;
    const makeOneEight = remainder === 0.125;
    const makeQuarter = remainder === 0.25;
    const makeThreeEight = remainder === 0.375;
    const makeHalf = remainder === 0.5;
    const makeFiveEight = remainder === 0.625;
    const threeQuarter = remainder === 0.75;
    const makeSevenEight = remainder === 0.875;
    const quotient = Math.floor(passedInch);
    const quotientString = quotient === 0 ? "" : quotient.toString();
    if (makeOneEight) {
      const convertDecimalToFraction = quotientString + ' ⅛ "';
      return convertDecimalToFraction;
    }
    if (makeQuarter) {
      const convertDecimalToFraction = quotientString + ' ¼ "';
      return convertDecimalToFraction;
    }
    if (makeThreeEight) {
      const convertDecimalToFraction = quotientString + ' ⅜ "';
      return convertDecimalToFraction;
    }
    if (makeHalf) {
      const convertDecimalToFraction = quotientString + ' ½ "';
      return convertDecimalToFraction;
    }
    if (makeFiveEight) {
      const convertDecimalToFraction = quotientString + ' ⅝ "';
      return convertDecimalToFraction;
    }
    if (threeQuarter) {
      const convertDecimalToFraction = quotientString + ' ¾ "';
      return convertDecimalToFraction;
    }
    if (makeSevenEight) {
      const convertDecimalToFraction = quotientString + ' ⅞ "';
      return convertDecimalToFraction;
    }

    return getTwoDecimalFormatter().format(passedInch) + ' "';
  }
  return Math.floor(passedInch) + ' "';
}

export function inchesToCm(passedInch: number) {
  const cmDecimal = passedInch * 2.54;
  const cmString = cmDecimal.toFixed(1) + " cm";
  // return cmString.replace(".", ",");  // Depending if we want a . or a ,
  return cmString;
}

export function getTwoDecimalFormatter() {
  return getNumberFormatter(2);
}

export function getShortDateFormatter() {
  const localLanguage = isFrench() ? "fr-CA" : "en-CA";

  return new Intl.DateTimeFormat(localLanguage, {
    year: "numeric",
    month: "short",
    day: "numeric",
  });
}
