import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import _ from "lodash";
import { useCallback, useEffect } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useStore } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Routes from "../routes";
import { AxiosInterceptors } from "../routes/AxiosInterceptors";
import "../styles/global.scss";
import { useCart } from "../utils/CartHooks";
import { useCurrency } from "../utils/Currencies";
import { useDesignFetcher } from "../utils/DesignsFetcherHooks";
import { useProjectFetcher } from "../utils/ProjectsFetcherHooks";
import { useTeamFetcher } from "../utils/TeamsFetcherHooks";
import { useUserInfoFetcher } from "../utils/UserHooks";
import SegmentHistoryListener from "./SegmentHistoryListener";
import "./i18n";

import "@stripe/stripe-js"; //Recommended by Stripe to put the import here (see https://github.com/stripe/stripe-js)
import "material-symbols";
import "moment/min/locales";
import { useTranslation } from "react-i18next";
import { useParams, useSearchParams } from "react-router-dom";
import { ErrorFallback } from "../common/components/ErrorFallback/ErrorFallback";
import { useSelector } from "../common/hooks/useSelector";
import { MY_TEAMS_ACTION_ENUM } from "../common/reducers/myTeams";
import { GlobalLoadingProvider } from "../context";
import { SpecsProvider } from "../context/Specs/SpecsProvider";
import { theme } from "../styles/theme";

const App = () => {
  const [searchParams] = useSearchParams();
  const { i18n } = useTranslation();
  const { dispatch } = useStore();
  const params = useParams();
  const langFromParams = searchParams.get("lang");

  //When user will be logged in, userInfos will be set and valid
  //If not, then no point in calling the other APIs
  const userInfos = useSelector((state) => state.userInfo);

  //If userInfos is not set it can be because we are not logged in yet
  //or that the user is using the url directly withhout going through the login page
  //If it's the latter, so we must check if the user is logged in and set the userInfos in the store
  const { initUserInfo } = useUserInfoFetcher();
  useEffect(() => {
    initUserInfo();
  }, [dispatch, initUserInfo]);

  const { initCurrency, getCurrency } = useCurrency();
  useEffect(() => {
    initCurrency();
  }, [initCurrency]);

  const { fetchMyDesignsAndUpdateStore } = useDesignFetcher();
  const initDesigns = useCallback(async () => {
    if (!userInfos || _.isEmpty(userInfos) || !params.projectId) {
      return;
    }

    return fetchMyDesignsAndUpdateStore(params.projectId);
  }, [fetchMyDesignsAndUpdateStore, params.projectId, userInfos]);

  const { fetchMyProjectsAndUpdateStore } = useProjectFetcher();
  const { fetchMyTeamsAndUpdateStore, fetchAllTeamsAndUpdateStore } =
    useTeamFetcher();
  const initTeamsAndProjects = useCallback(async () => {
    if (!userInfos || _.isEmpty(userInfos) || !userInfos.id) {
      return;
    }

    //loading is set to true before the call of project because the sidenav need both teams and projects,
    //and we need to know when both are completed to show to result.
    //if we just call them back to back the loading indicator flicker
    dispatch({
      type: MY_TEAMS_ACTION_ENUM.UPDATE_MY_TEAMS_LOADING,
      payload: { loading: true },
    });

    //Fetch all teams if designer
    if (userInfos.isDesigner) {
      await fetchAllTeamsAndUpdateStore();
    }
    fetchMyProjectsAndUpdateStore();
    fetchMyTeamsAndUpdateStore();
  }, [fetchMyProjectsAndUpdateStore, userInfos]);

  const { fetchCartItemsAndUpdateStore } = useCart();
  const initCart = useCallback(async () => {
    if (!userInfos || _.isEmpty(userInfos) || !userInfos.id) {
      return;
    }

    return fetchCartItemsAndUpdateStore(getCurrency());
  }, [fetchCartItemsAndUpdateStore, userInfos, getCurrency]);

  useEffect(() => {
    initDesigns();
    initTeamsAndProjects();
    initCart();
  }, [initDesigns, initTeamsAndProjects, initCart]);

  useEffect(() => {
    if (langFromParams) {
      i18n.changeLanguage(langFromParams);
    }
  }, [langFromParams]);

  return (
    <ThemeProvider theme={theme}>
      <GlobalLoadingProvider>
        <SpecsProvider>
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <SegmentHistoryListener>
              <Box>
                <CssBaseline>
                  <AxiosInterceptors />
                  <Routes />
                </CssBaseline>
              </Box>
              <ToastContainer
                position="bottom-center"
                pauseOnFocusLoss={false}
                newestOnTop
                style={{ width: "800px" }}
              />
            </SegmentHistoryListener>
          </ErrorBoundary>
        </SpecsProvider>
      </GlobalLoadingProvider>
    </ThemeProvider>
  );
};

export default App;
