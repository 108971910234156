import { Skeleton, Typography } from "@mui/material";
import { memo } from "react";
import { Box } from "../../../../common/components";
import { Styles, palette } from "../../../../styles/theme";

const styles: Styles = {
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
};

const TeammateSkeleton = () => {
  return (
    <Box sx={styles.container}>
      <Box display="flex" alignItems="center" gap={1.25} flexGrow={1}>
        <Skeleton variant="circular" width={40} height={40} />
        <Box display="flex" flexDirection="column">
          <Typography variant="textRegular" color={palette.blue.secondary}>
            <Skeleton width={150} variant="text" />
          </Typography>
          <Typography variant="textSm">
            <Skeleton width={200} variant="text" />
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default memo(TeammateSkeleton);
