import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  Typography,
} from "@mui/material";
import { CSSProperties, PropsWithChildren, useContext, useState } from "react";
import { generatePath, useNavigate } from "react-router-dom";
import { DashboardContext } from "../../../../../context/Dashboard";
import { Styles, palette } from "../../../../../styles/theme";
import { ROUTES } from "../../../../../utils/Constant";
import { usePathActive } from "../../../../../utils/RouterUtils";
import {
  getRotationStyle,
  useThemeBreakpoints,
} from "../../../../../utils/themeUtils";
import { Box, Icon } from "../../../../components";

const styles: Styles = {
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    textDecoration: "none",
  },
  accordion: {
    boxShadow: "none",
    width: "100%",
  },
  accordionSummary: {
    minHeight: "unset",
    borderRadius: "4px !important",
    "&>div": {
      margin: 0,
    },
    width: "100%",
    padding: "8px",
    fontStyle: "normal",
    fontWeight: "normal",

    color: "black",
    display: "flex",
    flexDirection: "row",
    cursor: "pointer",

    alignItems: "center",
    gap: "8px",
    "&:hover": {
      background: palette.blue.paleEffect,
    },
  },
  active: {
    background: palette.blue.pale,
    "&:hover": {
      background: palette.blue.pale,
    },
    "& .accordionExpandButton:hover": {
      background: "#D7EAF9",
    },
  },
  accordionDetails: {
    padding: "0 0 4px 8px",
    display: "flex",
    flexDirection: "column",
    gap: "4px",
  },
};

interface Props extends PropsWithChildren {
  id: string;
  name: string;
}

export const TeamNavLink = ({ id, name, children }: Props) => {
  const [expanded, setExpanded] = useState(false);
  const navigate = useNavigate();
  const { isMobile } = useThemeBreakpoints();
  const { toggleNavigationDrawerOpen } = useContext(DashboardContext);

  const to = generatePath(ROUTES.TEAM_PROJECTS, { teamId: id });

  let isActive = usePathActive(to);
  //TODO find a way to not render an <a> tag inside an <a> tag
  return (
    <Box
      style={styles.container as CSSProperties}
      data-test={`leftNavigation.teams.team.${name}`}
    >
      <Accordion sx={styles.accordion} disableGutters expanded={expanded}>
        <AccordionSummary
          onClick={() => {
            navigate(to);
            toggleNavigationDrawerOpen?.();
          }}
          expandIcon={
            <IconButton
              disableRipple
              className="accordionExpandButton"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setExpanded((prev) => !prev);
              }}
              sx={{ p: 0 }}
            >
              <Icon
                size={isMobile ? 18 : 22}
                icon="expand_more"
                style={{ ...getRotationStyle(expanded) }}
              />
            </IconButton>
          }
          aria-controls="panel1-content"
          id="panel1-header"
          sx={
            {
              ...styles.accordionSummary,
              ...(isActive && styles.active),
            } as CSSProperties
          }
        >
          <Typography
            color={palette.grey.nearBlack}
            variant="actionBoldSmallIcon"
            maxWidth={{ xs: "194px", lg: "167px" }}
            textOverflow={"ellipsis"}
            whiteSpace={"nowrap"}
            overflow={"hidden"}
          >
            {name}
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={styles.accordionDetails}>
          {children}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};
