import { useTranslation } from "react-i18next";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { FullWidthTabs } from "./components/FullWidthTabs";

import DialogTitleWithCloseButton from "../DialogTitleWithCloseButton/DialogTitleWithCloseButton";

import { makeStyles } from "@mui/styles";
import {
  DesignPricing,
  Size,
  SpecsProductPricing,
  UserInfo,
} from "../../types";

interface Props {
  open: boolean;
  onClose: () => void;
  designId: string;
  productName: string;
  designPricing: DesignPricing;
  pricingDef: SpecsProductPricing;
  sizesOption: Size[];
  userInfo: UserInfo;
}

export const CalculatePriceModal = ({
  open,
  onClose,
  designId,
  productName,
  designPricing,
  pricingDef,
  sizesOption,
  userInfo,
}: Props) => {
  const handleClickClose = () => {
    onClose();
  };

  const useStyles = makeStyles((theme) => ({
    dialogPaper: {
      minHeight: "90vh",
      maxHeight: "90vh",
      minWidth: "800px",
      paddingTop: "32px",
      paddingBottom: "32px",
    },
  }));

  const classes = useStyles();

  const { t } = useTranslation();

  return (
    <Dialog
      classes={{ paper: classes.dialogPaper }}
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitleWithCloseButton
        id="customized-dialog-title"
        onClose={handleClickClose}
      >
        {t("pricing.calculate_price")}
      </DialogTitleWithCloseButton>

      <DialogContent sx={{ px: "40px !important" }}>
        {productName && designPricing && (
          <FullWidthTabs
            designId={designId}
            productName={productName}
            sizesOption={sizesOption}
            designPricing={designPricing}
            pricingDef={pricingDef}
            userInfo={userInfo}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};
